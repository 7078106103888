@use '../3-helpers/variables';

.quote-sable-flow {

  sable-input-multiselect {
    sable-contextual-alert {
      .aon-contextual-alert {
        width: 165%;

        @media only screen and (max-width: variables.$site-body-width-S) {
          width: 100%;
        }
      }
    }
  }

  .aon-activity-sableentityintersect {
    sable-entity-intersect-flow-adapter {
      .aon-form-component {
        margin-bottom: 32px;

        > .aon-form-label + .aon-form-radio-group {
          margin-top: 12px;
        }
      }
    }
  }

  sable-forms-flow-adapter {
    sable-input-radio {
      .aon-form-component {
        .aon-form-radio-group {
          .aon-form-radio {
            &:only-child {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}
