@use '../3-helpers/variables';

app-historical-statements {
    .historical-statements-container {
        .form-group {
            .aon-form-input-wrapper {
                .view-button {
                    margin-left: 20px;

                    @media screen and (max-width: variables.$site-body-width-S) {
                        margin-left: 0px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}