@use "../3-helpers/variables";
@use "../3-helpers/mixins";

app-enrolment-summary-control-icon {
    .enroment-summary-control-icon-container {
        display: flex;
        justify-content: flex-end;
        position: sticky;
        z-index: 1;
        top: 40px;
        height: 0px;

        &.has-other-sticky-banner {
            @media screen and (max-width: variables.$site-body-width-L) {
                top: 80px;
            }
        }

        @media only screen and (max-width: variables.$site-body-width-M) {
            &:not(.active-benefits-plans) {
                button {
                    margin-top: 20px;
                }
            }
        }

        button {
            border-radius: 50% !important;
            min-width: 0px !important;
            height: 44px;
            width: 44px !important;
            display: flex;
            justify-content: center;
        }

        @media screen and (min-width: variables.$site-body-width-XXL) {
            margin-right: -200px;
        }
    }
}