@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.plan-card-container {

  .plan {
    &.basic-card {
      margin-bottom: 20px;

      .no-cover-plan {

        .header-text {
          @media screen and (max-width: variables.$site-body-width-S) {
            margin-bottom: 10px;
          }
        }
      }

      &.div-border {
        padding: 31px !important;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding: 19px !important;
        }
      }

      .accordion {
        .expansion-panel {
          border: 0px;
          border-top: 1px solid variables.$expansion-panel-border-top-color;
          border-bottom: 1px solid variables.$expansion-panel-border-bottom-color;
          padding: 20px 0px 20px 0px;

          .header-text {
            font-size: 16px !important;
          }

          i {
            font-size: 16px !important;
          }

          @media screen and (max-width: variables.$site-body-width-S) {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
      }

      .cost-container {

        @media screen and (min-width: variables.$site-body-width-M) {
          p {
            text-align: right;
          }
        }

        .headline-cost {
          font-family: variables.$heading-font-light;
          font-size: 30px;
          line-height: 32px;
        }

        .cost-label {
          margin: 0px 0px 4px 0px;
          color: variables.$cost-label-color;
        }
      }

      .plan-card-spinner-sleeve {
        display: flex;
        margin-right: -32px;
        padding-top: 40px;
        justify-content: center;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
          margin-right: unset;
        }
      }

      .available-amount-container {
        background-color: variables.$plan-card-available-amount-panel-bg-color;
        padding: 20px;
        /*ENSURE INPUTS HAVE TRANSPARENT BACKGROUND AGAINST AVAILABLE AMOUNT CONTAINER WHICH IS COLOURED*/
        .k-numerictextbox,
        button {
          background-color: transparent;
        }

        @media screen and (min-width: variables.$site-body-width-S) {
          .amount-input-container {
            padding-right: 5px
          }

          .calc-button-container {
            padding-left: 5px;
          }
        }
      }

      .grid-divider {
        overflow-x: visible;
        position: relative;
      }

      .grid-divider > [class*='col-']:nth-child(n + 2):after {
        content: '';
        background-color: variables.$tbs-divider-color;
        position: absolute;
        top: 0;
        bottom: 0;

        @media screen and (max-width: variables.$site-body-width-S) {
          display: none;
        }

        @media screen and (min-width: variables.$site-body-width-M) {
          width: 1px;
          height: auto;
        }
      }

      @media screen and (max-width: variables.$site-body-width-S) {

        .available-amount-container {
          background-color: variables.$bg-color-pearl;

          .input-value {
            margin-bottom: 20px;
          }
        }

        .expansion-panel {
          padding: 20px;
        }
      }
    }
  }
}
