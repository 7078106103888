@use '../3-helpers/variables';

.benefit-details {

  .cost-breakdown {
    display: flex;
    margin-bottom: 8px;
    align-items: baseline;
    justify-content: space-between;

    p {
      padding: 0;
      margin: 0;
    }

    span {
      flex: 1;
      border-bottom: 2px dotted #333;
    }

    &.total > p {
      font-family: variables.$main-font-bold;
    }
  }

  .category-name {
    font-size: 16px;
    font-weight: variables.$main-font-weight-bold;
    line-height: 16px;
    color: variables.$paragraph-color-two;
    display: flex;
    align-items: center;

    img {
      width: variables.$benefit-card-icon-width;
      height: variables.$benefit-card-icon-height;
    }
  }
  /*START THIS SECTION SHOULD BE PULLED OUT INTO ITS OWN CSS FILE ULTIMATELY AS IT APPLIES TO THE BENEFIT DETAIL, CTA AND PLAN CARDS*/
  .cost-container {

    @media screen and (min-width: variables.$site-body-width-M) {
      p {
        text-align: right;
      }
    }

    .headline-cost {
      font-family: variables.$main-font-light;
      font-size: 32px;
      line-height: 32px;
      word-break: break-word;

      &.cost-multi-payment-source-value {
        font-size: 20px;
      }
    }

    .cost-label {
      margin: 0px 0px 4px 0px;
      color: variables.$cost-label-color;

      &.has-info-icon {
        display: flex;
        justify-content: right;
        align-items: center;

        @media screen and (max-width: variables.$site-body-width-S) {
          justify-content: left;
        }

        i {
          margin-left: 8px;
        }
      }
    }
  }
  /*END THIS SECTION SHOULD BE PULLED OUT INTO ITS OWN CSS FILE ULTIMATELY AS IT APPLIES TO THE BENEFIT DETAIL, CTA AND PLAN CARDS*/



  .accordion {
    .expansion-panel {
      padding: variables.$benefit-card-accordion-padding;

      i {
        &.fa-download {
          font-size: variables.$benefit-card-accordion-font-size;
        }
      }


      &.accordion-item {
        .accordion-header {
          .header-sleeve {

            .header-text,
            .header-controls i {
              font-size: variables.$benefit-card-accordion-font-size;
            }
          }
        }
      }

      table {

        th,
        td {
          border: 0;
        }

        tr {

          th:not(:first-child),
          td:not(:first-child) {
            text-align: right;
          }
        }

        thead {
          tr {
            background-color: variables.$bg-color-fog;
          }
        }

        tbody {
          tr:nth-child(even) {
            background-color: variables.$bg-color-fog;
          }

          .participants-mobile-view {
            tr {
              background-color: transparent;
            }
          }
        }
      }

      @media screen and (max-width: variables.$site-body-width-S) {
        table {
          thead th:not(:first-child) {
            display: none;
          }

          td,
          th {
            display: block;
          }

          td {
            padding: 0 12px;
          }

          td[data-th] {
            font-family: variables.$main-font-bold;
          }

          tr {

            th:not(:first-child),
            td:not(:first-child) {
              text-align: left;
            }
          }

          .participants-mobile-view {
            .interim-wrapper {
              background-color: variables.$bg-color-fog;
            }
          }
        }
      }
    }
  }

  .pending-approval-wrapper {
    background-color: variables.$secondary-color-lagoon-lighter;
    padding: 20px;
  }

  .basic-card {
    margin-bottom: 20px;

    .button-sleeve {
      margin-top: 20px;
    }
  }
}

.benefit-card-sleeve:last-child {
  .benefit-details {
    .basic-card {
      margin-bottom: 0px;
    }
  }
}

.page-has-balance-cards {
  @media only screen and (max-width: variables.$site-body-width-L) {
    .benefit-card-sleeve:last-child {
      .benefit-details {
        .basic-card {
          margin-bottom: 20px;
        }
      }
    }
  }
}

/*Handle multi instance implementation where benefit-details component is wrapped in an expansion panel*/
.expansion-panel {
  .benefit-details {
    .benefit-details-coverage-display {
      margin-top: 0px !important;
    }
  }
}
