@use '../3-helpers/variables';

.dependents-info {
  &.profile-dependents-container {

    h1 {
      @media screen and (max-width:variables.$site-body-width-S) {
        color: variables.$core-color-blue;
      }
    }

    h5 {
      text-transform: none;
    }

    h3 {
      margin: 0px;
      font-size: 24px !important;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .basic-card {
      padding: 32px;

      .fa-times,
      .fa-edit {
        font-size: 20px;
        color: variables.$icon-default-color;
        cursor: pointer;
        font-weight: variables.$main-font-weight-light;
      }

      p {
        a {
          text-decoration: underline;
          color: variables.$link-default-color;
        }
      }

      @media only screen and (max-width:variables.$site-body-width-S) {



        p.lg {
          font-weight: variables.$main-font-weight-normal;
          font-size: 16px !important;
          line-height: 24px;
          margin-bottom: 16px !important;
        }
      }
    }

  }


  .accordion .expansion-panel {
    border: 0px;
    border-bottom: 0px;
    padding: 0px 0px 0px 0px;
  }

  .dependency-data {
    display: inline-block;

    label {
      margin-bottom: 2px;
    }

    p {
      margin: 0px 0px 20px 0px;
    }

    margin-bottom: 20px;
  }

  .dependency-data-residant {
    label {
      margin-bottom: 2px;
    }

    p {
      margin: 0px 0px 20px 0px;
    }

    margin-bottom: 20px;
    margin-left: 15px;
  }
}

.add-edit-dep {

  .fa-times,
  .fa-edit {
    font-size: 20px;
    color: variables.$icon-default-color;
    cursor: pointer;
    font-weight: variables.$main-font-weight-light;
  }
}