@use '../3-helpers/variables';

.itemlist-page {
  .add-icon-color {
    color: variables.$icon-default-color;
  }

  .entity-card {
    .canine {
      &::before {
        color: variables.$icon-default-color;
        content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
        margin-right: 4px;
      }
    }


    .feline {
      &::before {
        color: variables.$icon-default-color;
        content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
        margin-right: 4px;
      }
    }

    .vehicle {
      &::before {
        color: variables.$icon-default-color;
        content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
        margin-right: 4px;
      }
    }


    .page-content-sleeve {
      padding: 0;
    }

    .entity {
      border: 1px solid variables.$bg-color-gray04;
      background-color: variables.$white;
      border-radius: 8px;
      padding: 20px;

      i.fa-trash-alt {
        cursor: pointer;
      }

      @media screen and (max-width: variables.$site-body-width-S) {
        padding: 12px;
      }

      .checkbox-wrapper {
        margin-bottom: 0;

        .fa-info-circle,
        .fa-edit {
          color: variables.$quote-coverage-selection-border-color;
        }

        i.fa-edit {
          cursor: pointer;
        }

        .sable-label-pair {
          .icon-col {
            display: none;
          }
        }

        label {
          margin-bottom: 0px;
        }

        i+label {
          font-family: variables.$main-font-regular;
          font-weight: variables.$main-font-weight-normal;
          position: relative;
          top: 0;
          padding-left: 11px;
          padding-right: 10px;
          cursor: pointer;
          line-height: 24px;
          font-size: 16px;
          display: block;
        }

        &.disabled {
          opacity: 1;
          cursor: default;

          .fa-info-circle,
          i+label {
            cursor: default;
          }
        }
      }

      &.entity-valid {
        //Placeholder - we do not have a specific border colour for valid entity (just default entity) but we have this class to use should we decide to add one later.
      }

      .aon-form-radio:not(.aon-direction-column):only-child {
        margin-bottom: 0 !important;
      }

      &.selected {
        border: 2px solid variables.$quote-coverage-selection-border-color;
        padding: 20px;

        .aon-form-radio:not(.aon-direction-column):only-child {
          margin-bottom: 20px !important;
        }

        .aon-form-radio:not(.aon-direction-column) input[type=radio]+label:before {
          margin-right: 8px;
        }

        .payment-card-radio:not(.aon-direction-column):only-child {
          margin-bottom: 0 !important;
        }
      }

      .underwriting {
        i.fa-check-circle {
          color: green;
        }

        i.fa-times-circle {
          color: red;
        }

        i.fa-check-tick {
          color: green;
          font-weight: variables.$main-font-weight-normal;
        }

        i.fa-times-close {
          color: red;
        }
      }
    }



    .add-entity {
      background-color: variables.$white;
      border: 1px solid variables.$bg-color-gray04;
      border-radius: 12px;
      padding: 20px 20px 20px 24px;

      @media screen and (max-width: variables.$site-body-width-S) {
        padding: 12px;
      }
    }

    .back-btn {
      display: flex;
      justify-content: center;
    }
  
    .aon-two-part-telephone-input {
      display: flex;
    
      .country-code {
        padding-right: 20px;
      }
    }
  
  }

  input[type=radio]+label:before {
    flex-shrink: 0;
  }

  h5 {
    margin: 0px 0px 0px 0px;
    color: variables.$core-color-blue;
  }

  .xl-para {
    font-family: variables.$main-font-light;
    font-size: variables.$text-size-xl;
    margin: 0;
  }

  //This class is used to wrap sable view contents which can be sent in either div, span or a tags so needs extending to all
  .lg-para {

    div,
    span,
    a {
      font-family: variables.$main-font-light;
      font-size: variables.$text-size-lg;
    }

    display: flex;
    align-items: center;
    margin: 0;

    i {
      @media screen and (max-width: variables.$site-body-width-S) {
        margin-left: 30px;
      }
    }
  }

  .quick-note {
    background-color: variables.$secondary-color-lagoon-lighter;
    padding: 20px;

    i {
      color: variables.$core-color-blue;
    }
  }

  .driverlist {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    .sable-label {
      padding-left: 0;
    }

    .sable-value {
      text-align: right;
      margin-right: 0 !important;
      padding-right: 0;
    }
  }

  .incidentlist {
    justify-content: space-between;
    margin-left: 0;
    margin-right: 0;

    .sable-label {
      padding-left: 0;
    }

    .sable-value {
      text-align: right;
      margin-right: 0 !important;
      padding-right: 0;
    }
  }

  .label-width {
    width: 100%;
  }
}

.underwriting-result {
  .alert {
    border: 0px;
  }
}