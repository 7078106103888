@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/product-article';

.article-page {
    .article-page-content-sleeve {

        @media only screen and (min-width: variables.$site-body-width-L) {
            padding-left: 130px;
            padding-right: 130px;
        }

        .article-details {
            padding-top: 0px;

            .left-nav-display {
                display: none;
            }
        }

        h1 {
            color: variables.$header-color-two;
            margin-bottom: 40px;
            margin-top: 4px;

            @media screen and (max-width: variables.$site-body-width-S) {
                margin-bottom: 20px;
                margin-top: 12px;
            }
        }
    }

    @media screen and (min-width: variables.$site-body-width-M) {
        .col-md-6 {
            flex: 0 0 58.33%;
            max-width: 58.33%;
        }
    }

    .article-page-header-asia {
        display: block;

        .article-page-padding {
            padding-top: 90px;


            img {
                vertical-align: middle;
                margin-right: 4px;
                width: 20px;
                height: 20px;
            }
        }
    }

    .article-page-header {
        display: none;
    }
}