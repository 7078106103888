@use '../3-helpers/variables';

.product-contact-info {
  background-color: variables.$case-study-bg-color;

  .close {
    color: white;
    flex-grow: 1;
    text-align: right;
    margin: 0px;
    opacity: 1;
    font-size: 15px
  }

  @media (min-width: variables.$small_screen_boundary) {
    .product-contact-info {
      margin-left: 5px;
    }

    .close {
      margin-top: 5px;
      margin-right: 20px;
    }
  }
}