@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.basic-card {

  &.password-rules {
    border: 0px;
    box-shadow: 0px 0px 0px;
    padding: 20px !important;
    width: 100%;
    background-color: variables.$password-rules-bg-color;
    border-radius: 4px;
    margin-bottom: 20px;

    .fa-circle {
      color: variables.$password-rules-icon-color !important;
    }

    .fa-check-circle {
      color: variables.$password-rules-icon-success-color !important;
    }

    .fa-times-circle {
      color: variables.$password-rules-icon-failure-color !important;
    }

    .row {
      margin-bottom: 10px;
      margin-left: 0px;
      flex-wrap: nowrap;

      i {
        margin: 0px 4px 0px 0px;
        padding: 0px;
        cursor: default;
        width: auto;
      }

      p {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.inverse-content-block,
.inverse {
  .basic-card {
    &.password-rules {
      background-color: variables.$password-rules-bg-color-inverse;
    }
  }
}

.inverse {
  &.forgot-password {
    .basic-card {
      &.password-rules {
        .fa-check-circle {
          color: variables.$password-rules-icon-success-inverse-color;
        }
      }
    }
  }
}