@use '../3-helpers/_variables';

.registration-core {
	input {
		&#WorkEmailSelected+label::before {
			padding-left: 15px;
		}
	}

	label {
		&.strong {
			font-weight: variables.$main-font-weight-bold !important;
		}
	}

	.WorkEmailSelected {
		display: flex !important;
		word-break: break-all;
		max-width: 360px;
		width: 100%;
	}
}

.registration-modal {
	.registration-sleeve {
		background-image: none;
	}

	.col-md-6 {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.img-sleeve {
		display: none;
	}

	.WorkEmailSelected {
		word-break: break-all;
		max-width: 300px;
		width: 100%;
	}

	input[type=email] {
		width: 80%;
	}
}