@use '../3-helpers/variables';

.additional-events-signpost-widget-content-sleeve-outer {
  .widget-container {
    margin-bottom: 0px;

    .contents {

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &.window-dates {
          font-family: variables.$main-font-bold;
        }
      }
    }

    &.basic-card {
      height: 100%;

      @media only screen and (max-width: variables.$site-body-width-L) {
        margin-bottom: 30px;
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      h3 {
        margin-bottom: 8px;
      }
    }

    .days-left-section {
      margin-bottom: 20px;
      margin-right: 20px;

      @media only screen and (min-width:variables.$site-body-width-M) {
        p {
          font-family: variables.$main-font-light;
          font-size: variables.$text-size-lg;
          line-height: variables.$line-height-lg;
          font-weight: variables.$main-font-weight-light;
        }
      }
    }

    .display-direction {
      flex-direction: column;

      .event-icon {
        margin-bottom: 20px;

        @media only screen and (min-width:variables.$site-body-width-L) {
          margin-bottom: 0px;
        }
      }

      input[type="button"] {
        margin-top: 20px;
      }

      @media only screen and (min-width:variables.$site-body-width-L) {
        flex-direction: row;

        input[type="button"] {
          margin-top: 0px;
        }
      }
    }

    .horizontal-line {
      border-bottom: 1px solid variables.$event-signpost-divider-color;
      margin: 32px 0px;
    }
  }
}