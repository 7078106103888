@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.wrapper-headline-widget-content-sleeve-outer {

  .wrapper-headline-widget {

    .widget-section-heading {
      text-align: center;
      margin-bottom: 40px;

      @media only screen and (min-width: variables.$site-body-width-XL) {
        h2 {
          margin-bottom: 12px;
        }
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        text-align: left;
        margin-bottom: 20px;
      }
    }


    .wrapper-headline-widget-container {
      .row {
        justify-content: center;

        .basic-card {
          display: flex;
          padding: 0px;
          min-height: 216px;

          @media only screen and (max-width: variables.$site-body-width-L) {
            flex-direction: column;
            padding: 0px !important;
          }

          .wrapper-headline-widget-image {
            width: 32%;
            flex-shrink: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;
            border-radius: 7px 0px 0px 7px;

            @media only screen and (max-width: variables.$site-body-width-L) {
              width: 100% !important;
              min-height: 216px;
              border-radius: 7px 7px 0px 0px;
            }

            @media only screen and (max-width: variables.$site-body-width-S) {
              border-radius: 0px;
            }
          }

          .wrapper-headline-widget-text {
            padding: 32px;
            flex-grow: 1;

            @media only screen and (max-width: variables.$site-body-width-L) {
              padding: 20px 20px 32px 20px;
              width: 100%;
              flex-direction: column;
            }

            .wrapper-headline-widget-button {

              app-aon-cta {

                button {
                  margin-right: 20px;

                  @media only screen and (max-width: variables.$site-body-width-S) {
                    margin-right: 0px;
                  }
                }

                &:last-child {
                  button {
                    margin-right: 0px;

                    @media only screen and (max-width: variables.$site-body-width-S) {
                      margin-bottom: 0px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}