@use '../3-helpers/variables';

.app-header-sleeve {
  @media only screen and (min-width: variables.$site-body-width-L) {
    position: relative !important;
  }

  .site-masthead {

    .tbs-logo,
    .client-logo {
      max-width: 200px;
      height: 32px;
    }

    .user-badge {
      border-radius: 50%;
      height: 6px;
      width: 6px;
      position: relative;
      top: -10px;
      right: -2px;
    }

    @media only screen and (max-width: variables.$site-body-width-S) {

      .tbs-logo,
      .client-logo {
        max-height: 28px;
        max-width: 160px;
      }
    }

    @media only screen and (max-width: variables.$site-body-width-L) {

      .tbs-logo {
        height: 32px;
        background: url('/assets/images/footer/aon-logo.svg') no-repeat;
      }

      .client-logo {
        height: 32px;
      }

      .navbar {
        &.navbar-collapsed {
          background-color: variables.$site-header-bg-color;

          .masthead-title {
            max-width: 200px;
            color: inherit;
            margin-right: 0px;
          }
        }
      }

      .logo-container {
        span {
          font-size: 0.75rem;
        }
      }

      button {
        &.navbar-toggler {
          min-width: 50px;

          &:hover,
          &:active,
          &:focus {
            i:before {
              float: none;
            }
          }
        }
      }
    }
  }
}

.nav-dropdown-list {
  list-style: none;
  padding-left: 0px !important;
}