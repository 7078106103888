@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/login';

.login {
  .login {
    &.inverse {
      .express-login {
        .message {
          p {
            color: variables.$alert-contextual-text-color;
          }
        }

        .email-info {
          color: variables.$white;
        }
      }
    }
  }
}