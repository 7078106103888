@use '../3-helpers/variables';
@use '../3-helpers/mixins';

h2 {
    &#category-heading {
        margin-bottom: 12px;
        margin-top: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
            margin-bottom: 8px;
            margin-top: 20px;
        }
    }
}

.category-product-list {
    .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .col-sm-12,
    .col-md-4 {
        padding-right: 10px;
        padding-left: 10px;
    }

    .basic-card {
        min-height: 100%;

        .middle-align {
            align-items: center;
        }

        h5 {
            @media only screen and (max-width: variables.$site-body-width-S) {
                color: variables.$header-color-four;
            }
        }

        .sm-prod-title {
            font-weight: variables.$main-font-weight-bold;
        }

        .category-icon {
            width: 48px;
            height: 48px;
            margin-right: 12px;

            @media only screen and (max-width: variables.$site-body-width-S) {
                width: 36px;
                height: 36px;
                margin-right: 8px;
            }
        }

        .cat-prods {
            .basic-card {
                padding: 20px;
                align-items: center;
                border-radius: 4px;

                &:hover {
                    background-color: variables.$bg-color-gray08;
                    border-color: variables.$core-color-teal-dark;
                }
            }

            .cat-prod-header {
                display: flex;
                align-items: center;

                .product-icon {
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    align-self: flex-start;

                    @media only screen and (max-width: variables.$site-body-width-S) {
                        width: 28px;
                        height: 28px;
                        margin-right: 8px;
                    }
                }

                h5 {
                    color: variables.$core-color-teal-dark;
                }
            }

            .m-b-prod-card {
                margin-top: 20px;

                @media only screen and (max-width: variables.$site-body-width-S) {
                    margin-bottom: 12px;
                    margin-top: 0px;
                }

                &.col-md-4 {
                    .basic-card {
                        @media only screen and (min-width: variables.$site-body-width-XL) {
                            padding: 20px 20px 28px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
        width: auto;
        margin-bottom: 80px;
    }

}