@use '../3-helpers/variables';

.app-content-sleeve {

  .page {

    &#client-content-page {

      .page-content-sleeve {
        padding-top: 40px;
        padding-bottom: 120px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 40px;
        }
      }

      .button-container {
        button {
          margin-right: 20px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-right: 0px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
