@use '../3-helpers/variables';
@use '../3-helpers/mixins';

/*Navbar Styling*/

.site-masthead {

  /*Masthead Nav bar in mobile mode*/
  @media only screen and (max-width: variables.$site-body-width-L) {
    .navbar {
      ul.navbar-nav {

        li.nav-item,
        .nav-item-dropdown {
          &.nav-li {
            a {
              &:hover {
                color: variables.$masthead-profile-text-color;
                background-color: variables.$masthead-profile-text-hover-bg-color !important;
                text-decoration: underline;
              }
            }

            &.nav-li-login-link {
              border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
            }
          }
        }
      }
    }
  }
}