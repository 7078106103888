@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.login {
  .login-sleeve {
    background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles7.svg);
    background-position: bottom left, top 222px right;
    background-repeat: no-repeat, no-repeat;
    background-size: 250px 204px, 207px 227px;
    padding-top: 40px;
    padding-bottom: 150px;

    .page-content-sleeve {
      @include mixins.inline-image($left: -117px, $top: -10px);
    }

    span.spinner-border {
      color: variables.$core-color-navy;
    }
  }

  &.inverse {

    a:focus-visible,
    button:focus-visible {
      outline: 2px solid variables.$bg-color-gray06 !important;
    }
  }

  .icon-margin {
    font-size: 16px;
    line-height: 36px;
    right: 28px;
    width: 19px;
  }

  .alert.banner.error p {
    color: variables.$paragraph-color-one;
  }

  @media only screen and (max-width: 564px) {
    .login-sleeve {
      background-image: url(/assets/images/account/pebbles5.svg);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 125px 102px;

      .page-content-sleeve {
        background-image: none;
      }
    }
  }
}

.express-login {
  .modal-body {
    .resend-code {
      font-size: 16px;

      a {
        color: variables.$core-color-marine;
        font-size: 16px;
      }

      & .btn {
        padding: 0;
        border: 0;
      }
    }
  }
}

.express-registration,
.express-login,
.registration-core {

  /** Checkbox disabled state */
  input[type="checkbox"]:disabled+label::before {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
}

.login-continue-as-guest {
  white-space: nowrap;
}

.ff-inherit{
  font-family: inherit !important;
}
.clr-inherit{
  color: inherit !important;
}