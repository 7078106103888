@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.pct-content-sleeve-outer {

  .pc-abs-img-1 {
    @include mixins.inline-image($right: 7%, $top: -150px);


    @media only screen and (max-width: variables.$site-body-width-S) {
      @include mixins.inline-image($right: 0px, $top: -60px);
    }
  }

  .pc-abs-img-2 {
    @include mixins.inline-image($left: 7%, $bottom: -125px);

    @media only screen and (max-width: variables.$site-body-width-S) {
      @include mixins.inline-image($left: 0px, $bottom: -60px);
    }
  }

  .page-content-sleeve {

    .pc-container {

      p {
        @include mixins.paragraph ($fontfamily: variables.$main-font-light,
          $fontsize: variables.$text-size-xl,
          $lineheight: 32px,
          $margin: 0px 0px 20px 0px);

        @media only screen and (max-width: variables.$site-body-width-S) {
          @include mixins.paragraph;
        }
      }


      .pc-header {
        text-align: center;
        margin-bottom: 40px;

        h2 {
          margin-bottom: 12px;
        }
      }

      .pc-cols {

        @media only screen and (min-width: variables.$site-body-width-S) {
          .pc-col-0 {
            padding-left: 0px;
          }

          .pc-col-2 {
            padding-right: 0px;
          }
        }
      }

      .button-sleeve {
        z-index: 1;
      }



      @media only screen and (max-width:variables.$site-body-width-S) {

        button {
          width: 100%;
        }


        .pc-header {
          text-align: left;
          margin-bottom: 12px;

          h2 {
            margin-bottom: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}