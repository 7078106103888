@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.pp-content-sleeve-outer {
  background-image: url('/assets/images/home/shared-container-backgrounds/home-dots1-lg.svg'), url('/assets/images/home/shared-container-backgrounds/graywaves1-xlarge.svg');
  background-position: -113px 56px, bottom left;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;

  @media only screen and (max-width: variables.$site-body-width-L) {
    //TODO, add small background image.
  }



  .page-content-sleeve {

    .ppc-container {


      .intro-text-container {

        h2 {
          margin-bottom: 12px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-bottom: 20px;
          }
        }

        p {
          @include mixins.paragraph ($fontfamily: variables.$main-font-light,
            $fontsize: variables.$text-size-xl,
            $lineheight: 32px,
            $margin: 0px 0px 20px 0px);

          @media only screen and (max-width: variables.$site-body-width-S) {
            @include mixins.paragraph;
            padding-left: 0px;
          }

          &.sm {
            color: variables.$app-paragraph-color-two;
            font-size: variables.$text-size-sm;
            line-height: 18px;
            margin: 0px 0px 12px 0px;
          }
        }

        h5 {
          color: variables.$app-header-color-one;

          &:before {
            content: url('/assets/images/template/pp-ribbon-lg.svg');
            margin-right: 10px;
            width: 18px;
            height: 24px;
            vertical-align: middle;
          }
        }

        .text-shamrock {
          background: variables.$app-color-shamrock-shadow;
          border-radius: 4px;
        }
      }

      .card-container {
        margin-top: 32px;

        @include mixins.inline-image($left: 882px, $top: 48%, $height: 653px, $width: 460px);

        .row {
          justify-content: center;
          margin-left: -10px;
          margin-right: -10px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            justify-content: initial;
            margin-left: -15px;
            margin-right: -15px;
          }

          .card-wrapper {
            z-index: 1;


            .basic-card {
              justify-content: space-between;
              height: 100%;

              .content-bottom {
                justify-content: space-between;
                height: 100%;
              }

              .list-style {
                ul {
                  @include mixins.paragraph;
                  padding-left: 1rem;
                  padding-top: 1rem;
                  margin: 0;

                  @media only screen and (max-width: variables.$site-body-width-S) {
                    padding-top: 20px;
                  }

                  li {
                    margin-bottom: 8px;
                    word-break: break-word;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }


                .price-info {

                  hr {
                    border-top: 1px solid variables.$bg-color-shadow;
                  }

                  .details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h1 {
                      &.text-end {
                        font-size: 40px;
                        line-height: 44px;
                      }
                    }
                  }
                }
              }
            }
          }

          button {
            margin-top: 20px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-top: 12px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}