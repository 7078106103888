@use '../3-helpers/variables';

.aon-announcement {
  background-color: variables.$inverse-container-color !important;
  padding: 20px 25px 20px 20px;


  @media only screen and (max-width: variables.$site-body-width-M) {
    padding: 16px 45px 16px 40px;
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    padding: 16px 25px 16px 20px;
  }

  .aon-announcement-container {
    display: flex;
    justify-content: space-between;

    .announcement-content {
      color: variables.$white !important;
      display: flex;
    }

    i {

      &.announcement-icon {
        color: variables.$white !important;
        margin-right: 8px;
      }

      &.fa-times {
        color: variables.$white !important;
        margin-left: 26px;
      }
    }

    p {
      margin: 0px;

      @media only screen and (max-width: variables.$site-body-width-M) {
        font-weight: variables.$main-font-weight-bold;
        font-size: variables.$text-size-sm;
        line-height: variables.$text-size-lg;
      }
    }
  }
}

.product-promo-banner {
  position: relative;

  .product-promo-banner-container {

    div {
      display: flex;
      padding: 20px 25px 20px 20px;
      border-bottom: 1px solid variables.$white;

      i {
        color: variables.$core-color-blue;
        margin-right: 8px;

        &.fa-times {
          color: variables.$icon-color-two;
          margin-left: 8px;
        }
      }

      span {
        flex-grow: 1;
      }
    }
  }
}