@use '../3-helpers/variables';

.mfa {
  &.inverse {
    @media only screen and (min-width: variables.$site-body-width-M) {
      background-size: cover;
      background-repeat: no-repeat;
      width: 100%;
    }

    .mfa-sleeve {
      background-repeat: no-repeat;
    }
  }
}

.mfa-section {
  .select-options {

    .left-spaces {
      margin-left: 5px;
    }

    @media screen and (max-width:variables.$site-body-width-S) {
      span.text-format {
        margin-left: 35px;
      }
    }

    @media screen and (min-width: variables.$site-body-width-S) and (max-width:variables.$site-body-width-L) {
      span.text-format {
        margin-left: 60px;
      }
    }
  }

  .enter-code {

    @media screen and (max-width:variables.$site-body-width-S) {
      .header-title {
        display: block;
      }
    }
  }

  .resend-code-wrapper {
    align-items: center;

    @media screen and (max-width:variables.$site-body-width-S) {
      flex-direction: column;
      align-items: initial;
    }

    input {
      margin-right: 0;
      margin-bottom: 20px;

      @media screen and (min-width:variables.$site-body-width-S) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }
  }

  a {
    &.btn {
      border: none;
    }
  }
}

.skinny-footer-active {
  .mfa {
    &.inverse {
      .mfa-sleeve {
        min-height: calc(100vh - 156px);
      }
    }
  }
}