@use "../3-helpers/variables";


.resources-masthead-dropdown,
.masthead-products-sleeve {
  padding: 20px 120px;

  h4 {
    color: variables.$masthead-resource-header-color;
  }
}

.profile-dropdown {
  background-color: variables.$masthead-profile-bg-color;
  left: unset !important;
  right: 0px;
  padding: 0px;
  border-radius: 0px;
  border: none;

  .profile-dropdown-menu {
    width: 300px;

    @media screen and (max-width: variables.$site-body-width-L) {
      width: 100%;
    }

    a {
      color: variables.$masthead-profile-text-color;
      padding-top: 10px;
      padding-bottom: 10px;

      &:hover,
      &:active,
      &:focus {
        color: variables.$masthead-profile-text-color;
        background-color: variables.$masthead-profile-text-hover-bg-color !important;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width: variables.$site-body-width-L) {
  .profile-dropdown {
    background-color: variables.$masthead-profile-bg-color;

    .profile-dropdown-menu {
      a {
        color: variables.$link-color-three;

        &:hover,
        &:active,
        &:focus {
          background-color: variables.$masthead-profile-text-hover-bg-color !important;
          text-decoration: underline;
        }
      }
    }
  }
}

.masthead-products-sleeve {
  .masthead-product-contents-sleeve {
    background-repeat: no-repeat;
    padding: 10px 0px 10px 100px;
    cursor: pointer;

    p:hover,
    h5:hover {
      text-decoration: underline;
    }

    h5 a {
      color: variables.$masthead-dropdown-authenticated-bg-color;

      &:hover {
        text-decoration: underline;
        color: variables.$masthead-product-header-color;
      }

      &:active {
        text-decoration: underline;
        color: variables.$masthead-product-header-active-color;
      }
    }

    h5 .title {
      font-weight: 900;
    }

    p a {
      color: inherit;
      font-family: inherit;

      &:hover {
        text-decoration: underline;
        color: inherit;
      }

      &:active {
        text-decoration: underline;
        color: inherit;
      }
    }
  }
}

.masthead-products-sleeve-category {

  padding: 28px 130px 40px 130px;

  @media screen and (max-width: variables.$site-body-width-L) {
    padding: 20px 20px 0px 20px;
  }

  .category-column {
    margin-left: -10px;

    @media screen and (max-width: variables.$site-body-width-L) {
      margin-left: 0px;
      margin-bottom: 20px;
    }

    .category-icon {
      min-height: 36px;
      min-width: 36px;
      cursor: pointer;
      margin-right: 12px;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin-right: 20px;
      }
    }

    p.lg {
      @media screen and (max-width: variables.$site-body-width-S) {
        font-weight: variables.$main-font-weight-bold;
      }
    }

    .category-products {
      a {
        min-height: 0px !important;
      }

      p {
        font-weight: variables.$main-font-weight-bold;
        text-decoration: underline;

        @media screen and (max-width: variables.$site-body-width-L) {
          a {
            color: variables.$link-inverse-default-color !important;
          }
        }
      }
    }

    .m-l-ss {
      margin-left: 56px;
    }
  }

  h5 a {
    color: variables.$link-color-three;

    @media screen and (max-width: variables.$site-body-width-L) {
      color: variables.$masthead-profile-text-color;
    }
  }

  h3 {

    @media screen and (max-width: variables.$site-body-width-L) {
      color: variables.$masthead-profile-text-color;
    }
  }
}

@media only screen and (max-width: variables.$site-body-width-L) {
  .product-content {
    margin-bottom: 10px;
  }

  .products-dropdown {

    background: variables.$masthead-dropdown-authenticated-bg-color !important;

    h5,
    p {
      color: variables.$masthead-profile-text-color;
    }

    a {
      color: rgba(255, 255, 255, 0.87);
    }
  }

  .masthead-products-sleeve {
    .masthead-product-contents-sleeve {
      background-size: 40px;
      background-repeat: no-repeat;
      padding: 0px 0px 0px 56px;
      cursor: pointer;
    }
  }
}