@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/1-pages/home';

.page {

  &#home {

    .aon-notification-banner {
      .banner_light_blue {
        background-color: variables.$secondary-color-lagoon-lighter !important;
        border-bottom: 1px solid variables.$white;

        .announcement-content {
          color: variables.$paragraph-color-one !important;
        }

        a {
          color: variables.$paragraph-color-one !important;
        }
      }

      .banner_dark_blue {
        background-color: variables.$inverse-container-color !important;
        border-bottom: 1px solid variables.$white;

        .announcement-content {
          color: variables.$white !important;
        }

        a {
          color: variables.$white !important;
        }
      }
    }


  }
}