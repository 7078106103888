@use '../3-helpers/variables';

.sq-content-sleeve-outer {
  .saved-quotes-container {
    .custom-accordion {
      .header-sleeve {
        .header-text {
          h5 {
            color: variables.$header-color-four;
          }
        }
      }
    }
  .flex-col-1 {
        flex: 1 1 0;
      } 

    .summary-sleeve {
      margin-bottom: 20px;

      .summary-heading {
        margin-bottom: 16px;
      }

      .sable-label-pair {
        font-size: variables.$text-size-sm;
        line-height: variables.$text-size-lg;
        color: variables.$label-text-color;
      }

      .sable-label {
        font-weight: variables.$main-font-weight-bold;
      }

      .accordion {
        .expansion-panel.accordion-item .accordion-header .header-sleeve {

          .header-text,
          .header-controls,
          .fal {
            color: variables.$expansion-panel-text-color;
          }
        }

        .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded {

          .header-text,
          .header-controls {
            margin-bottom: 10px;
          }
        }
      }
    }

    .data-metric-m {
      color: variables.$core-color-navy;
    }

    .basic-card {
      padding: 32px;

      .accordion .expansion-panel.accordion-item .accordion-body {
        color: variables.$expansion-panel-text-color !important;
      }

      .createdInfo {
        h6 {
          text-transform: none;
        }
      }

      .dropdown-divider {
        border-top: 0px;
      }
    }

    .quote-value {
      text-align: right;
    }

    p {
      &.quote-expires {
        background-color: variables.$feedback-color-warning-lighter;
        padding: 2px 10px 2px 10px;
        border-radius: 2px;
      }
    }

    @media screen and (max-width: variables.$site-body-width-S) {
      .basic-card {
        border-radius: 0px;
        padding: 20px;
      }

      .quote-value {
        text-align: left;

        h4 {
          font-size: 30px;
          font-weight: variables.$main-font-weight-light;
        }
      }
    }

    sable-dataview {
      .sable-label-pair {

        .icon-col {
          margin-right: 5px;
          flex-shrink: 0;
        }

        &.about-you {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/quote-summary-user.svg');
            }
          }
        }

        &.dog {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/quote-dog-icon.svg');
            }
          }
        }

        &.cat {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/quote-cat-icon.svg');
            }
          }
        }

        &.vehicles {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/auto-med-marine.svg');
            }
          }
        }

        &.drivers {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/driver-sm-marine.svg');
            }
          }
        }

        &.incidents {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/incidents-sm-marine.svg');
            }
          }
        }

        &.properties {
          .icon-col {
            width: 24px;
            height: 21px;

            &::before {
              color: variables.$icon-default-color;
              content: url('../../../assets/images/quote-item-list/homeowners-med-marine.svg');
            }
          }
        }
      }
    }

    .fa-trash-alt {
      cursor: pointer;
    }
  }
}