@use '../3-helpers/mixins';
@use '../3-helpers/variables';


.input_label {
  font-family: variables.$main-font-regular;
  color: variables.$input-title-text-color;
  font-size: 16px;
  font-weight: variables.$main-font-weight-bold;
  line-height: 20px;
  margin-bottom: 4px;
}

.input_content {
  font-family: variables.$main-font-regular;
  color: variables.$input-content-text-color;
  font-size: 16px;
  line-height: 20px;
  font-weight: variables.$main-font-weight-normal;
  margin-bottom: 0px;
}