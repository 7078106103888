@use '../3-helpers/variables';

.quote-bar {
  &.page-content-sleeve {
    margin: 0 auto;
    height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1200px) {
      width: 100%;
    }

    //Below 1600px our center container takes up 100% available space with padding left and right of 40px
    @media only screen and (max-width: variables.$site-body-width-XXL) {
      padding-left: 40px;
      padding-right: 40px;
    }

    //Below 575px reduce our center container padding to 20px
    @media only screen and (max-width: variables.$site-body-width-S) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.progress-bar-head {
  //fixed to relative
  position: relative;
  left: 0px;
  width: 100%;
  z-index: 2;

  @media only screen and (min-width: variables.$site-body-width-L) {
    //sticky to relative
    position: sticky;
    top: 0;
  }

  @media screen and (max-width: variables.$site-body-width-M) {
    font-weight: variables.$main-font-weight-bold;
  }

  .content {
    display: flex;
    align-items: center;

    .product-icon {
      color: variables.$icon-color-one;
      margin: 6px 8px 6px 0px;

      @media screen and (max-width: variables.$site-body-width-M) {
        margin: 6px 4px 6px 0px;
      }

      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;

        @media screen and (max-width: variables.$site-body-width-M) {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.progress-crumb {
      flex-grow: 1;
    }

    &.progress-crumb,
    &.progress-info {
      color: variables.$breadcrumb-main-color;
    }

    @media screen and (max-width: variables.$site-body-width-M) {
      font-size: 12px !important;
    }

    a {
      color: variables.$breadcrumb-main-color;

      span {
        color: #46535E;
        line-height: 20px;
      }
    }

  }
}

.progress-content {

  @media only screen and (max-width: variables.$site-body-width-L) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media only screen and (min-width: variables.$site-body-width-S) and (max-width: variables.$site-body-width-L) {
    margin-left: -20px;
  }

  .aon-progress-bar {
    .quote-progress-bar {
      padding-left: 0px;
      list-style: none;
      position: relative;
      display: flex;
      justify-content: space-between;
      margin: 0rem;

      p {
        margin: 0px 0px 0px 0px;
      }

      .quote-progress-bar-step {
        width: 100%;
        line-height: 20px;

        .step-not-start {
          font-size: variables.$text-size-sm;
          font-weight: variables.$main-font-weight-bold;
          line-height: variables.$text-size-lg;
          color: variables.$paragraph-color-two;

          i {
            color: variables.$paragraph-color-two;
          }
        }

        .step-finished {
          color: variables.$paragraph-color-three;
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          font-weight: variables.$main-font-weight-bold;

          i {
            color: variables.$paragraph-color-three;
          }
        }

        .step-selected {
          font-size: variables.$text-size-sm;
          font-weight: variables.$main-font-weight-bold;
          line-height: variables.$text-size-lg;
          color: variables.$link-hover-color;

          i {
            font-weight: variables.$main-font-weight-normal;
            color: variables.$expansion-panel-header-hover-color;
          }
        }

        .step-in-flight-name {
          color: variables.$paragraph-color-three;
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          font-weight: variables.$main-font-weight-bold;

          i {
            color: variables.$paragraph-color-three;
          }
        }

        .step-bypassed-name {
          font-size: variables.$text-size-sm;
          font-weight: variables.$main-font-weight-bold;
          line-height: variables.$text-size-lg;
          color: variables.$paragraph-color-two;

          i {
            color: variables.$paragraph-color-two;
          }
        }

        .quote-circle {
          height: 22px;
          width: 22px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;

          .fal,
          .far,
          .fas {
            cursor: default;
          }
        }

        .step-finished-circle {
          font-weight: variables.$main-font-weight-normal;
          line-height: 24px;
          background-color: variables.$paragraph-color-three;
          color: variables.$white;
          border: 2px solid variables.$paragraph-color-three;

          .fal,
          .far,
          .fas {
            font-size: variables.$text-size-sm;
            color: variables.$white !important;
          }
        }

        .step-selected-circle {
          font-weight: variables.$main-font-weight-bold;
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          background-color: variables.$white;
          color: variables.$paragraph-color-three;
          border: 2px solid variables.$paragraph-color-three;
        }

        .step-not-start-circle {
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          background-color: variables.$white;
          color: variables.$paragraph-color-two;
          border: 1px solid variables.$paragraph-color-two;
        }

        .step-in-flight-circle {
          font-weight: variables.$main-font-weight-bold;
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          background-color: variables.$white;
          color: variables.$paragraph-color-three;
          border: 2px dotted variables.$paragraph-color-three;
        }

        .step-bypassed-circle {
          font-size: variables.$text-size-sm;
          line-height: variables.$text-size-lg;
          background-color: variables.$white;
          color: variables.$paragraph-color-two;
          border: 1px solid variables.$paragraph-color-two;
        }

        .step-name {
          cursor: default;
          font-family: variables.$main-font-regular;

          &.step-clickable {
            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    &.horizontal {
      position: relative;
      width: 100vw;
      left: 0px;
      z-index: 1;

      .progress-bar-hl {
        display: inline;
        border-top: 4px solid variables.$paragraph-color-three;
        z-index: 1;
      }

      .accordion {
        .progressbar-expansion-panel {
          border: 0px;
          width: 100vw;
          padding: 0 20px;

          &.accordion-item {
            .accordion-header {
              background-color: transparent;
              border: 0px;
              padding: 0px;
              color: variables.$expansion-panel-header-color;

              &:hover {
                cursor: pointer;
              }

              .progressbar-header-sleeve {
                display: flex;
                align-items: center;
                min-height: 54px;
                background-color: transparent;
                padding-bottom: 0;

                i {
                  font-size: 20px;
                }

                .progress-bar-header-text {
                  flex-grow: 1;
                  font-size: 12px;
                  line-height: 16px;
                  overflow: hidden;

                  .progress-bar-gauge {
                    display: flex;
                    align-items: center;
                    position: relative;
                    margin-top: 10px;
                    margin-bottom: 11px;

                    div.img,
                    span.img {
                      position: absolute;
                      left: 0px;

                      &.dotted-bar {
                        background-image: url("../../../assets/images/progressbar/progress-bar-dotted-line--horizontal.png");
                        width: 100%;
                        height: 3px;
                        background-repeat: repeat-x;
                        background-size: contain;
                      }
                    }
                  }

                  .progress-bar-step-sleeve {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &.focus-visibility {
                      outline-offset: -2px;
                    }

                    @media only screen and (max-width: variables.$site-body-width-L) {
                      align-items: flex-start;
                    }

                    .progress-bar-steps {
                      .quote-progress-bar {
                        .quote-progress-bar-step {
                          span {
                            margin-left: 0px;
                          }

                          .step-name {
                            font-family: variables.$main-font-bold;
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                }

                &:not(.collapsed) {

                  .progress-bar-header-text,
                  .progress-bar-header-controls {
                    color: variables.$expansion-panel-header-color;
                  }
                }

                &:hover {

                  .progress-bar-header-text,
                  .progress-bar-header-controls {
                    color: variables.$expansion-panel-header-color;
                  }
                }
              }
            }

            .accordion-body {
              padding: 0px;

              .progressbar-expansion-panel-text {

                .step-name {
                  font-family: variables.$main-font-bold;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .bg-default-color {
        background-color: variables.$progress-horizontal-sleeve-bg-color;
      }

      .bg-white {
        background-color: variables.$white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
      }
    }

    &.vertical {
      position: sticky;
      top: 80px;
      margin-bottom: 100px;

      @media screen and (min-width: variables.$site-body-width-L) {
        top: 40px;
      }

      .quote-progress-bar-step {
        .quote-progress-bar-substep-background {
          background-image: url(../../../assets/images/progressbar/progress-bar-dotted-line--vertical.svg);
          background-repeat: repeat-y;
          height: 24px;
          background-size: 2px;
          margin-top: 1px;
        }

        .expansion-panel {
          border: none;
          padding: 0;
        }


        .accordion-item {
          background-color: transparent;

          .accordion-header {
            background-color: transparent;

            .header-sleeve {
              &:not(.collapsed) {
                margin-bottom: 0px;
                padding-bottom: 0px;
              }

              .header-text {
                flex-grow: inherit;
              }
            }

            i {
              font-size: 20px;
              vertical-align: middle;
            }
          }
        }
      }

      .progress-bar-vl {
        border-left: 3px solid variables.$paragraph-color-three;
        height: 44px;
      }

      .progress-bar-vl-substep {
        border-left: 3px solid variables.$paragraph-color-three;
        height: 24px;
      }

      .last-line {
        height: 55px !important;
      }

      .progress-bar-vl-dotted {
        background-image: url(../../../assets/images/progressbar/progress-bar-dotted-line--vertical.svg);
        background-repeat: repeat-y;
        height: 38px;
        background-size: 2px;
        margin-top: 2px;
      }
    }
  }
}



//Progress bar Color treatments used in quote/enrollment flow

.Shamrock {
  .progress-bar-bg-color {
    background-color: variables.$app-color-shamrock-light;
  }
}

.Tangerine {
  .progress-bar-bg-color {
    background-color: variables.$app-color-tangerine-light;
  }
}

.Turquoise {
  .progress-bar-bg-color {
    background-color: variables.$app-color-turquoise-light;
  }
}


.quote-progress-bar-sub-steps {
  p {
    font-size: variables.$text-size-xs;
    line-height: 16px;
  }

  .sub-steps {
    &:last-child {
      margin-bottom: 34px;
    }
  }

  .sub-step-selected {
    color: variables.$link-hover-color;
    font-size: variables.$text-size-xs;
    font-weight: variables.$main-font-weight-bold;
    line-height: 16px;
  }

  .sub-step-finished {
    color: variables.$paragraph-color-three;
    font-size: variables.$text-size-xs;
    line-height: 16px;
  }

  .sub-step-clickable {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}