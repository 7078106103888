@use '../3-helpers/variables';

.pcv-content-sleeve-outer {
  //Have to use a PNG rather than SVG here as we are applying a bgcolor and SVGs with bgcolor present a bug in webkit browsers
  //where a 1px horizontal line is visible at certain viewport sizes.
  background-image: url(../../../assets/images/product/product-compare/pet-gray-wave-bkgd-lg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: variables.$inverse-container-color;

  @media only screen and (max-width:variables.$site-body-width-S) {

    background-image: url(../../../assets/images/product/product-compare/pet-gray-wave-bkgd-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: variables.$inverse-container-color;
  }



}

.product-coverage {

  .pc-item {

    .pc-content {
      .pc-header {
        text-align: center;

        p {
          font-family: variables.$main-font-light;
          font-size: 21px;
          line-height: 32px;
          margin: 0px 0px 32px 0px;
        }
      }

      h3 {
        margin-bottom: 20px;
        text-align: center;
      }

      h2 {
        margin-bottom: 12px;
      }

      h5, .h5 {
        margin-top: 40px;
        text-align: center;
      }

      button {
        margin-bottom: 40px;
        margin-top: 40px;
      }


      .pc-content-item {
        p {

          i.fa-check {
            color: variables.$feedback-color-success-darker;
            margin-right: 10px;
            pointer-events: none;
          }

          i.fa-times {
            color: variables.$feedback-color-error-darker;
            margin-right: 10px;
            pointer-events: none;
          }

          display: flex;
          align-items: baseline;

          span.dotted {
            text-decoration: underline;
            text-decoration-style: dashed;
            text-underline-position: under;
          }
        }
      }
    }

    @media only screen and (max-width:variables.$site-body-width-S) {

      .pc-content {
        .pc-header {
          text-align: left;

          p {
            font-family: variables.$main-font-regular;
            font-size: 16px;
            font-weight: variables.$main-font-weight-normal;
            line-height: 24px;
            margin: 0px 0px 16px 0px;
          }
        }

        h3 {
          text-align: left;
        }

        h5 {
          max-width: 226px;
          width: 100%;
          text-align: left;
        }

        button {
          margin-bottom: 20px;
          position: relative;
          width: 100%
        }
      }
    }
  }
}