@use '../3-helpers/variables';


.ls-content-sleeve-outer {

  .ls-widgets {

    .ls-widgets-container {

      .basic-card {
        height: 100%;

        @media only screen and (max-width: variables.$site-body-width-L) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 20px;
        }
      }


      @media only screen and (max-width: variables.$site-body-width-S) {
        h3 {
          margin-bottom: 8px;
        }
      }

      .vl {
        border-left: 1px solid variables.$bg-color-shadow;
      }
    }
  }
}