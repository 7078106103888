@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.page {

  &#article {

    .progress-crumb {
      label {
        display: block;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .page-content-sleeve {

      .page-title {
        margin-bottom: 0px;
        margin-top: 20px;
      }

      &.article-content-breadcrumb {
        padding-top: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
        }
      }

      &.article-content {
        padding-top: 20px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }

      }

      &.article-coverage {
        padding-top: 80px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }


      &.related-articles {
        padding-top: 80px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.faq {
        padding-top: 80px;
        padding-bottom: 80px;

        .faqs-content {

          .faq-header {
            text-align: center;
          }
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;

          .faqs-content {
            .faq-header {
              text-align: left;
            }
          }
        }
      }
    }

    .sleeve-outer {
      &:last-child {
        .page-content-sleeve {
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-bottom: 60px;
          }
        }
      }
    }

    .faq-content-sleeve-outer {
      background-color: variables.$article-faq-background-color;
    }
  }

  &#article,
  &#article-dashboard,
  &#campaign {
    #download {

      @media screen and (max-width: variables.$site-body-width-S) {
        justify-content: unset;
        margin-top: 20px;
      }

      .download-heading {
        justify-content: start;

        .header {
          margin-bottom: 8px;
          text-transform: capitalize;
        }
      }

      .download-link {
        display: flex;
        justify-content: start;
        width: fit-content;

        .fa-download {
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
  }
}