@use '../3-helpers/variables';
@use '../3-helpers/mixins';


html {
  background-color: variables.$site-canvas-color;

  body {

    .app-header-sleeve {
      @media only screen and (min-width: variables.$site-body-width-L) {
        position: relative;
      }

      @import "template_masthead";
    }

    .app-content-sleeve {
      @media only screen and (min-width: variables.$site-body-width-L) {
        margin-top: 0;
      }
    }
  }
}