@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.app-content-sleeve {

  .quote-sleeve {
    background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
    background-repeat: no-repeat;
    background-size: cover; //DO NOT CHANGE THIS
    background-position: top;
    min-height: calc(100vh - 504px);

    @media screen and (max-width: variables.$site-body-width-S) {
      background-image: url(../../../assets/images/product/graywaves4-xsm.svg);
    }

    .quote-container {
      .page-content-sleeve {
        &.main {
          .row {
            &.quote-flow {
              justify-content: space-between;

              .aon-progress-bar {
                &.vertical {
                  padding-top: 40px;
                }
              }

              .quote-flow-content,
              .summary-content,
              .review-purchase-right {
                padding-top: 40px;

                @media screen and (max-width: variables.$site-body-width-L) {
                  padding-top: 0px;
                }

                .edit {
                  i {
                    font-size: 18px;
                    cursor: pointer;
                  }
                }
              }

              .review-purchase-right {
                .hidden-summary-detail {
                  display: none !important;
                }
              }

              .quote-flow-content {
                padding-bottom: 40px;


                .aon-static-content {
                  &.aon-form-header {
                    margin-bottom: 20px;
                  }
                }

                .term-period-dp {
                  .input-date-picker {
                    height: 44px;
                    border-radius: 0.25rem;
                  }
                }

                .aon-activity-sableentityintersect {
                  sable-entity-intersect-flow-adapter {
                    .aon-form-component {
                      margin-bottom: 32px;

                      > .aon-form-label + .aon-form-radio-group {
                        margin-top: 12px;
                      }
                    }
                  }
                }

                sable-forms-flow-adapter {
                  sable-input-radio {
                    .aon-form-component {
                      .aon-form-radio-group {
                        .aon-form-radio {
                          &:only-child {
                            margin-bottom: 0 !important;
                          }
                        }
                      }
                    }
                  }
                }
              }

              sable-flow {
                .container {
                  &.header {
                    h3 {
                      @include mixins.heading($fontfamily: variables.$heading-font-bold, $fontsize: 44px, $lineheight: 56px);
                    }
                  }

                  sable-form {
                    form {
                      .aon-auto-width {
                        width: 100%;

                        @media screen and (max-width: variables.$site-body-width-S) {
                          width: 100%;
                        }
                      }

                      @media screen and (max-width: variables.$site-body-width-S) {
                        width: 100%;
                      }

                      &.aon-form-full-width {
                        width: 100%;
                      }
                    }
                  }
                }

                .almost-done-bg-color {
                  .edit-state {
                    background: none;
                    border-radius: none;
                    box-shadow: none;
                    border: none;
                    padding: 0px;
                  }
                }
              }

              app-claim-flow {
                sable-flow {
                  .container {
                    &.header {
                      p {
                        font-size: variables.$text-size-lg;
                        line-height: 28px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .q-r-s-payperperiod {
          line-height: 19px;
          color: variables.$paragraph-color-one;
        }

        .benefit-perpay {
          display: inline-block;
          padding-bottom: 20px;
        }

        .summary-sleeve {
          color: variables.$label-text-color;

          a {
            text-decoration: none;
          }

          .fal,
          .far {
            color: variables.$icon-default-color;
          }

          .summary-heading {
            color: variables.$app-header-color-one;
          }

          .left-pane {
            .container {
              .row {
                &.summary {
                  .summary-col {
                    padding-left: 0px;

                    .accordion {
                      .sable-label-pair {
                        .icon-col {
                          display: none;
                        }
                      }

                      .expansion-panel {
                        margin-top: 12px;
                      }

                      .header-sleeve.expanded {
                        padding-bottom: 0px;
                      }
                    }

                    .sable-label-pair {
                      .icon-col {
                        width: 24px;
                        margin-right: 5px;
                        flex-shrink: 0;
                      }

                      &.header,
                      &.strong {

                        .sable-label,
                        .sable-value {
                          font-family: variables.$main-font-bold;
                          line-height: 20px;
                        }
                      }

                      &.about-you {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-summary-user.svg");
                          }
                        }
                      }

                      &.vehicles {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
                          }
                        }
                      }

                      &.drivers {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-driver-icon.svg");
                          }
                        }
                      }

                      &.incidents {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-accident-icon.svg");
                          }
                        }
                      }

                      &.properties {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-home-icon.svg");
                          }
                        }
                      }

                      &.cat {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
                          }
                        }
                      }

                      &.dog {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
                          }
                        }
                      }

                      &.studentloanassistance {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-savings-icon.svg");
                          }
                        }
                      }

                      &.job-title {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-job-icon.svg");
                          }
                        }
                      }

                      &.highest-degree {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-diploma-icon.svg");
                          }
                        }
                      }

                      &.sla-piggy-bank {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$icon-default-color;
                            content: url("../../../assets/images/quote-item-list/quote-savings-icon.svg");
                          }
                        }
                      }

                      &.health-screening-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/health-screening-sm-marine.svg");
                          }
                        }
                      }

                      &.cancer-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/cancer-sm-marine.svg");
                          }
                        }
                      }

                      &.critical-illness-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/critical-illness-sm-marine.svg");
                          }
                        }
                      }

                      &.dental-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/dental-sm-marine.svg");
                          }
                        }
                      }

                      &.hospital-cash-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/hospital-cash-sm-marine.svg");
                          }
                        }
                      }

                      &.medical-protect-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/medical-protect-sm-marine.svg");
                          }
                        }
                      }

                      &.parent-care-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/parent-care-sm-marine.svg");
                          }
                        }
                      }

                      &.personal-accident-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/personal-accident-sm-marine.svg");
                          }
                        }
                      }

                      &.term-life-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/term-life-sm-marine.svg");
                          }
                        }
                      }

                      &.travel-plan {
                        .icon-col {
                          height: 21px;

                          &::before {
                            color: variables.$core-color-marine;
                            content: url("../../../assets/images/quote-item-list/travel-sm-marine.svg");
                          }
                        }
                      }
                    }

                    .list-label-pair {
                      margin-bottom: 5px;

                      .sable-label {
                        font-weight: variables.$main-font-weight-bold;
                      }
                    }
                  }

                  &.PetHealthHistorySummary,
                  &.SLA_AboutYouSummary,
                  &.SLA_StudentLoanSummary,
                  &.SLA_FinanceSummary,
                  &.SLA_EducationSummary {

                    app-quote-summary,
                    .row {
                      width: 100%;
                    }

                    .expansion-panel {
                      padding: 6px 0px 6px 0px;
                      border-top: 1px solid #c0c0c0;

                      &.accordion-item,
                      .accordion-header {
                        background-color: transparent;
                      }

                      .accordion-body {
                        margin-bottom: 0px;
                        margin-top: 10px;
                      }
                    }
                  }
                }
              }
            }
          }

          .right-pane {
            &.icon-col-cta {
              font-size: 18px;
              text-align: right;

              i {
                cursor: pointer;
              }

              &.CoverageSummary {
                visibility: hidden;
              }
            }
          }
        }
      }
    }
  }
}


.modal-dialog {
  .modal-body {
    .sable-label-pair {

      .icon-col {
        width: 24px;
        margin-right: 5px;
        flex-shrink: 0;
      }

      .sable-label,
      .sable-value {
        font-size: 14px;
        line-height: 18px
      }

      &.header {

        .sable-label,
        .sable-value {
          font-family: variables.$main-font-bold;
          max-width: variables.$form-control-max-width;
        }
      }

      &.about-you {
        .icon-col {
          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/quote-summary-user.svg");
          }
        }
      }

      &.vehicles {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
          }
        }
      }

      &.drivers {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/quote-driver-icon.svg");
          }
        }
      }

      &.incidents {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/quote-accident-icon.svg");
          }
        }
      }

      &.properties {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/quote-home-icon.svg");
          }
        }
      }

      &.cat {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
          }
        }
      }

      &.dog {
        .icon-col {
          height: 21px;

          &::before {
            color: variables.$icon-default-color;
            content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
          }
        }
      }
    }
  }
}

.Auto_PrimaryDriverSummaryKey {
  app-quote-summary {
    .summary-container {
      sable-dataview {
        lib-dataview-input-label {
          .sable-label-pair {
            &:not(.vehicles):not(.primary-driver) {
              flex-wrap: wrap;
            }

            margin-bottom: 20px;

            .sable-label,
            .sable-value {
              flex-basis: 75%;
            }

            &:not(.primary-driver) {
              .sable-label {
                font-family: variables.$main-font-bold;
              }
            }

            .sable-value {
              margin-left: 28px;
            }
          }
        }
      }
    }
  }
}

.aon-modal-box {
  .aon-static-content {
    &.aon-form-header {
      h3 {
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 20px;
      }
    }
  }

  .aon-form-component {
    margin-bottom: 20px !important;

    &.aon-direction-col {
      .aon-form-legend {
        margin-bottom: 20px;

        .aon-form-legend-text {
          span {
            font-size: 16px;
            font-weight: variables.$main-font-weight-bold;
            color: variables.$header-color-three;
            line-height: 20px;
            margin-bottom: 0px;
            text-transform: uppercase;
          }
        }
      }

      .aon-form-radio {
        padding: 12px;
        margin-bottom: 12px;

        &:last-of-type {
          margin-bottom: 0px;
        }

        p.vet-address {
          color: variables.$paragraph-color-one !important;
        }
      }
    }
  }
}


