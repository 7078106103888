@use '../3-helpers/variables';

.article-page {
  .mobile-back-link {
    color: variables.$link-color-three;
  }

  .link-pointer {
    cursor: pointer;
  }

  .article-page-content-sleeve {
    margin: 0 auto;
    height: 100%;

    @media only screen and (max-width: variables.$site-body-width-XL) {
      width: 100%;
    }

    max-width: 100%;

    @media only screen and (max-width: variables.$site-body-width-XXL) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .xng-breadcrumb-list {
      .xng-breadcrumb-separator {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .s-s-para {
    @media screen and (max-width: variables.$site-body-width-S) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .article-page-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;

    @media screen and (max-width: variables.$site-body-width-S) {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 100%;
    }

    .page-content-sleeve {
      @media screen and (max-width: variables.$site-body-width-S) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .article-page-padding {
      padding-top: 40px;

      @media screen and (min-width: variables.$site-body-width-M) {
        xng-breadcrumb {
          padding-left: 0;
        }
      }

      h1 {
        color: variables.$header-color-one;
        padding-bottom: 80px;
        margin-top: 4px;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 40px;
          margin-top: 12px;
        }
      }

      img {
        vertical-align: middle;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin-right: 4px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .vp-article-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: variables.$value-prop-icon-circle-bg-color;
      background-repeat: no-repeat;
      background-position: center;
      flex-shrink: 0;

      @media only screen and (max-width: variables.$site-body-width-S) {
        display: none;
      }
    }
  }

  .article-details {
    padding-top: 80px;
    padding-bottom: 120px;
    justify-content: space-between;


    @media screen and (max-width: variables.$site-body-width-S) {
      padding-top: 0px;
      padding-bottom: 80px;
    }

    h4 {
      margin: 40px 0px 20px 0px;
      color: variables.$core-color-blue;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin: 30px 20px 20px 20px;
      }
    }

    h3 {
      color: variables.$core-color-blue;
      margin: 0px 0px 0px 0px;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    h5 {
      margin: 0px 0px 0px 0px;
      color: variables.$core-color-blue;
    }

    .article-para {
      p {
        margin: 20px 0px 40px 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin: 12px 20px 20px 20px;
        }
      }
    }

    .quick-note {
      @media screen and (max-width: variables.$site-body-width-S) {
        margin-left: 20px;
        margin-right: 20px;
      }

      background-color: #f2f6ff;
      padding: 20px 0px;

      i {
        color: variables.$core-color-blue;
        margin-left: 20px;
      }

      p {
        margin-top: 20px;
        margin-left: 40px;
        margin-right: 40px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin-right: 20px;
        }
      }
    }

    .question-div {
      background-color: variables.$bg-color-pearl;
      padding: 32px 32px 40px 32px;

      @media screen and (max-width: variables.$site-body-width-S) {
        padding: 32px 20px 32px 20px;

        h5 {
          text-align: center;
        }
      }

      i {
        color: variables.$core-color-blue;
        font-size: 44px;
        text-align: center;
      }

      .question-div-icon {
        margin-bottom: 40px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 20px;
        }
      }
    }

    .ready-to-save {
      background-color: variables.$bg-color-smoke;

      h5 {
        margin: 32px 20px 32px 32px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin: 0px 0px 12px 0px;
          text-align: center;
        }
      }

      button {
        margin: 20px 32px 20px 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin: 0px 0px 0px 0px;
        }
      }

      @media screen and (max-width: variables.$site-body-width-S) {
        padding: 20px;
      }
    }

    .related-article-details {
      .author-avatar {
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }

        @media screen and (max-width: variables.$site-body-width-S) {
          img {
            width: 44px;
            height: 44px;
            border-radius: 50%;
          }

          span {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .author-detail {
        border-bottom: 1px solid variables.$bg-color-shadow;
      }

      h4 {
        margin: 0px 0px 0px 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          color: variables.$header-color-four;
        }
      }

      h6 {
        margin: 12px 0px 8px 0px;
        color: variables.$header-color-four;
      }

      p {
        margin: 0px 0px 32px 0px;
      }

      .article-related-benefits {
        border-top: 1px solid variables.$related-benefit-article-line-color;
        padding-top: 32px;
        margin-top: 20px;

        .basic-card {
          border-radius: 4px;
          padding: 20px;
          position: relative;

          .promo-message {
            position: absolute;
            top: 4px;
            right: 4px;
            font-size: 12px;
            font-family: variables.$main-font-bold;
            letter-spacing: 0;
            line-height: 13px;
            color: variables.$feedback-color-success-darker;
            background-color: variables.$feedback-color-success-lighter;
            padding: 3px 4px;
          }

          .product-logo {
            width: 36px;
            height: 36px;
            margin-right: 8px;
          }

          .card-contents {
            .card-title {
              p {
                font-family: variables.$main-font-bold;
              }
            }
          }
        }
      }
    }
  }

  .accordion {
    .article-expansion-panel {
      border: 0;
      border-bottom: variables.$box-shadow-color;
      background-color: #ffffff;
      border-radius: 0px;

      @media screen and (max-width: variables.$site-body-width-S) {
        border-top: 1px solid variables.$box-shadow-color;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid variables.$box-shadow-color;
      }

      &.accordion-item {
        .accordion-header {
          background-color: #fff;
          border: 0px;
          padding: 0px;
          color: variables.$secondary-color-cobalt;

          &:hover {
            cursor: pointer;
          }

          .article-header-sleeve {
            display: flex;
            align-items: center;

            i {
              font-size: 20px;
            }

            .article-header-text {
              margin-bottom: 6px;
              flex-grow: 1;
              font-weight: variables.$main-font-weight-normal;
              font-size: 16px;

              @media screen and (max-width: variables.$site-body-width-S) {
                margin-top: 6px;
              }
            }

            &.expanded {

              .article-header-text,
              .article-header-controls {
                color: variables.$secondary-color-cobalt;
              }

              &:hover {

                .article-header-text,
                .article-header-controls {
                  color: variables.$secondary-color-cobalt;
                }
              }
            }
          }
        }

        .accordion-body {
          padding: 0px;

          .article-expansion-panel-text {
            background-color: #ffffff;
          }
        }
      }
    }
  }

  select {
    padding: 10px 20px 10px 20px;
  }

  select:not(.open):not(.disabled):not(.error):hover {
    border: 1px solid variables.$select-border-color-closed;
    padding: 10px 20px 10px 20px;
  }

  .playIcon {
    span {
      i {
        &.fa-play::before {
          content: "\f04b";
        }
      }
    }
  }

  .pause {
    span {
      i {
        &.fa-play::before {
          content: "\f04c";
        }
      }
    }
  }

  progress {
    background-color: variables.$link-color-sky;
  }

  progress::-webkit-progress-bar {
    background-color: variables.$link-color-sky;
  }

  progress::-webkit-progress-value {
    background-color: variables.$icon-default-color !important;
  }

  .article-page-header-asia {
    display: none;
  }

}