@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/shared-plan-cards';

.plan-card-container {
  .plan {
    &.basic-card {
      .cost-container {
        .headline-cost {
          color: variables.$secondary-color-navy;
        }
      }

      &.div-border {
        .select-plan {
          button {
            background-color: variables.$button-deselect-color !important;
          }
        }
      }
    }
  }
}
