@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.firsttime-user {
  .firsttime-user-sleeve {
    background-position: bottom left, top 222px right;
    background-repeat: no-repeat, no-repeat;
    background-size: 250px 204px, 207px 227px;
    justify-content: center;
    background-color: transparent;
    background-image: none;
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    .firsttime-user-sleeve {
      background-image: none;
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 125px 102px;

      .page-content-sleeve {
        background-image: none;
      }
    }
  }

  @media screen and (min-width: variables.$site-body-width-M) and (max-width: variables.$site-body-width-XL) {
    .container {
      margin-right: unset;
      margin-left: unset;
    }
  }
}

.other-info {

  input[type=password],
  input[type=text] {
    &.error {
      background-color: transparent
    }
  }

  .banner {
    p {
      span {
        font-size: 16px !important;
      }
    }
  }

  label {
    span {
      font-size: 16px !important;
    }
  }

  span {
    span {
      font-size: 16px !important;
    }
  }
}

.contact-preferences {
  .description {
    margin-bottom: 40px;

    @media screen and (max-width: variables.$site-body-width-S) {
      margin-bottom: 20px;
    }
  }

  .checkboxcontent {
    p {
      margin-bottom: 32px;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin-bottom: 20px;
      }
    }
  }

  .a-enabled {
    pointer-events: visible;
  }

  @media screen and (min-width: variables.$site-body-width-M) and (max-width: variables.$site-body-width-XL) {
    .container {
      margin-right: unset;
      margin-left: unset;
    }
  }
}

.inverse,
.inverse-content-block {
  &.contact-preferences {
    .label-disabled {
      opacity: 1;
      cursor: not-allowed;
    }
  }
}

app-privacy-policy,
app-terms-conditions,
app-participant-consent {

  .modal-body {
    max-height: 50vh;
    overflow-y: auto;
    padding: 32px !important;
    background-color: variables.$modal-edit-state-panel-bg;
  }
}

.modal-dialog {
  .modal-content {
    .card-img {
      display: none;
    }

    #privacy-policy,
    #terms-conditions {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.user-privacy-policy,
.user-terms-and-conditions {
  .page-only {
    display: none;
  }

  .modal-only {
    display: block !important;
  }
}

#privacy-policy,
#terms-conditions {
  .modal-only {
    display: none
  }
}