@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.aon-activity-eoiresults {
    .container.header p {
        margin: 0 0 20px !important;
    }

    .basic-card {
        padding: 32px;
    }

    .banner {
        border-top: 0px;
        padding: 28px 23px 28px 32px;
    }

    .entity {
        margin-bottom: 20px;
    }

    .last-child {
        margin-bottom: 0px !important;
    }

    .eligibility-questions-margin {
        margin-bottom: 32px;
    }

    @media screen and (max-width: variables.$site-body-width-S) {
        .alert-margin {
            padding-left: 20px;
            padding-right: 20px;
        }

        .flow-group {
            padding-left: 20px;
            padding-right: 20px;
        }

        .eligibility-questions-margin {
            margin-bottom: 20px;
        }
    }
}