@use '../3-helpers/variables';


.page {

  &#home {

    .vp-content-sleeve-outer {

      .value-prop {
        display: block;

        .vp-summary {
          font-size: 18px;
          font-weight: variables.$main-font-weight-normal;
        }

        .vp-container {
          text-align: center;

          @media only screen and (max-width: 576px) {
            text-align: left;
          }

          h2.section-header {
            margin-bottom: 0px;
            font-weight: variables.$main-font-weight-bold;

            @media only screen and (max-width: 576px) {
              margin-bottom: 20px;
            }
          }

          .vp-sleeve {

            h3 {
              color: variables.$value-prop-header-color;
              margin-bottom: 8px;
            }

            .vp-img-sleeve {

              @media only screen and (min-width: 576px) {
                //Set to the height of the tallest image in the container plus its padding
                height: 142px;

                //Then we can apply the custom padding-top required for each of the child images
                #ValueProposition-1 {
                  padding-top: 40px;
                }

                #ValueProposition-2 {
                  padding-top: 42px;
                }

                #ValueProposition-3 {
                  padding-top: 53px;
                }
              }

              @media only screen and (max-width: 991px) {
                height: 128px;

                img {
                  width: 52px;
                }
              }

              @media only screen and (max-width: 576px) {
                img {
                  width: 40px;
                }
              }
            }




            @media only screen and (max-width: 576px) {
              .vp-item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                .vp-text {
                  display: flex;
                  flex-direction: column;
                  margin-left: 20px;
                  margin-bottom: 20px;

                  p {
                    margin: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &#product {
    .vp-content-sleeve-outer {
      background-repeat: no-repeat;
      background-size: auto;
      background-position: left calc((100vw - 1200px)/2 + 580px) top;

      @media only screen and (max-width: variables.$site-body-width-XL) {
        background-repeat: no-repeat, no-repeat;
        background-size: auto, cover;
        background-position: left bottom, bottom;
      }

      .value-prop {
        .vp-sleeve {
          display: flex;
          justify-content: space-between;

          h2 {
            font-weight: variables.$main-font-weight-bold;
          }

          h5, .h5,
          p {
            color: variables.$core-color-navy;
          }

          .vp-intro {
            .vp-icon-main {
              height: 80px;
              width: 80px;
              border-radius: 50%;
              background-color: variables.$value-prop-icon-circle-bg-color;
              background-repeat: no-repeat;
              background-position: center;
              flex-shrink: 0;
              margin-bottom: 20px;

              @media only screen and (max-width: variables.$site-body-width-S) {
                height: 60px;
                width: 60px;
                margin-bottom: 20px;
              }

              @media only screen and (max-width: variables.$site-body-width-XL) {
                background-color: variables.$value-prop-icon-circle-bg-color !important;
              }
            }

            h2 {
              margin-bottom: 12px;
            }

            p {
              color: variables.$text-color-gray01;
            }
          }

          .vp-items {

            @media only screen and (max-width: variables.$site-body-width-S) {
              padding-top: 0px;
            }

            a {
              color: variables.$label-text-color;
            }

            .row {
              margin-bottom: 32px;

              @media only screen and (max-width: variables.$site-body-width-S) {
                margin-bottom: 20px;
              }

              .vp-item-icon {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: variables.$value-prop-icon-circle-bg-color;
                background-repeat: no-repeat;
                background-position: center;
                flex-shrink: 0;
                background-size: 32px 32px;

                @media only screen and (max-width: variables.$site-body-width-S) {
                  height: 40px;
                  width: 40px;
                  background-size: 24px 24px;
                }
              }

              .item-content-text-container {
                padding-left: 20px;

                p {
                  margin-bottom: 0px;
                }

                @media only screen and (max-width: variables.$site-body-width-S) {
                  padding-left: 16px;
                }
              }

              h5, .h5 {
                margin-bottom: 8px;
              }
            }
          }

          @media only screen and (min-width: variables.$site-body-width-XL) {
            .vp-items {
              padding-left: 50px;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}