@use '../../global/3-helpers/variables';

.app-header-sleeve {
    @media only screen and (min-width: variables.$site-body-width-L) {
        position: relative !important;
    }

    .delegate-sleeve {
        background-color: variables.$masthead-delegate-bg-color;
        color: variables.$masthead-delegate-text-color;
        min-height: 44px;
        z-index: auto;

        .site-masthead-delegate-bar {
            display: flex;
            justify-content: space-between;
            padding-left: 20px;
            align-items: center;

            p {
                color: variables.$masthead-delegate-text-color;
                margin-bottom: 0px;
            }

            .select-delegate {
                display: flex;
                align-items: center;

                .delegate-user-list {

                    ul:not(.nav-dropdown-list) {
                        list-style-type: none;
                        margin: 0px;

                        li {

                            border-top: 4px solid transparent;

                            &.show {
                                border-top: 4px solid variables.$masthead-delegate-button-hover-border !important;
                                background-color: variables.$masthead-delegate-button-hover !important;
                            }

                            &:hover {
                                border-top: 4px solid variables.$masthead-delegate-button-hover-border !important;
                                background-color: variables.$masthead-delegate-button-hover;
                            }

                            a {
                                padding: 10px 20px 14px 20px;

                                .fal {
                                    margin-left: 8px;
                                    color: variables.$masthead-delegate-text-color;
                                    font-size: 16px;
                                }

                                &:focus-visible {
                                    outline: 2px solid variables.$white !important;
                                }
                            }

                            .dropdown-menu {
                                background-color: variables.$masthead-delegate-button-hover;
                                margin: 0px;
                                border-radius: 0px;
                                width: 300px;
                                padding: 0px;
                                border: 0px;
                                left: unset;
                                right: 0px;
                                overflow-y: auto;
                                max-height: 324px;

                                li {

                                    border-top: unset !important;

                                    a {
                                        padding: 14px 20px;
                                        color: variables.$masthead-delegate-text-color;
                                        background-color: variables.$masthead-delegate-button-hover;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;

                                        &:hover {
                                            background-color: variables.$masthead-delegate-bg-color;
                                        }

                                        &:focus-visible {
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            border-bottom: 2px solid transparent;
                        }

                        .dropdown-toggle::after {
                            display: none;
                        }

                    }

                    ::-webkit-scrollbar {
                        width: 8px;
                        height: auto;
                    }

                    ::-webkit-scrollbar-track {
                        background-color: variables.$bg-color-smoke;
                        width: 8px;
                    }

                    ::-webkit-scrollbar-thumb {
                        background: variables.$scrollbar-thumb-color;
                        border-radius: 4px;
                        height: auto !important;
                        border: 1px solid variables.$bg-color-smoke;
                    }
                }
            }

        }
    }
}