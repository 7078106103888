@forward "../../global/3-helpers/mixins";


@mixin inline-image($left: unset, $top: unset, $bottom: unset, $right: unset, $height: unset, $width: unset, $zindex: unset) {
  .img-sleeve {
    position: relative;

    img {
      position: absolute;
      left: $left;
      top: $top;
      bottom: $bottom;
      right: $right;
      height: $height;
      width: $width;
      z-index: $zindex;
    }
  }
}