@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.forgot-username {
  .forgot-username-sleeve {
    background-color: variables.$inverse-container-color;
    background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
    background-position: bottom left, top 222px right;
    background-repeat: no-repeat, no-repeat;
    background-size: 250px 204px, 207px 227px;
    justify-content: center;
    min-height: calc(100vh - 156px);

    .page-content-sleeve {
      @include mixins.inline-image($left: -117px, $top: -10px);
    }
  }


  @media only screen and (max-width: variables.$site-body-width-S) {
    .forgot-username-sleeve {
      background-image: url(/assets/images/account/pebbles5.svg);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 125px 102px;
    }
  }
}