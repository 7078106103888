@use '../3-helpers/variables';

.help-nav {
	.nav {
		background-color: variables.$help-nav-bar-background-color;
	}

	.nav-tabs {
		a {
			text-decoration: none;
			padding: 0px 20px 0px 20px;
			color: variables.$help-nav-text-color;
		}

		.nav-link.active {
			color: variables.$help-nav-text-color;
			background-color: variables.$help-nav-link-border-background-color;
			border-color: variables.$help-nav-link-border-background-color;
			font-family: variables.$main-font-bold;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.nav-link {
			margin-bottom: unset;
			padding-top: 18px;
			padding-bottom: 18px;

			&:focus-visible {
				outline-offset: -4px;
			}

		}

		.nav-item {
			padding: 0;
		}

		@media screen and (max-width:variables.$site-body-width-S) {
			display: none;
		}
	}
}