@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/masthead-language';

.masthead-language-container {
  @media screen and (max-width: variables.$site-body-width-L) {
    select {
      color: variables.$masthead-profile-text-color;

      option {
        color: variables.$select-text-color;
      }
    }

    .dropdown:focus {
      color: variables.$masthead-profile-text-color;
    }
  }
}