@use '../3-helpers/variables';

#product-hero {
	.text-inner {
		.breadcrumb-content-sleeve-outer {
			margin-bottom: 4px;

			.category-icon {
				height: 16px;
				width: 16px;
				margin-right: 6px;
			}

			.aon-breadcrumb-trail {
				color: variables.$paragraph-color-two;
			}
		}

		h2 {
			font-weight: variables.$main-font-weight-bold;
		}
	}

	.text-bottom-section {
		display: flex;

		@media only screen and (max-width: variables.$site-body-width-S) {
			flex-direction: column;
		}

		app-aon-cta {
			button {
				margin-right: 20px !important;

				@media only screen and (max-width: variables.$site-body-width-S) {
					margin-right: unset !important;
					margin-top: 20px;
				}
			}

			&:first-child {
				button {
					@media only screen and (max-width: variables.$site-body-width-S) {
						margin-top: 0;
					}
				}
			}

			&:last-child {
				button {
					margin-right: 0;
				}
			}
		}
	}
}