@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.coverage-eoi {
  table {

    th,
    td {
      border: 0;
    }

    tr {

      th:not(:first-child),
      td:not(:first-child) {
        text-align: right;
      }
    }

    thead {
      tr {
        background-color: variables.$bg-color-fog;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: variables.$bg-color-fog;
      }

      .participants-mobile-view {
        tr {
          background-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: variables.$site-body-width-S) {
    table {
      thead th:not(:first-child) {
        display: none;
      }

      td,
      th {
        display: block;
      }

      td {
        padding: 0 12px;
      }

      td[data-th] {
        font-family: variables.$main-font-bold;
      }

      tr {

        th:not(:first-child),
        td:not(:first-child) {
          text-align: left;
        }
      }

      .participants-mobile-view {
        .interim-wrapper {
          background-color: variables.$bg-color-fog;
        }
      }
    }

    .coverage-table td[data-th]:before {
      content: attr(data-th);
    }
  }
}