@use '../3-helpers/variables';
@use '../3-helpers/mixins';



.products-content-sleeve-outer {
  background-image: url('/assets/images/home/shared-container-backgrounds/home-dots1-lg.svg'), url('/assets/images/home/shared-container-backgrounds/home-dots2-lg.svg'), url('/assets/images/home/shared-container-backgrounds/graywaves1-xlarge.svg');
  background-position: top right, bottom left, bottom left;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: auto, auto, cover;

  &.related-products {
    background-image: url('/assets/images/home/shared-container-backgrounds/related-products-horizontal.svg');
    background-position: 50% 0px;
    background-size: auto;
    @media only screen and (min-width: variables.$site-body-width-M) {
      margin-bottom: 0!important;
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      background-image: url('/assets/images/home/shared-container-backgrounds/related-products-vertical.svg');
      background-position: 0% 60px;
      background-size: contain;
    }

    .related-products-min-height{
      min-height: 500px;
    }
     .related-products-top-0{
     top: 0px !important;
    }
    .rp-abs-img-1 {
      @include mixins.inline-image($right: 0px, $top: -135px);
    }

    .rp-abs-img-2 {
      @include mixins.inline-image($left: 0px, $top: -175px);
      z-index: 0;
    }

    .faq-sleeve {
      width: 100%;
    }
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    background-image: none;
  }





  .product-cards {
    @media only screen and (min-width:variables.$site-body-width-M){
      padding-top: 60px;
      padding-bottom: 140px;
    }

    .p-abs-img-1 {
      @include mixins.inline-image($left: -45px, $top: -0px);

      @media only screen and (max-width: variables.$site-body-width-S) {
        @include mixins.inline-image($left: -15px, $top: -15px);
      }
    }

    .products-heading {
      text-align: center;
      margin-bottom: 40px;

      @media only screen and (max-width: variables.$site-body-width-S) {
        text-align: left;
      }
    }

    .col-md-6 {
      @media screen and (min-width:variables.$site-body-width-S) and (max-width:variables.$site-body-width-L) {
        flex: 0 0 47%;
        max-width: 47%;
      }
    }

    .col-md-5 {
      flex: 0 0 44%;
      max-width: 44%;
    }

    .product-list{
      .col-md-5 {
        flex: initial;
        max-width: initial;
      }
    }

    .card-sleeve {

      .card-wrap{
        &:nth-child(3n){
          padding-left: 10px;
        }
        &:nth-child(3n + 1){
          padding-right: 10px;
        }
        &:nth-child(3n+2){
          padding-left: 10px;
          padding-right: 10px;
        }
        margin-bottom: 20px;
      }

      .basic-card {

        border-radius: 8px;

        img {
          width: 36px;
          height: 36px;
          margin-right: 12px;
          align-self: flex-start;
        }

        &.home-product {

          padding: 20px;
          position: relative;
          min-height: 100%;
          cursor: pointer;

          .card-header{
            display: flex;
            align-items: center;

            h5{
              color: variables.$header-color-four;
            }
          }

          .promo-message {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 12px;
            font-family: variables.$main-font-bold;
            letter-spacing: 0;
            line-height: 13px;
            color: variables.$feedback-color-success-darker;
            background-color: variables.$feedback-color-success-lighter;
            padding: 3px 4px;
            border-radius: 0 7px 0 0;
          }

          .card-contents {
            width: 100%;
            overflow-wrap: anywhere;
          }

          @media only screen and (max-width: variables.$site-body-width-L) {
            padding: 20px;
          }

          h3 {
            color: variables.$product-card-header-color;
          }

          P {

            &.card-description {
              color: variables.$product-card-desc-text-color;
            }

            &.card-category {
              color: variables.$product-card-cat-text-color;
            }
          }
        }

        @media only screen and (min-width:variables.$site-body-width-S) and (max-width: variables.$site-body-width-L) {
          margin-left: 15px;
        }
      }
    }

    h2:not(.product-card-title) {
      @include mixins.heading ($fontfamily: variables.$heading-font-regular,
        $fontsize: 28px,
        $lineheight: 42px,
        $weight: 700,
        $color: variables.$header-color-one )
    }

    @media (max-width: variables.$site-body-width-S) {
      h2 {
        @include mixins.heading ($fontfamily: variables.$heading-font-regular,
          $fontsize: 24px,
          $lineheight: 38px,
          $color: variables.$product-card-header-color );
      }
    }
  }
}