@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.benefit-card-container {

  .benefit {
    &.basic-card {

      &.div-border {
        padding: 31px !important;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding: 20px !important;
        }
      }

      .accordion {
        .expansion-panel {
          border: 0px;
          border-top: 1px solid variables.$box-shadow-color;
          border-bottom: 1px solid variables.$box-shadow-color;
          padding: 12px 0px;

          .accordion-body {
            div.expansion-panel-content {
              margin-top: 12px;

              table {
                margin-bottom: 0px;

                tr {
                  td:not(:first-child) {
                    text-align: right;
                  }
                }

                th {
                  border-top: none;
                }
              }
            }
          }

          .header-text {
            font-size: 16px !important;
          }

          i {
            font-size: 16px !important;
          }

          @media screen and (max-width: variables.$site-body-width-S) {
            margin-left: 0px;
            margin-right: 0px;
          }
        }
      }

      .cost-container {
        .employee-cost {
          font-size: 32px;
          line-height: 32px;
          text-align: right;
          font-weight: variables.$main-font-weight-light;
        }

        .employer-cost,
        .total-cost {
          text-align: right;
        }

        .cost-label {
          margin: 0;
          color: variables.$cost-label-color;
          text-align: right;
        }
      }

      .action-button {
        border: 1px solid variables.$button-default-active-color;
        flex: 1;
      }

      .plan-image {
        height: 16px;
        width: 16px;
      }

      @media screen and (max-width: variables.$site-body-width-M) {
        .cost-container {

          .employee-cost,
          .employer-cost,
          .total-cost {
            text-align: left;
          }

          .cost-label {
            float: left;
          }
        }

        .expansion-panel {
          padding: 20px;
        }
      }
    }
  }
}

.sable-view-for-benefit-card {

  .QuoteSummaryKey,
  .summary-sleeve {
    .sable-label-pair {
      color: variables.$label-text-color;
      flex-direction: column;
      margin-bottom: 20px !important;
    }

    .sable-label {
      font-weight: variables.$main-font-weight-bold;
      margin-bottom: 4px;
    }

  }

  .summary-sleeve {
    .summary-heading {
      display: none;
    }
  }
}

.confirm-summaryview {
  .hidden-detail {
    display: none !important;
  }

  .hidden-detail-paymentconfirmation {
    display: none !important;
  }
}
