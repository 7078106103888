@use '../3-helpers/variables';

.beneficiaries-info {
  &.profile-beneficiaries-container {

    h6 {
      text-transform: none;
    }

    h3 {
      margin: 0px;
      font-size: 24px !important;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .basic-card {
      margin-bottom: 20px;

      &.edit-state {
        padding: 32px;
      }

      .fa-times,
      .fa-edit {
        font-size: 20px;
        color: variables.$icon-default-color;
        cursor: pointer;
        font-weight: variables.$main-font-weight-light;
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        p.lg {
          font-weight: variables.$main-font-weight-normal;
          font-size: 16px !important;
          line-height: 24px;
          margin-bottom: 16px !important;
        }
      }

      .allocation-item-icon {
        width: 24px;
        height: 20px;
      }

      .allocation-beneficiary-wrapper {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .accordion .expansion-panel {
      p {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}