@use '../3-helpers/variables';

.navbar {

  ul.head-menu {
    &.navbar-nav {
      >li.nav-item-dropdown {
        &.anaval-language-selection {

          .masthead-language-container {

            li.nav-item {

              @media only screen and (min-width: variables.$site-body-width-L) {
                //Add transparent border so when we apply coloured border on hover nothing moves around//
                border-top: 4px solid transparent;
                min-height: 68px !important;
              }

              &:hover {
                @media only screen and (min-width: variables.$site-body-width-L) {
                  border-top: 4px solid variables.$site-header-link-hover-color;
                }

                background-color: variables.$masthead-dropdown-bg-color;

                .text-info {
                  color: variables.$site-header-link-hover-color !important
                }
              }

              &.show {
                @media only screen and (min-width: variables.$site-body-width-L) {
                  border-top: 4px solid variables.$site-header-link-active-color;
                }

                background-color: variables.$masthead-dropdown-bg-color;

                .text-info {
                  color: variables.$site-header-link-active-color !important;
                }
              }

              &.nav-item-menu {
                background-color: variables.$masthead-dropdown-bg-color;
              }

              &.dropdown {
                @media only screen and (max-width: variables.$site-body-width-L) {
                  border-bottom: 1px solid variables.$bg-color-shadow !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.masthead-language-container {
  .language-dropdown {
    background-color: variables.$masthead-profile-bg-color;
    left: unset;
    right: 0px;
    padding: 0px;
    border-radius: 0px;
    border: none;

    .active-language {
      color: variables.$masthead-profile-text-color;
    }

    .language-dropdown-menu {
      width: 300px;
      list-style: none;
      padding-left: 0px;

      @media screen and (max-width: variables.$site-body-width-L) {
        width: 100%;
      }

      a {
        color: variables.$masthead-profile-text-color;
        padding-top: 10px;
        padding-bottom: 10px;

        &:hover,
        &:active,
        &:focus {
          color: variables.$masthead-profile-text-color;
          background-color: variables.$masthead-profile-text-hover-bg-color !important;
          text-decoration: underline;
        }
      }
    }
  }

  @media screen and (max-width: variables.$site-body-width-S) {
    padding: 20px 15px 8px;
  }

  @media screen and (max-width: variables.$site-body-width-L) {
    .language-dropdown-wrap {
      width: 100%;
      position: relative;
      display: block;

      .dropdown {
        border: 1px solid variables.$select-border-color;
        background-color: transparent;
        appearance: none;
        padding: 10px 20px;

      }

      &:after {
        content: "\f0dd";
        font-family: variables.$font-awesome;
        font-size: variables.$text-size-xs + 4;
        color: variables.$icon-color-two;
        position: absolute;
        pointer-events: none;
        width: 20px;
        right: 0;
        top: 10px;
      }
    }
  }
}