@use '../3-helpers/variables';


.quote-review-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @media screen and (max-width: variables.$site-body-width-S) {
    background-image: url(../../../assets/images/product/graywaves4-xsm.svg);
  }

  .quote-review-page {
    padding-top: 80px;
    padding-bottom: 150px;

    h5 {
      margin: 0px 0px 0px 0px;
    }

    label {
      margin-bottom: 2px;
    }

    .mb-2 {
      margin-bottom: 2px !important;
    }

    .data-metric-lg,
    .data-metric-m {
      color: variables.$core-color-navy;
    }

    hr {
      border-top: 1px solid variables.$box-shadow-color;
    }

    .accordion {
      .quote-expansion-panel {
        border: 0;
        border-bottom: variables.$box-shadow-color;
        background-color: #ffffff;
        border-radius: 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          border-top: variables.$box-shadow-color;
        }

        &.accordion-item {
          .accordion-header {
            background-color: #fff;
            border: 0px;
            padding: 0px;
            color: variables.$secondary-color-cobalt;

            &:hover {
              cursor: pointer;
            }

            .quote-header-sleeve {
              display: flex;
              align-items: center;

              i {
                font-size: 20px;
              }

              .quote-header-text {
                margin-bottom: 6px;
                flex-grow: 1;
                font-weight: variables.$main-font-weight-normal;
                font-size: 16px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  margin-top: 6px;
                }
              }

              &.expanded {

                .quote-header-text,
                .quote-header-controls {
                  color: variables.$secondary-color-cobalt;
                }

                &:hover {

                  .quote-header-text,
                  .quote-header-controls {
                    color: variables.$secondary-color-cobalt;
                  }
                }
              }
            }
          }

          .accordion-body {
            padding: 0px;

            .quote-expansion-panel-text {
              background-color: #ffffff;
            }
          }
        }
      }
    }

    @media screen and (max-width:variables.$site-body-width-S) {
      .quote-review-cost {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }
  }

  .icon-pointer {
    cursor: pointer;
  }

  .basic-card {
    padding: 32px;

    @media screen and (max-width: variables.$site-body-width-S) {
      width: auto;
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  sable-dataview {
    .accordion {
      .expansion-panel {
        border-top: 1px solid variables.$box-shadow-color;
        border-bottom: 1px solid variables.$box-shadow-color;
        padding: 8px 0;

        &.accordion-item {
          .accordion-header {
            .header-sleeve {
              .header-text {
                line-height: 20px;
                font-size: 16px;
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      .accordion-body {
        margin-top: 20px;
      }
    }
  }

  app-quote-review-summary {
    .summary-container {
      margin-top: 20px;
    }
  }
}