@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/1-pages/masthead';

.app-header-sleeve {
  .badge {
    padding: 0.25em 0.4em;
  }

  .site-masthead {

    .cart-badge {

      .badge {
        border-radius: 1rem;
      }

      .badge-danger {
        background-color: variables.$feedback-color-error;
      }

      .icon-badge-container {
        position: relative;
      }

      .icon-badge-icon {
        position: relative;
      }

      .icon-badge {
        font-size: 12px;
        color: white;
        text-align: center;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        top: -8px;
        left: 15px;
      }

      @media only screen and (max-width: variables.$site-body-width-L) {

        .icon-badge {
          top: 20px;
          left: 30px;
        }
      }
    }
  }
}