@use '../3-helpers/variables';


.page {

  &#product-coverage-details {

    h1,
    h3 {
      scroll-margin-top: 90px;
    }

    // This sleeve is for header background wave image
    &.Turquoise {
      .pcd-header-sleeve-outer {
        background-image: url(../../../assets/images/product/product-insurance/auto-prod-detail-wave-xlarge.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;


        @media screen and (max-width: variables.$site-body-width-S) {
          background-image: url(../../../assets/images/product/product-insurance/auto-prod-detail-wave-sm.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 100%;
        }
      }
    }

    &.Tangerine {
      .pcd-header-sleeve-outer {
        background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-xlarge.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;

        @media screen and (max-width: variables.$site-body-width-S) {
          background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-sm.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 100%;
        }
      }
    }

    &.Shamrock {
      .pcd-header-sleeve-outer {
        background-image: url(../../../assets/images/product/product-insurance/pp-prod-detail-wave-xlarge.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;

        @media screen and (max-width: variables.$site-body-width-S) {
          background-image: url(../../../assets/images/product/product-insurance/pp-prod-detail-wave-sm.svg);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 100%;
        }
      }
    }

    .pcd-header-sleeve-outer {
      background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-xlarge.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 100%;

      @media screen and (max-width: variables.$site-body-width-S) {
        background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-sm.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;
      }

      //Header sleeve class: To Handle styling of header content in desktop and mobile view
      .product-coverage-details-header {
        padding-top: 40px;

        h1 {
          padding-bottom: 60px;
          margin-top: 4px;
          color: variables.$header-color-one;

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-bottom: 40px;
            margin-top: 12px;
          }
        }

        //Styling the breadcrumb by overiding the existing class
        .xng-breadcrumb-item {

          .xng-breadcrumb-link {
            font-size: variables.$text-size-sm;
            line-height: variables.$text-size-lg;
          }

          label.xng-breadcrumb-trail {
            font-size: variables.$text-size-sm;
            line-height: variables.$text-size-lg;
          }
        }

        .xng-breadcrumb-separator {
          margin-left: 4px;
          margin-right: 4px;
        }

        img {
          margin-right: 20px;
          width: 80px;
          height: 80px;
          vertical-align: middle;

          @media screen and (max-width: variables.$site-body-width-S) {
            margin-right: 4px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    //Page Body

    .pcd-content-sleeve-outer {
      .page-content-sleeve {

        &.product-coverage-details {

          .cover-types {

            .content-title {
              margin-top: 20px;
            }
          }

          .cover-details {
            .content-title {
              margin-top: 24px;

              >* {
                width: auto;
                padding-left: 0;
                padding-right: 0;
              }
            }

            h3 {
              margin-bottom: 0px;
            }

            h5 {

              @media screen and (max-width:variables.$site-body-width-S) {
                max-width: 300px;
                width: 100%
              }
            }

            .fa-check {
              color: variables.$feedback-color-success-darker;
            }

            .fa-times {
              color: variables.$feedback-color-error-darker;
            }
          }

          .pets-content {
            @media screen and (max-width: variables.$site-body-width-S) {
              margin-left: -20px;
              margin-right: -20px;
            }

            background-color: variables.$white;
            text-align: center;
            margin-bottom: 40px;
            border: 1px solid #ACC0C4;
            border-radius: 8px;

            .pet-img {
              margin-top: 32px;
              margin-bottom: 12px;
              width: 52px;
              height: 45px;
            }

            .div-wrapper {
              margin-top: -28px;

              &.pet-title-margin {
                margin-top: 0px;

                p {
                  margin-bottom: 20px;

                  &:last-child {
                    margin-bottom: 12px;
                  }
                }
              }
            }


            .save-percent {
              background-color: variables.$bg-color-gray07;
              padding-left: 32px;
              padding-right: 32px;
              display: block;

              &.save-percent-details {
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
              }

              @media screen and (max-width:576px) {
                text-align: center;
              }

              p.lg {
                font-family: variables.$main-font-bold;
                margin-top: 20px;
                color: variables.$paragraph-color-four;
                text-align: left;
              }
            }

            .pet-content-tile {

              @media screen and (max-width: variables.$site-body-width-S) {
                padding-left: 0px;
                padding-right: 0px;
              }

              .basic-card {
                border: none;
                border-radius: 4px;
              }

              .card1 {
                background-color: variables.$product-coverage-detail-card1-color;
                width: 49.3%;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding: 24px 0 0 0;
                margin-bottom: 32px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  border-radius: 0px;
                  width: 100%;
                  margin-bottom: 8px;
                }
              }

              .card2 {
                background-color: variables.$app-color-tangerine-light;
                width: 49.3%;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                padding: 24px 20px 0 20px;
                margin-bottom: 32px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  border-radius: 0px;
                  width: 100%;
                  margin-bottom: 20px;
                }
              }

              .bottom-left-cap {
                padding-top: 8px;
                padding-bottom: 8px;
                background-color: variables.$white;
                opacity: 70%;
                border-bottom-left-radius: 4px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  border-radius: 0px;
                }

                img {
                  height: 18px;
                  width: 14px;
                  margin-right: 6px;
                }

                p {
                  color: variables.$core-color-blue;
                }
              }

              .bottom-right-cap {
                padding-top: 8px;
                padding-bottom: 8px;
                background-color: variables.$white;
                opacity: 70%;
                border-bottom-right-radius: 4px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  border-radius: 0px;
                }

                img {
                  height: 18px;
                  width: 14px;
                  margin-right: 6px;
                }

                p {
                  color: variables.$core-color-blue;
                }
              }
            }
          }

          @media only screen and (max-width: variables.$site-body-width-S) {
            .p-dropdown {
              .wrap {
                &:after {
                  color: variables.$white;
                }
              }
            }
          }
        }
      }
    }

    .dotted {
      @media screen and (max-width:variables.$site-body-width-S) {
        text-decoration: underline;
        text-decoration-style: dashed;
      }
    }

    .page-content-sleeve {

      &.faqs {
        padding-top: 100px;
        margin-top: 0;

        @media screen and (min-width:variables.$site-body-width-L) {
          padding-left: 15px;
        }

        @media screen and (min-width:variables.$site-body-width-S) and (max-width:variables.$site-body-width-M) {
          padding-left: 0;
        }

        .faqs-content {
          align-items: start;
        }

        .faq-sleeve {
          &.col-md-8 {
            width: 100% !important;
          }
        }
      }
    }
  }
}