@use "../3-helpers/variables";

.si-content-sleeve-outer {
  .security-info {
    .security-info-container {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit-icon-margin {
          margin-top: -12px;
          margin-right: -12px;
        }
      }

      .basic-card {
        padding: 32px;
        margin-bottom: 20px;

        &.edit-state {
          padding: 20px !important;
          margin-bottom: 0px;
        }

        .icon-margin {
          font-size: 16px;
          line-height: 36px;
          right: 28px;
          width: 19px;
        }

        .forgot-password {
          margin-left: 20px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          .forgot-password {
            margin-top: 20px;
            margin-left: 0px;
          }

          .basic-card {
            border-radius: 0px;
          }
        }

        .form-group {
          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-bottom: 20px;
          }

          .mfa-email-section {
            margin-bottom: 32px;

            >p {
              margin-bottom: 0px;
            }

          }

          .mfa-edit-section-italic {
            font-style: italic;
            font-size: variables.$text-size-sm;
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}