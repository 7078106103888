@use '../3-helpers/variables';
@use "../../global/3-helpers/mixins" as globmixins;

/* start of how it works details section scss */

.page {
  &#product-details {

    .pd-header-sleeve-outer {
      background-image: url(../../../assets/images/product/how-it-works/prod-detail-wave-xlarge.svg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 100%;

      @media screen and (max-width: variables.$site-body-width-S) {
        background-image: url(../../../assets/images/product/how-it-works/prod-detail-wave-sm.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;
      }

      .page-content-sleeve {

        &.product-details-header {
          padding-top: 40px;

          h1 {
            padding-bottom: 80px;
            margin-top: 4px;
            color: variables.$header-color-one;

            @media screen and (max-width: variables.$site-body-width-S) {
              padding-bottom: 40px;
              margin-top: 12px;
            }
          }

          .xng-breadcrumb-item {

            .xng-breadcrumb-link {
              font-size: variables.$text-size-sm;
              line-height: variables.$text-size-lg;
            }

            label.xng-breadcrumb-trail {
              font-size: variables.$text-size-sm;
              line-height: variables.$text-size-lg;
            }
          }

          img {
            margin-right: 20px;
            width: 80px;
            height: 80px;
            vertical-align: middle;

            @media screen and (max-width: variables.$site-body-width-S) {
              margin-right: 4px;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .faq-header {
      text-align: left;
    }


    h1 {
      color: variables.$header-color-one;
    }

    h3 {
      scroll-margin-top: 100px;
    }

    .fa-times {
      font-size: 24px;
      padding: 10px;
      color: variables.$icon-default-color;
    }
  }
}
