@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.page {

  &#wrapper {

    .page-content-sleeve {
      &.wrapper-headline-widget {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.wrapper-video {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.wrapper-gateway {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.wrapper-gateway-v2 {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.wrapper-features {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .sleeve-outer {
      &:last-child {
        .page-content-sleeve {
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-bottom: 60px;
          }
        }
      }
    }
  }
}