@use '../3-helpers/variables';
@use '../3-helpers/mixins';

//Each page provides its own container block specifications at app level.
//IE, the product cards themselves will be defined globally but their container may have different dimensions by consuming application or even page.

.page {

  &#home {

    .faq-content-sleeve-outer {
      background-image: url("/assets/images/faqs/pebbles5.svg"), url("/assets/images/faqs/pebbles6.svg");
      background-position: 0px bottom, right 20px;
      background-repeat: no-repeat, no-repeat;
      background-size: 200px, 167px;

      @media only screen and (max-width: variables.$site-body-width-S) {
        background-image: none;
      }

      h2 {
        margin-bottom: 32px;
      }

      @media (max-width: variables.$site-body-width-S) {
        h2 {
          margin-bottom: 20px;
        }
      }
    }

    .see-all-faq {
      margin-top: 32px;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin-top: 24px;
      }
    }

    .aon-notification-banner {
      .notification-banner {
        background-color: variables.$inverse-container-color !important;
        border-bottom: 1px solid variables.$white;

        .announcement-content {
          color: variables.$white !important;
        }

        a {
          color: variables.$white;
        }
      }
    }

    .page-content-sleeve {

      &.value-prop {
        padding-top: 80px;
        padding-bottom: 30px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 40px;
        }
      }

      &.product-cards {
        padding-top: 60px;
        padding-bottom: 180px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.expanded-value-prop {
        padding-top: 100px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.faqs {
        padding-top: 80px;
        padding-bottom: 150px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 40px;
        }
      }

      &.client-content-widget {
        padding-top: 60px;
        padding-bottom: 60px;

        &.priority {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        &.no-heading {
          padding-top: 0px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.pension-content-widget {
        padding-bottom: 60px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 20px;
        }
      }

      &.broker-info {
        padding-bottom: 60px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 20px;
        }
      }

      &.privacy-toggle-banner {
        padding-bottom: 30px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 20px;
        }
      }
    }
  }
}