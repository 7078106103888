@use '../3-helpers/variables';

ul.tab-list {
    li.tab-item {
        text-align: center;
        background-color: variables.$tablist-background;
        border-right: 1px solid variables.$tablist-solid-background;

        a {
            text-decoration: none;
            color: variables.$tablist-solid-background;
        }

        &.solid {
            background-color: variables.$tablist-solid-background;
            border-right: 2px solid variables.$tablist-solid-background;

            a {
                color: variables.$tablist-solid-color;
            }
        }

        &:last-child {
            border-right: none;
        }
    }

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    border: 1px solid variables.$tablist-solid-background;
    background-color: variables.$tablist-solid-background;
}