@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.cs-content-sleeve-outer {
  background-color: variables.$case-study-bg-color;
  position: relative;


  .cs-abs-img-1 {
    @include mixins.inline-image($right: 0, $top: -75px, $height: 264px, $width: 268px);


    @media only screen and (max-width: variables.$site-body-width-S) {
      @include mixins.inline-image($right: 0px, $top: -60px);
    }
  }


  .cs-abs-img-3 {
    position: absolute;
    right: -31px;
    bottom: 100px;

    .img-sleeve {
      background-image: url(/assets/images/product/case-study/dots11.svg);
      background-size: cover;
      width: 270px;
      height: 471px;
      background-repeat: no-repeat;
    }
  }

  .cs-abs-img-2 {
    position: absolute;
    left: -32px;
    top: 100px;

    .img-sleeve {
      background-image: url(/assets/images/product/case-study/dots12.svg);
      background-size: cover;
      width: 340px;
      height: 528px;
      background-repeat: no-repeat;
      background-position: right;

      @media only screen and (max-width: variables.$site-body-width-XL) {
        width: 240px;
      }
    }
  }


  .page-content-sleeve {

    .cs-container {
      color: variables.$case-study-main-text-color;


      @media only screen and (max-width: variables.$site-body-width-S) {
        padding-top: 20px;
        padding-bottom: 20px;
        background: none;
        background-color: variables.$case-study-bg-color;
      }


      h2,
      h3,
      h4,
      h5 {
        color: variables.$case-study-main-text-color;

        @media only screen and (max-width: variables.$site-body-width-S) {
          text-align: left;
        }
      }

      label,
      p {
        color: variables.$paragraph-inverse-color-one;
      }

      h1 {
        color: variables.$case-study-heading-text-color;
      }

      h3.save-amount,
      h4 {
        font-weight: variables.$main-font-weight-light;
        font-family: variables.$heading-font-light;
      }

      h4:not(.float-end) {
        font-family: variables.$heading-font-bold;
        font-size: 20px;
      }

      h1,
      h3 {
        font-weight: variables.$main-font-weight-light;
        font-family: variables.$heading-font-light;
      }

      h4.float-end {
        font-weight: variables.$main-font-weight-light;
        font-family: variables.$heading-font-light;
      }

      .drop-down-selector {
        .dropdown-toggle {
          background: transparent;
          border-color: variables.$white;
        }

        .dropdown-menu {
          width: 100%;
          text-align: center;
        }
      }

      .nav-tabs {
        border-bottom: 1px solid variables.$tab-border-color;

        .nav-item {
          width: 33.33%;
        }

        .nav-link {
          text-decoration: none;
          text-transform: uppercase;
          @include mixins.paragraph ($fontfamily: variables.$main-font-bold,
            $lineheight: 20px,
            $color: variables.$white-secondary,
            $margin: 0);

          &.active {
            background-color: variables.$case-study-bg-color;
            border-top-width: 0px;
            border-left-width: 0px;
            border-right-width: 0px;
            border-bottom-width: 3px;
            text-decoration: none;
            color: variables.$case-study-main-text-color;
          }

          &:not(.active) {
            &:hover {
              border-color: transparent;
            }
          }
        }
      }

      .inverse-content-block,
      .inverse {
        p {
          padding-top: 40px;
          padding-bottom: 20px;
          margin: 0;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            text-align: left;
          }
        }

        button {
          @media only screen and (max-width: variables.$site-body-width-S) {
            width: 100%;
          }
        }
      }

      .two-row-two-col-text,
      .one-row-two-col-text {
        .basic-card {
          justify-content: space-between;
          align-items: center;


          @media only screen and (max-width: variables.$site-body-width-S) {
            justify-content: initial;
            align-items: initial;
          }

          .content-wrapper {
            justify-content: space-between;
            align-items: center;
            margin-top: 8px;
            margin-bottom: 8px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              justify-content: initial;
              align-items: initial;
              flex-direction: column;
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }

      .tab-content {
        padding-top: 20px;

        .tab-pane {
          >div {
            >p {
              color: variables.$white;
            }
          }
        }

        .cs-sleeve {
          margin: 0 auto;
          margin-bottom: 20px !important;

          @media only screen and (max-width: variables.$site-body-width-S) {
            width: auto;
          }

          .cs-cards-sleeve {
            display: flex;
            margin-bottom: 40px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-left: -15px;
              margin-right: -15px;
            }

            .basic-card {
              border: 0;
              border-radius: 0;
              padding: 20px;
              box-shadow: none;
              flex-direction: column;
              display: flex;
              align-items: center;

              label {
                margin-bottom: 12px;
              }

              img {
                margin-bottom: 10px;
              }

              p,
              h1 {
                margin-bottom: 0px;
              }

              .text-link {
                a {
                  color: variables.$case-study-main-text-color;
                }
              }

              @include mixins.link($color: variables.$link-inverse-default-color, $viscolor: variables.$link-inverse-default-color, $hovcolor: variables.$link-inverse-hover-color, $actcolor: variables.$link-inverse-active-color, $foccolor: variables.$link-inverse-active-color );

              @media only screen and (max-width: variables.$site-body-width-S) {
                flex-direction: column;
                p,
                label {
                  text-align: center;
                  font-size: 14px;
                  line-height: 18px;
                  margin: 0px 0px 12px 0px;
                }
              }

              &:nth-child(odd) {
                background-color: variables.$case-study-left-cost-card-bg-color;
                margin-right: 8px;
                border-radius: 12px 0px 0px 12px;

                @media only screen and (max-width: variables.$site-body-width-S) {
                  border: 0;
                  margin-right: 0px;
                  border-radius: 0px 0px 0px 0px;
                }
              }

              &:nth-child(even) {
                background-color: variables.$case-study-right-cost-card-bg-color;
                border-radius: 0px 12px 12px 0px;

                @media only screen and (max-width: variables.$site-body-width-S) {
                  border: 0;
                  border-radius: 0px 0px 0px 0px;
                }
              }
            }
          }

          .cs-data {
            @media screen and (max-width:variables.$site-body-width-S) {
              margin-left: -20px;
              margin-right: -20px;
            }

            >.header {
              h5 {
                padding: 0;
              }
            }

            .row {
              align-items: center;
              margin-left: 0px;
              margin-right: 0px;
              padding-left: 32px;
              padding-right: 32px;
              padding-top: 6px;
              padding-bottom: 6px;

              @media screen and (max-width:variables.$site-body-width-S) {
                padding-left: 20px;
                padding-right: 20px;
              }

              &.header {
                padding-top: 12px;
                padding-bottom: 12px;
                background-color: variables.$case-study-datasection-top-header-row-bg-color;

                &.section-header-top-color {
                  background-color: variables.$case-study-datasection-top-header-row-bg-color;
                }

                &:nth-last-child(2) {
                  background-color: variables.$case-study-datasection-bottom-header-row-bg-color;
                }
              }

              &.top-cap {
                &:first-child {
                  border-radius: 12px 12px 0px 0px;
                }

                @media screen and (max-width:variables.$site-body-width-S) {
                  border-radius: 0px 0px 0px 0px;
                }
              }

              &.bottom-cap {
                border-radius: 0px 0px 12px 12px;

                @media screen and (max-width:variables.$site-body-width-S) {
                  border-radius: 0px 0px 0px 0px;
                }
              }

              &.bottom-cap-summary {
                border-radius: 0px 0px 12px 12px;
                background-color: variables.$white-primary !important;

                h3 {
                  width: 100%;
                  color: variables.$core-color-blue;
                }

                h3:before {
                  content: url(/assets/images/product/case-study/case-study-ribbon.svg)
                }

                @media screen and (max-width:variables.$site-body-width-S) {
                  border-radius: 0px 0px 0px 0px;
                }
              }

              p,
              h4,
              h5 {
                margin-bottom: 0px;
              }

              h5 {
                text-align: left;
              }
            }

            .bg-color-white-25 {
              background-color: variables.$case-study-datasection-bottom-bg-color;
              justify-content: space-between;
            }

            .section {
              &.top {
                background-color: variables.$case-study-datasection-top-bg-color;
              }

              &.bottom {

                .row,
                .hr-line-spacing {
                  background-color: variables.$case-study-datasection-bottom-bg-color;

                  &.table-total {
                    background-color: variables.$case-study-datasection-table-total-row-bg-color;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;

                    h3 {
                      margin: 0px;
                      color: variables.$case-study-datasection-table-total-row-header-color;
                      margin-left: 10px;
                      font-family: variables.$heading-font-bold;
                    }
                  }
                }

              }

              .hr-line-spacing {
                padding-left: 32px;
                padding-right: 32px;
                padding-top: 19px;
              }

              .hr-line {
                border-top: 1px solid variables.$case-study-divider-color;
              }

              .row {
                &:first-child {
                  padding-top: 26px;
                }

                &:last-child {
                  padding-bottom: 20px;
                }
              }

              @media screen and (max-width:variables.$site-body-width-S) {
                h4 {
                  font-family: variables.$main-font-light;
                  font-size: 18px;
                  line-height: 28px;
                }
              }

              .w-75,
              .w-25 {
                padding-left: 0;
                padding-right: 0;
              }

              .w-75 {
                p {
                  color: variables.$white;
                }
              }
            }
          }
        }
      }
    }

    .case-study-dropdown {
      &::after {
        float: right;
        margin-top: 7px;
      }
    }
  }
}

.disclaimer-para {
  margin: 0 auto;
  text-align: center;
  position: relative;

  .cs-abs-img-4 {
    position: absolute;
    left: -250px;
    bottom: 0;
  }
}