@use '../3-helpers/variables';

.popover {
    &.tax-summary-popover {
        .popover-header {
            background-color: variables.$popover-header-bg-color
        }

        .popover-body {
            div {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;

                >span {
                    &:first-child {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}