@use '../3-helpers/variables';

.profile-nav {
	background-color: variables.$inverse-container-color;

	.nav {
		background-color: variables.$profile-nav-bar-background-color;
	}

	.page-content-sleeve {
		min-width: variables.$site-body-width-XL;
		width: fit-content;
	}

	.nav-tabs {
		border-bottom: 0 !important;

		a {
			text-decoration: none;
			padding: 0px 20px 0px 20px;
			color: variables.$profile-nav-text-color;

			i+span {
				text-decoration: underline;
			}
		}

		.nav-link.active {
			color: variables.$profile-nav-text-color;
			background-color: variables.$profile-nav-link-border-background-color;
			border-color: variables.$profile-nav-link-border-background-color;
			font-family: variables.$main-font-bold;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.nav-link {
			margin-bottom: unset;
			padding-top: 18px;
			padding-bottom: 18px;

			&:focus-visible {
				outline-offset: -4px;
			}

		}

		.nav-item {
			padding: 0;
		}

		.tab-badge {
			border-radius: 50%;
			height: 6px;
			width: 6px;
			position: relative;
			top: -8px;
			right: -2px;
		}
	}
}