@use '../3-helpers/mixins';
@use '../3-helpers/variables';

//Each page provides its own container block specifications at app level.
//IE, the product cards themselves will be defined globally but their container may have different dimensions by consuming application or even page.

.page {
  &#product {
    background: variables.$white-primary;
    div[class*="content-sleeve-outer"]:focus {
      outline: none;
    }

    .products-content-sleeve-outer {
      margin-top: 80px;
      margin-bottom: 100px;
    }

    .page-content-sleeve {
      &.value-prop {
        padding-top: 80px;
        padding-bottom: 120px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 40px;
          padding-bottom: 80px;
        }

        @media only screen and (min-width: variables.$site-body-width-XL) {
          padding-right: 0;
          min-height: 697px;
        }
      }

      &:focus {
        outline: none;
      }

      &.how-it-works {
        padding-top: 100px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.brands-you-love {
        padding-top: 60px;
        padding-bottom: 120px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 40px;
          padding-bottom: 60px;
        }
      }

      &.popular-products {
        padding-top: 60px;
        padding-bottom: 160px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 40px;
          padding-bottom: 80px;
        }
      }

      &.product-categories {
        padding-top: 120px;
        padding-bottom: 120px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 40px;
          padding-bottom: 60px;
        }
      }

      &.product-carrier {
        padding-top: 40px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 32px;
          padding-bottom: 60px;
        }
      }

      &.product-compare {
        padding-top: 60px;
        padding-bottom: 60px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 80px;
          padding-bottom: 80px;
        }
      }

      &.product-coverage {
        padding-top: 120px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 100px;
          padding-bottom: 60px;
        }
      }

      &.case-study {
        padding-top: 120px;
        padding-bottom: 120px;
        position: relative;
        z-index: 1;

        .inverse-content-block {
          background-color: transparent !important;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 60px;
          padding-bottom: 60px;
        }
      }

      &.expanded-value-prop {
        padding-top: 100px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .evp-cta {
          margin-top: 52px;
        }
      }

      &.product-editorial {
        padding-top: 180px;
        padding-bottom: 80px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 80px;
          padding-bottom: 0px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }

      &.faqs {
        padding-top: 100px;
        padding-bottom: 120px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 40px;
        }

        .faqs-content {
          align-items: start;
        }

        .faq-sleeve {
          &.col-md-8 {
            width: 100% !important;
          }
        }
      }
    }

    .cs-wave-color {
      background-color: variables.$case-study-bg-color;
    }

    .faq-content-sleeve-outer {
      h2 {
        margin-bottom: 32px;
      }

      @media (max-width: variables.$site-body-width-S) {
        h2 {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.Shamrock {
  .articlepage, .page {
    &#product {
      .vp-content-sleeve-outer {
        background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-green.svg");

        @media only screen and (max-width: variables.$site-body-width-XL) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-shamrock.svg");
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-shamrock.svg");
        }

        .value-prop {
          .vp-sleeve {
            .vp-intro {
              .vp-icon-main {
                background-color: variables.$app-color-shamrock-opacity-01;
              }
            }
          }
        }
      }

      .progress-bar-bg-color {
        background-color: variables.$app-color-shamrock;
      }

      .article-page-bg-image {
        background-image: url("/assets/images/product-article/shamrock-prod-detail-wave-xlarge.svg");

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-shamrock.svg");
        }
      }
    }
  }
}

.Tangerine {
  .articlepage, .page {
    &#product {
      .vp-content-sleeve-outer {
        background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-orange.svg");

        @media only screen and (max-width: variables.$site-body-width-XL) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-tangerinelight.svg");
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-tangerinelight.svg");
        }

        .value-prop {
          .vp-sleeve {
            .vp-intro {
              .vp-icon-main {
                background-color: variables.$app-color-tangerine-opacity-01;
              }
            }
          }
        }
      }

      .progress-bar-bg-color {
        background-color: variables.$app-color-tangerine-light;
      }

      .article-page-bg-image {
        background-image: url("/assets/images/product-article/tangerinelight-prod-detail-wave-xlarge.svg");

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-tangerinelight.svg");
        }
      }
    }
  }
}

.Turquoise {
  .articlepage, .page {
    &#product {
      .vp-content-sleeve-outer {
        background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-blue.svg");

        @media only screen and (max-width: variables.$site-body-width-XL) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-turquoise.svg");
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-turquoise.svg");
        }

        .value-prop {
          .vp-sleeve {
            .vp-intro {
              .vp-icon-main {
                background-color: variables.$app-color-turquoise-opacity-01;
              }
            }
          }
        }
      }

      .progress-bar-bg-color {
        background-color: variables.$app-color-turquoise;
      }

      .article-page-bg-image {
        background-image: url("/assets/images/product-article/turquoise-prod-detail-wave-xlarge.svg");

        @media only screen and (max-width: variables.$site-body-width-S) {
          background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-turquoise.svg");
        }
      }
    }
  }
}