@use '../3-helpers/variables';


.qtc-main-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  @media screen and (max-width: 576px) {
    background-color: variables.$bg-color-fog;

    .col-md-6 {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .qtc-page {
    padding-top: 40px;
    padding-bottom: 150px;

    .header-sleeve {

      p {
        margin: 0px 0px 32px 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin: 0px 0px 20px 0px;
        }
      }
    }

    .progress-bar-head {
      .xng-breadcrumb-trail {
        margin-top: 0;
        margin-bottom: 0;
      }

      .xng-breadcrumb-link {
        text-decoration: none;
      }

      .content {
        display: flex;
        align-items: center;
      }

      .progress-crumb {
        .row {
          align-items: center;
          margin-right: 0px;
        }

        p {
          margin-bottom: 0px;
          text-decoration: underline;
        }
      }
    }

    .basic-card {
      padding: 32px;

      p:last-child {
        margin-bottom: 32px;
      }
    }
  }
}
