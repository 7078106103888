@use '../3-helpers/variables';
@use "../3-helpers/mixins";


.cta-content-sleeve-outer {

  .cta-widgets {

    .cta-widgets-container {

      .basic-card {
        height: 100%;

        @media only screen and (max-width: variables.$site-body-width-L) {
          margin-bottom: 30px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 20px;
        }
      }


      @media only screen and (max-width: variables.$site-body-width-S) {
        h3 {
          margin-bottom: 8px;
        }
      }

      .vertical-line {

        @media only screen and (min-width:variables.$site-body-width-L) {
          border-right: 1px solid variables.$bg-color-shadow;
        }
      }

      @media only screen and (min-width:variables.$site-body-width-L) {

        .event-info-container {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }

      .horizontal-line {
        border-bottom: 1px solid variables.$bg-color-shadow;
        margin: 20px 0px;

        @media only screen and (min-width:variables.$site-body-width-M) {
          margin: 32px 0px;
        }

        @media only screen and (min-width:variables.$site-body-width-L) {
          border-bottom: 0px;
        }
      }

      .active-event-widget {

        .content-top {
          p {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }

            &.window-dates {
              font-family: variables.$main-font-bold;
            }
          }
        }

        .event-widget-intro {
          margin-bottom: 20px;

          @media only screen and (min-width:variables.$site-body-width-M) {
            margin-bottom: 32px;

            h2 {
              text-align: center;
            }
          }

          @media only screen and (min-width:variables.$site-body-width-L) {
            text-align: center;
          }
        }

        .row {
          height: 100%;

          .col-12 {

            p {
              .lg {
                margin-bottom: 30px;

                @media only screen and (max-width:variables.$site-body-width-S) {
                  margin-bottom: 20px;
                }
              }
            }

            button {
              margin-right: 20px;
              margin-top: 20px;

              &:last-child {
                margin-right: 0;
              }

              @media only screen and (max-width:variables.$site-body-width-S) {
                margin-right: 0;
              }
            }

            .widget-content {
              height: 100%;
              display: flex;

              @media only screen and (max-width:variables.$site-body-width-S) {
                flex-direction: column;
              }
            }
          }
        }

        .event-info-container {
          .days-left-section {
            margin-bottom: 20px;

            @media only screen and (min-width:variables.$site-body-width-M) {
              margin-bottom: 0px;

              p {
                font-family: variables.$main-font-light;
                font-size: variables.$text-size-lg;
                line-height: variables.$line-height-lg;
                font-weight: variables.$main-font-weight-light;

                a {
                  font-family: variables.$main-font-light;
                }
              }
            }
          }
        }

        &.multi-event-view {
          .event-info-container {
            .days-left-section {
              margin-bottom: 20px;
              margin-right: 0px;
            }
          }
        }
      }

      .display-direction {
        flex-direction: column;

        @media only screen and (min-width: variables.$site-body-width-M) {
          flex-direction: row;
        }
      }

      .event-icon {
        margin-bottom: 20px;

        @media only screen and (min-width:variables.$site-body-width-M) {
          margin-bottom: 0px;
        }
      }

      .width-50 {
        flex-direction: column;

        @media only screen and (min-width:variables.$site-body-width-M) {
          flex-direction: row;
          flex: 0 0 auto;
          width: 50%;
        }
      }
    }
  }
}

.dark-mode {
  .cta-content-sleeve-outer {
    .cta-widgets {
      .cta-widgets-container {

        .h5.color-1 {
          color: variables.$header-color-one !important;
        }

        .h5.color-3 {
          color: variables.$header-color-three !important;
        }
      }
    }
  }
}
