@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/quote-itemlist';

.itemlist-page {
    .addEditContainer {
        .phone-number {
            display: none;
        }
    }
}