@use '../3-helpers/variables';

.ped-content-sleeve-outer {
  background-image: url(/assets/images/product/product-editorial/graywaves3-xlarge.svg);
  background-size: cover;
  margin-right: -2px;

  .page-content-sleeve {
    &:focus {
      outline: none;
    }

    .ped-container {
      .row {
        margin-right: -10px;
        margin-left: -10px;
      }

      .col-sm-12,
      .col-sm-4,
      .col-sm-6 {
        padding-right: 10px;
        padding-left: 10px;
      }

      .editorial-heading {
        margin-bottom: 40px;
        text-align: center;
      }

      .basic-card {
        padding: 0px;
        border-radius: 4px;
        min-height: 100%;

        .image-product-editorial {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          width: 100%;
          height: auto;
        }

        .article-link {
          cursor: pointer;
          color: variables.$paragraph-color-three;
        }

        .product-editorial-title,
        .article-link {
          padding: 0px 20px;
        }

        .article-content {
          padding: 0px 20px 20px 20px;
        }
      }

    }
  }
}


@media only screen and (max-width: variables.$site-body-width-S) {
  .ped-content-sleeve-outer {
    background-image: url(/assets/images/product/product-editorial/graywaves3-xsmall.svg);
    background-repeat: no-repeat;
    background-size: contain;

    .ped-container {
      background-color: variables.$product-editorial-mobile-background-color;
      padding-bottom: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .editorial-heading {
        margin-bottom: 20px;
      }

      .ped-item {
        margin-bottom: 20px;
      }
    }
  }
}