@use '../3-helpers/variables';


.quote-modal {
    .fa-arrow-left {
        display: none;
    }

    .close-btn {
        background-color: transparent;
        color: variables.$getquote-modal-close-btn-color;
        min-width: 0px;
    }

    p a {
        color: variables.$getquote-modal-product-text-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .products-sleeve {
        display: flex;
        padding: 10px 0px 10px 0px;

        &.selected,
        &:hover {
            border-radius: 4px;
            background-color: variables.$app-color-marine-opacity-008;
            background-position: 10px;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
        .close {
            width: 0%;
            float: none;
            position: relative;
            bottom: 10px;
            margin-left: -10px;
        }

        .fa-times {
            display: none;
        }

        .fa-arrow-left {
            display: block;
        }
    }
}