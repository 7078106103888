@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.modal-content {
  app-user-dependants {


    .dependants-card-modal {
      .button-sleeve {
        margin-top: 32px;
      }

      .participant-records {
        >.row {
          margin-left: -32px;
          margin-right: -32px;
          padding-top: 20px;
          padding-bottom: 20px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-left: -20px;
            margin-right: -20px;
          }

          &.odd {
            background-color: variables.$tbs-odd-row-bg-color;
          }

          .col {
            padding-left: 32px;
            padding-right: 32px;
          }
        }
      }

      .add-control {
        a {
          text-decoration: none;

          .fa-plus {
            font-size: 16px;
          }

          span {
            text-decoration: underline;
          }
        }
      }

      h3 {
        color: variables.$secondary-color-navy;
      }

      input[type=button].prominent,
      button.prominent {
        background-color: variables.$secondary-color-navy;
        border: 1px solid variables.$secondary-color-navy;
      }

      .fa-calendar {
        color: variables.$core-color-marine;
      }
    }
  }
}

.dependents-card {
  margin-bottom: 40px;

  .contextual {
    .message {
      display: flex;
      align-items: start;
    }
  }

  .dependants-container {
    @media only screen and (max-width: variables.$site-body-width-S) {
      .eligible-participants {
        flex-direction: column;
      }
    }

    .fa-lock {
      font-size: 14px;
      line-height: 18px;
      color: variables.$icon-color-three;
    }
  }

  #show-activity-content {
    margin-top: 40px;

    @media only screen and (max-width: variables.$site-body-width-S) {
      margin-top: 20px;
    }
  }

  .button-sleeve {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: variables.$site-body-width-M) {
      align-items: flex-start;
      margin-top: 20px;
    }
  }


}