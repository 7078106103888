@use '../3-helpers/variables';

.q-com-sleeve {
  .q-com-sleeve {
    background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;

    @media screen and (max-width: variables.$site-body-width-S) {
      background-image: url(../../../assets/images/product/graywaves4-xsm.svg);

      .printcontent {
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

  .quote-save {
    p {
      margin-bottom: 0;
      text-decoration: underline;
      color: variables.$paragraph-color-three;
    }

    i {
      margin-right: 10px;
      color: variables.$icon-default-color;
    }
  }

  .basic-card {
    padding: 32px;
    margin-bottom: 20px;

    .total-payment-sablelabel {
      border-top: 1px solid variables.$bg-color-shadow;
      padding-top: 20px;
      font-size: 20px;
    }

    @media screen and (max-width: variables.$site-body-width-S) {
      padding: 20px;
      border-radius: 0px;

      .quote-value {
        flex-direction: column;
      }
    }

    &#first-card {
      margin-top: 40px;
    }

    &#my-info {
      div {
        margin-bottom: 20px;
      }
    }

    ul {
      padding-inline-start: 32px;
      margin-bottom: 32px;
    }

    .data-metric-lg,
    .data-metric-m {
      color: variables.$core-color-blue;
      margin-bottom: 6px;
    }

    .fa-dog,
    .fa-cat {
      color: variables.$icon-default-color;
      font-size: 20px;
      margin-right: 4px;
    }

    .sable-label-pair {
      color: variables.$label-text-color;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .sable-label {
      font-weight: variables.$main-font-weight-bold;
      margin-bottom: 4px;
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .expansion-panel {
    border-top: 1px solid rgba(192, 192, 192, 0.5);
    border-bottom: 1px solid rgba(192, 192, 192, 0.5);
    padding: 8px 0;

    &.accordion-item {
      .accordion-header {
        .header-sleeve {
          .header-text {
            line-height: 20px;
            font-size: 16px;
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .per-pay-text {
    text-align: right;

    @media screen and (max-width: variables.$site-body-width-S) {
      text-align: left;
    }
  }

}