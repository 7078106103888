@use '../3-helpers/variables';

.page-not-found {
    margin-top: 40px;

    .not-found-container {
        max-width: 820px;
        margin: 0 auto;
        flex-flow: column;

        >i {
            font-size: 80px;
            line-height: 88px;
        }

        h6 {
            color: variables.$text-color-gray02;
        }

        >img {
            width: max-content;
            margin: 0 auto 20px;
            max-width: 100%;
        }

        >button {
            margin: 0 auto;
        }

        p {
            &.xl {
                color: black;
            }

            &.errorid {
                color: variables.$text-color-gray02;
            }
        }
    }

}

.client-code-not-found {
    @media screen and (max-width:variables.$site-body-width-S) {
        img {
            height: 300px;
            margin-bottom: 20px;
        }
    }
}

.vb-global-error-page {
    background-image: url(../../../assets/images/vb-global-error-page/graywaves5-xlarge.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 100%;
    padding: 0px 22px;

    @media screen and (max-width: variables.$site-body-width-S) {
        background-image: url(../../../assets/images/vb-global-error-page/graywaves5-xsm.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 100%;
    }

    .error-data {
        display: flex;
        justify-content: center;
    }

    .section-1 {
        padding: 24px;

        @media screen and (max-width: variables.$site-body-width-XL) {
            position: relative;
            padding-top: 0px;
            padding-right: 0px;
            padding-bottom: 0px;
            margin-bottom: 20px;
        }

        @media screen and (min-width: variables.$site-body-width-XL) {
            position: absolute;
        }

        img {
            width: 273px;
            height: 365px;

            @media screen and (max-width: variables.$site-body-width-XL) {
                position: absolute;
                left: -70px;
                bottom: -5px;
                width: 91px;
                height: 130px;
            }
            @media screen and (min-width: variables.$site-body-width-XL) {
                position: absolute;
            }
        }
    }

    .section-2 {
        padding-top: 48px;
        padding-bottom: 96px;

        @media screen and (max-width: variables.$site-body-width-S) {
            width: 65%;
            padding-top: 85px;
            padding-bottom: 130px;
        }

        img {
            width: 80px;
            height: 80px;

            @media screen and (max-width: variables.$site-body-width-S) {
                width: 60px;
                height: 60px;
            }
        }
    }

    .section-3 {
        padding-top: 120px;
        padding-left: 24px;
        padding-bottom: 24px;

        @media screen and (max-width: variables.$site-body-width-S) {
            position: relative;
            padding-top: 0px;
            padding-left: 0px;
            padding-bottom: 0px;
        }

        img {
            width: 270px;
            height: 335px;

            @media screen and (max-width: variables.$site-body-width-S) {
                position: absolute;
                top: 23px;
                width: 84px;
                height: 119px;
                right: -60px;
            }
        }
    }
}