@use '../3-helpers/variables';

.masthead-inbox {
    kendo-grid {
        border-radius: 12px;
        box-shadow: 0px 0px 6px variables.$box-shadow-color;
        background-color: variables.$white;

        thead {
            tr {
                th {
                    background-color: variables.$white;
                    padding: 20px 32px 8px;
                    border: none;
                    border-bottom: 1px solid variables.$bg-color-shadow;
                    font-family: variables.$main-font-bold;
                    font-size: 16px;
                }
            }
        }

        tr {
            td {
                background-color: variables.$white;
                padding: 16px 16px 16px 32px;
                border: none;
                border-bottom: 1px solid variables.$bg-color-shadow;
                font-size: 16px;
            }
        }

        .k-pager-numbers {
            .k-link {
                color: variables.$link-default-color;
                font-size: variables.$text-size-lg;

                &:hover {
                    background-color: transparent;
                    color: variables.$link-default-color;
                }

                &.k-state-selected {
                    background-color: variables.$bg-color-smoke;
                    border-radius: 50%;
                    color: variables.$link-default-color;

                    &:hover {
                        color: variables.$link-default-color;
                    }
                }
            }

        }

        .k-pager-wrap .k-link {
            color: variables.$link-default-color;
        }

        .k-grid-remove-command {
            background: none;
            border: none;
            text-decoration: underline;
        }

        @media screen and (max-width: variables.$site-body-width-M) {
            kendo-pager-numeric-buttons {
                >select {
                    height: 100%;
                }
            }
        }
    }

    .modal-content {
        img {
            max-width: 100%;
            height: auto;
        }

        .modal-body {
            max-height: 50vh;
            overflow: auto;
        }
    }
}

.k-grid-filter-popup {
    .k-filter-menu {
        .k-action-buttons {
            .k-button {
                min-width: auto;
            }
        }
    }
}

.inbox-message-page-sleeve {
    @media screen and (max-width: variables.$site-body-width-S) {
        padding: 20px;
    }

    padding: 32px;
    min-height: 100vh;

    .basic-card {
        &.edit-state {
            background-color: variables.$bg-color-gray07;
        }
    }

    img {
        max-width: 100%;
    }
}