@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.aon-activity-eoiquestions {
    .eoi-header {
        margin-bottom: 40px;
    }

    .banner {
        border-top: 0px;
        padding: 28px 23px 28px 32px;
    }

    .eligibility-questions {
        .title {
            font-size: 20px;
            font-family: variables.$main-font-bold;
            letter-spacing: 0;
            line-height: 24px;
        }
    }

    .container.header p {
        margin: 0 0 12px !important;
    }

    .basic-card {
        padding: 32px 32px 12px 32px;
        margin-bottom: 20px;
    }

    .eligibility-questions-margin {
        margin-bottom: 32px;
    }

    @media screen and (max-width: variables.$site-body-width-S) {
        .container {
            .card {
                margin-bottom: 20px;
            }
        }

        .container.header {
            h3 {
                margin: 0 0 12px !important;
            }

            p {
                margin: 0 0 32px !important;
            }
        }

        .alert-margin {
            padding-left: 20px;
            padding-right: 20px;
        }

        .flow-group {
            padding-left: 20px;
            padding-right: 20px;
        }

        .eligibility-questions-margin {
            margin-bottom: 20px;
        }

        .eoi-header {
            margin-bottom: 32px;
            padding: 0px 20px;
        }

        .eoi-tellus {
            padding: 0px 20px;
        }
    }
}