@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.doc-upload-outer-sleeve {

  .modal-header {
    button.close {
      .fal {
        color: variables.$icon-color-one;
      }
    }
  }

  .modal-body {

    .edit-state {
      padding: 32px;
      height: 50vh;
      overflow: auto;
    }
  }
}


app-participant-documents {
  .participant-component {
    .fa-check-circle {
      color: variables.$feedback-color-success-darker !important;
      width: 20px;
      height: 24px;
    }

    .fa-exclamation-circle {
      color: variables.$feedback-color-warning-darker !important;
      width: 20px;
      height: 24px;
    }
  }
}
