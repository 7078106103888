@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/product-announcement';

.banner_light_blue {

  background-color: variables.$secondary-color-lagoon-lighter !important;

  .aon-announcement-container {

    .announcement-content {
      color: variables.$paragraph-color-one !important;
    }

    i {

      &.announcement-icon {
        color: variables.$core-color-blue !important;
      }

      &.fa-times {
        color: variables.$icon-color-two !important;
      }
    }

  }
}

.banner_dark_blue {

  background-color: variables.$inverse-container-color !important;

  .aon-announcement-container {

    .announcement-content {
      color: variables.$white !important;
    }

    i {

      &.announcement-icon {
        color: variables.$white !important;
      }

      &.fa-times {
        color: variables.$white !important;
      }
    }

  }
}