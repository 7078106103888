@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.modal-dialog {

  .modal-content {
    //WE SHOULD NOT ADD MARGIN TOP TO MODALS, RATHER COMPONENT LEVEL NGB MODAL CONFIG OBJECT SHOULD HAVE CENTERED = TRUE IF REQUIRED
    padding: 32px;
    background: variables.$modal-bg-color;
    box-shadow: 0 0 6px variables.$modal-box-shadow;
    border-radius: 12px;

    @media only screen and (max-width: variables.$site-body-width-S) {
      padding: 20px;
      border-radius: 0px !important;
    }

    .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: none;
      padding-top: 0px;
      padding-bottom: 20px;
      padding-left: 0px;
      padding-right: 0px;

      /*h3 should be the only class used in modal headers, all modal header tags should be h1, either with or without an h3 class depending on the size of the modal*/
      >h1 {
        &.modal-title {
          &.h3 {
            margin-bottom: 0px;
          }
        }
      }

      button {
        &.close {
          margin: 0px;
          margin-left: 10px;
          padding: 0px;
          background-color: transparent;
          color: variables.$button-default-active-color;
          min-width: 0px;
          border: none;
          opacity: 1;
          text-align: right;

          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
          }

          @media screen and (max-width: variables.$site-body-width-S) {
            width: auto;
            margin-right: 0px !important;
          }
        }

        .aon-icon,
        .fal,
        .far,
        .fas {
          color: variables.$icon-default-color;
        }

        &:first-child {
          margin-left: auto;
        }
      }

      p {
        font-family: variables.$main-font-light;
        font-size: variables.$text-size-xl;
        line-height: variables.$line-height-xl;
        font-weight: variables.$main-font-weight-light !important;
        margin: 0px 0px 0px 0px;

        @include mixins.link(variables.$main-font-light);

        @media screen and (max-width:variables.$site-body-width-S) {
          font-size: 17px !important;
          line-height: 24px;
        }
      }
    }

    .modal-body {
      padding: 0px;

      label {
        margin-bottom: 8px;
      }

      .edit-state {
        background-color: variables.$modal-edit-state-panel-bg;

        > p:last-child {
          margin-bottom: 0px;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    ::-webkit-scrollbar-track {
      background-color: variables.$bg-color-smoke;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: variables.$scrollbar-thumb-color;
      border-radius: 4px;
      height: auto;
    }
  }


  .summary-icon {
    position: absolute;
    right: 15px;
    top: 5px;
    color: variables.$icon-default-color;
    cursor: pointer;
  }
}

.modal-backdrop.fade {
  opacity: 0.7;
}

.modal-backdrop {
  background-color: variables.$modal-backdrop-bg-color;
}


.modal-slim {
  max-width: 382px;

  @media screen and (max-width: variables.$site-body-width-S) {
    max-width: unset;
  }

  .modal-content {
    padding: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border: none;
  }

  .modal-body {
    padding: 0;
  }
}

.progress-modalbox {
  .modal-dialog {
    .modal-content {
      padding: 0px;
      border-radius: 0 0 12px 12px;
      border: 0;
    }

    .modal-body {
      button.close {
        margin: 0 0 0 10px;
        padding: 0;
        background-color: transparent;
        color: variables.$getquote-modal-close-btn-color;
        min-width: 0px;
        border: none;
        opacity: 1;
        position: absolute;
        top: 20px;
        right: 10px;
        width: 44px;
        height: 44px;
        text-align: center;
      }
    }
  }
}


/*THIS NEEDS TO BE FIXED IN SABLE-MODAL SO THAT THE HTML HAS THE SAME STRUCTURE AS OUR MODALS
 THIS CODE IS A TEMPORARY FIX TO AT LEAST STYLE THE CURRENT HTML AS CONSISTENTLY AS POSSIBLE FOR THE CLOSE BUTTON
*/
.aon-modal-box {
  button {
    &.close {
      margin: 0 0 0 10px;
      padding: 0;
      background-color: transparent;
      color: variables.$icon-default-color;
      min-width: 0px;
      border: none;
      float: right;
      font-size: 25px;
    }
  }
}
