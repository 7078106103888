@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.forgot-password {
  .forgot-password-sleeve {
    background-color: variables.$inverse-container-color;
    background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
    background-position: bottom left, top 222px right;
    background-repeat: no-repeat, no-repeat;
    background-size: 250px 204px, 207px 227px;
    justify-content: center;
  }

  .icon-margin {
    font-size: 16px;
    line-height: 36px;
    right: 28px;
    width: 19px;
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    .forgot-password-sleeve {
      background-image: url(/assets/images/account/pebbles5.svg);
      background-position: bottom left;
      background-repeat: no-repeat;
      background-size: 125px 102px;

      .page-content-sleeve {
        background-image: none;
      }
    }
  }
}