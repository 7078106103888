@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.registration {
	.registration-sleeve {
		background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
		background-position: bottom left, top 222px right;
		background-repeat: no-repeat, no-repeat;
		background-size: 250px 204px, 207px 227px;

		.page-content-sleeve {
			@include mixins.inline-image($left: -117px, $top: -10px);
		}

		.fa-circle {
			margin-right: 4px;
		}

		/*styling of calender icon in the input*/
		.calendar {
			border-left: transparent;

			&:active,
			&:hover,
			&:focus {
				background-color: transparent;
			}
		}
	}

	/*To handle when we browse the site on mobile screen*/
	@media only screen and (max-width: variables.$site-body-width-S) {
		.registration-sleeve {
			background-image: url(/assets/images/account/pebbles5.svg);
			background-position: bottom left;
			background-repeat: no-repeat;
			background-size: 125px 102px;

			.page-content-sleeve {
				background-image: none;
			}
		}
	}

	&.registration-modal {
		.progress {
			position: relative;
			width: 100%;
			background-color: variables.$secondary-color-teal-light-alpha25 !important;
		}

		.progress-bar {
			background-color: variables.$secondary-color-teal-light !important;
		}

		.close {
			margin-top: 0px !important;
			margin-right: 0px !important;
		}

		.registration-core {
			padding: 0px 32px 32px 32px;

			@media only screen and (max-width: variables.$site-body-width-S) {
				padding: 0px 20px 20px 20px;
			}
		}

		.successstep {
			position: relative;
			top: 2px;
		}

		.express-registration {
			padding: 0px 32px 32px 32px;

			@media only screen and (max-width: variables.$site-body-width-S) {
				padding: 0px 20px 20px 20px;
			}
		}

		.page-only {
			display: none !important;
		}

		.fa-calendar,
		.fa-eye,
		.fa-eye-slash {
			&.secondary {
				line-height: 26px;
				right: 16px;
			}
		}

		.modal-only {
			.fa-info-circle {
				position: relative;
				top: 2px;
			}
		}
	}

	&.registration-page {
		.modal-only {
			display: none !important;
		}

		.fa-calendar,
		.fa-eye,
		.fa-eye-slash {
			&.secondary {
				right: 16px;
				line-height: 28px;
			}
		}
	}

	input {
		border-radius: 4px;
	}

	.validation-failed {
		color: variables.$feedback-color-error !important;
	}

	.validation-success {
		color: variables.$password-rules-icon-success-inverse-color !important;
	}

	&.inverse {

		input[type="text"],
		input[type="checkbox"]+label::before {
			border: 1px solid variables.$white;
			background: transparent;
			color: variables.$input-title-text-color-inverse;
		}

		.btn-inverse-secondary {
			background-color: transparent;
			border-color: variables.$white;
			padding: 0px 16px 0px 16px;
		}

		.fa-circle {
			color: variables.$white;
		}

		+.validation-pending {
			color: variables.$white;
		}

		a:focus-visible,
		button:focus-visible {
			outline: 2px solid #fff !important;
		}
	}

	.step-validation {

		input#HomePostalCode::-webkit-outer-spin-button,
		input#HomePostalCode::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

}

.back-btn {
	min-width: 100px !important;

	@media only screen and (max-width: variables.$site-body-width-S) {
		margin-top: 20px;
	}
}