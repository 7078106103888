@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.payment-transactions {

  .nav-tabs {
    border-bottom: 1px solid variables.$tab-border-color;
    position: relative;
  }

  .nav-link.active {
    padding: 0px 20px 8px 20px;
    border-bottom: 4px solid variables.$tab-link-current-color;
    color: variables.$tab-link-current-color !important;
    cursor: pointer;
  }

  .nav-link:hover {
    padding: 0px 20px 8px 20px;
    border-bottom: 4px solid variables.$tab-link-color;
    color: variables.$tab-link-color !important;
    cursor: pointer;
  }

  .nav-link.active:hover {
    color: variables.$tab-link-current-color;
  }

  .nav-tabs .nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 0px 20px 12px 20px;
    cursor: pointer;
    min-width: 140px;
    background-color: transparent;
    color: variables.$tab-link-color;
    font-size: 16px;
    font-weight: variables.$main-font-weight-bold;
    text-align: center;
    text-transform: uppercase;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  .p-dropdown {
    .dropdown {
      padding: 10px 20px;
      @include mixins.paragraph($fontfamily: variables.$main-font-light, $fontsize: variables.$text-size-xs + 4, $lineheight: 24px, $color: variables.$select-text-color, $weight: variables.$main-font-weight-bold, $margin: 0);
      appearance: none;
      position: relative;
      border-radius: 0;
    }

    .wrap {
      width: 100%;
      position: relative;
      display: block;

      &:after {
        content: "\f0dd";
        font-family: variables.$font-awesome;
        font-size: variables.$text-size-xs + 4;
        color: variables.$icon-color-two;
        position: absolute;
        pointer-events: none;
        width: 10%;
        right: 0;
        top: 30%;
      }
    }
  }

  .w-tab-menu {
    border-bottom: 0px;
    .tab-badge {
      height: 6px;
      width: 6px;
      border-radius: 50%;
      position: relative;
      top: -8px;
      right: 3px;
    }

    .w-tab-content {
      padding-top: 28px;
      position: relative;
      display: block;

      @media only screen and (max-width: variables.$site-body-width-S) {
        padding-top: 0;
      }

      .basic-card {
        padding: 0px 0px 32px 0px;
        border-radius: 12px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          overflow: auto;
        }

        table {

          td:first-child,
          th:first-child {
            padding-left: 32px;
          }

          td:last-child,
          th:last-child {
            padding-right: 32px;
          }

          th {
            padding-top: 20px;
            padding-bottom: 8px;

            p {
              color: variables.$paragraph-color-four;
            }
          }

          td {
            padding-top: 16px;
            padding-bottom: 16px;
          }

          th:not(:first-child),
          td:not(:first-child) {
            padding-left: 12px;
          }

          th:not(:last-child),
          td:not(:last-child) {
            padding-right: 12px;
          }

          th:not(:first-child) {
            border-left: 1px solid variables.$tab-border-color;
          }

          tr {
            border-bottom: 1px solid variables.$tab-border-color;
          }
        }

        app-payment-details {
          .payment-details-not-switch-consented {
            padding: 32px 32px 0px 32px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .payment-methods-container,
  .payment-credit-card-ingenico {
    .entity {
      border: 1px solid variables.$tab-border-color;
      border-radius: 12px;
      padding: 20px;
    }
  }

  .payment-switch {
    padding: 32px 32px 0px 32px;

    @media only screen and (max-width: variables.$site-body-width-S) {
      padding: 0;
    }

    .cancel-btn {
      min-width: 100px !important;
    }

    .confirm-switch-btn {
      padding: 12px 10px;
    }

    .payment-border {
      border: 1px solid variables.$tab-border-color;
      border-radius: 12px;
    }
  }

  .payment-failed-mark {
    color: variables.$feedback-color-error !important;
  }

  #saved-cards {
    color: variables.$paragraph-color-four;
  }
}

@media only screen and (max-width: variables.$site-body-width-S) {
  .profile-page-container {
    .payment-transactions {
      .payment-switch {
        .selectmethod {
          justify-content: space-between;
          flex-wrap: wrap;

          >div {
            flex: 1;
          }

          >button {
            margin-top: 20px;
          }
        }
      }
    }
  }
}