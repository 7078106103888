@use '../3-helpers/variables';


.is-invalid {
    border-radius: 4px;
    border-color: variables.$feedback-color-error;
    border-width: 1px;
    border-style: solid;
}

.is-invalidExpDate {
    border-radius: 4px;
    border-color: #CA110F;
    border-width: 2px;
    border-style: solid;
}

.payment-page {
  padding-bottom: 80px;

  .payment-page-purchase-section {
    @media screen and (max-width: variables.$site-body-width-S) {
      padding-bottom: 40px;
    }
  }
  // Fixed form width for new card selection on payment page
  .fixed-form-width-payment-card {
    @media screen and (min-width: variables.$site-body-width-S) {
      width: variables.$form-control-max-width;
    }

    .form-btn-negative-margin {
      margin-left: -32px;
    }
  }
}

.payment-confirmation {
    .product-title {
        display: flex;
        flex-direction: row;

        &.isTabView{
          flex-direction: column;
          max-width: 55%;
          word-wrap: break-word;
          .policy-status-div{
            justify-items: flex-start;
          }
        }

        @media screen and (max-width: variables.$site-body-width-S) {
            flex-direction: column;
        }
    }
}

.payment-confirm-btn-section {
    margin: 40px 0px 100px 0px;

    @media screen and (max-width: variables.$site-body-width-S) {
        margin: 20px 0px 40px 0px;

        .payment-confirm-continue {
            margin-top: 20px;
        }
    }
}
