@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/quote-coverage-selection';

.aon-activity-coverage {
  .header {
    &.container {
      display: block;
    }
  }
}