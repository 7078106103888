@use '../3-helpers/variables';



.page {

  &#help {

    .help-page {
      scroll-margin-top: 90px;

      .help-page-header {
        background-image: url(../../../assets/images/help/gray-prod-detail-wave-xlarge.svg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 100%;

        @media screen and (max-width: variables.$site-body-width-S) {
          background-image: url(../../../assets/images/help/gray-prod-detail-wave-sm.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 0 100%;
        }

        .page-content-sleeve {
          @media screen and (max-width: variables.$site-body-width-S) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        .help-page-padding {
          padding-top: 40px;

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
          }

          h1 {
            color: variables.$header-color-one;
            padding-bottom: 80px;
            margin-top: 6px;

            @media screen and (max-width: variables.$site-body-width-S) {
              padding-bottom: 40px;
              margin-top: 12px;
            }
          }

          img {
            vertical-align: middle;

            @media screen and (max-width: variables.$site-body-width-S) {
              margin-right: 4px;
              width: 20px;
              height: 20px;
            }
          }
        }

        .vp-help-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 28px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: variables.$value-prop-icon-circle-bg-color;
          background-repeat: no-repeat;
          background-position: center;
          flex-shrink: 0;

          @media only screen and (max-width: variables.$site-body-width-S) {
            display: none;
          }
        }
      }

      .help-details {
        padding-top: 32px;
        padding-bottom: 150px;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 80px;
        }

        section:not(:first-child) {
          h2 {
            margin-top: 40px;
          }
        }

        h2.category-name:not(:first-child) {
          margin-top: 40px;
          margin-bottom: 0px;
        }

        h3 {
          scroll-margin-top: 80px;

          @media screen and (max-width: variables.$site-body-width-S) {
            margin-left: 30px;
          }
        }

        .logo-icon {
          img {
            max-width: 100px;
            height: auto;
            width: auto;
          }
        }
      }

      .help-card {
        background: variables.$card-bg-color;
        border: 1px solid variables.$card-border-color;
        border-radius: 8px;
        width: 100%;
        padding: 32px;

        @media screen and (max-width: variables.$site-body-width-S) {
          border-radius: 0;
          padding: 20px;
        }

        .card {
          border: 0;

          .card-body {
            padding: 0;

            .page-content-sleeve {
              padding: 0;
            }

            p {
              i {

                &.fal,
                &.far {
                  color: variables.$help-page-info-icon-color;
                }
              }
            }
          }

          .accordion .expansion-panel {
            border: 0px;
            border-bottom: 1px solid variables.$bg-color-shadow;
            padding: 20px 0px 20px 0px;
          }
        }
      }

      .icon-mb-6 {
        margin-bottom: 6px;
      }

      p {
        margin: 0px 0px 0px 0px;
      }

      .vertical {
        border: none;
        border-left: 1px solid variables.$bg-color-shadow;
        height: 100%;

        @media screen and (max-width: variables.$site-body-width-M) {
          border: none;
          border-top: 1px solid variables.$bg-color-shadow;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 20px;
        }
      }

      .back-to-top {
        display: flex;
        justify-content: flex-end;
        text-decoration: underline;
        color: variables.$link-default-color;
        padding-top: 20px;

        a:hover {
          color: variables.$link-hover-color;
        }
      }

      @media screen and (max-width: variables.$site-body-width-S) {
        .sticky-dropdown {
          background-color: variables.$select-bg-color;
          position: sticky;
          top: 0px;
          z-index: 10;
        }
      }
    }
  }
}