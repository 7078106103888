@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.self-service {
  &.dark-mode {
    .editable-mbr-site-toolbar {
      .toolbar-header {
        span {
          // Added this to avoid the color changing into white due to parent styling for the dark mode theme
          color: variables.$paragraph-color-one !important;
        }
      }
    }
  }

  .editable-mbr-site-toolbar {
    height: 35px;
    width: 100%;
    background-color: #EEF6F7;
    border: 1px solid #ACC0C4;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > p {
      margin-bottom: 0px !important;
      font-size: 14px !important;
      padding-left: 10px !important;
    }


    &.global-toolbar {
      position: sticky;
      top: 0px;
      z-index: 100;
    }

    &.local-toolbar {
      justify-content: space-between;
      margin-bottom: 0px;
      border-radius: 8px 8px 0px 0px;

      &.spacer {
        margin-bottom: 20px;
      }

      &.inside-card {
        border: 0px;
        border-bottom: 1px solid #ACC0C4;
      }

      &.overlaid {
        position: absolute;
      }

      &.page-top {
        margin-top: 20px
      }
    }

    .toolbar-header {
      display: flex;

      .toolbar-header-label {
        font-weight: variables.$main-font-weight-bold;
      }
    }

    .toolbar-control {
      height: 100%;
      display: flex;
      align-items: center;
      width: 40px;
      justify-content: center;
      cursor: pointer;
      border-left: 1px solid #ACC0C4;

      &::after {
        display: none !important;
      }

      &#menu-main-population-preview {
        display: flex;
        width: auto;
        padding-left: 20px;
        padding-right: 20px;

        .label {
          font-weight: variables.$main-font-weight-bold;
        }

        .population-name {
          margin-right: 10px;
        }
      }
    }

    .toolbar-control-menu-wrappper {
      height: 100%;
    }

    .toolbar-control-dropdown-menu {
      background-color: #EEF6F7;

      ul {
        padding-left: 0px;
        margin-bottom: 0px;

        li {
          cursor: pointer;
          display: flex;
          justify-content: space-between;

          &:not(.header) {
            padding-right: 0px;
          }

          &.header {
            font-family: variables.$main-font-bold;
            background-color: white;
            cursor: default;
          }

          i {
            width: 40px;
            display: flex;
            justify-content: center;
            margin-left: 20px;
          }

          &.dropdown-item {

            &:active,
            &.active {
              background-color: transparent;
              color: unset;
            }
          }
        }
      }
    }
  }

  .content-editor-modal {
    .modal-body {
      .edit-state {
        max-height: 60vh;
        overflow: auto;

        textarea {
          background-color: transparent;
        }

        kendo-fileselect {
          max-width: variables.$form-control-max-width;

          .k-upload-dropzone,
          .k-upload-button-wrap,
          button {
            width: 100%;
          }
        }

        .choose-existing-media-control {
          width: variables.$form-control-max-width;
        }

        .upload-container-queue {
          width: variables.$form-control-max-width;

          .upload-container-queue-list {
            .delete-container {
              cursor: pointer;
              display: flex;
              flex-grow: 1;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
  /* Override the predefined class 'k-upload' */
  .k-upload {
    background-color: #f0f0f000 !important;
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    justify-content: start !important;
  }

  .k-upload-dropzone {
    padding-inline: 0 !important;
  }
  /* Override the predefined class 'k-upload' */


  .k-table .k-checkbox {
    opacity: 1;
    border: 1px solid var(--tbs-theme-interactive-default);
  }

  .k-pager .k-button {
    background-color: var(--tbs-theme-interactive-default) !important;
  }

  .k-pager-md .k-pager-numbers-wrap .k-button {
    margin-right: 1px;
  }

  .publishing-tasks {
    padding-top: 40px;
    padding-bottom: 120px;
  }
}
