@use '../3-helpers/variables';

.aon-activity-coverage {
  .header {
    &.container {
      display: block;
    }
  }

  .container {
    .card {
      background: unset;
      border: unset;
      border-radius: unset;
      box-shadow: unset;
      padding: unset;
      width: unset;

      .card-body {
        .qcs-sleeve {

          &.quote-coverage-selection {
            .select-plan {
              margin: 20px;
            }

            .row {
              margin-right: -10px;
              margin-left: -10px;
            }

            .col-md-4,
            .col-12 {
              padding-right: 10px;
              padding-left: 10px;
            }

            .basic-card {
              &.plan-list {
                .div-border {
                  &.singlebenefit {
                    border: none;
                  }
                }
              }

              &:has(div.div-border.singlebenefit) {
                border: 2px solid variables.$quote-coverage-selection-border-color;
              }
            }
          }

          &.quote-benefit-selection {
            .beneficiary-allocation-block {
              a {
                @media screen and (max-width: variables.$site-body-width-S) {
                  padding: 0;
                }
              }
            }
          }

          .dropdown-menu {
            width: 100%;
          }


          @media screen and (max-width:variables.$site-body-width-S) {
            .expansion-panel {
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          i.fal.fa-plus {
            margin-top: 16px;
          }

          i.fal.fa-plus.n-mt-0 {
            margin-top: 0px !important;
          }

          i.fal.fa-minus {
            margin-top: 16px;
          }

          i.fal.fa-minus.n-mt-0 {
            margin-top: 0px !important;
          }

          button {
            &.dropdown-toggle {
              background-color: transparent;
              color: black;
              border-color: black;
            }
          }

          .dropdown-toggle {
            &:hover {
              border: 2px solid variables.$icon-default-color;
              padding: 11px 33px 11px 11px;
            }

            &::after {
              float: right;
              margin-top: 3%;
            }
          }

          @media screen and (max-width:variables.$site-body-width-S) {
            .contextual {
              .message {
                display: flex;
                align-items: start;
              }
            }
          }

          h1 {
            @media screen and (max-width:variables.$site-body-width-S) {
              margin: 0px 0px 12px 0px;
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          a {
            padding-left: 0px;
            padding-right: 0px;

            @media screen and (max-width:variables.$site-body-width-S) {
              padding-top: 0px;
              padding-bottom: 20px;
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          .plan-list-bottom {

            .card-content-bottom {
              .select-plan {
                display: flex;
              }
            }

            @media screen and (max-width:variables.$site-body-width-S) {
              margin-bottom: 0px;
            }
          }

          .content-left {
            @media screen and (max-width:variables.$site-body-width-S) {
              padding-left: 20px;
              padding-right: 20px;
            }

            input.subtle {
              margin-right: 20px;

              @media screen and (max-width:variables.$site-body-width-S) {
                margin-bottom: 20px;
                margin-right: 0px;
              }
            }
          }

          .logo-image {
            height: 44px;

            @media screen and (max-width:variables.$site-body-width-S) {
              margin-left: 20px;
              margin-top: 20px;
            }
          }

          .coverage-options {
            margin-bottom: 20px;
          }

          .content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }


          p.xl {
            margin: 0px 0px 40px 0px;

            @media screen and (max-width:variables.$site-body-width-S) {
              margin: 0px 20px 20px 20px;
            }
          }

          .plan-list {
            .payment-info {

              h5,
              i {
                color: variables.$header-color-one;
              }
            }

            .pay-label {
              font-family: variables.$main-font-bold;
              background-color: variables.$quote-coverage-pay-background-color;
              font-size: 12px;
              padding: 3px 4px;
              border-radius: 2px;
              color: variables.$core-color-blue;
            }

            @media screen and (max-width:variables.$site-body-width-S) {

              .col-12,
              .col-md-4 {
                margin-left: 0px;
                margin-right: 0px;
              }
            }

            &.row {

              .card-sleeve {
                &.first {
                  padding-right: 10px;
                }

                &.second {
                  padding-right: 10px;
                  padding-left: 10px;
                }

                &.third {
                  padding-left: 10px;
                }
              }
            }

            .plan {


              .plan-cost {
                &.data-metric-m {
                  color: variables.$data-metric-cost-color;
                  font-family: variables.$heading-font-light;
                }
              }

              @media screen and (max-width:variables.$site-body-width-S) {
                margin-bottom: 20px;
              }

              .with-wellness {
                border-radius: 2px;
                float: right;
                margin-top: -5px;
                margin-right: 20px;

                p {
                  padding: 2px 4px 2px 4px;
                  background-color: skyblue;
                }
              }

              .plan-header {
                padding-top: 20px;
                padding-left: 20px;
                padding-right: 20px;
              }

              .plan-benefits {
                padding-left: 20px;
                padding-right: 20px;

                p {
                  color: variables.$paragraph-color-two;
                }
              }

              .plan-cost-panel {
                background-color: variables.$bg-color-gray07;
                padding: 20px;

                p {
                  margin: 0px;
                }
              }


              .plan-data {
                padding-left: 0px !important;
                padding-right: 0px !important;

                img {
                  max-width: 240px;
                  margin-bottom: 8px;
                }
              }

              .price-data {
                text-align: right;
                margin: 0px !important;
                padding-left: 0px !important;
                padding-right: 0px !important;

                @media screen and (max-width:variables.$site-body-width-S) {
                  text-align: left;
                }
              }


              h1 {
                font-family: variables.$main-font-regular;
                font-weight: variables.$main-font-weight-light;
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 6px;
              }

              &.tabbed-card {
                border: 1px solid variables.$tabbed-card-border-color;
              }

              &.div-border {
                border: 2px solid variables.$quote-coverage-selection-border-color;

                .plan-header {
                  padding-top: 19px;
                  padding-left: 19px;
                  padding-right: 19px;
                }

                .plan-benefits {
                  padding-left: 19px;
                  padding-right: 19px;
                }

                .plan-cost-panel {
                  padding-left: 19px;
                  padding-right: 19px;
                }

                .select-plan {
                  margin-left: 19px;
                  margin-right: 19px;
                  margin-bottom: 19px;

                  button {
                    display: flex;
                    background-color: variables.$button-default-active-color;
                    justify-content: center;
                    align-items: center;
                  }
                }

                &.tabbed-card {
                  padding: 31px !important;
                }
              }
            }
          }

          b {
            span {
              color: variables.$header-color-three;
            }
          }

          .benefit-addon-tile {

            &.checked {
              border: 2px solid variables.$quote-coverage-selection-border-color;
              padding: 31px;
            }

            input[type=checkbox] {
              display: none;
            }

            margin-bottom: 20px;
            padding: 32px;

            @media screen and (max-width:variables.$site-body-width-S) {
              padding: 20px;
            }

            .coverage-msg {
              color: variables.$paragraph-color-four;
            }

            label {
              p {
                color: variables.$paragraph-color-one;
              }
            }

            a {
              color: variables.$link-default-color;
              text-decoration: underline;
            }

            .wellness-cover {
              width: variables.$form-control-max-width;

              .aon-form-input-wrapper.select:after {
                content: "\f0d7";
                font-family: variables.$font-awesome;
                font-weight: variables.$main-font-weight-bold;
                font-size: 16px;
                color: variables.$icon-color-two;
                line-height: 24px;
                position: absolute;
                z-index: 1;
                top: 38px;
                right: 16px;
                text-align: center;
                pointer-events: none;
              }

              select {
                appearance: none;
              }

              p {
                font-weight: variables.$main-font-weight-bold;
              }
            }
          }

          .selection-controls {
            padding-left: 20px;
            padding-right: 20px;

            input[type=button] {
              margin-bottom: 20px;
            }
          }

          .coverage-detail-panel {
            h6 {
              text-transform: uppercase;
              margin-top: 0px;
            }

            .sable-label {
              width: 65%;
            }

            .sable-value {
              width: 35%;
              text-align: right;
            }
          }

          @media screen and (max-width:variables.$site-body-width-S) {
            .page-content-sleeve {
              padding-left: 0px;
              padding-right: 0px;
            }
          }
        }
      }

      .fa-check {
        color: variables.$button-default-text-default-color;
      }
    }

    .coverage-detail-panel {
      .sable-label-pair {
        color: variables.$label-text-color;
        font-size: 14px;
        margin-bottom: 10px;
      }

      .sable-label.with-desc {
        cursor: pointer;
        text-decoration-line: underline;
        text-decoration-style: dashed;
        text-underline-position: under;
        -moz-text-decoration-line: underline;
        -moz-text-decoration-style: dashed;
        -webkit-text-decoration-line: underline;
        -webkit-text-decoration-style: dashed;
      }

      .sable-label.with-desc i {
        color: #46535E;
      }

      .sable-value {
        text-align: right;

        .sable-label-value-item {
          width: 100%;
          text-align: right;
          font-size: 14px;
        }
      }
    }
  }


  .flow-group {
    @media screen and (max-width:variables.$site-body-width-S) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.n-coverage-color {
  color: #5D6D78;
}

td.data-metric-m-table {
  text-align: left !important;

}
