@use '../3-helpers/variables';

.active-benefits-container {
  .alert.banner.success p {
    i {
      color: variables.$icon-default-color;
    }
  }

  .fa-phone {
    color: variables.$icon-color-two;
  }

  h1 {
    margin-bottom: 12px;
  }

  .aon-alert.banner p,
  .alert.banner p {
    margin: 8px 0px 12px 0px;
  }

  .benefits-card {
    margin: 20px 0px !important;
    background: variables.$card-bg-color;
    border: 1px solid variables.$card-border-color;
    border-radius: 8px;
    width: 100%;
    padding: 32px;

    @media screen and (max-width: variables.$site-body-width-S) {
      border-radius: 0;
      padding: 20px;
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
    }

    .data-metric-m {
      color: variables.$core-color-navy;
    }

    .flex-col-1 {
      flex: 1 1 0;
    }

    .flex-col-2 {
      flex: 2 1 0;
    }
  }

  .b-b-header {
    border-bottom: 1px solid variables.$box-shadow-color;
  }

  .rt-sidebar-top-position {
    margin-left: 34px;
  }

  .accordion {
    .benefit-expansion-panel {
      border: 0;
      border-bottom: 1px solid variables.$box-shadow-color;
      background-color: variables.$white;
      border-radius: 0px;

      &.accordion-item {
        .accordion-header {
          background-color: variables.$white;
          border: 0px;
          padding: 0px;
          color: variables.$secondary-color-cobalt;

          &:hover {
            cursor: pointer;
          }

          .benefit-header-sleeve {
            display: flex;
            align-items: center;

            i {
              font-size: 20px;
            }

            .benefit-header-text {
              flex-grow: 1;
              color: variables.$expansion-panel-header-color;
              font-weight: variables.$main-font-weight-normal;
              padding: 20px 0px;
              margin-bottom: 0px;

              @media screen and (max-width: variables.$site-body-width-S) {
                padding: 10px 0px;
              }
            }

            .benefit-header-controls {
              color: variables.$expansion-panel-header-color;
            }

            &.expanded {

              .benefit-header-text,
              .benefit-header-controls {
                color: variables.$secondary-color-cobalt;
                font-weight: variables.$main-font-weight-bold;
              }

              &:hover {

                .benefit-header-text,
                .benefit-header-controls {
                  color: variables.$secondary-color-cobalt;
                }
              }
            }
          }
        }

        .accordion-body {
          padding: 0px;

          .benefit-expansion-panel-text {
            background-color: variables.$white;
          }
        }
      }
    }
  }

  .xs-para {
    font-size: variables.$text-size-xs;
    line-height: normal;
  }

  .input_label {
    word-break: break-word;
  }

  .benefits_cost {
    text-align: right;
  }

  .link-button {
    text-align: left;
    background: none !important;
    border: none;
    color: variables.$link-default-color;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
  }

  .row-color {
    background-color: variables.$bg-color-gray07;
  }

  .benefit-relationship {
    flex-basis: 100%;
    color: variables.$core-color-navy;
  }

  .highlight-policy-status {
    background-color: variables.$feedback-color-success-lighter;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
  }
  .policy-status-div {
    margin-top: 4px;
    margin-left: 10px;
    >p {
      display: inline-block;
      margin: 0 !important;
    }
  }
  .popover-gray{
    background-color: variables.$text-color-gray01 !important;
  
  }
  .popover-body{  color: variables.$bg-color-gray08 !important;}
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after{
    border-bottom-color:variables.$text-color-gray01 !important;  }
}

.benefit-modal {
  .fal {
    color: variables.$icon-default-color !important;
  }

  .close-btn {
    background-color: transparent;
    color: variables.$getquote-modal-close-btn-color;
    min-width: 0px;
  }

  .benefits_cost {
    text-align: right;
  }
}

.sub-total {
  color: variables.$core-color-blue;
}
