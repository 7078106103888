@use '../../app-vb-us/2-components/profile-dependents-info';

.dependents-info {
    &.profile-dependents-container {
        .basic-card {
            .phone-number {
                display: none;
            }
        }
    }
}