@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.aon-activity-coverage {
    .flow-group {
        margin-top: 32px;
    }

    .quote-benefit-selection {
        .div-border {
            border: 2px solid variables.$quote-coverage-selection-border-color;
        }

        .fa-check {
            color: #FFFFFF;
        }

        .pay-frequency {
            i {
                color: variables.$icon-color-three;
            }

            .bg-color-slate {
                background-color: variables.$bg-color-slate;
            }

            .popover {
                .arrow {
                    &:after {
                        border-bottom-color: variables.$bg-color-slate;
                    }
                }

                .popover-body {
                    color: variables.$white;
                }
            }
        }

        .plan-list-header {
            .plan-review-quote {
                h3 {
                    display: inline-block;

                    @media screen and (max-width: variables.$site-body-width-S) {
                        display: block;
                    }
                }

                a {
                    @media screen and (max-width: variables.$site-body-width-S) {
                        padding: 0 !important;
                    }
                }
            }
        }

        .plan-dependent {
            .input_label {
                display: inline-block;
                white-space: nowrap;
            }
        }

        .plan-info {
            .coverages-list {
                .plan-price {
                    border-right: 1px solid #979797;
                    padding-right: 20px;
                }

                .coverage-name {
                    display: inline-block;
                }
            }
        }
    }

}