@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.aon-activity-lifestyle-reasons {
    .basic-card {
        .icon-container {
            height: 60px;
            width: 60px;
            flex-grow: 1;
            text-align: right;
            font-size: 50px;
        }
    }
}