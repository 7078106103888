@use "../3-helpers/variables";

app-payment-source-balance-card {
    .payment-source-balance-card-container {
        .payment-source-balance-card {
            width: 100%;
            margin-bottom: 20px;

            .basic-card {
                height: 100%;
            }
        }

        &.horizontal-orientation {
            margin-bottom: 10px;

            @media screen and (max-width: variables.$site-body-width-L) {
                margin-bottom: 0px;
            }
        }

        &.vertical-orientation {
            .row>*:last-child {
                .payment-source-balance-card {
                    margin-bottom: 0px;
                }
            }
        }
    }
}