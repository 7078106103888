@use '../3-helpers/variables';

app-benefit-details-mini {
    .basic-card {
        height: 325px;
        padding: 20px;

        .content-top {
            .tag-container {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                margin-top: -20px;
            }

            .benefit-name {
                overflow-wrap: anywhere;
            }

            .tag {
                margin-right: -20px;
                border-radius: 0px 8px 0px 4px;
            }

            .category-name {
                margin-bottom: 8px;
                display: flex;
                align-items: center;

                img {
                    width: variables.$benefit-card-icon-width;
                    height: variables.$benefit-card-icon-height;
                }
            }
        }

        .content-info {
            flex-grow: 1;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-track {
                background-color: variables.$bg-color-smoke;
                width: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background: variables.$scrollbar-thumb-color;
                border-radius: 4px;
                height: auto;
            }
        }
    }

    .active-benefits-card {
        &.basic-card {
            @media screen and (max-width: variables.$site-body-width-S) {
                ngb-popover-window {
                    .popover-body {
                        max-width: 200px !important;
                    }
                }
            }
        }
    }
}