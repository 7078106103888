@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.page {
  &#campaign {

    .sleeve-outer {
      .page-content-sleeve {
        .page-title {
          margin-bottom: 0px;
          margin-top: 20px;
        }

        &.campaign-content-breadcrumb {
          padding-top: 40px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
          }

          .progress-crumb {
            label {
              display: block;
              width: 250px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .campaign-contents {

      .sleeve-outer {
        .page-content-sleeve {
          padding-top: 40px;
          padding-bottom: 40px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        &:first-child {
          .page-content-sleeve {
            padding-top: 20px;
          }
        }

        &:last-child {
          .page-content-sleeve {
            padding-bottom: 120px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              padding-bottom: 60px;
            }
          }
        }


        &.faq-content-sleeve-outer {
          background-color: variables.$article-faq-background-color;

          .page-content-sleeve {
            &.faq {
              .faqs-content {
                .faq-header {
                  text-align: center;
                }
              }

              @media only screen and (max-width: variables.$site-body-width-S) {
                .faqs-content {
                  .faq-header {
                    text-align: left;
                  }
                }
              }
            }
          }
        }

        &.article-dashboard-features-sleeve-outer {
          background-color: variables.$campaign-feature-background-color;
        }
      }
    }
  }
}