@use "../3-helpers/variables";
@use "../3-helpers/mixins";

aon-tax-modeller-tables-modal {
  .modal-body {
    height: calc(80vh - 40px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-header {
    .modal-title {
      @media screen and (max-width: variables.$site-body-width-S) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 1px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: variables.$bg-color-smoke;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: variables.$scrollbar-thumb-color;
    border-radius: 4px;
    height: 1px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0px;

    .row {
      margin: unset;
    }

    th,
    td {
      border: 0;
      padding: 8px 0px !important;
      border-top: unset !important;
      border-bottom: unset !important;

      @media screen and (max-width: variables.$site-body-width-S) {
        line-height: unset !important;
      }
    }

    .c-align-left {
      text-align: left;
    }

    tr {

      th:not(:first-child),
      td:not(:first-child) {
        text-align: right;
      }

      @media screen and (max-width: variables.$site-body-width-S) {
        th:not(:first-child) {
          &:not(:last-child) {
            padding-left: 6px !important;
            padding-right: 6px !important;
          }
        }

        td:not(:first-child) {
          &:not(:last-child) {
            padding-left: 13px !important;
            padding-right: 13px !important;
          }
        }
      }

      td:first-child,
      th:first-child {
        padding-left: 20px !important;
      }

      td:last-child,
      th:last-child {
        padding-right: 20px !important;
      }
    }

    thead {
      tr {
        background-color: variables.$bg-color-fog;

        th {
          @media screen and (max-width: variables.$site-body-width-S) {
            font-size: 14px;
          }
        }
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: variables.$bg-color-fog;
      }

      tr {
        @media screen and (max-width: variables.$site-body-width-S) {
          td:not(:first-child) {
            font-size: 15px;
          }

          td:first-child {
            font-size: 14px;
          }
        }
      }
    }
  }

  .value-emphasis-table {
    background-color: variables.$bg-color-fog;
    padding: 20px 0px;

    tr:first-child {

      th,
      td {
        padding-bottom: unset !important;
        padding-top: unset !important;

        &:not(:first-child) {
          @media screen and (max-width: variables.$site-body-width-S) {
            font-size: 14px;
          }
        }
      }
    }

    .value-emphasis-table-item td:first-child {
      padding-top: unset !important;
      font-size: 14px;
      line-height: 18px;
    }

    .value-emphasis-table-item td:not(:first-child) {
      @include mixins.data-metric-m;

      @media screen and (max-width: variables.$site-body-width-S) {
        font-size: 18px !important;
      }
    }
  }
}