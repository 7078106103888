@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.active-benefits-plan-container {
  .w-tab-menu {
    .active {
      color: variables.$tab-link-current-color;
    }

    a {
      text-decoration: none;
      background-color: transparent !important;
      color: #9b9b9b;
    }

    .nav-link-container {
      position: relative;

      .badge {
        position: absolute;
        right: 8px;
        top: 10px;
        border-radius: 50%;
        height: 6px;
        width: 6px;
      }
    }
  }

  &.my-plans-active-benefits{
    .w-tab-menu {
      li{
        position: relative;
      }
      a {
        text-decoration: none;
        background-color: transparent !important;
        color: variables.$text-color-gray02;
        border: none;
        min-width: 130px;
        justify-content: center;
        min-height: 100%;
        border-bottom: 4px solid transparent;
  
        &::before{
          content: "";
          position: absolute;
          background-color: variables.$core-color-teal-dark-hover;
          left: 0px;
          bottom: -3px;
          height: 1px;
          width: 100%;
          transform: scaleX(0);
          transform-origin: right bottom;
          transition: transform 0.2s ease;
        }
  
        &:not(.active){
          &:hover{
            border-bottom: 4px solid transparent;
            &::before{
              transform-origin: left top;
              transform: scaleX(1);
            }
          }
        }
  
        &:focus{
          border: none;
        }
  
        &.active {
          color: variables.$tab-link-current-color;
          border-style: solid;
          border-color: variables.$core-color-teal-dark-active;
          border-width: 0 0 4px;
        }
      }

      .badge {
        position: absolute;
        right: 14px;
        top: 10px;
        border-radius: 50%;
        height: 6px;
        width: 6px;
      }
    }
  }

  .basic-card {
    .alert-danger {
      i {
        color: variables.$feedback-color-error-darker;
      }
    }

    .alert-success {
      i {
        color: variables.$feedback-color-success-darker;
      }
    }

    .renewalHeader {
      font-weight: variables.$main-font-weight-bold;
      color: variables.$feedback-color-success-darker;
    }

    .exipredHeader {
      font-weight: variables.$main-font-weight-bold;
      color: variables.$feedback-color-error-darker;
    }

    .claimsHeader {
      font-weight: variables.$main-font-weight-bold;
      color: variables.$core-color-blue;
    }

    .benefit-action {
      #price {
        .data-metric-m {
          color: variables.$core-color-blue;
        }
      }
    }

    .PlanDetails {
      .data-metric-lg {
        color: variables.$core-color-blue;
      }
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      .active-product {
        flex-direction: column;

        .product-title {
          &.flex-nowrap {
            flex-wrap: wrap !important;

            >h3 {
              flex: 1;
              margin: 0 !important;
            }

            .policy-status-div {
              width: 100%;
              margin-top: 12px;

              >p {
                display: inline-block;
                margin: 0 !important;
              }
            }
          }
        }

        .benefit-action {
          #price {
            &.text-end {
              text-align: left !important;

              >h2 {
                margin: 20px 0 !important;

              }
            }
          }
        }
      }
    }
  }

  .alert.banner.success p {
    i {
      color: variables.$icon-default-color;
    }
  }

  .product-title {
    display: inline-flex;
  }

  .benefits-card {
    margin: 8px 0px;
    background: variables.$card-bg-color;
    border: 1px solid variables.$card-border-color;
    border-radius: 8px;
    width: 100%;
    padding: 32px;

    @media screen and (max-width: variables.$site-body-width-S) {
      border-radius: 0;
      padding: 20px;
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
    }

    .data-metric-m {
      color: variables.$core-color-blue;
    }
  }

  .b-b-header {
    border-bottom: 1px solid variables.$box-shadow-color;

    @media screen and (max-width: variables.$site-body-width-S) {
      border-bottom: none;
    }
  }

  .b-b-question-answer {
    border-bottom: 1px solid #a29c9c;
  }

  .b-t-create-account {
    border-top: 1px solid #a29c9c;
  }

  .summary-heading {
    font-family: variables.$main-font-bold;
    font-size: 24px;
    line-height: 32px;
    color: #012774;
    background: none;
    margin: 0 0 20px
  }

  .next-steps-heading {
    font-family: variables.$main-font-bold;
    font-size: 20px;
    line-height: 24px;
    color: #2e2e2e;
    background: none;
    margin: 0 0 20px;
  }

  .rt-sidebar-top-position {
    margin-left: 34px;
  }

  .accordion {
    .benefit-expansion-panel {
      border: 0;
      border-bottom: 1px solid variables.$box-shadow-color;
      background-color: variables.$white;
      border-radius: 0px;

      &.accordion-item {
        .accordion-header {
          background-color: variables.$white;
          border: 0px;
          padding: 0px;
          color: variables.$secondary-color-cobalt;

          &:hover {
            cursor: pointer;
          }

          .benefit-header-sleeve {
            display: flex;
            align-items: center;

            i {
              font-size: 20px;
            }

            .benefit-header-text {
              margin-bottom: 6px;
              flex-grow: 1;
              font-weight: variables.$main-font-weight-normal;
              font-size: 16px;
              margin-top: 6px;
            }

            &.expanded {

              .benefit-header-text,
              .benefit-header-controls {
                color: variables.$secondary-color-cobalt;
                font-weight: variables.$main-font-weight-bold;
              }

              &:hover {

                .benefit-header-text,
                .benefit-header-controls {
                  color: variables.$secondary-color-cobalt;
                }
              }
            }
          }
        }

        .accordion-body {
          padding: 0px;

          .benefit-expansion-panel-text {
            background-color: variables.$white;
          }
        }
      }
    }
  }

  .xs-para {
    font-size: variables.$text-size-xs;
    line-height: normal;
  }

  .input_label {
    word-break: break-word;
  }

  .benefits_cost {
    text-align: right;
  }

  .link-button {
    text-align: left;
    background: none !important;
    border: none;
    color: variables.$link-default-color;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
  }

  .policy-status-div {
    white-space: nowrap;
  }

  .highlight-policy-status {
    background-color: variables.$feedback-color-success-lighter;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
  }

  .highlight-policy-yellow {
    background-color: variables.$feedback-color-warning-lighter;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
  }

  .highlight-policy-red {
    background-color: variables.$feedback-color-error-lighter;
    padding: 2px 4px 2px 4px;
    border-radius: 2px;
  }

  .filter-policy-item-1 {
    -webkit-order: 1;
    /* Safari 6.1+ */
    order: 1;

    @media screen and (max-width: variables.$site-body-width-S) {
      -webkit-order: 2;
      /* Safari 6.1+ */
      order: 2;
    }
  }

  .filter-policy-item-2 {
    -webkit-order: 2;
    /* Safari 6.1+ */
    order: 2;

    @media screen and (max-width: variables.$site-body-width-S) {
      -webkit-order: 1;
      /* Safari 6.1+ */
      order: 1;
    }
  }

  .active-tabConent {
    margin: 32px 0 32px 0;

    @media screen and (max-width: variables.$site-body-width-S) {
      margin: 20px 0 20px 0;
    }
  }

  .row-spacing-header {
    @media screen and (min-width: variables.$site-body-width-M) {
      margin-bottom: 40px;
    }
  }

  .row-spacing {
    margin-bottom: 20px;

    @media screen and (min-width: variables.$site-body-width-M) {
      margin-bottom: 32px;
    }
  }

  .card-title-spacing {
    @media screen and (min-width: variables.$site-body-width-M) {
      margin-bottom: 20px;
    }
  }

  /*Used on VB Asia active benefits page*/
  div#filter {
    order: 1;

    @media screen and (max-width: variables.$site-body-width-S) {
      order: 2;
      margin-bottom: 20px;
    }
  }

  /*END Used on VB Asia active benefits page*/
  /*Used on OLB active benefits page*/
  div {
    &.row {
      &#active-benefits-page-controls {
        #effective-date-picker {
          @media screen and (max-width: variables.$site-body-width-M) {
            margin-bottom: 20px;
          }
        }
      }

      &#pending-benefits-page-controls {
        #pending-benefits-div {
          @media screen and (max-width: variables.$site-body-width-M) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  /*END Used on OLB active benefits page*/

  div#download {
    order: 2;

    @media screen and (max-width: variables.$site-body-width-S) {
      order: 1;
      margin-bottom: 20px;
    }
  }

  div#price {
    text-align: right;

    @media screen and (max-width: variables.$site-body-width-S) {
      text-align: left;
    }
  }


  .payment-schedule {
    p {
      color: variables.$paragraph-color-three;
    }
  }

}

.benefit-modal {
  .close-btn {
    background-color: transparent;
    color: variables.$getquote-modal-close-btn-color;
    min-width: 0px;
  }

  .benefits_cost {
    text-align: right;
  }

}