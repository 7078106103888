@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/masthead-profile';

@media only screen and (max-width: variables.$site-body-width-L) {
  .masthead-products-sleeve {
    padding: 0px;

    .product-content {
      padding: 10px 20px 0 20px !important;

      &:hover,
      &:active,
      &:focus {
        background-color: variables.$secondary-color-navy;
        text-decoration: underline;
      }
    }

    .masthead-content-sleeve {
      padding-left: 20px;
      padding-top: 14px;
    }

    .masthead-product-contents-sleeve {
      min-height: 50px;
    }
  }
}