@use "../3-helpers/variables";

.benefit-selection-beneficiary {
    .allocation-input-wrapper {
        width: 25%;

        @media screen and (max-width: variables.$site-body-width-S) {
            width: 39%;
        }
    }

    hr {
        opacity: 1;
        border-top-color: variables.$bg-color-gray04;
    }
}

.profile-beneficiaries-container {
    .benefit-selection-beneficiary {
        .beneficiary-allocation-type {
            .allocation-input-wrapper {
                width: 30%;
            }

            .basic-card {
                &.edit-state {
                    background-color: variables.$white;
                }
            }
        }

        .non-designated-container {
            .profile-add-edit-beneficiary {
                .basic-card {
                    &.edit-state {
                        background-color: variables.$white;
                    }
                }
            }
        }
    }
}

.non-designated-container {
    margin: 0;
    background: variables.$card-edit-state-bg-color;

    >.beneficiary-allocation-block.col-md-7 {
        padding: 20px;
        border-radius: 8px;

        button:not(:hover) {
            background: variables.$button-ghost-default-color;
        }
    }
}

.designated-container {
    .beneficiary-allocation-block {
        padding-bottom: 20px;
    }
}

.beneficiary-allocation-type {
    @media screen and (max-width: variables.$site-body-width-M) {
        margin-bottom: 20px;
    }
}