@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.ab-content-sleeve-outer {

  .active-benefits-container {

    .basic-card {
      margin-bottom: 20px;
    }
  }

}





.enrolment-summary-content-for-print {
  display: none;
}


kendo-pdf-document {
  .profile-active-benefits {
    app-benefit-details {
      display: block;
      break-inside: avoid;
    }

    .linename-for-print * {
      font-size: 24px !important;
    }

    .active-benefits-plancontainer-for-print {
      background-color: white;
    }

    .basic-card {
      .cost-container {
        float: right;
        text-align: right;
      }
    }

    .nav-tabs {
      &.inverse-content-block {
        &.nav {
          display: none;
        }
      }
    }

    .header-sleeve {
      .header-text {
        color: variables.$expansion-panel-header-color;
        flex-grow: 1;
        font-family: variables.$main-font-bold;
        font-weight: variables.$main-font-weight-normal !important;
        line-height: 24px;
      }
    }

    footer,
    #pending-benefits-page-controls,
    .active-benefits-nav-tabs,
    .hide-perpay-toggle-for-print,
    .hide-pending-date-picker-for-print,
    #active-benefits-print,
    app-event-broadcast-bar,
    .ignore-from-media-print,
    #hide-accordian-benefit-details-for-print,
    #divChatButton,
    #download {
      display: none !important;
    }

    .benefit-details-printcontent {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    #active-tab-for-print,
    #product-logo-for-print,
    #effective-date-picker {
      display: block !important;
    }

    #product-logo-for-print {
      margin-bottom: 20px !important;
      margin-top: 0px !important;
    }

    .mybenefits-margin-alignment-for-print {
      margin-bottom: 10px !important;
      margin-top: 0px !important;
    }

    .cost-container-for-print-alignment {
      position: relative;
      bottom: 0;
      right: 10px;
    }

    .accordion-body {
      .sable-label-pair {
        .sable-label-value-item {
          padding-right: 20px !important;
        }
      }
    }

    .accordion-item.expansion-panel {
      .col-4.text-end {
        p {
          padding-right: 20px;
        }
      }
    }
  }

  .enrolment-summary-content-for-print {
    display: block !important;
  }
}
