@use '../3-helpers/variables';

.aon-activity-sableview {
  .container {
    .card {
      .card-body {
        app-sable-view {
          div {
            sable-dataview {
              app-sable-dataview-card {
                .confirmation-card-color {
                  background-color: variables.$bg-color-pearl;
                  padding: 0px 20px;

                  div {
                    hr {
                      margin-top: 0px;
                      margin-bottom: 0px;
                    }
                  }
                }

                .session-date-font {
                  font-size: 20px;
                  line-height: 24px;
                  font-weight: variables.$main-font-weight-bold;
                  color: variables.$paragraph-color-four;
                }
              }
            }
          }
        }

        .flow-group {
          margin-top: 32px;
        }
      }
    }
  }
}

.aon-activity-sableform {
  .container {
    .card {
      .card-body {
        .aon-width-double {
          margin-bottom: 32px;
          color: variables.$label-text-color;
        }

        textarea {
          margin-bottom: 0px;
        }
      }
    }
  }
}