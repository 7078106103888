@use "../3-helpers/variables";

app-manual-allocation {
    aon-input-currency.validation-error.ng-touched.ng-invalid .k-input-inner {
        border: 2px solid variables.$feedback-color-error;
    }

    aon-input-currency.validation-error.ng-touched.ng-valid .k-input .k-input-inner {
        border: 2px solid variables.$feedback-color-error;
    }

    aon-input-currency.validation-error.ng-untouched.ng-invalid .k-input-inner {
        border: 2px solid variables.$feedback-color-error;
    }

    aon-input-currency.validation-error.ng-untouched.ng-valid .k-input .k-input-inner {
        border: 2px solid variables.$feedback-color-error;
    }

    .validation-error {
        aon-input-currency .k-input-inner {
            border: 2px solid variables.$feedback-color-error;
        }
    }

    .cost-value {
        word-break: break-word;
    }

    .control-wrapper {
        .payment-source-wrapper {
            @media only screen and (max-width: variables.$site-body-width-S) {
                margin-bottom: 20px;
            }
        }

        .payment-source-amount-wrapper {
            .aon-input-control {
                flex-grow: 2;
            }

            .icon-button-sleeve {
                width: auto;
            }
        }
    }
}