@use '../3-helpers/variables';
@use '../3-helpers/mixins';

//When we correctly implement xng breadcrumb application wide this file can be removed//
//The below is a copy of the xng breadcrumb styling switching the xng part of the selectors for aon. Modules which use xng breadcrumb will use the styles injected by xng into the page head//
.custom-aon-breadcrumb {
    .aon-breadcrumb-root {
        margin: 0;
        color: variables.$breadcrumb-main-color;
    }

    .aon-breadcrumb-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 0
    }

    .aon-breadcrumb-item {
        list-style: none
    }

    .aon-breadcrumb-trail {
        display: flex;
        align-items: center;
        color: variables.$breadcrumb-main-color;
        margin-bottom: 0px;
        font-family: variables.$main-font-regular;
        font-weight: variables.$main-font-weight-normal;
    }

    .aon-breadcrumb-link {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: inherit;
        transition: -webkit-text-decoration .3s;
        transition: text-decoration .3s;
        transition: text-decoration .3s, -webkit-text-decoration .3s;
        cursor: pointer;
        text-decoration: underline
    }

    .aon-breadcrumb-link-disabled {
        pointer-events: none;
        cursor: disabled
    }

    .aon-breadcrumb-separator {
        display: flex;
        -webkit-user-select: none;
        user-select: none;
        margin-left: 8px;
        margin-right: 8px
    }
}