@use "../3-helpers/variables";

.benefit-selection-beneficiary {
  .top-container {
    position: relative;
    right: 46%;
  }

  .justify-content-start {
    justify-content: flex-start
  }
}