@use '../3-helpers/variables';

.plan-summary-container {
  .custom-accordion {
    .header-sleeve {
      .header-text {
        h5 {
          color: variables.$expansion-panel-header-color;
        }
      }
    }
  }

  .summary-sleeve {
    margin-bottom: 20px;

    .accordion {
      display: none;
    }
  }

  .basic-card {
    padding: 32px;
  }

  .fa-trash-alt {
    cursor: pointer;
  }
}
