@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.purchase-right-section-sleeve {

  label {
    font-weight: variables.$main-font-weight-normal;
    font-family: variables.$main-font-regular;
  }

  .label-strong {
    font-family: variables.$main-font-bold;
  }


  .label-total-cost {
    font-size: 21px;
  }

  .tax-css {
    label {
      font-size: 14px;
    }
  }

  .benefit-row {
    display: flex;
    align-items: flex-start;
  }

  .benefit-name {
    padding-right: 5px;
    flex-grow: 1;
    font-family: variables.$main-font-regular;
  }

  .benefit-name.benefit-product-type {
    font-family: variables.$main-font-light !important;
    font-weight: variables.$main-font-weight-light;
  }

  .benefit-name.benefit-name-plan {
    font-family: variables.$main-font-bold;
  }

  .benefit-total-cost {
    font-family: variables.$main-font-bold;
  }
}
