@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.page {
  &#article-index {

    .k-listview {
      background-color: variables.$transparent;
    }
  }
}
