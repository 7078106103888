@use '../3-helpers/variables';

/*FAQ PAGE*/


.faq-page {
  scroll-margin-top: 90px;

  .faq-page-header {
    background-image: url(../../../assets/images/faqs/gray-prod-detail-wave-xlarge.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;

    @media screen and (max-width: variables.$site-body-width-S) {
      background-image: url(../../../assets/images/faqs/gray-prod-detail-wave-sm.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 100%;
    }

    .page-content-sleeve {
      @media screen and (max-width: variables.$site-body-width-S) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .faq-page-padding {
      padding-top: 40px;

      @media screen and (max-width: variables.$site-body-width-S) {
        padding-top: 20px;
      }

      h1 {
        color: variables.$header-color-one;
        padding-bottom: 80px;
        margin-top: 6px;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding-bottom: 40px;
          margin-top: 12px;
        }
      }

      img {
        vertical-align: middle;

        @media screen and (max-width: variables.$site-body-width-S) {
          margin-right: 4px;
          width: 20px;
          height: 20px;
        }
      }
    }

    .vp-faq-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 28px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: variables.$value-prop-icon-circle-bg-color;
      background-repeat: no-repeat;
      background-position: center;
      flex-shrink: 0;

      @media only screen and (max-width: variables.$site-body-width-S) {
        display: none;
      }
    }
  }

  .faq-details {
    padding-top: 32px;
    padding-bottom: 106px;
    justify-content: space-between;

    @media screen and (max-width: variables.$site-body-width-S) {
      padding-top: 32px;
      padding-bottom: 60px;
    }

    h4 {
      color: variables.$header-color-two;
    }

    h3 {
      scroll-margin-top: 90px;

      @media screen and (max-width: variables.$site-body-width-S) {
        margin-left: 30px;
      }
    }

    .faq-card {
      background: variables.$card-bg-color;
      border: 1px solid variables.$card-border-color;
      border-radius: 8px;
      width: 100%;
      padding: 32px;

      @media screen and (max-width: variables.$site-body-width-S) {
        border-radius: 0;
        padding: 20px;
      }

      .card {
        border: 0;

        .card-body {
          padding: 0;

          .page-content-sleeve {
            padding: 0;


            .faqs-content {
              display: flex;
              flex-direction: column;

              .faq-sleeve {
                max-width: 100% !important;
                width: 100%;

                .accordion {

                  .expansion-panel {

                    .accordion-header {
                      .header-sleeve {
                        text-decoration: none;

                        &.expanded {
                          padding-bottom: 14px;
                        }
                      }

                      .header-text {
                        font-family: variables.$heading-font-bold;
                      }
                    }

                    .accordion-body {
                      .expansion-panel-text {
                        margin: 0 0 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .back-to-top {
      display: flex;
      justify-content: flex-end;
      text-decoration: underline;
      color: variables.$link-default-color;
      padding-top: 20px;

      a:hover {
        color: variables.$link-hover-color;
      }
    }
  }
}


/*FAQ MODULE (USED ON HOME AND PRODUCT PAGES)*/

.faq-content-sleeve-outer {

  .faqs-content {
    display: flex;
    flex-direction: column;
    align-items: center;


    @media only screen and (max-width: variables.$site-body-width-S) {
      background-image: none;
      margin-top: 0px;
      margin-bottom: 0px;
      align-items: flex-start;
    }

    .faq-header {

      @media only screen and (min-width: variables.$site-body-width-S) {
        margin-bottom: 32px;
      }
    }

    .faq-sleeve {
      width: 100%;

      .accordion {
        .expansion-panel.accordion-item {
          .accordion-header {
            .header-sleeve {
              text-decoration: none;
            }

            .header-text {
              font-family: variables.$heading-font-bold;
            }
          }
        }
      }
    }
  }
}

/*FAQs WITHIN MODALS*/
.modal-content {
  .faqs-content {
    .faq-sleeve {
      max-width: 100%
    }
  }
}
