@use "../3-helpers/variables";

.aon-activity-reviewpurchase {
  &.review-page {
    padding-bottom: 80px;
  }

  .container.header {
    h3 {
      width: 66%;
    }
  }

  .ben-review-quote {

    .basic-card {
      padding: 32px;

      .PlanDetails {
        .plan-details-width {
          flex: 0 0 58.33333333%;

          .relationship-text {
            font-size: 14px;
          }

          .edit-quote,
          .delete-quote {
            color: variables.$icon-default-color;
            cursor: pointer;
          }

          .covered-dependent {
            margin-bottom: 20px !important;
          }
        }

        .total-payment-sablelabel {
          width: 172%;
          border-top: 1px solid variables.$bg-color-shadow;
          padding-top: 20px;
          font-size: 20px;
        }

        .data-metric-m {
          color: variables.$data-metric-cost-color;
        }
      }
    }

    .important-information {
      background-color: variables.$bg-color-smoke;
      box-shadow: 0 0 6px 0 #e0e0e0;
      padding: 20px;
      border-radius: 4px
    }

    hr {
      margin-top: 0px;
      margin-bottom: 0px;
      border-top: 1px solid variables.$expansion-panel-border-bottom-color;
    }

    .expansion-panel {
      border: 0px;
      border-bottom: 0px;
      padding: 0px;
      background-color: transparent;

      &.accordion-item {
        box-shadow: none;

        .accordion-header {
          color: variables.$expansion-panel-header-color;
          background-color: transparent;
          border: 0px;
          padding: 0px;

          &:hover {
            cursor: pointer;
          }

          .header-sleeve {
            display: flex;
            align-items: center;

            i {
              font-size: 16px;
            }

            .header-text {
              color: variables.$expansion-panel-header-color;
              flex-grow: 1;
              font-family: variables.$main-font-bold;
              font-size: 16px;
              line-height: 20px;
            }

            .header-controls {
              color: variables.$expansion-panel-header-color;
            }

            &:hover {

              .header-text,
              .header-controls {
                color: variables.$expansion-panel-header-hover-color;
              }
            }

            &.expanded {

              .header-text,
              .header-controls {
                color: variables.$expansion-panel-header-expanded-color;
              }

              &:hover {

                .header-text,
                .header-controls {
                  color: variables.$expansion-panel-header-hover-color;
                }
              }
            }
          }
        }

        .card-body {
          padding: 0px;
          color: variables.$expansion-panel-text-color;
        }
      }
    }

    .accordion .expansion-panel {
      border-top: 1px solid variables.$expansion-panel-border-bottom-color;
      padding: 8px 0px 8px 0px;

      p {
        margin: 0px;
        padding: 0px;
      }

      &:last-child {
        border-bottom: 1px solid variables.$expansion-panel-border-bottom-color;
      }
    }

    .plan-details-adjusted-cost {
      width: 70%;
      float: right;
    }

    .sable-label-pair {
      color: variables.$label-text-color;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .sable-label {
      font-weight: variables.$main-font-weight-bold;
      margin-bottom: 4px;
    }

    .checkout-continue {
      @media screen and (max-width: variables.$site-body-width-S) {
        margin-bottom: 40px;
      }
    }
  }

  .container {
    .flow-group {
      display: none;
    }

    .divider {
      display: none;
    }

    .card {
      .card-body {
        .flow-group {
          display: unset;
        }
      }
    }
  }

  .hidden-detail {
    display: none !important;
  }

  .hidden-detail-reviewpurchase {
    display: none !important;
  }

}

.checkout-bar {
  color: variables.$paragraph-inverse-color-one !important;
  height: 40px;
  width: 100%;
  background-color: variables.$inverse-container-color;

  .fa-shopping-cart {
    padding: 8px 8px 8px 130px;
    color: variables.$icon-inverse-color !important;
  }

  .checkout {
    height: 24px;
    font-size: 16px;
    font-family: variables.$main-font-bold;
    letter-spacing: 0;
    line-height: 24px;
  }
}

.promo-discount-content-sleeve-outer {

  .promo-discount-applied-msg-sleeve-outer,
  .fa-check-circle {
    color: variables.$feedback-color-success-darker;
  }

  .promo-discount-line-through {
    text-decoration: line-through;
  }

  .sable-label-value-item {
    color: variables.$feedback-color-success-darker;
    font-size: 14px;
    line-height: 18px;
  }

  .sable-label-value-item:before {
    content: "\f645";
    font-family: variables.$font-awesome;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
    padding-right: 8px;
    vertical-align: middle;
    font-weight: variables.$main-font-weight-bold;
  }
}
