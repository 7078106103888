@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.hiw-content-sleeve-outer {

  .page-content-sleeve {
    .hiw-container {

      @include mixins.inline-image($left: 59px, $top: 87px, $height: 654px, $width: 592px);


      .row {
        justify-content: space-between;

        @media only screen and (max-width: variables.$site-body-width-XL) {
          justify-content: space-around;
        }

        .card-img {
          border-radius: 12px;
        }

        .image-wrapper {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom left;
          align-self: baseline;
          padding: 30px;

          @media only screen and (min-width: variables.$site-body-width-XL) {
            background-image: none !important;
          }

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-bottom: 30px;
          }
        }


        .content-wrapper {
          @media only screen and (max-width: variables.$site-body-width-S) {
            padding: 20px;
          }

          .header-title {
            margin-bottom: 40px;
            font-weight: variables.$main-font-weight-bold;
            color: variables.$header-color-one;
            line-height: 36px;

            @media only screen and (max-width: variables.$site-body-width-L) {
              margin-bottom: 28px;
            }

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-bottom: 20px;
            }
          }


          .items {

            p {
              margin-bottom: 0px;
              color: variables.$high-emphasis-primary-text-color;
              line-height: 24px;
            }

            h2,
            h6, .h6 {
              margin-bottom: 12px;
            }

            h6, .h6 {
              color: variables.$low-emphasis-text-color;
            }

            h2 {
              /*Revert to using global H2 in this case so reset style via mixin*/
              @include mixins.heading ($fontfamily: variables.$heading-font-regular, $fontsize: 28px, $lineheight: 36px, $margin: 0px 0px 12px 0px);

              /*Converting h2 to h3 in mobile mode*/
              @media screen and (max-width:variables.$site-body-width-S) {
                @include mixins.heading ($fontfamily: variables.$heading-font-regular, $fontsize: 24px, $lineheight: 38px, $weight: variables.$main-font-weight-bold, $margin: 0px 0px 12px 0px);
              }
            }

            .break-line {
              border-top: 1px solid variables.$how-it-works-divider-color;
              margin-top: 32px;
              margin-bottom: 32px;


              @media only screen and (max-width: variables.$site-body-width-L) {
                margin-top: 20px;
                margin-bottom: 20px;
              }
            }
          }

          button {
            margin-top: 40px;

            @media only screen and (max-width: variables.$site-body-width-L) {
              margin-top: 32px;
            }

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-top: 20px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}