@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.hiw-reversed-content-sleeve-outer {

  .page-content-sleeve {

    .hiw-reversed-container {

      @include mixins.inline-image($left: -5px, $top: -90px, $height: 517px, $width: 529px);

      .header {

        @include mixins.inline-image($left: -50px, $top: -40px, $height: 72px, $width: 72px);
      }


      .hiw-btm-bck-img {
        left: 85%;
        right: 3%;
        margin-top: 48px;
        position: relative;
      }

      .row {
        justify-content: space-between;

        @media only screen and (max-width: variables.$site-body-width-XL) {
          justify-content: space-around;
        }

        .card-img {
          border-radius: 12px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-top: 0px;
          }
        }

        .image-wrapper {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: top left;
          align-self: baseline;
          padding-top: 30px;

          @media only screen and (min-width: variables.$site-body-width-XL) {
            background-image: none !important;
          }

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding: 30px;
            margin-top: 40px;
          }
        }


        .content-wrapper {
          margin-top: 110px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-top: 0px;
          }

          .header-title {
            margin: 0px;
            font-weight: variables.$main-font-weight-bold;
          }

          .items {

            p {
              margin-bottom: 0px;
            }

            h2,
            h6 {
              margin-bottom: 12px;
            }

            h2 {
              font-weight: variables.$main-font-weight-bold;
            }

            li {
              color: variables.$paragraph-color-four;
            }

            .break-line {
              border-top: 1px solid variables.$how-it-works-divider-color;
              margin-top: 32px;
              margin-bottom: 32px;


              @media only screen and (max-width: variables.$site-body-width-L) {
                margin-top: 20px;
                margin-bottom: 20px;
              }
            }
          }

          button {
            margin-top: 40px;
            margin-bottom: 40px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-top: 20px;
              margin-bottom: 20px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}