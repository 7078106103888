@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.anaval-cart {
  &.show {
    border-top: 4px solid variables.$masthead-cart-bg-color !important;

    .text-info {
      .icon-badge-icon {
        color: variables.$icon-inverse-color !important;
      }
    }

    background-color: variables.$masthead-cart-bg-color !important;

    &:hover,
    &:active {
      background-color: variables.$masthead-cart-bg-color !important;

      a.nav-link {
        background-color: variables.$masthead-cart-bg-color !important;

        .icon-badge-icon {
          color: variables.$icon-inverse-color !important;
        }
      }
    }

  }
}

.main-navbar-controls {
  .nav-cart-mobile {
    .cart-dropdown {
      .shopping-cart-container {
        .cart-dropdown-menu {
          .cart-item {
            i.fal {
              color: variables.$icon-inverse-color !important;

              &:hover,
              &:active,
              &:focus {
                color: variables.$icon-inverse-color !important;
              }
            }
          }
        }
      }
    }
  }

  &:has(+ #navbarsDefault) {
    @media screen and (max-width: variables.$site-body-width-M) {
      .cart-dropdown {
        min-width: calc(100vw - 50px) !important;
      }
    }
  }
}

.qContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.disableTooltip+.tooltip {
  display: none !important;
}

.ellipseIcon {
  position: absolute;
  top: 0px;
  align-items: start !important;
}

.cart-dropdown {
  background-color: variables.$masthead-cart-bg-color;
  top: 60px;
  left: unset;
  right: -1px;
  overflow: auto;
  min-width: 400px;
  min-height: 354px;
  max-height: 80vh;
  padding: 0px;
  border-radius: 0px;

  @media screen and (max-width: variables.$site-body-width-M) {
    top: 70px;
    left: auto;
    min-width: 100vw !important;
    width: 100%;
    right: -50px;
    border: none;
  }

  @media only screen and (max-width: 375px) {
    top: 36px;
    left: -2px;
    min-width: 100vw !important;
    width: 100%;
  }

}

.shopping-cart-container {
  .cart-dropdown-menu {

    @media screen and (max-width: variables.$site-body-width-L) {
      width: 100%;
    }


  }

  .vb-cart-sleeve {
    color: variables.$masthead-profile-text-color;
    right: 0px;
    padding: 20px;
    overflow-y: auto;
    z-index: 1;

    H5 {
      margin: 0px 0px 8px 0px;
    }

    a,
    p,
    span {
      color: variables.$white;
    }

    .cart-inverse-color {
      color: variables.$bg-color-gray06;
    }

    .btn-group {
      width: 100%;

      button {
        width: 50%;
      }
    }

    button {
      width: 100%;
    }

    .vb-cart-flex-layout {
      display: flex;
      flex-direction: row;

      .lead-item {
        flex-grow: 1;
        margin: 0px 0px 16px 0px;

        &.product-name {
          text-decoration: underline;
        }
      }
    }

    .vb-cart-cost {
      span:first-child {
        display: block;
      }

      h2 {
        display: inline-block;
        margin: 0px 8px 0px 0px;
      }

      h6 {
        display: inline-block;
        margin: 0px 8px 0px 0px;
      }

      span.xs {
        text-transform: capitalize;
      }
    }

    .fa-exclamation-triangle,
    .fa-edit,
    .fa-trash-alt {
      font-size: 20px;
    }

    .vb-cart-item {
      margin-left: 0px;
      background-color: variables.$masthead-profile-text-hover-bg-color !important;
      height: auto;
      padding: 16px;
      margin-bottom: 20px;

      .vb-item-sleeve {
        width: 100%;
      }

      &.row {
        flex-direction: column;
      }
    }

    .cart-item {
      i.fal {
        color: variables.$icon-inverse-color !important;

        &:hover,
        &:active,
        &:focus {
          color: variables.$icon-inverse-color !important;
        }
      }

      @media only screen and (max-width: 992px) {
        i.fal {
          color: variables.$icon-inverse-color !important;

          &:hover,
          &:active,
          &:focus {
            color: variables.$icon-inverse-color !important;
          }
        }
      }
    }

    .cost-copy {
      font-size: 20px;
      font-family: variables.$main-font-bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    .employee-cost {
      font-weight: variables.$main-font-weight-bold;
    }
  }

  .align-right {
    text-align: end;
  }

  .inverse-button {
    color: variables.$button-default-inverse-text-default-color;
    background-color: variables.$button-default-inverse-color;
    border: 1px solid variables.$button-default-inverse-color;

    &:hover {
      text-decoration: underline;
      background-color: variables.$button-default-inverse-hover-color;
      border: 1px solid variables.$button-default-inverse-hover-color;
    }

    &:active {
      text-decoration: underline;
      background-color: variables.$button-default-inverse-active-color;
      border: 1px solid variables.$button-default-inverse-active-color;
    }
  }
}
