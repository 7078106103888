@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.documents {
  &.profile-document-container {
    .basic-card {
      padding: 32px;
      margin-bottom: 20px;


      &.edit-state {
        padding: 20px;
        margin-bottom: 0px;
      }

      .fa-info-circle {
        color: variables.$profile-page-info-icon-color;
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        p.lg {
          font-weight: variables.$main-font-weight-normal;
          font-size: 16px !important;
          line-height: 24px;
          margin-bottom: 16px !important;
        }
      }
    }

    .other-documents {

      kendo-grid.basic-card {
        padding: 0px;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding: 0px !important;
        }
      }

      .k-table-md {
        @include mixins.paragraph;
        margin: 0px;

        @media screen and (max-width:variables.$site-body-width-S) {
          font-size: variables.$text-size-xs;
          line-height: variables.$line-height-xs;
        }
      }

      kendo-label>.k-label {
        margin-right: 5px;
      }

      caption {
        border-bottom: solid 1px;
      }

      .k-toolbar,
      .k-table-thead,
      .k-grid .k-table-row.k-table-alt-row,
      .k-grid-content-sticky {
        background-color: variables.$white;
      }

      td {
        border-color: transparent;

        a {
          text-decoration: underline !important;
          cursor: default !important;
        }

        .no-export {
          a {
            cursor: pointer !important;
          }
        }

        &:first-child {
          padding-left: 32px;

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-left: 20px;
          }
        }

        &:last-child {
          padding-right: 32px;

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-left: 20px;
          }
        }

        kendo-svg-icon {
          svg {
            display: none;
          }
        }
      }

      th {
        border-color: variables.$card-border-color;
        border-left: 0px;
        border-right: 1px solid variables.$card-border-color;

        &:first-child {
          border-right: 0px;
        }

        &.k-focus {
          outline: black auto 2px !important;
        }

        .k-column-title {
          font-weight: variables.$main-font-weight-bold;
        }

        kendo-svg-icon {
          svg {
            display: none;
          }
        }
      }

      .k-table-td {
        border-bottom: 1px solid variables.$card-border-color;

        &.k-focus {
          outline: black auto 2px !important;
        }
      }

      kendo-grid-toolbar {
        padding: 32px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @media screen and (max-width: variables.$site-body-width-S) {
          padding-left: 20px;
        }
      }

      kendo-pager {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-color: transparent;

        &.k-focus {
          outline: black auto 2px !important;
        }
      }

      kendo-pager-next-buttons,
      kendo-pager-prev-buttons {
        @media screen and (max-width: variables.$site-body-width-S) {
          display: none;
        }
      }

      .k-table-row:hover,
      .k-detail-row {
        background-color: variables.$document-expanded-bg-color;
      }

      .k-table-row:hover .k-grid-content-sticky {
        background-color: variables.$document-expanded-bg-color;
      }

      .remove-row-border {
        td {
          background-color: variables.$document-expanded-bg-color;
          border-bottom: 0px !important;
        }
      }

      .row-details-wrapper {
        overflow-wrap: anywhere;
        padding-top: 24px;
        padding-bottom: 32px;

        @media screen and (max-width: variables.$site-body-width-M) {
          padding-top: 4px;
          padding-bottom: 12px;
        }
      }

      .k-hierarchy-cell .k-i-plus::before,
      .k-i-sort-desc-small::before {
        content: "\f078";
        font-family: variables.$font-awesome;
      }

      .k-hierarchy-cell .k-i-minus::before,
      .k-i-sort-asc-small::before {
        content: "\f077";
        font-family: variables.$font-awesome;
      }
    }
  }
}