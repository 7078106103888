@use '../3-helpers/variables';
@use '../3-helpers/mixins';

@use '../../app-vb-us/2-components/quote-benefit-review';

.aon-activity-reviewpurchase {
  .container.header {

    p {
      display: none;
    }
  }
}