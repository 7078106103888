:root {
  --main-font-bold: Helvetica Now Text Bold, Roboto, Times, sans-serif;
  --main-font-regular: Helvetica Now Text Regular, Roboto, Times, sans-serif;
  --main-font-light: Helvetica Now Text Light, Roboto, Times, sans-serif;
  --heading-font-bold: Helvetica Now Display Bold, Roboto, Times, sans-serif;
  --heading-font-regular: Helvetica Now Display Regular, Roboto, Times, sans-serif;
  --heading-font-light: Helvetica Now Display Light, Roboto, Times, sans-serif;
  --performance-best: #12A88A;
  --performance-good: #8ABD45;
  --performance-okay: #FFA600;
  --performance-bad: #F25D00;
  --performance-worst: #EA2238;
}

html[lang=ja-JP] {
  --main-font-bold: "Noto Sans JP Thin Bold",
  Helvetica Now Text Bold, Roboto, Times, sans-serif;
  --main-font-regular: "Noto Sans JP Thin Regular",
  Helvetica Now Text Regular, Roboto, Times, sans-serif;
  --main-font-light: "Noto Sans JP Thin Light",
  Helvetica Now Text Light, Roboto, Times, sans-serif;
  --heading-font-bold: "Noto Sans JP Thin Bold",
  Helvetica Now Display Bold, Roboto, Times, sans-serif;
  --heading-font-regular: "Noto Sans JP Thin Regular",
  Helvetica Now Display Regular, Roboto, Times, sans-serif;
  --heading-font-light: "Noto Sans JP Thin Light",
  Helvetica Now Display Light, Roboto, Times, sans-serif;
}

.input_label {
  font-family: var(--main-font-regular);
  color: #46535E;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 4px;
}

.input_content {
  font-family: var(--main-font-regular);
  color: #46535E;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}

html {
  background-color: #4C4D4F;
}
html body .app-header-sleeve {
  /*Navbar Styling*/
}
@media only screen and (min-width: 992px) {
  html body .app-header-sleeve {
    position: relative;
  }
}
html body .app-header-sleeve :root {
  --main-font-bold: Helvetica Now Text Bold, Roboto, Times, sans-serif;
  --main-font-regular: Helvetica Now Text Regular, Roboto, Times, sans-serif;
  --main-font-light: Helvetica Now Text Light, Roboto, Times, sans-serif;
  --heading-font-bold: Helvetica Now Display Bold, Roboto, Times, sans-serif;
  --heading-font-regular: Helvetica Now Display Regular, Roboto, Times, sans-serif;
  --heading-font-light: Helvetica Now Display Light, Roboto, Times, sans-serif;
  --performance-best: #12A88A;
  --performance-good: #8ABD45;
  --performance-okay: #FFA600;
  --performance-bad: #F25D00;
  --performance-worst: #EA2238;
}
html body .app-header-sleeve html[lang=ja-JP] {
  --main-font-bold: "Noto Sans JP Thin Bold",
  Helvetica Now Text Bold, Roboto, Times, sans-serif;
  --main-font-regular: "Noto Sans JP Thin Regular",
  Helvetica Now Text Regular, Roboto, Times, sans-serif;
  --main-font-light: "Noto Sans JP Thin Light",
  Helvetica Now Text Light, Roboto, Times, sans-serif;
  --heading-font-bold: "Noto Sans JP Thin Bold",
  Helvetica Now Display Bold, Roboto, Times, sans-serif;
  --heading-font-regular: "Noto Sans JP Thin Regular",
  Helvetica Now Display Regular, Roboto, Times, sans-serif;
  --heading-font-light: "Noto Sans JP Thin Light",
  Helvetica Now Display Light, Roboto, Times, sans-serif;
}
html body .app-header-sleeve .site-masthead {
  /*Masthead Nav bar in mobile mode*/
}
@media only screen and (max-width: 992px) {
  html body .app-header-sleeve .site-masthead .navbar ul.navbar-nav li.nav-item.nav-li a:hover,
  html body .app-header-sleeve .site-masthead .navbar ul.navbar-nav .nav-item-dropdown.nav-li a:hover {
    color: #262836;
    background-color: #CDDBDE !important;
    text-decoration: underline;
  }
  html body .app-header-sleeve .site-masthead .navbar ul.navbar-nav li.nav-item.nav-li.nav-li-login-link,
  html body .app-header-sleeve .site-masthead .navbar ul.navbar-nav .nav-item-dropdown.nav-li.nav-li-login-link {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25) !important;
  }
}
@media only screen and (min-width: 992px) {
  html body .app-content-sleeve {
    margin-top: 0;
  }
}

.page#home .faq-content-sleeve-outer {
  background-image: url("/assets/images/faqs/pebbles5.svg"), url("/assets/images/faqs/pebbles6.svg");
  background-position: 0px bottom, right 20px;
  background-repeat: no-repeat, no-repeat;
  background-size: 200px, 167px;
}
@media only screen and (max-width: 576px) {
  .page#home .faq-content-sleeve-outer {
    background-image: none;
  }
}
.page#home .faq-content-sleeve-outer h2 {
  margin-bottom: 32px;
}
@media (max-width: 576px) {
  .page#home .faq-content-sleeve-outer h2 {
    margin-bottom: 20px;
  }
}
.page#home .see-all-faq {
  margin-top: 32px;
}
@media screen and (max-width: 576px) {
  .page#home .see-all-faq {
    margin-top: 24px;
  }
}
.page#home .aon-notification-banner .notification-banner {
  background-color: #262836 !important;
  border-bottom: 1px solid #FFFFFF;
}
.page#home .aon-notification-banner .notification-banner .announcement-content {
  color: #FFFFFF !important;
}
.page#home .aon-notification-banner .notification-banner a {
  color: #FFFFFF;
}
.page#home .page-content-sleeve.value-prop {
  padding-top: 80px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.value-prop {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.page#home .page-content-sleeve.product-cards {
  padding-top: 60px;
  padding-bottom: 180px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.product-cards {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#home .page-content-sleeve.expanded-value-prop {
  padding-top: 100px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.expanded-value-prop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#home .page-content-sleeve.faqs {
  padding-top: 80px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.faqs {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.page#home .page-content-sleeve.client-content-widget {
  padding-top: 60px;
  padding-bottom: 60px;
}
.page#home .page-content-sleeve.client-content-widget.priority {
  padding-top: 0px;
  padding-bottom: 0px;
}
.page#home .page-content-sleeve.client-content-widget.no-heading {
  padding-top: 0px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.client-content-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#home .page-content-sleeve.pension-content-widget {
  padding-bottom: 60px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.pension-content-widget {
    padding-bottom: 20px;
  }
}
.page#home .page-content-sleeve.broker-info {
  padding-bottom: 60px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.broker-info {
    padding-bottom: 20px;
  }
}
.page#home .page-content-sleeve.privacy-toggle-banner {
  padding-bottom: 30px;
}
@media only screen and (max-width: 576px) {
  .page#home .page-content-sleeve.privacy-toggle-banner {
    padding-bottom: 20px;
  }
}

.page#home .aon-notification-banner .banner_light_blue {
  background-color: #CBEEF6 !important;
  border-bottom: 1px solid #FFFFFF;
}
.page#home .aon-notification-banner .banner_light_blue .announcement-content {
  color: #46535E !important;
}
.page#home .aon-notification-banner .banner_light_blue a {
  color: #46535E !important;
}
.page#home .aon-notification-banner .banner_dark_blue {
  background-color: #262836 !important;
  border-bottom: 1px solid #FFFFFF;
}
.page#home .aon-notification-banner .banner_dark_blue .announcement-content {
  color: #FFFFFF !important;
}
.page#home .aon-notification-banner .banner_dark_blue a {
  color: #FFFFFF !important;
}

.page#product {
  background: rgba(255, 255, 255, 0.87);
}
.page#product div[class*=content-sleeve-outer]:focus {
  outline: none;
}
.page#product .products-content-sleeve-outer {
  margin-top: 80px;
  margin-bottom: 100px;
}
.page#product .page-content-sleeve.value-prop {
  padding-top: 80px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.value-prop {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}
@media only screen and (min-width: 1200px) {
  .page#product .page-content-sleeve.value-prop {
    padding-right: 0;
    min-height: 697px;
  }
}
.page#product .page-content-sleeve:focus {
  outline: none;
}
.page#product .page-content-sleeve.how-it-works {
  padding-top: 100px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.how-it-works {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#product .page-content-sleeve.brands-you-love {
  padding-top: 60px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.brands-you-love {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
.page#product .page-content-sleeve.popular-products {
  padding-top: 60px;
  padding-bottom: 160px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.popular-products {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}
.page#product .page-content-sleeve.product-categories {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.product-categories {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}
.page#product .page-content-sleeve.product-carrier {
  padding-top: 40px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.product-carrier {
    padding-top: 32px;
    padding-bottom: 60px;
  }
}
.page#product .page-content-sleeve.product-compare {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.product-compare {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.page#product .page-content-sleeve.product-coverage {
  padding-top: 120px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.product-coverage {
    padding-top: 100px;
    padding-bottom: 60px;
  }
}
.page#product .page-content-sleeve.case-study {
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 1;
}
.page#product .page-content-sleeve.case-study .inverse-content-block {
  background-color: transparent !important;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.case-study {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.page#product .page-content-sleeve.expanded-value-prop {
  padding-top: 100px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.expanded-value-prop {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#product .page-content-sleeve.expanded-value-prop .evp-cta {
  margin-top: 52px;
}
.page#product .page-content-sleeve.product-editorial {
  padding-top: 180px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.product-editorial {
    padding-top: 80px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.page#product .page-content-sleeve.faqs {
  padding-top: 100px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#product .page-content-sleeve.faqs {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.page#product .page-content-sleeve.faqs .faqs-content {
  align-items: start;
}
.page#product .page-content-sleeve.faqs .faq-sleeve.col-md-8 {
  width: 100% !important;
}
.page#product .cs-wave-color {
  background-color: #262836;
}
.page#product .faq-content-sleeve-outer h2 {
  margin-bottom: 32px;
}
@media (max-width: 576px) {
  .page#product .faq-content-sleeve-outer h2 {
    margin-bottom: 20px;
  }
}

.Shamrock .articlepage#product .vp-content-sleeve-outer, .Shamrock .page#product .vp-content-sleeve-outer {
  background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-green.svg");
}
@media only screen and (max-width: 1200px) {
  .Shamrock .articlepage#product .vp-content-sleeve-outer, .Shamrock .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-shamrock.svg");
  }
}
@media only screen and (max-width: 576px) {
  .Shamrock .articlepage#product .vp-content-sleeve-outer, .Shamrock .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-shamrock.svg");
  }
}
.Shamrock .articlepage#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main, .Shamrock .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
  background-color: rgba(26, 178, 148, 0.1);
}
.Shamrock .articlepage#product .progress-bar-bg-color, .Shamrock .page#product .progress-bar-bg-color {
  background-color: #1AB294;
}
.Shamrock .articlepage#product .article-page-bg-image, .Shamrock .page#product .article-page-bg-image {
  background-image: url("/assets/images/product-article/shamrock-prod-detail-wave-xlarge.svg");
}
@media only screen and (max-width: 576px) {
  .Shamrock .articlepage#product .article-page-bg-image, .Shamrock .page#product .article-page-bg-image {
    background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-shamrock.svg");
  }
}

.Tangerine .articlepage#product .vp-content-sleeve-outer, .Tangerine .page#product .vp-content-sleeve-outer {
  background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-orange.svg");
}
@media only screen and (max-width: 1200px) {
  .Tangerine .articlepage#product .vp-content-sleeve-outer, .Tangerine .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-tangerinelight.svg");
  }
}
@media only screen and (max-width: 576px) {
  .Tangerine .articlepage#product .vp-content-sleeve-outer, .Tangerine .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-tangerinelight.svg");
  }
}
.Tangerine .articlepage#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main, .Tangerine .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
  background-color: rgba(227, 131, 99, 0.1);
}
.Tangerine .articlepage#product .progress-bar-bg-color, .Tangerine .page#product .progress-bar-bg-color {
  background-color: #EFAF8F;
}
.Tangerine .articlepage#product .article-page-bg-image, .Tangerine .page#product .article-page-bg-image {
  background-image: url("/assets/images/product-article/tangerinelight-prod-detail-wave-xlarge.svg");
}
@media only screen and (max-width: 576px) {
  .Tangerine .articlepage#product .article-page-bg-image, .Tangerine .page#product .article-page-bg-image {
    background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-tangerinelight.svg");
  }
}

.Turquoise .articlepage#product .vp-content-sleeve-outer, .Turquoise .page#product .vp-content-sleeve-outer {
  background-image: url("/assets/images/product/value-prop/backgrounds/value-prop-bg-light-blue.svg");
}
@media only screen and (max-width: 1200px) {
  .Turquoise .articlepage#product .vp-content-sleeve-outer, .Turquoise .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xlarge-turquoise.svg");
  }
}
@media only screen and (max-width: 576px) {
  .Turquoise .articlepage#product .vp-content-sleeve-outer, .Turquoise .page#product .vp-content-sleeve-outer {
    background-image: url("/assets/images/product/value-prop/backgrounds/dots7.svg"), url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-turquoise.svg");
  }
}
.Turquoise .articlepage#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main, .Turquoise .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
  background-color: rgba(41, 176, 195, 0.1);
}
.Turquoise .articlepage#product .progress-bar-bg-color, .Turquoise .page#product .progress-bar-bg-color {
  background-color: #29B0C3;
}
.Turquoise .articlepage#product .article-page-bg-image, .Turquoise .page#product .article-page-bg-image {
  background-image: url("/assets/images/product-article/turquoise-prod-detail-wave-xlarge.svg");
}
@media only screen and (max-width: 576px) {
  .Turquoise .articlepage#product .article-page-bg-image, .Turquoise .page#product .article-page-bg-image {
    background-image: url("/assets/images/product/value-prop/backgrounds/valuepropbg-xsmall-turquoise.svg");
  }
}

.app-content-sleeve .page#profile .w-tab-menu {
  margin-bottom: 20px;
}
.app-content-sleeve .page#profile .page-content-sleeve.active-benefits {
  padding-top: 40px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.active-benefits {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.active-benefits-plan {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.active-benefits-plan {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .app-content-sleeve .page#profile .page-content-sleeve.active-benefits-plan .active-benefits-plan-container .w-tab-menu:not(.active-benefits-nav-tabs) .w-tab-link {
    padding: 8px 0 0;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.personal-info {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.personal-info {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.dependents-and-beneficiaries-info {
  padding-top: 40px;
  padding-bottom: 150px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.dependents-and-beneficiaries-info {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.documents {
  padding-top: 40px;
  padding-bottom: 130px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.documents {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.saved-quotes {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.saved-quotes {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.payment-transactions {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.payment-transactions {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.security-info {
  padding-top: 40px;
  padding-bottom: 130px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.security-info {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.app-content-sleeve .page#profile .page-content-sleeve.mid-year-changes-info {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#profile .page-content-sleeve.mid-year-changes-info {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.app-content-sleeve .page#profile .benefits-card {
  margin: 10px;
}

@media only screen and (min-width: 992px) {
  .app-header-sleeve {
    position: relative !important;
  }
}
.app-header-sleeve .site-masthead .tbs-logo,
.app-header-sleeve .site-masthead .client-logo {
  max-width: 200px;
  height: 32px;
}
.app-header-sleeve .site-masthead .user-badge {
  border-radius: 50%;
  height: 6px;
  width: 6px;
  position: relative;
  top: -10px;
  right: -2px;
}
@media only screen and (max-width: 576px) {
  .app-header-sleeve .site-masthead .tbs-logo,
  .app-header-sleeve .site-masthead .client-logo {
    max-height: 28px;
    max-width: 160px;
  }
}
@media only screen and (max-width: 992px) {
  .app-header-sleeve .site-masthead .tbs-logo {
    height: 32px;
    background: url("/assets/images/footer/aon-logo.svg") no-repeat;
  }
  .app-header-sleeve .site-masthead .client-logo {
    height: 32px;
  }
  .app-header-sleeve .site-masthead .navbar.navbar-collapsed {
    background-color: #FFFFFF;
  }
  .app-header-sleeve .site-masthead .navbar.navbar-collapsed .masthead-title {
    max-width: 200px;
    color: inherit;
    margin-right: 0px;
  }
  .app-header-sleeve .site-masthead .logo-container span {
    font-size: 0.75rem;
  }
  .app-header-sleeve .site-masthead button.navbar-toggler {
    min-width: 50px;
  }
  .app-header-sleeve .site-masthead button.navbar-toggler:hover i:before, .app-header-sleeve .site-masthead button.navbar-toggler:active i:before, .app-header-sleeve .site-masthead button.navbar-toggler:focus i:before {
    float: none;
  }
}

.nav-dropdown-list {
  list-style: none;
  padding-left: 0px !important;
}

.app-header-sleeve .badge {
  padding: 0.25em 0.4em;
}
.app-header-sleeve .site-masthead .cart-badge .badge {
  border-radius: 1rem;
}
.app-header-sleeve .site-masthead .cart-badge .badge-danger {
  background-color: #EA2238;
}
.app-header-sleeve .site-masthead .cart-badge .icon-badge-container {
  position: relative;
}
.app-header-sleeve .site-masthead .cart-badge .icon-badge-icon {
  position: relative;
}
.app-header-sleeve .site-masthead .cart-badge .icon-badge {
  font-size: 12px;
  color: white;
  text-align: center;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: absolute;
  top: -8px;
  left: 15px;
}
@media only screen and (max-width: 992px) {
  .app-header-sleeve .site-masthead .cart-badge .icon-badge {
    top: 20px;
    left: 30px;
  }
}

.page.T3 {
  /*Left Navigation Menu Styling*/
}
.page.T3 select {
  padding: 0px 34px 0px 12px;
}
.page.T3 select:not(.open):not(.disabled):not(.error):hover {
  border: 1px solid rgba(0, 123, 182, 0.0784313725);
  padding: 0px 34px 0px 12px;
}
.page.T3 .t3-icon {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 28px;
  margin-left: 7px;
}
.page.T3 .sidebar {
  position: sticky;
}
.page.T3 .sidebar .nav-item {
  word-break: break-word;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}
.page.T3 .sidebar .nav-item .nav-link {
  font-family: var(--main-font-light);
  color: #5D6D78;
  text-decoration: none;
  padding: 6px 20px 6px 20px;
  border-radius: 0px;
}
.page.T3 .sidebar .nav-item .nav-link.nav-link-sub-category.active {
  border-left: none !important;
  padding-left: 20px;
}
.page.T3 .sidebar .nav-item .nav-link.nav-link-sub-category:not(.active):hover {
  border-left: none !important;
  padding-left: 20px;
}
.page.T3 .sidebar .nav-item .nav-link.active {
  border-left: 4px solid #001B1F;
  color: #001B1F;
  font-family: var(--main-font-bold);
  text-decoration: none;
  background-color: transparent;
  padding-left: 16px;
}
.page.T3 .sidebar .nav-item .nav-link:focus-visible {
  outline-offset: -3px;
}
.page.T3 .sidebar .nav-item .nav-link:not(.active):hover {
  border-left: 4px solid #004852;
  color: #004852;
  padding-left: 16px;
}
.page.T3 .xng-breadcrumb-item a {
  text-decoration: underline;
  text-transform: capitalize;
}
@media only screen and (max-width: 576px) {
  .page.T3 .p-dropdown .dropdown {
    background-color: #012774;
    padding: 10px 20px;
    font-family: var(--main-font-light);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #9CDFFF;
    margin: 0;
    appearance: none;
    position: relative;
    border-radius: 0;
  }
  .page.T3 .p-dropdown .wrap {
    width: 100%;
    position: relative;
    display: block;
  }
  .page.T3 .p-dropdown .wrap:after {
    content: "\f0dd";
    font-family: "Font Awesome 6 Pro";
    font-size: 16px;
    color: #9CDFFF;
    position: absolute;
    pointer-events: none;
    width: 10%;
    right: 0;
    top: 30%;
  }
}
.page.T3 .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details {
  padding-top: 60px;
  padding-bottom: 120px;
  justify-content: space-between;
}
.page.T3 .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details.product-coverage-details-nowave {
  padding-top: 80px;
}
@media screen and (max-width: 576px) {
  .page.T3 .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .page.T3 .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details.product-coverage-details-nowave {
    padding-top: 32px;
  }
}
.page.T3 .pd-content-sleeve-outer .page-content-sleeve.product-details {
  padding-top: 80px;
  padding-bottom: 120px;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .page.T3 .pd-content-sleeve-outer .page-content-sleeve.product-details {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.page.T3 .pd-content-sleeve-outer .page-content-sleeve.product-details hr {
  border-top: 1px solid #C0C0C0;
}
.page.T3 .faq-content-sleeve-outer {
  background-image: url(../../../assets/images/product/product-insurance/pet-gray-wave-bkgd-lg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 576px) {
  .page.T3 .faq-content-sleeve-outer {
    background-image: url(../../../assets/images/product/product-insurance/pet-gray-wave-bkgd-sm.svg);
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.page.T3 .faq-content-sleeve-outer .page-content-sleeve.faqs {
  padding-top: 180px;
  padding-bottom: 120px;
  margin-top: 80px;
}
@media only screen and (max-width: 576px) {
  .page.T3 .faq-content-sleeve-outer .page-content-sleeve.faqs {
    margin-top: 20px;
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

/*Additional styles for page when presented within a modal*/
.modal .page.T3 .sidebar {
  top: 0px;
}

.xng-breadcrumb-item a {
  text-decoration: underline;
  text-transform: capitalize;
}

/*Styles for Benefit Info modal treatment of T3 content*/
app-benefit-info-modal #benefit-info-modal .modal-nav-bar {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #EEF6F7;
}
app-benefit-info-modal #benefit-info-modal .modal-nav-bar i.fa-light.fa-chevron-left {
  cursor: pointer;
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information {
  padding-top: 40px;
}
@media only screen and (max-width: 576px) {
  app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information {
    padding-top: 20px;
  }
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve section {
  overflow-y: auto;
  height: calc(100vh - 196px);
  overflow-x: hidden;
}
@media only screen and (max-width: 576px) {
  app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve section {
    height: unset;
  }
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve a {
  display: unset;
  padding: unset;
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve li {
  color: #46535E;
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve ol {
  margin-bottom: 16px;
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve ul {
  list-style-type: disc;
  margin-bottom: 16px;
}
app-benefit-info-modal #benefit-info-modal .page .pd-content-sleeve-outer .page-content-sleeve.benefit-information .benefit-information #product-detail-content-sleeve ul ul {
  list-style-type: circle;
}
app-benefit-info-modal #benefit-info-modal ::-webkit-scrollbar {
  width: 8px;
  height: auto;
}
app-benefit-info-modal #benefit-info-modal ::-webkit-scrollbar-track {
  background-color: #E4E4E4;
  width: 8px;
}
app-benefit-info-modal #benefit-info-modal ::-webkit-scrollbar-thumb {
  background: #5D6D78;
  border-radius: 4px;
  height: auto !important;
}

@media only screen and (max-width: 992px) {
  app-masthead-benefits .modal-dialog {
    max-width: unset;
    width: 100vw;
  }
}
.app-content-sleeve .page#client-content-page .page-content-sleeve {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#client-content-page .page-content-sleeve {
    padding-top: 20px;
    padding-bottom: 40px;
  }
}
.app-content-sleeve .page#client-content-page .button-container button {
  margin-right: 20px;
}
@media only screen and (max-width: 576px) {
  .app-content-sleeve .page#client-content-page .button-container button {
    margin-right: 0px;
    margin-bottom: 20px;
  }
}

.page#wrapper .page-content-sleeve.wrapper-headline-widget {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .page-content-sleeve.wrapper-headline-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#wrapper .page-content-sleeve.wrapper-video {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .page-content-sleeve.wrapper-video {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#wrapper .page-content-sleeve.wrapper-gateway {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .page-content-sleeve.wrapper-gateway {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#wrapper .page-content-sleeve.wrapper-gateway-v2 {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .page-content-sleeve.wrapper-gateway-v2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#wrapper .page-content-sleeve.wrapper-features {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .page-content-sleeve.wrapper-features {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#wrapper .sleeve-outer:last-child .page-content-sleeve {
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#wrapper .sleeve-outer:last-child .page-content-sleeve {
    padding-bottom: 60px;
  }
}

.page.legal-content .page-content-sleeve {
  padding-top: 40px;
}
.page.legal-content .page-content-sleeve .page-only {
  padding-bottom: 150px;
}
.page.legal-content .page-content-sleeve ul,
.page.legal-content .page-content-sleeve ol {
  margin-bottom: 16px;
}

.modal-content .page.legal-content {
  background-color: transparent;
  min-height: unset;
}
.modal-content .page.legal-content .page-content-sleeve {
  padding: 0px;
}

.page#article .progress-crumb label {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page#article .page-content-sleeve .page-title {
  margin-bottom: 0px;
  margin-top: 20px;
}
.page#article .page-content-sleeve.article-content-breadcrumb {
  padding-top: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article .page-content-sleeve.article-content-breadcrumb {
    padding-top: 20px;
  }
}
.page#article .page-content-sleeve.article-content {
  padding-top: 20px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#article .page-content-sleeve.article-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article .page-content-sleeve.article-coverage {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#article .page-content-sleeve.article-coverage {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article .page-content-sleeve.related-articles {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 576px) {
  .page#article .page-content-sleeve.related-articles {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article .page-content-sleeve.faq {
  padding-top: 80px;
  padding-bottom: 80px;
}
.page#article .page-content-sleeve.faq .faqs-content .faq-header {
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .page#article .page-content-sleeve.faq {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page#article .page-content-sleeve.faq .faqs-content .faq-header {
    text-align: left;
  }
}
.page#article .sleeve-outer:last-child .page-content-sleeve {
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#article .sleeve-outer:last-child .page-content-sleeve {
    padding-bottom: 60px;
  }
}
.page#article .faq-content-sleeve-outer {
  background-color: #FFFFFF;
}
@media screen and (max-width: 576px) {
  .page#article #download, .page#article-dashboard #download, .page#campaign #download {
    justify-content: unset;
    margin-top: 20px;
  }
}
.page#article #download .download-heading, .page#article-dashboard #download .download-heading, .page#campaign #download .download-heading {
  justify-content: start;
}
.page#article #download .download-heading .header, .page#article-dashboard #download .download-heading .header, .page#campaign #download .download-heading .header {
  margin-bottom: 8px;
  text-transform: capitalize;
}
.page#article #download .download-link, .page#article-dashboard #download .download-link, .page#campaign #download .download-link {
  display: flex;
  justify-content: start;
  width: fit-content;
}
.page#article #download .download-link .fa-download, .page#article-dashboard #download .download-link .fa-download, .page#campaign #download .download-link .fa-download {
  margin-right: 8px;
  cursor: pointer;
}

.page#article-dashboard .progress-crumb label {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page#article-dashboard .article-dashboard-hero {
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .article-dashboard-hero {
    padding-bottom: 20px;
  }
}
.page#article-dashboard .page-content-sleeve.article-dashboard-breadcrumb {
  padding-top: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .page-content-sleeve.article-dashboard-breadcrumb {
    padding-top: 20px;
  }
}
.page#article-dashboard .page-content-sleeve.article-dashboard-features {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .page-content-sleeve.article-dashboard-features {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article-dashboard .page-content-sleeve.article-dashboard-coverage {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .page-content-sleeve.article-dashboard-coverage {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article-dashboard .page-content-sleeve.articles-widget {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .page-content-sleeve.articles-widget {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article-dashboard .page-content-sleeve.article-dashboard-suggested-benefits {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .page-content-sleeve.article-dashboard-suggested-benefits {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#article-dashboard .sleeve-outer:last-child .page-content-sleeve {
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#article-dashboard .sleeve-outer:last-child .page-content-sleeve {
    padding-bottom: 60px;
  }
}

.page#article-index .k-listview {
  background-color: transparent;
}

.page.custom-logged-off-page .page-content-sleeve {
  padding-top: 40px;
  padding-bottom: 150px;
}
.page.custom-logged-off-page .page-content-sleeve ul,
.page.custom-logged-off-page .page-content-sleeve ol {
  margin-bottom: 16px;
}

.page#campaign .sleeve-outer .page-content-sleeve .page-title {
  margin-bottom: 0px;
  margin-top: 20px;
}
.page#campaign .sleeve-outer .page-content-sleeve.campaign-content-breadcrumb {
  padding-top: 40px;
}
@media only screen and (max-width: 576px) {
  .page#campaign .sleeve-outer .page-content-sleeve.campaign-content-breadcrumb {
    padding-top: 20px;
  }
}
.page#campaign .sleeve-outer .page-content-sleeve.campaign-content-breadcrumb .progress-crumb label {
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page#campaign .campaign-contents .sleeve-outer .page-content-sleeve {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .page#campaign .campaign-contents .sleeve-outer .page-content-sleeve {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.page#campaign .campaign-contents .sleeve-outer:first-child .page-content-sleeve {
  padding-top: 20px;
}
.page#campaign .campaign-contents .sleeve-outer:last-child .page-content-sleeve {
  padding-bottom: 120px;
}
@media only screen and (max-width: 576px) {
  .page#campaign .campaign-contents .sleeve-outer:last-child .page-content-sleeve {
    padding-bottom: 60px;
  }
}
.page#campaign .campaign-contents .sleeve-outer.faq-content-sleeve-outer {
  background-color: #FFFFFF;
}
.page#campaign .campaign-contents .sleeve-outer.faq-content-sleeve-outer .page-content-sleeve.faq .faqs-content .faq-header {
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .page#campaign .campaign-contents .sleeve-outer.faq-content-sleeve-outer .page-content-sleeve.faq .faqs-content .faq-header {
    text-align: left;
  }
}
.page#campaign .campaign-contents .sleeve-outer.article-dashboard-features-sleeve-outer {
  background-color: #FFFFFF;
}

.product-intro {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 480px;
  max-height: 687px;
  overflow: hidden;
}
@media only screen and (max-width: 1200px) {
  .product-intro {
    min-height: 420px;
  }
}
@media only screen and (max-width: 992px) {
  .product-intro {
    min-height: 340px;
  }
}
@media only screen and (max-width: 576px) {
  .product-intro {
    background-size: contain;
    padding-top: 144px;
    max-height: unset;
  }
}
.product-intro .product-intro-sleeve {
  background-image: url("/assets/images/home/hero/backgrounds/herobg-xlarge.svg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 687px;
}
.product-intro .product-intro-sleeve .break-line {
  background-color: #82939A;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1600px) {
  .product-intro .product-intro-sleeve .break-line {
    background-color: #82939A;
  }
}
.product-intro .product-intro-sleeve .product-intro-text {
  padding-left: 0px;
  padding-right: 0px;
}
.product-intro .product-intro-sleeve .product-intro-text .hero-subheader {
  color: #262836;
  font-weight: 700;
}
@media only screen and (max-width: 576px) {
  .product-intro .product-intro-sleeve .product-intro-text .product-title {
    max-width: 275px;
    width: 100%;
  }
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve {
  margin: 0px;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product {
  padding-left: 0px;
  padding-right: 0px;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product .product-contents-sleeve {
  background-repeat: no-repeat;
  background-position: 0px;
  padding: 10px 0px 10px 32px;
  cursor: pointer;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product .product-contents-sleeve.selected, .product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product .product-contents-sleeve:hover {
  border-radius: 4px;
  background-color: rgba(0, 123, 182, 0.0784313725);
  margin-left: -10px;
  padding-left: 42px;
  background-position: 10px;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product:nth-child(odd) .product-contents-sleeve {
  margin-right: 32px;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product p.product-name {
  margin: 0px;
}
@media only screen and (max-width: 575px) {
  .product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product p.product-name {
    font-size: 12px;
  }
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product p.product-name a {
  color: #46535E;
  text-decoration: none;
}
.product-intro .product-intro-sleeve .product-intro-text .products-sleeve .product p.product-name a:hover {
  text-decoration: underline;
}
.product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner .hero-header {
  word-break: break-word;
}
.product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner p {
  font-family: var(--main-font-light);
}
@media screen and (min-width: 1200px) {
  .product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner p {
    font-size: 21px !important;
    line-height: 28px !important;
  }
}
@media screen and (min-width: 576px) and (max-height: 1200px) {
  .product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner p {
    font-size: 18px;
    line-height: 24px;
  }
}
.product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner p > a, .product-intro .product-intro-sleeve .product-intro-text .text-top-section .text-inner p:not(.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat, .aon-icon, .strong) {
  font-family: var(--main-font-light);
}
@media screen and (min-width: 576px) {
  .product-intro .product-intro-sleeve input.prominent {
    margin-right: 20px;
  }
}
@media only screen and (min-width: 1200px) {
  .product-intro#home-hero .product-intro-sleeve, .product-intro#product-hero .product-intro-sleeve {
    overflow: hidden;
    width: 656px;
    margin-left: 100px;
    padding: 60px 86px 60px 90px;
    min-height: 480px;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text, .product-intro#product-hero .product-intro-sleeve .product-intro-text {
    width: 480px;
  }
}
@media only screen and (max-width: 1200px) {
  .product-intro#home-hero, .product-intro#product-hero {
    background-position: top right;
  }
  .product-intro#home-hero .product-intro-sleeve, .product-intro#product-hero .product-intro-sleeve {
    overflow: hidden;
    background-position-x: -80px;
    padding: 40px 0 40px 40px;
    width: 656px;
    min-height: 420px;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text, .product-intro#product-hero .product-intro-sleeve .product-intro-text {
    width: 430px;
  }
}
@media only screen and (max-width: 992px) {
  .product-intro#home-hero .product-intro-sleeve, .product-intro#product-hero .product-intro-sleeve {
    background-size: 992px;
    min-height: 340px;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text .text-top-section h2, .product-intro#product-hero .product-intro-sleeve .product-intro-text .text-top-section h2 {
    color: #262836;
  }
}
@media only screen and (max-width: 576px) {
  .product-intro#home-hero .product-intro-sleeve, .product-intro#product-hero .product-intro-sleeve {
    background-image: url("/assets/images/home/hero/backgrounds/herobg-xsmall.svg");
    background-position: top;
    padding: 44px 20px 20px 20px;
    width: 100%;
    max-height: unset;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text, .product-intro#product-hero .product-intro-sleeve .product-intro-text {
    width: 100%;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text .text-top-section h2, .product-intro#product-hero .product-intro-sleeve .product-intro-text .text-top-section h2 {
    color: #262836;
    margin-bottom: 8px;
    width: 70%;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text .text-top-section .text-inner, .product-intro#product-hero .product-intro-sleeve .product-intro-text .text-top-section .text-inner {
    padding-top: 20px;
  }
  .product-intro#home-hero .product-intro-sleeve .product-intro-text .hero-subheader, .product-intro#product-hero .product-intro-sleeve .product-intro-text .hero-subheader {
    margin-bottom: 6px !important;
  }
  .product-intro#home-hero .product-intro-sleeve button.prominent, .product-intro#product-hero .product-intro-sleeve button.prominent {
    width: 100%;
  }
}

.home-hero .text-bottom-section-category .hero-subheader {
  color: #46535E !important;
  font-weight: 700;
}
.home-hero .text-bottom-section-category .basic-card {
  min-height: 100%;
  border-radius: 4px;
  padding: 20px;
}
@media screen and (max-width: 576px) {
  .home-hero .text-bottom-section-category .basic-card {
    padding: 16px 20px !important;
  }
}
.home-hero .text-bottom-section-category .basic-card .category-names {
  flex-direction: column;
}
@media screen and (max-width: 576px) {
  .home-hero .text-bottom-section-category .basic-card .category-names {
    flex-direction: row;
    align-items: center;
  }
}
.home-hero .text-bottom-section-category .basic-card h5 {
  color: #007585;
  cursor: pointer;
}
.home-hero .text-bottom-section-category .category-icon {
  background-repeat: no-repeat;
  min-height: 36px;
  min-width: 36px;
  cursor: pointer;
  margin-bottom: 8px;
}
@media screen and (max-width: 576px) {
  .home-hero .text-bottom-section-category .category-icon {
    margin-bottom: 0px;
    margin-right: 8px;
  }
}
@media screen and (min-width: 576px) {
  .home-hero .text-bottom-section-category .product-card-left-margin {
    margin-left: -10px;
  }
}
@media screen and (max-width: 576px) {
  .home-hero .text-bottom-section-category .product-card-top-margin {
    margin-top: 10px;
  }
}
.home-hero h6 {
  color: #5D6D78;
}

.products-content-sleeve-outer {
  background-image: url("/assets/images/home/shared-container-backgrounds/home-dots1-lg.svg"), url("/assets/images/home/shared-container-backgrounds/home-dots2-lg.svg"), url("/assets/images/home/shared-container-backgrounds/graywaves1-xlarge.svg");
  background-position: top right, bottom left, bottom left;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: auto, auto, cover;
}
.products-content-sleeve-outer.related-products {
  background-image: url("/assets/images/home/shared-container-backgrounds/related-products-horizontal.svg");
  background-position: 50% 0px;
  background-size: auto;
}
@media only screen and (min-width: 768px) {
  .products-content-sleeve-outer.related-products {
    margin-bottom: 0 !important;
  }
}
@media only screen and (max-width: 576px) {
  .products-content-sleeve-outer.related-products {
    background-image: url("/assets/images/home/shared-container-backgrounds/related-products-vertical.svg");
    background-position: 0% 60px;
    background-size: contain;
  }
}
.products-content-sleeve-outer.related-products .related-products-min-height {
  min-height: 500px;
}
.products-content-sleeve-outer.related-products .related-products-top-0 {
  top: 0px !important;
}
.products-content-sleeve-outer.related-products .rp-abs-img-1 .img-sleeve {
  position: relative;
}
.products-content-sleeve-outer.related-products .rp-abs-img-1 .img-sleeve img {
  position: absolute;
  left: unset;
  top: -135px;
  bottom: unset;
  right: 0px;
  height: unset;
  width: unset;
  z-index: unset;
}
.products-content-sleeve-outer.related-products .rp-abs-img-2 {
  z-index: 0;
}
.products-content-sleeve-outer.related-products .rp-abs-img-2 .img-sleeve {
  position: relative;
}
.products-content-sleeve-outer.related-products .rp-abs-img-2 .img-sleeve img {
  position: absolute;
  left: 0px;
  top: -175px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
.products-content-sleeve-outer.related-products .faq-sleeve {
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .products-content-sleeve-outer {
    background-image: none;
  }
}
@media only screen and (min-width: 768px) {
  .products-content-sleeve-outer .product-cards {
    padding-top: 60px;
    padding-bottom: 140px;
  }
}
.products-content-sleeve-outer .product-cards .p-abs-img-1 .img-sleeve {
  position: relative;
}
.products-content-sleeve-outer .product-cards .p-abs-img-1 .img-sleeve img {
  position: absolute;
  left: -45px;
  top: 0px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .products-content-sleeve-outer .product-cards .p-abs-img-1 .img-sleeve {
    position: relative;
  }
  .products-content-sleeve-outer .product-cards .p-abs-img-1 .img-sleeve img {
    position: absolute;
    left: -15px;
    top: -15px;
    bottom: unset;
    right: unset;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
.products-content-sleeve-outer .product-cards .products-heading {
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .products-content-sleeve-outer .product-cards .products-heading {
    text-align: left;
  }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  .products-content-sleeve-outer .product-cards .col-md-6 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}
.products-content-sleeve-outer .product-cards .col-md-5 {
  flex: 0 0 44%;
  max-width: 44%;
}
.products-content-sleeve-outer .product-cards .product-list .col-md-5 {
  flex: initial;
  max-width: initial;
}
.products-content-sleeve-outer .product-cards .card-sleeve .card-wrap {
  margin-bottom: 20px;
}
.products-content-sleeve-outer .product-cards .card-sleeve .card-wrap:nth-child(3n) {
  padding-left: 10px;
}
.products-content-sleeve-outer .product-cards .card-sleeve .card-wrap:nth-child(3n+1) {
  padding-right: 10px;
}
.products-content-sleeve-outer .product-cards .card-sleeve .card-wrap:nth-child(3n+2) {
  padding-left: 10px;
  padding-right: 10px;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card {
  border-radius: 8px;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card img {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  align-self: flex-start;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product {
  padding: 20px;
  position: relative;
  min-height: 100%;
  cursor: pointer;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product .card-header {
  display: flex;
  align-items: center;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product .card-header h5 {
  color: #007585;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product .promo-message {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-family: var(--main-font-bold);
  letter-spacing: 0;
  line-height: 13px;
  color: #0D725E;
  background-color: #C1F2E9;
  padding: 3px 4px;
  border-radius: 0 7px 0 0;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product .card-contents {
  width: 100%;
  overflow-wrap: anywhere;
}
@media only screen and (max-width: 992px) {
  .products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product {
    padding: 20px;
  }
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product h3 {
  color: #46535E;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product P.card-description {
  color: #5D6D78;
}
.products-content-sleeve-outer .product-cards .card-sleeve .basic-card.home-product P.card-category {
  color: #5D6D78;
}
@media only screen and (min-width: 576px) and (max-width: 992px) {
  .products-content-sleeve-outer .product-cards .card-sleeve .basic-card {
    margin-left: 15px;
  }
}
.products-content-sleeve-outer .product-cards h2:not(.product-card-title) {
  font-family: var(--heading-font-regular);
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  color: #262836;
  background: none;
  margin: 0px 0px 20px 0px;
}
@media (max-width: 576px) {
  .products-content-sleeve-outer .product-cards h2 {
    font-family: var(--heading-font-regular);
    font-size: 24px;
    font-weight: 300;
    line-height: 38px;
    color: #46535E;
    background: none;
    margin: 0px 0px 20px 0px;
  }
}

.basic-card.password-rules {
  border: 0px;
  box-shadow: 0px 0px 0px;
  padding: 20px !important;
  width: 100%;
  background-color: #E5EFF0;
  border-radius: 4px;
  margin-bottom: 20px;
}
.basic-card.password-rules .fa-circle {
  color: #5D6D78 !important;
}
.basic-card.password-rules .fa-check-circle {
  color: #12A88A !important;
}
.basic-card.password-rules .fa-times-circle {
  color: #EA2238 !important;
}
.basic-card.password-rules .row {
  margin-bottom: 10px;
  margin-left: 0px;
  flex-wrap: nowrap;
}
.basic-card.password-rules .row i {
  margin: 0px 4px 0px 0px;
  padding: 0px;
  cursor: default;
  width: auto;
}
.basic-card.password-rules .row p {
  margin: 0px;
  padding: 0px;
}

.inverse-content-block .basic-card.password-rules,
.inverse .basic-card.password-rules {
  background-color: #46535E;
}

.inverse.forgot-password .basic-card.password-rules .fa-check-circle {
  color: #12A88A;
}

.page#home .vp-content-sleeve-outer .value-prop {
  display: block;
}
.page#home .vp-content-sleeve-outer .value-prop .vp-summary {
  font-size: 18px;
  font-weight: 400;
}
.page#home .vp-content-sleeve-outer .value-prop .vp-container {
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container {
    text-align: left;
  }
}
.page#home .vp-content-sleeve-outer .value-prop .vp-container h2.section-header {
  margin-bottom: 0px;
  font-weight: 700;
}
@media only screen and (max-width: 576px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container h2.section-header {
    margin-bottom: 20px;
  }
}
.page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve h3 {
  color: #262836;
  margin-bottom: 8px;
}
@media only screen and (min-width: 576px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve {
    height: 142px;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve #ValueProposition-1 {
    padding-top: 40px;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve #ValueProposition-2 {
    padding-top: 42px;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve #ValueProposition-3 {
    padding-top: 53px;
  }
}
@media only screen and (max-width: 991px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve {
    height: 128px;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve img {
    width: 52px;
  }
}
@media only screen and (max-width: 576px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-img-sleeve img {
    width: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-item .vp-text {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .page#home .vp-content-sleeve-outer .value-prop .vp-container .vp-sleeve .vp-item .vp-text p {
    margin: 0px;
  }
}
.page#product .vp-content-sleeve-outer {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: left calc((100vw - 1200px) / 2 + 580px) top;
}
@media only screen and (max-width: 1200px) {
  .page#product .vp-content-sleeve-outer {
    background-repeat: no-repeat, no-repeat;
    background-size: auto, cover;
    background-position: left bottom, bottom;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve {
  display: flex;
  justify-content: space-between;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve h2 {
  font-weight: 700;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve h5, .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .h5,
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve p {
  color: #262836;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  margin-bottom: 20px;
}
@media only screen and (max-width: 576px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro .vp-icon-main {
    background-color: #FFFFFF !important;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro h2 {
  margin-bottom: 12px;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-intro p {
  color: #46535E;
}
@media only screen and (max-width: 576px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items {
    padding-top: 0px;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items a {
  color: #46535E;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row {
  margin-bottom: 32px;
}
@media only screen and (max-width: 576px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row {
    margin-bottom: 20px;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .vp-item-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
  background-size: 32px 32px;
}
@media only screen and (max-width: 576px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .vp-item-icon {
    height: 40px;
    width: 40px;
    background-size: 24px 24px;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .item-content-text-container {
  padding-left: 20px;
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .item-content-text-container p {
  margin-bottom: 0px;
}
@media only screen and (max-width: 576px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .item-content-text-container {
    padding-left: 16px;
  }
}
.page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row h5, .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items .row .h5 {
  margin-bottom: 8px;
}
@media only screen and (min-width: 1200px) {
  .page#product .vp-content-sleeve-outer .value-prop .vp-sleeve .vp-items {
    padding-left: 50px;
    padding-right: 0;
  }
}

.evp-content-sleeve-outer {
  background-image: url("/assets/images/home/shared-container-backgrounds/dots3.svg"), url("/assets/images/home/shared-container-backgrounds/pebbles8.svg");
  background-position: left top, right center;
  background-repeat: no-repeat, no-repeat;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer {
    background-position: left top, right 41%, left bottom;
    background-size: auto, 52px, auto;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container h2 {
  font-weight: 700 !important;
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container app-aon-cta button {
    margin-bottom: 20px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container .evp-description-text p {
  font-family: var(--main-font-light);
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
  color: #46535E;
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container .evp-description-text p {
    font-family: var(--main-font-light);
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #46535E;
    margin: 0px 0px 16px 0px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container .evp-description-text p {
    font-family: var(--main-font-regular);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #46535E;
    margin: 0px 0px 16px 0px;
    margin-bottom: 0px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #row-0 {
  margin-top: 0;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-0 {
    margin-top: 41px;
    margin-bottom: 34px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-0 {
    margin-top: 16px;
    margin-bottom: 20px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #row-1 {
  margin-top: -23px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-1 {
    margin-top: 0;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-1 {
    margin-top: 0;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #row-2 {
  margin-top: -52px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-2 {
    margin-top: -13px;
    margin-bottom: 34px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #row-2 {
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #img-0 {
  background-image: url("/assets/images/home/shared-container-backgrounds/photobg1.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 70px 0 36px 28px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-0 {
    padding-top: 39px;
    padding-left: 17px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-0 {
    margin-bottom: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #img-1 {
  background-image: url("/assets/images/home/shared-container-backgrounds/photobg2.svg");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 67px 0 52px 57px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-1 {
    padding-top: 18px;
    padding-left: 23px;
    padding-bottom: 35px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-1 {
    margin-bottom: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container #img-2 {
  background-image: url("/assets/images/home/shared-container-backgrounds/photobg3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 80px 0 92px 0px;
  background-position: 28px 0px;
}
@media only screen and (max-width: 992px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-2 {
    padding-top: 50px;
    padding-bottom: 35px;
  }
}
@media only screen and (max-width: 576px) {
  .evp-content-sleeve-outer .page-content-sleeve .evp-container #img-2 {
    margin-bottom: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.evp-content-sleeve-outer .page-content-sleeve .evp-container img.card-img {
  border-radius: 12px;
}

#home .evp-content-sleeve-outer {
  background-position-x: 0px, 95%;
  background-position-y: 165px, 46%;
}

#product .evp-content-sleeve-outer {
  background-size: auto, auto;
  background-position: 0px 165px, 95% 46%;
}

.modal-dialog .modal-content {
  padding: 32px;
  background: #FFFFFF;
  box-shadow: 0 0 6px rgba(192, 192, 192, 0.5);
  border-radius: 12px;
}
@media only screen and (max-width: 576px) {
  .modal-dialog .modal-content {
    padding: 20px;
    border-radius: 0px !important;
  }
}
.modal-dialog .modal-content .modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: none;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 0px;
  padding-right: 0px;
  /*h3 should be the only class used in modal headers, all modal header tags should be h1, either with or without an h3 class depending on the size of the modal*/
}
.modal-dialog .modal-content .modal-header > h1.modal-title.h3 {
  margin-bottom: 0px;
}
.modal-dialog .modal-content .modal-header button.close {
  margin: 0px;
  margin-left: 10px;
  padding: 0px;
  background-color: transparent;
  color: #001B1F;
  min-width: 0px;
  border: none;
  opacity: 1;
  text-align: right;
}
.modal-dialog .modal-content .modal-header button.close:hover, .modal-dialog .modal-content .modal-header button.close:active, .modal-dialog .modal-content .modal-header button.close:focus {
  background-color: transparent;
}
@media screen and (max-width: 576px) {
  .modal-dialog .modal-content .modal-header button.close {
    width: auto;
    margin-right: 0px !important;
  }
}
.modal-dialog .modal-content .modal-header button .aon-icon,
.modal-dialog .modal-content .modal-header button .fal,
.modal-dialog .modal-content .modal-header button .far,
.modal-dialog .modal-content .modal-header button .fas {
  color: #007585;
}
.modal-dialog .modal-content .modal-header button:first-child {
  margin-left: auto;
}
.modal-dialog .modal-content .modal-header p {
  font-family: var(--main-font-light);
  font-size: 21px;
  line-height: 28px;
  font-weight: 300 !important;
  margin: 0px 0px 0px 0px;
}
.modal-dialog .modal-content .modal-header p a {
  font-family: var(--main-font-light);
  text-decoration: underline;
  color: #007585;
  cursor: pointer;
}
.modal-dialog .modal-content .modal-header p a:visited {
  text-decoration: none;
  color: #007585;
}
.modal-dialog .modal-content .modal-header p a:hover {
  text-decoration: none;
  color: #004852;
}
.modal-dialog .modal-content .modal-header p a:active {
  text-decoration: none;
  color: #001B1F;
}
.modal-dialog .modal-content .modal-header p a:focus {
  text-decoration: none;
  color: #001B1F;
}
@media screen and (max-width: 576px) {
  .modal-dialog .modal-content .modal-header p {
    font-size: 17px !important;
    line-height: 24px;
  }
}
.modal-dialog .modal-content .modal-body {
  padding: 0px;
}
.modal-dialog .modal-content .modal-body label {
  margin-bottom: 8px;
}
.modal-dialog .modal-content .modal-body .edit-state {
  background-color: #EEF6F7;
}
.modal-dialog .modal-content .modal-body .edit-state > p:last-child {
  margin-bottom: 0px;
}
.modal-dialog .modal-content ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.modal-dialog .modal-content ::-webkit-scrollbar-track {
  background-color: #E4E4E4;
  width: 8px;
}
.modal-dialog .modal-content ::-webkit-scrollbar-thumb {
  background: #5D6D78;
  border-radius: 4px;
  height: auto;
}
.modal-dialog .summary-icon {
  position: absolute;
  right: 15px;
  top: 5px;
  color: #007585;
  cursor: pointer;
}

.modal-backdrop.fade {
  opacity: 0.7;
}

.modal-backdrop {
  background-color: #4C4D4F;
}

.modal-slim {
  max-width: 382px;
}
@media screen and (max-width: 576px) {
  .modal-slim {
    max-width: unset;
  }
}
.modal-slim .modal-content {
  padding: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
}
.modal-slim .modal-body {
  padding: 0;
}

.progress-modalbox .modal-dialog .modal-content {
  padding: 0px;
  border-radius: 0 0 12px 12px;
  border: 0;
}
.progress-modalbox .modal-dialog .modal-body button.close {
  margin: 0 0 0 10px;
  padding: 0;
  background-color: transparent;
  color: #001B1F;
  min-width: 0px;
  border: none;
  opacity: 1;
  position: absolute;
  top: 20px;
  right: 10px;
  width: 44px;
  height: 44px;
  text-align: center;
}

/*THIS NEEDS TO BE FIXED IN SABLE-MODAL SO THAT THE HTML HAS THE SAME STRUCTURE AS OUR MODALS
 THIS CODE IS A TEMPORARY FIX TO AT LEAST STYLE THE CURRENT HTML AS CONSISTENTLY AS POSSIBLE FOR THE CLOSE BUTTON
*/
.aon-modal-box button.close {
  margin: 0 0 0 10px;
  padding: 0;
  background-color: transparent;
  color: #007585;
  min-width: 0px;
  border: none;
  float: right;
  font-size: 25px;
}

.byl-content-sleeve-outer .page-content-sleeve {
  background-image: url("/assets/images/product/brands/backgrounds/partner-bkgd-shape.svg");
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
@media only screen and (max-width: 576px) {
  .byl-content-sleeve-outer .page-content-sleeve {
    background-position: left 100px top 40px;
  }
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row {
  align-items: center;
  justify-content: space-between;
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row .text-wrapper p {
  margin-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .byl-content-sleeve-outer .page-content-sleeve .byl-container .row .text-wrapper p {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .byl-content-sleeve-outer .page-content-sleeve .byl-container .row .text-wrapper button {
    margin-top: 0px;
    margin-bottom: 32px;
    width: 100%;
  }
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row .logo-wrapper {
  text-align: right;
}
@media only screen and (max-width: 1200px) {
  .byl-content-sleeve-outer .page-content-sleeve .byl-container .row .logo-wrapper {
    text-align: center;
  }
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row .logo-wrapper .logo {
  width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row .logo-wrapper img {
  padding-bottom: 20px;
  padding-right: 20px;
}
.byl-content-sleeve-outer .page-content-sleeve .byl-container .row .logo-wrapper img:nth-child(4n) {
  padding-right: 0px;
}

.cs-content-sleeve-outer {
  background-color: #262836;
  position: relative;
}
.cs-content-sleeve-outer .cs-abs-img-1 .img-sleeve {
  position: relative;
}
.cs-content-sleeve-outer .cs-abs-img-1 .img-sleeve img {
  position: absolute;
  left: unset;
  top: -75px;
  bottom: unset;
  right: 0;
  height: 264px;
  width: 268px;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .cs-abs-img-1 .img-sleeve {
    position: relative;
  }
  .cs-content-sleeve-outer .cs-abs-img-1 .img-sleeve img {
    position: absolute;
    left: unset;
    top: -60px;
    bottom: unset;
    right: 0px;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
.cs-content-sleeve-outer .cs-abs-img-3 {
  position: absolute;
  right: -31px;
  bottom: 100px;
}
.cs-content-sleeve-outer .cs-abs-img-3 .img-sleeve {
  background-image: url(/assets/images/product/case-study/dots11.svg);
  background-size: cover;
  width: 270px;
  height: 471px;
  background-repeat: no-repeat;
}
.cs-content-sleeve-outer .cs-abs-img-2 {
  position: absolute;
  left: -32px;
  top: 100px;
}
.cs-content-sleeve-outer .cs-abs-img-2 .img-sleeve {
  background-image: url(/assets/images/product/case-study/dots12.svg);
  background-size: cover;
  width: 340px;
  height: 528px;
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (max-width: 1200px) {
  .cs-content-sleeve-outer .cs-abs-img-2 .img-sleeve {
    width: 240px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container {
  color: #E5EFF0;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container {
    padding-top: 20px;
    padding-bottom: 20px;
    background: none;
    background-color: #262836;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h2,
.cs-content-sleeve-outer .page-content-sleeve .cs-container h3,
.cs-content-sleeve-outer .page-content-sleeve .cs-container h4,
.cs-content-sleeve-outer .page-content-sleeve .cs-container h5 {
  color: #E5EFF0;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container h2,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container h3,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container h4,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container h5 {
    text-align: left;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container label,
.cs-content-sleeve-outer .page-content-sleeve .cs-container p {
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h1 {
  color: #EEF6F7;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h3.save-amount,
.cs-content-sleeve-outer .page-content-sleeve .cs-container h4 {
  font-weight: 300;
  font-family: var(--heading-font-light);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h4:not(.float-end) {
  font-family: var(--heading-font-bold);
  font-size: 20px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h1,
.cs-content-sleeve-outer .page-content-sleeve .cs-container h3 {
  font-weight: 300;
  font-family: var(--heading-font-light);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container h4.float-end {
  font-weight: 300;
  font-family: var(--heading-font-light);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .drop-down-selector .dropdown-toggle {
  background: transparent;
  border-color: #FFFFFF;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .drop-down-selector .dropdown-menu {
  width: 100%;
  text-align: center;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .nav-tabs {
  border-bottom: 1px solid #CDDBDE;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .nav-tabs .nav-item {
  width: 33.33%;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .nav-tabs .nav-link {
  text-decoration: none;
  text-transform: uppercase;
  font-family: var(--main-font-bold);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .nav-tabs .nav-link.active {
  background-color: #262836;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 3px;
  text-decoration: none;
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse-content-block p,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse p {
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse-content-block p,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse p {
    padding-top: 20px;
    text-align: left;
  }
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse-content-block button,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .inverse button {
    width: 100%;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .two-row-two-col-text .basic-card,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .one-row-two-col-text .basic-card {
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .two-row-two-col-text .basic-card,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .one-row-two-col-text .basic-card {
    justify-content: initial;
    align-items: initial;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .two-row-two-col-text .basic-card .content-wrapper,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .one-row-two-col-text .basic-card .content-wrapper {
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .two-row-two-col-text .basic-card .content-wrapper,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .one-row-two-col-text .basic-card .content-wrapper {
    justify-content: initial;
    align-items: initial;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content {
  padding-top: 20px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .tab-pane > div > p {
  color: #FFFFFF;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve {
  margin: 0 auto;
  margin-bottom: 20px !important;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve {
    width: auto;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve {
  display: flex;
  margin-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card {
  border: 0;
  border-radius: 0;
  padding: 20px;
  box-shadow: none;
  flex-direction: column;
  display: flex;
  align-items: center;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card label {
  margin-bottom: 12px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card img {
  margin-bottom: 10px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card p,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card h1 {
  margin-bottom: 0px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card .text-link a {
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card a {
  font-family: var(--main-font-regular);
  text-decoration: underline;
  color: #E5EFF0;
  cursor: pointer;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card a:visited {
  text-decoration: none;
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card a:hover {
  text-decoration: none;
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card a:active {
  text-decoration: none;
  color: #E5EFF0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card a:focus {
  text-decoration: none;
  color: #E5EFF0;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card {
    flex-direction: column;
  }
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card p,
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card label {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin: 0px 0px 12px 0px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 8px;
  border-radius: 12px 0px 0px 12px;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card:nth-child(odd) {
    border: 0;
    margin-right: 0px;
    border-radius: 0px 0px 0px 0px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0px 12px 12px 0px;
}
@media only screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-cards-sleeve .basic-card:nth-child(even) {
    border: 0;
    border-radius: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data > .header h5 {
  padding: 0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row {
  align-items: center;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.header {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: rgba(255, 255, 255, 0.15);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.header.section-header-top-color {
  background-color: rgba(255, 255, 255, 0.15);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.header:nth-last-child(2) {
  background-color: rgba(255, 255, 255, 0.45);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.top-cap:first-child {
  border-radius: 12px 12px 0px 0px;
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.top-cap {
    border-radius: 0px 0px 0px 0px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap {
  border-radius: 0px 0px 12px 12px;
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap {
    border-radius: 0px 0px 0px 0px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap-summary {
  border-radius: 0px 0px 12px 12px;
  background-color: rgba(255, 255, 255, 0.87) !important;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap-summary h3 {
  width: 100%;
  color: #012774;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap-summary h3:before {
  content: url(/assets/images/product/case-study/case-study-ribbon.svg);
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row.bottom-cap-summary {
    border-radius: 0px 0px 0px 0px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row p,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row h4,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row h5 {
  margin-bottom: 0px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .row h5 {
  text-align: left;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .bg-color-white-25 {
  background-color: rgba(255, 255, 255, 0.25);
  justify-content: space-between;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.top {
  background-color: rgba(255, 255, 255, 0.1);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .row,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .hr-line-spacing {
  background-color: rgba(255, 255, 255, 0.25);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .row.table-total,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .hr-line-spacing.table-total {
  background-color: rgba(255, 255, 255, 0.8);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .row.table-total h3,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section.bottom .hr-line-spacing.table-total h3 {
  margin: 0px;
  color: #262836;
  margin-left: 10px;
  font-family: var(--heading-font-bold);
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .hr-line-spacing {
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 19px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .hr-line {
  border-top: 1px solid #C0C0C0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .row:first-child {
  padding-top: 26px;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .row:last-child {
  padding-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section h4 {
    font-family: var(--main-font-light);
    font-size: 18px;
    line-height: 28px;
  }
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .w-75,
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .w-25 {
  padding-left: 0;
  padding-right: 0;
}
.cs-content-sleeve-outer .page-content-sleeve .cs-container .tab-content .cs-sleeve .cs-data .section .w-75 p {
  color: #FFFFFF;
}
.cs-content-sleeve-outer .page-content-sleeve .case-study-dropdown::after {
  float: right;
  margin-top: 7px;
}

.disclaimer-para {
  margin: 0 auto;
  text-align: center;
  position: relative;
}
.disclaimer-para .cs-abs-img-4 {
  position: absolute;
  left: -250px;
  bottom: 0;
}

.product-contact-info {
  background-color: #262836;
}
.product-contact-info .close {
  color: white;
  flex-grow: 1;
  text-align: right;
  margin: 0px;
  opacity: 1;
  font-size: 15px;
}
@media (min-width: 576px) {
  .product-contact-info .product-contact-info {
    margin-left: 5px;
  }
  .product-contact-info .close {
    margin-top: 5px;
    margin-right: 20px;
  }
}

.ab-content-sleeve-outer .active-benefits-container .basic-card {
  margin-bottom: 20px;
}

.enrolment-summary-content-for-print {
  display: none;
}

kendo-pdf-document .profile-active-benefits app-benefit-details {
  display: block;
  break-inside: avoid;
}
kendo-pdf-document .profile-active-benefits .linename-for-print * {
  font-size: 24px !important;
}
kendo-pdf-document .profile-active-benefits .active-benefits-plancontainer-for-print {
  background-color: white;
}
kendo-pdf-document .profile-active-benefits .basic-card .cost-container {
  float: right;
  text-align: right;
}
kendo-pdf-document .profile-active-benefits .nav-tabs.inverse-content-block.nav {
  display: none;
}
kendo-pdf-document .profile-active-benefits .header-sleeve .header-text {
  color: #007585;
  flex-grow: 1;
  font-family: var(--main-font-bold);
  font-weight: 400 !important;
  line-height: 24px;
}
kendo-pdf-document .profile-active-benefits footer,
kendo-pdf-document .profile-active-benefits #pending-benefits-page-controls,
kendo-pdf-document .profile-active-benefits .active-benefits-nav-tabs,
kendo-pdf-document .profile-active-benefits .hide-perpay-toggle-for-print,
kendo-pdf-document .profile-active-benefits .hide-pending-date-picker-for-print,
kendo-pdf-document .profile-active-benefits #active-benefits-print,
kendo-pdf-document .profile-active-benefits app-event-broadcast-bar,
kendo-pdf-document .profile-active-benefits .ignore-from-media-print,
kendo-pdf-document .profile-active-benefits #hide-accordian-benefit-details-for-print,
kendo-pdf-document .profile-active-benefits #divChatButton,
kendo-pdf-document .profile-active-benefits #download {
  display: none !important;
}
kendo-pdf-document .profile-active-benefits .benefit-details-printcontent {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}
kendo-pdf-document .profile-active-benefits #active-tab-for-print,
kendo-pdf-document .profile-active-benefits #product-logo-for-print,
kendo-pdf-document .profile-active-benefits #effective-date-picker {
  display: block !important;
}
kendo-pdf-document .profile-active-benefits #product-logo-for-print {
  margin-bottom: 20px !important;
  margin-top: 0px !important;
}
kendo-pdf-document .profile-active-benefits .mybenefits-margin-alignment-for-print {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
}
kendo-pdf-document .profile-active-benefits .cost-container-for-print-alignment {
  position: relative;
  bottom: 0;
  right: 10px;
}
kendo-pdf-document .profile-active-benefits .accordion-body .sable-label-pair .sable-label-value-item {
  padding-right: 20px !important;
}
kendo-pdf-document .profile-active-benefits .accordion-item.expansion-panel .col-4.text-end p {
  padding-right: 20px;
}
kendo-pdf-document .enrolment-summary-content-for-print {
  display: block !important;
}

.sq-content-sleeve-outer .saved-quotes-container .custom-accordion .header-sleeve .header-text h5 {
  color: #007585;
}
.sq-content-sleeve-outer .saved-quotes-container .flex-col-1 {
  flex: 1 1 0;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve {
  margin-bottom: 20px;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .summary-heading {
  margin-bottom: 16px;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .sable-label-pair {
  font-size: 14px;
  line-height: 18px;
  color: #46535E;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .sable-label {
  font-weight: 700;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text,
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .header-controls,
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .fal {
  color: #46535E;
}
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded .header-text,
.sq-content-sleeve-outer .saved-quotes-container .summary-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded .header-controls {
  margin-bottom: 10px;
}
.sq-content-sleeve-outer .saved-quotes-container .data-metric-m {
  color: #262836;
}
.sq-content-sleeve-outer .saved-quotes-container .basic-card {
  padding: 32px;
}
.sq-content-sleeve-outer .saved-quotes-container .basic-card .accordion .expansion-panel.accordion-item .accordion-body {
  color: #46535E !important;
}
.sq-content-sleeve-outer .saved-quotes-container .basic-card .createdInfo h6 {
  text-transform: none;
}
.sq-content-sleeve-outer .saved-quotes-container .basic-card .dropdown-divider {
  border-top: 0px;
}
.sq-content-sleeve-outer .saved-quotes-container .quote-value {
  text-align: right;
}
.sq-content-sleeve-outer .saved-quotes-container p.quote-expires {
  background-color: #FFF6B7;
  padding: 2px 10px 2px 10px;
  border-radius: 2px;
}
@media screen and (max-width: 576px) {
  .sq-content-sleeve-outer .saved-quotes-container .basic-card {
    border-radius: 0px;
    padding: 20px;
  }
  .sq-content-sleeve-outer .saved-quotes-container .quote-value {
    text-align: left;
  }
  .sq-content-sleeve-outer .saved-quotes-container .quote-value h4 {
    font-size: 30px;
    font-weight: 300;
  }
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair .icon-col {
  margin-right: 5px;
  flex-shrink: 0;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.about-you .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.about-you .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-summary-user.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.dog .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.dog .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-dog-icon.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.cat .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.cat .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-cat-icon.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.vehicles .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.vehicles .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/auto-med-marine.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.drivers .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.drivers .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/driver-sm-marine.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.incidents .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.incidents .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/incidents-sm-marine.svg");
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.properties .icon-col {
  width: 24px;
  height: 21px;
}
.sq-content-sleeve-outer .saved-quotes-container sable-dataview .sable-label-pair.properties .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/homeowners-med-marine.svg");
}
.sq-content-sleeve-outer .saved-quotes-container .fa-trash-alt {
  cursor: pointer;
}

.si-content-sleeve-outer .security-info .security-info-container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.si-content-sleeve-outer .security-info .security-info-container .content .edit-icon-margin {
  margin-top: -12px;
  margin-right: -12px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card {
  padding: 32px;
  margin-bottom: 20px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card.edit-state {
  padding: 20px !important;
  margin-bottom: 0px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card .icon-margin {
  font-size: 16px;
  line-height: 36px;
  right: 28px;
  width: 19px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card .forgot-password {
  margin-left: 20px;
}
@media only screen and (max-width: 576px) {
  .si-content-sleeve-outer .security-info .security-info-container .basic-card .forgot-password {
    margin-top: 20px;
    margin-left: 0px;
  }
  .si-content-sleeve-outer .security-info .security-info-container .basic-card .basic-card {
    border-radius: 0px;
  }
}
@media only screen and (max-width: 576px) {
  .si-content-sleeve-outer .security-info .security-info-container .basic-card .form-group {
    margin-bottom: 20px;
  }
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card .form-group .mfa-email-section {
  margin-bottom: 32px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card .form-group .mfa-email-section > p {
  margin-bottom: 0px;
}
.si-content-sleeve-outer .security-info .security-info-container .basic-card .form-group .mfa-edit-section-italic {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 0px;
}

.pi-content-sleeve-outer .personal-info .profile-personal-container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card {
  padding: 32px;
  margin-bottom: 20px;
}
.pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card.edit-state {
  padding: 20px;
  margin-bottom: 0px;
}
@media only screen and (min-width: 576px) {
  .pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card .item .alert {
    max-width: 280px;
  }
}
.pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card .address-section .form-group:last-child,
.pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card .phone-section .form-group:last-child {
  margin-bottom: 20px;
}
.pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card .fa-info-circle {
  color: #46535E;
}
@media only screen and (max-width: 576px) {
  .pi-content-sleeve-outer .personal-info .profile-personal-container .basic-card p.lg {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 16px !important;
  }
}

.active-benefits-plan-container {
  /*Used on VB Asia active benefits page*/
  /*END Used on VB Asia active benefits page*/
  /*Used on OLB active benefits page*/
  /*END Used on OLB active benefits page*/
}
.active-benefits-plan-container .w-tab-menu .active {
  color: #003C55;
}
.active-benefits-plan-container .w-tab-menu a {
  text-decoration: none;
  background-color: transparent !important;
  color: #9b9b9b;
}
.active-benefits-plan-container .w-tab-menu .nav-link-container {
  position: relative;
}
.active-benefits-plan-container .w-tab-menu .nav-link-container .badge {
  position: absolute;
  right: 8px;
  top: 10px;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu li {
  position: relative;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a {
  text-decoration: none;
  background-color: transparent !important;
  color: #5D6D78;
  border: none;
  min-width: 130px;
  justify-content: center;
  min-height: 100%;
  border-bottom: 4px solid transparent;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a::before {
  content: "";
  position: absolute;
  background-color: #004852;
  left: 0px;
  bottom: -3px;
  height: 1px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: right bottom;
  transition: transform 0.2s ease;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a:not(.active):hover {
  border-bottom: 4px solid transparent;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a:not(.active):hover::before {
  transform-origin: left top;
  transform: scaleX(1);
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a:focus {
  border: none;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu a.active {
  color: #003C55;
  border-style: solid;
  border-color: #001B1F;
  border-width: 0 0 4px;
}
.active-benefits-plan-container.my-plans-active-benefits .w-tab-menu .badge {
  position: absolute;
  right: 14px;
  top: 10px;
  border-radius: 50%;
  height: 6px;
  width: 6px;
}
.active-benefits-plan-container .basic-card .alert-danger i {
  color: #CA110F;
}
.active-benefits-plan-container .basic-card .alert-success i {
  color: #0D725E;
}
.active-benefits-plan-container .basic-card .renewalHeader {
  font-weight: 700;
  color: #0D725E;
}
.active-benefits-plan-container .basic-card .exipredHeader {
  font-weight: 700;
  color: #CA110F;
}
.active-benefits-plan-container .basic-card .claimsHeader {
  font-weight: 700;
  color: #012774;
}
.active-benefits-plan-container .basic-card .benefit-action #price .data-metric-m {
  color: #012774;
}
.active-benefits-plan-container .basic-card .PlanDetails .data-metric-lg {
  color: #012774;
}
@media only screen and (max-width: 576px) {
  .active-benefits-plan-container .basic-card .active-product {
    flex-direction: column;
  }
  .active-benefits-plan-container .basic-card .active-product .product-title.flex-nowrap {
    flex-wrap: wrap !important;
  }
  .active-benefits-plan-container .basic-card .active-product .product-title.flex-nowrap > h3 {
    flex: 1;
    margin: 0 !important;
  }
  .active-benefits-plan-container .basic-card .active-product .product-title.flex-nowrap .policy-status-div {
    width: 100%;
    margin-top: 12px;
  }
  .active-benefits-plan-container .basic-card .active-product .product-title.flex-nowrap .policy-status-div > p {
    display: inline-block;
    margin: 0 !important;
  }
  .active-benefits-plan-container .basic-card .active-product .benefit-action #price.text-end {
    text-align: left !important;
  }
  .active-benefits-plan-container .basic-card .active-product .benefit-action #price.text-end > h2 {
    margin: 20px 0 !important;
  }
}
.active-benefits-plan-container .alert.banner.success p i {
  color: #007585;
}
.active-benefits-plan-container .product-title {
  display: inline-flex;
}
.active-benefits-plan-container .benefits-card {
  margin: 8px 0px;
  background: #FFFFFF;
  border: 1px solid #ACC0C4;
  border-radius: 8px;
  width: 100%;
  padding: 32px;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container .benefits-card {
    border-radius: 0;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    width: auto;
  }
}
.active-benefits-plan-container .benefits-card .data-metric-m {
  color: #012774;
}
.active-benefits-plan-container .b-b-header {
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container .b-b-header {
    border-bottom: none;
  }
}
.active-benefits-plan-container .b-b-question-answer {
  border-bottom: 1px solid #a29c9c;
}
.active-benefits-plan-container .b-t-create-account {
  border-top: 1px solid #a29c9c;
}
.active-benefits-plan-container .summary-heading {
  font-family: var(--main-font-bold);
  font-size: 24px;
  line-height: 32px;
  color: #012774;
  background: none;
  margin: 0 0 20px;
}
.active-benefits-plan-container .next-steps-heading {
  font-family: var(--main-font-bold);
  font-size: 20px;
  line-height: 24px;
  color: #2e2e2e;
  background: none;
  margin: 0 0 20px;
}
.active-benefits-plan-container .rt-sidebar-top-position {
  margin-left: 34px;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel {
  border: 0;
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  background-color: #FFFFFF;
  border-radius: 0px;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header {
  background-color: #FFFFFF;
  border: 0px;
  padding: 0px;
  color: #00519B;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve {
  display: flex;
  align-items: center;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve i {
  font-size: 20px;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve .benefit-header-text {
  margin-bottom: 6px;
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
  margin-top: 6px;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded .benefit-header-text,
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded .benefit-header-controls {
  color: #00519B;
  font-weight: 700;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded:hover .benefit-header-text,
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded:hover .benefit-header-controls {
  color: #00519B;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-body {
  padding: 0px;
}
.active-benefits-plan-container .accordion .benefit-expansion-panel.accordion-item .accordion-body .benefit-expansion-panel-text {
  background-color: #FFFFFF;
}
.active-benefits-plan-container .xs-para {
  font-size: 12px;
  line-height: normal;
}
.active-benefits-plan-container .input_label {
  word-break: break-word;
}
.active-benefits-plan-container .benefits_cost {
  text-align: right;
}
.active-benefits-plan-container .link-button {
  text-align: left;
  background: none !important;
  border: none;
  color: #007585;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}
.active-benefits-plan-container .policy-status-div {
  white-space: nowrap;
}
.active-benefits-plan-container .highlight-policy-status {
  background-color: #C1F2E9;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
}
.active-benefits-plan-container .highlight-policy-yellow {
  background-color: #FFF6B7;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
}
.active-benefits-plan-container .highlight-policy-red {
  background-color: #FCDCDC;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
}
.active-benefits-plan-container .filter-policy-item-1 {
  -webkit-order: 1;
  /* Safari 6.1+ */
  order: 1;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container .filter-policy-item-1 {
    -webkit-order: 2;
    /* Safari 6.1+ */
    order: 2;
  }
}
.active-benefits-plan-container .filter-policy-item-2 {
  -webkit-order: 2;
  /* Safari 6.1+ */
  order: 2;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container .filter-policy-item-2 {
    -webkit-order: 1;
    /* Safari 6.1+ */
    order: 1;
  }
}
.active-benefits-plan-container .active-tabConent {
  margin: 32px 0 32px 0;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container .active-tabConent {
    margin: 20px 0 20px 0;
  }
}
@media screen and (min-width: 768px) {
  .active-benefits-plan-container .row-spacing-header {
    margin-bottom: 40px;
  }
}
.active-benefits-plan-container .row-spacing {
  margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
  .active-benefits-plan-container .row-spacing {
    margin-bottom: 32px;
  }
}
@media screen and (min-width: 768px) {
  .active-benefits-plan-container .card-title-spacing {
    margin-bottom: 20px;
  }
}
.active-benefits-plan-container div#filter {
  order: 1;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container div#filter {
    order: 2;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .active-benefits-plan-container div.row#active-benefits-page-controls #effective-date-picker {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .active-benefits-plan-container div.row#pending-benefits-page-controls #pending-benefits-div {
    margin-bottom: 20px;
  }
}
.active-benefits-plan-container div#download {
  order: 2;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container div#download {
    order: 1;
    margin-bottom: 20px;
  }
}
.active-benefits-plan-container div#price {
  text-align: right;
}
@media screen and (max-width: 576px) {
  .active-benefits-plan-container div#price {
    text-align: left;
  }
}
.active-benefits-plan-container .payment-schedule p {
  color: #007585;
}

.benefit-modal .close-btn {
  background-color: transparent;
  color: #001B1F;
  min-width: 0px;
}
.benefit-modal .benefits_cost {
  text-align: right;
}

.registration {
  /*To handle when we browse the site on mobile screen*/
}
.registration .registration-sleeve {
  background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
  background-position: bottom left, top 222px right;
  background-repeat: no-repeat, no-repeat;
  background-size: 250px 204px, 207px 227px;
  /*styling of calender icon in the input*/
}
.registration .registration-sleeve .page-content-sleeve .img-sleeve {
  position: relative;
}
.registration .registration-sleeve .page-content-sleeve .img-sleeve img {
  position: absolute;
  left: -117px;
  top: -10px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
.registration .registration-sleeve .fa-circle {
  margin-right: 4px;
}
.registration .registration-sleeve .calendar {
  border-left: transparent;
}
.registration .registration-sleeve .calendar:active, .registration .registration-sleeve .calendar:hover, .registration .registration-sleeve .calendar:focus {
  background-color: transparent;
}
@media only screen and (max-width: 576px) {
  .registration .registration-sleeve {
    background-image: url(/assets/images/account/pebbles5.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 125px 102px;
  }
  .registration .registration-sleeve .page-content-sleeve {
    background-image: none;
  }
}
.registration.registration-modal .progress {
  position: relative;
  width: 100%;
  background-color: rgba(41, 176, 195, 0.2509803922) !important;
}
.registration.registration-modal .progress-bar {
  background-color: #29B0C3 !important;
}
.registration.registration-modal .close {
  margin-top: 0px !important;
  margin-right: 0px !important;
}
.registration.registration-modal .registration-core {
  padding: 0px 32px 32px 32px;
}
@media only screen and (max-width: 576px) {
  .registration.registration-modal .registration-core {
    padding: 0px 20px 20px 20px;
  }
}
.registration.registration-modal .successstep {
  position: relative;
  top: 2px;
}
.registration.registration-modal .express-registration {
  padding: 0px 32px 32px 32px;
}
@media only screen and (max-width: 576px) {
  .registration.registration-modal .express-registration {
    padding: 0px 20px 20px 20px;
  }
}
.registration.registration-modal .page-only {
  display: none !important;
}
.registration.registration-modal .fa-calendar.secondary,
.registration.registration-modal .fa-eye.secondary,
.registration.registration-modal .fa-eye-slash.secondary {
  line-height: 26px;
  right: 16px;
}
.registration.registration-modal .modal-only .fa-info-circle {
  position: relative;
  top: 2px;
}
.registration.registration-page .modal-only {
  display: none !important;
}
.registration.registration-page .fa-calendar.secondary,
.registration.registration-page .fa-eye.secondary,
.registration.registration-page .fa-eye-slash.secondary {
  right: 16px;
  line-height: 28px;
}
.registration input {
  border-radius: 4px;
}
.registration .validation-failed {
  color: #EA2238 !important;
}
.registration .validation-success {
  color: #12A88A !important;
}
.registration.inverse input[type=text],
.registration.inverse input[type=checkbox] + label::before {
  border: 1px solid #FFFFFF;
  background: transparent;
  color: #E5EFF0;
}
.registration.inverse .btn-inverse-secondary {
  background-color: transparent;
  border-color: #FFFFFF;
  padding: 0px 16px 0px 16px;
}
.registration.inverse .fa-circle {
  color: #FFFFFF;
}
.registration.inverse + .validation-pending {
  color: #FFFFFF;
}
.registration.inverse a:focus-visible,
.registration.inverse button:focus-visible {
  outline: 2px solid #fff !important;
}
.registration .step-validation input#HomePostalCode::-webkit-outer-spin-button,
.registration .step-validation input#HomePostalCode::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.back-btn {
  min-width: 100px !important;
}
@media only screen and (max-width: 576px) {
  .back-btn {
    margin-top: 20px;
  }
}

/*FAQ PAGE*/
.faq-page {
  scroll-margin-top: 90px;
}
.faq-page .faq-page-header {
  background-image: url(../../../assets/images/faqs/gray-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-page-header {
    background-image: url(../../../assets/images/faqs/gray-prod-detail-wave-sm.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
@media screen and (max-width: 576px) {
  .faq-page .faq-page-header .page-content-sleeve {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.faq-page .faq-page-header .faq-page-padding {
  padding-top: 40px;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-page-header .faq-page-padding {
    padding-top: 20px;
  }
}
.faq-page .faq-page-header .faq-page-padding h1 {
  color: #262836;
  padding-bottom: 80px;
  margin-top: 6px;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-page-header .faq-page-padding h1 {
    padding-bottom: 40px;
    margin-top: 12px;
  }
}
.faq-page .faq-page-header .faq-page-padding img {
  vertical-align: middle;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-page-header .faq-page-padding img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
.faq-page .faq-page-header .vp-faq-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 576px) {
  .faq-page .faq-page-header .vp-faq-icon {
    display: none;
  }
}
.faq-page .faq-details {
  padding-top: 32px;
  padding-bottom: 106px;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-details {
    padding-top: 32px;
    padding-bottom: 60px;
  }
}
.faq-page .faq-details h4 {
  color: #46535E;
}
.faq-page .faq-details h3 {
  scroll-margin-top: 90px;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-details h3 {
    margin-left: 30px;
  }
}
.faq-page .faq-details .faq-card {
  background: #FFFFFF;
  border: 1px solid #ACC0C4;
  border-radius: 8px;
  width: 100%;
  padding: 32px;
}
@media screen and (max-width: 576px) {
  .faq-page .faq-details .faq-card {
    border-radius: 0;
    padding: 20px;
  }
}
.faq-page .faq-details .faq-card .card {
  border: 0;
}
.faq-page .faq-details .faq-card .card .card-body {
  padding: 0;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve {
  padding: 0;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content {
  display: flex;
  flex-direction: column;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content .faq-sleeve {
  max-width: 100% !important;
  width: 100%;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content .faq-sleeve .accordion .expansion-panel .accordion-header .header-sleeve {
  text-decoration: none;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content .faq-sleeve .accordion .expansion-panel .accordion-header .header-sleeve.expanded {
  padding-bottom: 14px;
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content .faq-sleeve .accordion .expansion-panel .accordion-header .header-text {
  font-family: var(--heading-font-bold);
}
.faq-page .faq-details .faq-card .card .card-body .page-content-sleeve .faqs-content .faq-sleeve .accordion .expansion-panel .accordion-body .expansion-panel-text {
  margin: 0 0 8px;
}
.faq-page .faq-details .back-to-top {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: #007585;
  padding-top: 20px;
}
.faq-page .faq-details .back-to-top a:hover {
  color: #004852;
}

/*FAQ MODULE (USED ON HOME AND PRODUCT PAGES)*/
.faq-content-sleeve-outer .faqs-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .faq-content-sleeve-outer .faqs-content {
    background-image: none;
    margin-top: 0px;
    margin-bottom: 0px;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 576px) {
  .faq-content-sleeve-outer .faqs-content .faq-header {
    margin-bottom: 32px;
  }
}
.faq-content-sleeve-outer .faqs-content .faq-sleeve {
  width: 100%;
}
.faq-content-sleeve-outer .faqs-content .faq-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve {
  text-decoration: none;
}
.faq-content-sleeve-outer .faqs-content .faq-sleeve .accordion .expansion-panel.accordion-item .accordion-header .header-text {
  font-family: var(--heading-font-bold);
}

/*FAQs WITHIN MODALS*/
.modal-content .faqs-content .faq-sleeve {
  max-width: 100%;
}

.pp-content-sleeve-outer {
  background-image: url("/assets/images/home/shared-container-backgrounds/home-dots1-lg.svg"), url("/assets/images/home/shared-container-backgrounds/graywaves1-xlarge.svg");
  background-position: -113px 56px, bottom left;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, cover;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container h2 {
  margin-bottom: 12px;
}
@media only screen and (max-width: 576px) {
  .pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container h2 {
    margin-bottom: 20px;
  }
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container p {
  font-family: var(--main-font-light);
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  color: #46535E;
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 576px) {
  .pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container p {
    font-family: var(--main-font-regular);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #46535E;
    margin: 0px 0px 16px 0px;
    padding-left: 0px;
  }
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container p.sm {
  color: #262836;
  font-size: 14px;
  line-height: 18px;
  margin: 0px 0px 12px 0px;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container h5 {
  color: #262836;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container h5:before {
  content: url("/assets/images/template/pp-ribbon-lg.svg");
  margin-right: 10px;
  width: 18px;
  height: 24px;
  vertical-align: middle;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .intro-text-container .text-shamrock {
  background: rgba(10, 194, 152, 0.2);
  border-radius: 4px;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container {
  margin-top: 32px;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .img-sleeve {
  position: relative;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .img-sleeve img {
  position: absolute;
  left: 882px;
  top: 48%;
  bottom: unset;
  right: unset;
  height: 653px;
  width: 460px;
  z-index: unset;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row {
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}
@media only screen and (max-width: 576px) {
  .pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row {
    justify-content: initial;
    margin-left: -15px;
    margin-right: -15px;
  }
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper {
  z-index: 1;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card {
  justify-content: space-between;
  height: 100%;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .content-bottom {
  justify-content: space-between;
  height: 100%;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style ul {
  font-family: var(--main-font-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #46535E;
  margin: 0px 0px 16px 0px;
  padding-left: 1rem;
  padding-top: 1rem;
  margin: 0;
}
@media only screen and (max-width: 576px) {
  .pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style ul {
    padding-top: 20px;
  }
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style ul li {
  margin-bottom: 8px;
  word-break: break-word;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style ul li:last-child {
  margin-bottom: 0;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style .price-info hr {
  border-top: 1px solid #C0C0C0;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style .price-info .details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row .card-wrapper .basic-card .list-style .price-info .details h1.text-end {
  font-size: 40px;
  line-height: 44px;
}
.pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row button {
  margin-top: 20px;
}
@media only screen and (max-width: 576px) {
  .pp-content-sleeve-outer .page-content-sleeve .ppc-container .card-container .row button {
    margin-top: 12px;
    width: 100%;
  }
}

.login .login-sleeve {
  background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles7.svg);
  background-position: bottom left, top 222px right;
  background-repeat: no-repeat, no-repeat;
  background-size: 250px 204px, 207px 227px;
  padding-top: 40px;
  padding-bottom: 150px;
}
.login .login-sleeve .page-content-sleeve .img-sleeve {
  position: relative;
}
.login .login-sleeve .page-content-sleeve .img-sleeve img {
  position: absolute;
  left: -117px;
  top: -10px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
.login .login-sleeve span.spinner-border {
  color: #262836;
}
.login.inverse a:focus-visible,
.login.inverse button:focus-visible {
  outline: 2px solid #E5EFF0 !important;
}
.login .icon-margin {
  font-size: 16px;
  line-height: 36px;
  right: 28px;
  width: 19px;
}
.login .alert.banner.error p {
  color: #46535E;
}
@media only screen and (max-width: 564px) {
  .login .login-sleeve {
    background-image: url(/assets/images/account/pebbles5.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 125px 102px;
  }
  .login .login-sleeve .page-content-sleeve {
    background-image: none;
  }
}

.express-login .modal-body .resend-code {
  font-size: 16px;
}
.express-login .modal-body .resend-code a {
  color: #007BB6;
  font-size: 16px;
}
.express-login .modal-body .resend-code .btn {
  padding: 0;
  border: 0;
}

.express-registration,
.express-login,
.registration-core {
  /** Checkbox disabled state */
}
.express-registration input[type=checkbox]:disabled + label::before,
.express-login input[type=checkbox]:disabled + label::before,
.registration-core input[type=checkbox]:disabled + label::before {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.login-continue-as-guest {
  white-space: nowrap;
}

.ff-inherit {
  font-family: inherit !important;
}

.clr-inherit {
  color: inherit !important;
}

.login .login.inverse .express-login .message p {
  color: #262836;
}
.login .login.inverse .express-login .email-info {
  color: #FFFFFF;
}

.profile-nav {
  background-color: #262836;
}
.profile-nav .nav {
  background-color: #262836;
}
.profile-nav .page-content-sleeve {
  min-width: 1200px;
  width: fit-content;
}
.profile-nav .nav-tabs {
  border-bottom: 0 !important;
}
.profile-nav .nav-tabs a {
  text-decoration: none;
  padding: 0px 20px 0px 20px;
  color: #E5EFF0;
}
.profile-nav .nav-tabs a i + span {
  text-decoration: underline;
}
.profile-nav .nav-tabs .nav-link.active {
  color: #E5EFF0;
  background-color: #46535E;
  border-color: #46535E;
  font-family: var(--main-font-bold);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.profile-nav .nav-tabs .nav-link {
  margin-bottom: unset;
  padding-top: 18px;
  padding-bottom: 18px;
}
.profile-nav .nav-tabs .nav-link:focus-visible {
  outline-offset: -4px;
}
.profile-nav .nav-tabs .nav-item {
  padding: 0;
}
.profile-nav .nav-tabs .tab-badge {
  border-radius: 50%;
  height: 6px;
  width: 6px;
  position: relative;
  top: -8px;
  right: -2px;
}

.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .img-sleeve {
  position: relative;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .img-sleeve img {
  position: absolute;
  left: 59px;
  top: 87px;
  bottom: unset;
  right: unset;
  height: 654px;
  width: 592px;
  z-index: unset;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row {
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row {
    justify-content: space-around;
  }
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .card-img {
  border-radius: 12px;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .image-wrapper {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  align-self: baseline;
  padding: 30px;
}
@media only screen and (min-width: 1200px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .image-wrapper {
    background-image: none !important;
  }
}
@media only screen and (max-width: 576px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .image-wrapper {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper {
    padding: 20px;
  }
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .header-title {
  margin-bottom: 40px;
  font-weight: 700;
  color: #262836;
  line-height: 36px;
}
@media only screen and (max-width: 992px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .header-title {
    margin-bottom: 28px;
  }
}
@media only screen and (max-width: 576px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .header-title {
    margin-bottom: 20px;
  }
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items p {
  margin-bottom: 0px;
  color: #46535E;
  line-height: 24px;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items h2,
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items h6, .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items .h6 {
  margin-bottom: 12px;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items h6, .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items .h6 {
  color: #5D6D78;
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items h2 {
  /*Revert to using global H2 in this case so reset style via mixin*/
  font-family: var(--heading-font-regular);
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  color: #262836;
  background: none;
  margin: 0px 0px 12px 0px;
  /*Converting h2 to h3 in mobile mode*/
}
@media screen and (max-width: 576px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items h2 {
    font-family: var(--heading-font-regular);
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    color: #262836;
    background: none;
    margin: 0px 0px 12px 0px;
  }
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items .break-line {
  border-top: 1px solid #C0C0C0;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper .items .break-line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper button {
  margin-top: 40px;
}
@media only screen and (max-width: 992px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper button {
    margin-top: 32px;
  }
}
@media only screen and (max-width: 576px) {
  .hiw-content-sleeve-outer .page-content-sleeve .hiw-container .row .content-wrapper button {
    margin-top: 20px;
    width: 100%;
  }
}

.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .img-sleeve {
  position: relative;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .img-sleeve img {
  position: absolute;
  left: -5px;
  top: -90px;
  bottom: unset;
  right: unset;
  height: 517px;
  width: 529px;
  z-index: unset;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .header .img-sleeve {
  position: relative;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .header .img-sleeve img {
  position: absolute;
  left: -50px;
  top: -40px;
  bottom: unset;
  right: unset;
  height: 72px;
  width: 72px;
  z-index: unset;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .hiw-btm-bck-img {
  left: 85%;
  right: 3%;
  margin-top: 48px;
  position: relative;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row {
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row {
    justify-content: space-around;
  }
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .card-img {
  border-radius: 12px;
}
@media only screen and (max-width: 576px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .card-img {
    margin-top: 0px;
  }
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .image-wrapper {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
  align-self: baseline;
  padding-top: 30px;
}
@media only screen and (min-width: 1200px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .image-wrapper {
    background-image: none !important;
  }
}
@media only screen and (max-width: 576px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .image-wrapper {
    padding: 30px;
    margin-top: 40px;
  }
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper {
  margin-top: 110px;
}
@media only screen and (max-width: 576px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper {
    margin-top: 0px;
  }
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .header-title {
  margin: 0px;
  font-weight: 700;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items p {
  margin-bottom: 0px;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items h2,
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items h6 {
  margin-bottom: 12px;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items h2 {
  font-weight: 700;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items li {
  color: #262836;
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items .break-line {
  border-top: 1px solid #C0C0C0;
  margin-top: 32px;
  margin-bottom: 32px;
}
@media only screen and (max-width: 992px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper .items .break-line {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper button {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .hiw-reversed-content-sleeve-outer .page-content-sleeve .hiw-reversed-container .row .content-wrapper button {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
  }
}

.forgot-password .forgot-password-sleeve {
  background-color: #262836;
  background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
  background-position: bottom left, top 222px right;
  background-repeat: no-repeat, no-repeat;
  background-size: 250px 204px, 207px 227px;
  justify-content: center;
}
.forgot-password .icon-margin {
  font-size: 16px;
  line-height: 36px;
  right: 28px;
  width: 19px;
}
@media only screen and (max-width: 576px) {
  .forgot-password .forgot-password-sleeve {
    background-image: url(/assets/images/account/pebbles5.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 125px 102px;
  }
  .forgot-password .forgot-password-sleeve .page-content-sleeve {
    background-image: none;
  }
}

.forgot-username .forgot-username-sleeve {
  background-color: #262836;
  background-image: url(/assets/images/account/pebbles5.svg), url(/assets/images/account/pebbles6.svg);
  background-position: bottom left, top 222px right;
  background-repeat: no-repeat, no-repeat;
  background-size: 250px 204px, 207px 227px;
  justify-content: center;
  min-height: calc(100vh - 156px);
}
.forgot-username .forgot-username-sleeve .page-content-sleeve .img-sleeve {
  position: relative;
}
.forgot-username .forgot-username-sleeve .page-content-sleeve .img-sleeve img {
  position: absolute;
  left: -117px;
  top: -10px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .forgot-username .forgot-username-sleeve {
    background-image: url(/assets/images/account/pebbles5.svg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 125px 102px;
  }
}

.quote-modal .fa-arrow-left {
  display: none;
}
.quote-modal .close-btn {
  background-color: transparent;
  color: #001B1F;
  min-width: 0px;
}
.quote-modal p a {
  color: #46535E;
  text-decoration: none;
}
.quote-modal p a:hover {
  text-decoration: underline;
}
.quote-modal .products-sleeve {
  display: flex;
  padding: 10px 0px 10px 0px;
}
.quote-modal .products-sleeve.selected, .quote-modal .products-sleeve:hover {
  border-radius: 4px;
  background-color: rgba(0, 123, 182, 0.0784313725);
  background-position: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .quote-modal .close {
    width: 0%;
    float: none;
    position: relative;
    bottom: 10px;
    margin-left: -10px;
  }
  .quote-modal .fa-times {
    display: none;
  }
  .quote-modal .fa-arrow-left {
    display: block;
  }
}

.quote-bar.page-content-sleeve {
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .quote-bar.page-content-sleeve {
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  .quote-bar.page-content-sleeve {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .quote-bar.page-content-sleeve {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.progress-bar-head {
  position: relative;
  left: 0px;
  width: 100%;
  z-index: 2;
}
@media only screen and (min-width: 992px) {
  .progress-bar-head {
    position: sticky;
    top: 0;
  }
}
@media screen and (max-width: 768px) {
  .progress-bar-head {
    font-weight: 700;
  }
}
.progress-bar-head .content {
  display: flex;
  align-items: center;
}
.progress-bar-head .content .product-icon {
  color: #262836;
  margin: 6px 8px 6px 0px;
}
@media screen and (max-width: 768px) {
  .progress-bar-head .content .product-icon {
    margin: 6px 4px 6px 0px;
  }
}
.progress-bar-head .content .product-icon img {
  width: 28px;
  height: 28px;
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .progress-bar-head .content .product-icon img {
    width: 20px;
    height: 20px;
  }
}
.progress-bar-head .content.progress-crumb {
  flex-grow: 1;
}
.progress-bar-head .content.progress-crumb, .progress-bar-head .content.progress-info {
  color: #262836;
}
@media screen and (max-width: 768px) {
  .progress-bar-head .content {
    font-size: 12px !important;
  }
}
.progress-bar-head .content a {
  color: #262836;
}
.progress-bar-head .content a span {
  color: #46535E;
  line-height: 20px;
}

@media only screen and (max-width: 992px) {
  .progress-content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 992px) {
  .progress-content {
    margin-left: -20px;
  }
}
.progress-content .aon-progress-bar .quote-progress-bar {
  padding-left: 0px;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0rem;
}
.progress-content .aon-progress-bar .quote-progress-bar p {
  margin: 0px 0px 0px 0px;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step {
  width: 100%;
  line-height: 20px;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-not-start {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-not-start i {
  color: #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished {
  color: #007585;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished i {
  color: #007585;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-selected {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #004852;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-selected i {
  font-weight: 400;
  color: #004852;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-in-flight-name {
  color: #007585;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-in-flight-name i {
  color: #007585;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-bypassed-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-bypassed-name i {
  color: #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .quote-circle {
  height: 22px;
  width: 22px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .quote-circle .fal,
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .quote-circle .far,
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .quote-circle .fas {
  cursor: default;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished-circle {
  font-weight: 400;
  line-height: 24px;
  background-color: #007585;
  color: #FFFFFF;
  border: 2px solid #007585;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished-circle .fal,
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished-circle .far,
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-finished-circle .fas {
  font-size: 14px;
  color: #FFFFFF !important;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-selected-circle {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background-color: #FFFFFF;
  color: #007585;
  border: 2px solid #007585;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-not-start-circle {
  font-size: 14px;
  line-height: 18px;
  background-color: #FFFFFF;
  color: #5D6D78;
  border: 1px solid #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-in-flight-circle {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  background-color: #FFFFFF;
  color: #007585;
  border: 2px dotted #007585;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-bypassed-circle {
  font-size: 14px;
  line-height: 18px;
  background-color: #FFFFFF;
  color: #5D6D78;
  border: 1px solid #5D6D78;
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-name {
  cursor: default;
  font-family: var(--main-font-regular);
}
.progress-content .aon-progress-bar .quote-progress-bar .quote-progress-bar-step .step-name.step-clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.progress-content .aon-progress-bar.horizontal {
  position: relative;
  width: 100vw;
  left: 0px;
  z-index: 1;
}
.progress-content .aon-progress-bar.horizontal .progress-bar-hl {
  display: inline;
  border-top: 4px solid #007585;
  z-index: 1;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel {
  border: 0px;
  width: 100vw;
  padding: 0 20px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header {
  background-color: transparent;
  border: 0px;
  padding: 0px;
  color: #007585;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve {
  display: flex;
  align-items: center;
  min-height: 54px;
  background-color: transparent;
  padding-bottom: 0;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve i {
  font-size: 20px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text {
  flex-grow: 1;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-gauge {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  margin-bottom: 11px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-gauge div.img,
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-gauge span.img {
  position: absolute;
  left: 0px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-gauge div.img.dotted-bar,
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-gauge span.img.dotted-bar {
  background-image: url("../../../assets/images/progressbar/progress-bar-dotted-line--horizontal.png");
  width: 100%;
  height: 3px;
  background-repeat: repeat-x;
  background-size: contain;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-step-sleeve {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-step-sleeve.focus-visibility {
  outline-offset: -2px;
}
@media only screen and (max-width: 992px) {
  .progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-step-sleeve {
    align-items: flex-start;
  }
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-step-sleeve .progress-bar-steps .quote-progress-bar .quote-progress-bar-step span {
  margin-left: 0px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve .progress-bar-header-text .progress-bar-step-sleeve .progress-bar-steps .quote-progress-bar .quote-progress-bar-step .step-name {
  font-family: var(--main-font-bold);
  font-size: 12px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve:not(.collapsed) .progress-bar-header-text,
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve:not(.collapsed) .progress-bar-header-controls {
  color: #007585;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve:hover .progress-bar-header-text,
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-header .progressbar-header-sleeve:hover .progress-bar-header-controls {
  color: #007585;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-body {
  padding: 0px;
}
.progress-content .aon-progress-bar.horizontal .accordion .progressbar-expansion-panel.accordion-item .accordion-body .progressbar-expansion-panel-text .step-name {
  font-family: var(--main-font-bold);
  font-size: 12px;
}
.progress-content .aon-progress-bar.horizontal .bg-default-color {
  background-color: #EEF6F7;
}
.progress-content .aon-progress-bar.horizontal .bg-white {
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.progress-content .aon-progress-bar.vertical {
  position: sticky;
  top: 80px;
  margin-bottom: 100px;
}
@media screen and (min-width: 992px) {
  .progress-content .aon-progress-bar.vertical {
    top: 40px;
  }
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .quote-progress-bar-substep-background {
  background-image: url(../../../assets/images/progressbar/progress-bar-dotted-line--vertical.svg);
  background-repeat: repeat-y;
  height: 24px;
  background-size: 2px;
  margin-top: 1px;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .expansion-panel {
  border: none;
  padding: 0;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .accordion-item {
  background-color: transparent;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .accordion-item .accordion-header {
  background-color: transparent;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .accordion-item .accordion-header .header-sleeve:not(.collapsed) {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .accordion-item .accordion-header .header-sleeve .header-text {
  flex-grow: inherit;
}
.progress-content .aon-progress-bar.vertical .quote-progress-bar-step .accordion-item .accordion-header i {
  font-size: 20px;
  vertical-align: middle;
}
.progress-content .aon-progress-bar.vertical .progress-bar-vl {
  border-left: 3px solid #007585;
  height: 44px;
}
.progress-content .aon-progress-bar.vertical .progress-bar-vl-substep {
  border-left: 3px solid #007585;
  height: 24px;
}
.progress-content .aon-progress-bar.vertical .last-line {
  height: 55px !important;
}
.progress-content .aon-progress-bar.vertical .progress-bar-vl-dotted {
  background-image: url(../../../assets/images/progressbar/progress-bar-dotted-line--vertical.svg);
  background-repeat: repeat-y;
  height: 38px;
  background-size: 2px;
  margin-top: 2px;
}

.Shamrock .progress-bar-bg-color {
  background-color: #72D4C0;
}

.Tangerine .progress-bar-bg-color {
  background-color: #EFAF8F;
}

.Turquoise .progress-bar-bg-color {
  background-color: #94D8E1;
}

.quote-progress-bar-sub-steps p {
  font-size: 12px;
  line-height: 16px;
}
.quote-progress-bar-sub-steps .sub-steps:last-child {
  margin-bottom: 34px;
}
.quote-progress-bar-sub-steps .sub-step-selected {
  color: #004852;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}
.quote-progress-bar-sub-steps .sub-step-finished {
  color: #007585;
  font-size: 12px;
  line-height: 16px;
}
.quote-progress-bar-sub-steps .sub-step-clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pct-content-sleeve-outer .pc-abs-img-1 .img-sleeve {
  position: relative;
}
.pct-content-sleeve-outer .pc-abs-img-1 .img-sleeve img {
  position: absolute;
  left: unset;
  top: -150px;
  bottom: unset;
  right: 7%;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .pct-content-sleeve-outer .pc-abs-img-1 .img-sleeve {
    position: relative;
  }
  .pct-content-sleeve-outer .pc-abs-img-1 .img-sleeve img {
    position: absolute;
    left: unset;
    top: -60px;
    bottom: unset;
    right: 0px;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
.pct-content-sleeve-outer .pc-abs-img-2 .img-sleeve {
  position: relative;
}
.pct-content-sleeve-outer .pc-abs-img-2 .img-sleeve img {
  position: absolute;
  left: 7%;
  top: unset;
  bottom: -125px;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .pct-content-sleeve-outer .pc-abs-img-2 .img-sleeve {
    position: relative;
  }
  .pct-content-sleeve-outer .pc-abs-img-2 .img-sleeve img {
    position: absolute;
    left: 0px;
    top: unset;
    bottom: -60px;
    right: unset;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
.pct-content-sleeve-outer .page-content-sleeve .pc-container p {
  font-family: var(--main-font-light);
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
  color: #46535E;
  margin: 0px 0px 20px 0px;
}
@media only screen and (max-width: 576px) {
  .pct-content-sleeve-outer .page-content-sleeve .pc-container p {
    font-family: var(--main-font-regular);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #46535E;
    margin: 0px 0px 16px 0px;
  }
}
.pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-header {
  text-align: center;
  margin-bottom: 40px;
}
.pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-header h2 {
  margin-bottom: 12px;
}
@media only screen and (min-width: 576px) {
  .pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-cols .pc-col-0 {
    padding-left: 0px;
  }
  .pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-cols .pc-col-2 {
    padding-right: 0px;
  }
}
.pct-content-sleeve-outer .page-content-sleeve .pc-container .button-sleeve {
  z-index: 1;
}
@media only screen and (max-width: 576px) {
  .pct-content-sleeve-outer .page-content-sleeve .pc-container button {
    width: 100%;
  }
  .pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-header {
    text-align: left;
    margin-bottom: 12px;
  }
  .pct-content-sleeve-outer .page-content-sleeve .pc-container .pc-header h2 {
    margin-bottom: 20px;
    width: 100%;
  }
}

.resources-masthead-dropdown,
.masthead-products-sleeve {
  padding: 20px 120px;
}
.resources-masthead-dropdown h4,
.masthead-products-sleeve h4 {
  color: #007585;
}

.profile-dropdown {
  background-color: #EEF6F7;
  left: unset !important;
  right: 0px;
  padding: 0px;
  border-radius: 0px;
  border: none;
}
.profile-dropdown .profile-dropdown-menu {
  width: 300px;
}
@media screen and (max-width: 992px) {
  .profile-dropdown .profile-dropdown-menu {
    width: 100%;
  }
}
.profile-dropdown .profile-dropdown-menu a {
  color: #262836;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profile-dropdown .profile-dropdown-menu a:hover, .profile-dropdown .profile-dropdown-menu a:active, .profile-dropdown .profile-dropdown-menu a:focus {
  color: #262836;
  background-color: #CDDBDE !important;
  text-decoration: underline;
}

@media screen and (min-width: 992px) {
  .profile-dropdown {
    background-color: #EEF6F7;
  }
  .profile-dropdown .profile-dropdown-menu a {
    color: #262836;
  }
  .profile-dropdown .profile-dropdown-menu a:hover, .profile-dropdown .profile-dropdown-menu a:active, .profile-dropdown .profile-dropdown-menu a:focus {
    background-color: #CDDBDE !important;
    text-decoration: underline;
  }
}
.masthead-products-sleeve .masthead-product-contents-sleeve {
  background-repeat: no-repeat;
  padding: 10px 0px 10px 100px;
  cursor: pointer;
}
.masthead-products-sleeve .masthead-product-contents-sleeve p:hover,
.masthead-products-sleeve .masthead-product-contents-sleeve h5:hover {
  text-decoration: underline;
}
.masthead-products-sleeve .masthead-product-contents-sleeve h5 a {
  color: #012774;
}
.masthead-products-sleeve .masthead-product-contents-sleeve h5 a:hover {
  text-decoration: underline;
  color: #0138A7;
}
.masthead-products-sleeve .masthead-product-contents-sleeve h5 a:active {
  text-decoration: underline;
  color: #001D5F;
}
.masthead-products-sleeve .masthead-product-contents-sleeve h5 .title {
  font-weight: 900;
}
.masthead-products-sleeve .masthead-product-contents-sleeve p a {
  color: inherit;
  font-family: inherit;
}
.masthead-products-sleeve .masthead-product-contents-sleeve p a:hover {
  text-decoration: underline;
  color: inherit;
}
.masthead-products-sleeve .masthead-product-contents-sleeve p a:active {
  text-decoration: underline;
  color: inherit;
}

.masthead-products-sleeve-category {
  padding: 28px 130px 40px 130px;
}
@media screen and (max-width: 992px) {
  .masthead-products-sleeve-category {
    padding: 20px 20px 0px 20px;
  }
}
.masthead-products-sleeve-category .category-column {
  margin-left: -10px;
}
@media screen and (max-width: 992px) {
  .masthead-products-sleeve-category .category-column {
    margin-left: 0px;
    margin-bottom: 20px;
  }
}
.masthead-products-sleeve-category .category-column .category-icon {
  min-height: 36px;
  min-width: 36px;
  cursor: pointer;
  margin-right: 12px;
}
@media screen and (max-width: 576px) {
  .masthead-products-sleeve-category .category-column .category-icon {
    margin-right: 20px;
  }
}
@media screen and (max-width: 576px) {
  .masthead-products-sleeve-category .category-column p.lg {
    font-weight: 700;
  }
}
.masthead-products-sleeve-category .category-column .category-products a {
  min-height: 0px !important;
}
.masthead-products-sleeve-category .category-column .category-products p {
  font-weight: 700;
  text-decoration: underline;
}
@media screen and (max-width: 992px) {
  .masthead-products-sleeve-category .category-column .category-products p a {
    color: #E5EFF0 !important;
  }
}
.masthead-products-sleeve-category .category-column .m-l-ss {
  margin-left: 56px;
}
.masthead-products-sleeve-category h5 a {
  color: #262836;
}
@media screen and (max-width: 992px) {
  .masthead-products-sleeve-category h5 a {
    color: #262836;
  }
}
@media screen and (max-width: 992px) {
  .masthead-products-sleeve-category h3 {
    color: #262836;
  }
}

@media only screen and (max-width: 992px) {
  .product-content {
    margin-bottom: 10px;
  }
  .products-dropdown {
    background: #012774 !important;
  }
  .products-dropdown h5,
  .products-dropdown p {
    color: #262836;
  }
  .products-dropdown a {
    color: rgba(255, 255, 255, 0.87);
  }
  .masthead-products-sleeve .masthead-product-contents-sleeve {
    background-size: 40px;
    background-repeat: no-repeat;
    padding: 0px 0px 0px 56px;
    cursor: pointer;
  }
}
@media only screen and (max-width: 992px) {
  .masthead-products-sleeve {
    padding: 0px;
  }
  .masthead-products-sleeve .product-content {
    padding: 10px 20px 0 20px !important;
  }
  .masthead-products-sleeve .product-content:hover, .masthead-products-sleeve .product-content:active, .masthead-products-sleeve .product-content:focus {
    background-color: #001B5A;
    text-decoration: underline;
  }
  .masthead-products-sleeve .masthead-content-sleeve {
    padding-left: 20px;
    padding-top: 14px;
  }
  .masthead-products-sleeve .masthead-product-contents-sleeve {
    min-height: 50px;
  }
}
/* start of how it works details section scss */
.page#product-details .pd-header-sleeve-outer {
  background-image: url(../../../assets/images/product/how-it-works/prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#product-details .pd-header-sleeve-outer {
    background-image: url(../../../assets/images/product/how-it-works/prod-detail-wave-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
.page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header {
  padding-top: 40px;
}
.page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header h1 {
  padding-bottom: 80px;
  margin-top: 4px;
  color: #262836;
}
@media screen and (max-width: 576px) {
  .page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header h1 {
    padding-bottom: 40px;
    margin-top: 12px;
  }
}
.page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header .xng-breadcrumb-item .xng-breadcrumb-link {
  font-size: 14px;
  line-height: 18px;
}
.page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header .xng-breadcrumb-item label.xng-breadcrumb-trail {
  font-size: 14px;
  line-height: 18px;
}
.page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header img {
  margin-right: 20px;
  width: 80px;
  height: 80px;
  vertical-align: middle;
}
@media screen and (max-width: 576px) {
  .page#product-details .pd-header-sleeve-outer .page-content-sleeve.product-details-header img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
.page#product-details .faq-header {
  text-align: left;
}
.page#product-details h1 {
  color: #262836;
}
.page#product-details h3 {
  scroll-margin-top: 100px;
}
.page#product-details .fa-times {
  font-size: 24px;
  padding: 10px;
  color: #007585;
}

.ped-content-sleeve-outer {
  background-image: url(/assets/images/product/product-editorial/graywaves3-xlarge.svg);
  background-size: cover;
  margin-right: -2px;
}
.ped-content-sleeve-outer .page-content-sleeve:focus {
  outline: none;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .row {
  margin-right: -10px;
  margin-left: -10px;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .col-sm-12,
.ped-content-sleeve-outer .page-content-sleeve .ped-container .col-sm-4,
.ped-content-sleeve-outer .page-content-sleeve .ped-container .col-sm-6 {
  padding-right: 10px;
  padding-left: 10px;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .editorial-heading {
  margin-bottom: 40px;
  text-align: center;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card {
  padding: 0px;
  border-radius: 4px;
  min-height: 100%;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card .image-product-editorial {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: auto;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card .article-link {
  cursor: pointer;
  color: #007585;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card .product-editorial-title,
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card .article-link {
  padding: 0px 20px;
}
.ped-content-sleeve-outer .page-content-sleeve .ped-container .basic-card .article-content {
  padding: 0px 20px 20px 20px;
}

@media only screen and (max-width: 576px) {
  .ped-content-sleeve-outer {
    background-image: url(/assets/images/product/product-editorial/graywaves3-xsmall.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .ped-content-sleeve-outer .ped-container {
    background-color: #F8F8F8;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .ped-content-sleeve-outer .ped-container .editorial-heading {
    margin-bottom: 20px;
  }
  .ped-content-sleeve-outer .ped-container .ped-item {
    margin-bottom: 20px;
  }
}
.pcr-content-sleeve-outer .product-carrier {
  background-image: url(../../../assets/images/product/product-carrier/partner-bkgd-shape.svg);
  background-repeat: no-repeat;
  background-position: 100% 20%;
}
@media only screen and (max-width: 576px) {
  .pcr-content-sleeve-outer .product-carrier {
    background-repeat: no-repeat;
    background-position: left 100px top 20px;
  }
}

.pcr-container {
  margin-top: 40px;
  margin-bottom: 80px;
}
.pcr-container .pcr-content h3 {
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .pcr-container .pcr-content .ci-content h4 {
    margin-bottom: 12px;
  }
}
.pcr-container .pcr-content .ci-image {
  width: 244px;
  height: 60px;
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .pcr-container .pcr-content .ci-image {
    margin-bottom: 12px;
  }
}
.pcr-container .pcr-content .carrier-data-metric {
  font-family: var(--main-font-light);
  font-size: 40px;
}
@media only screen and (max-width: 576px) {
  .pcr-container {
    margin-top: 40px;
    margin-bottom: 0px;
  }
  .pcr-container .pcr-content .carrier-item {
    margin-bottom: 40px;
  }
  .pcr-container .pcr-content h3 {
    margin-bottom: 12px;
  }
}

.pcr-logo-wrapper img {
  max-width: 100%;
}

.pcm-content-sleeve-outer.inverse {
  min-height: unset;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .product-data {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info {
  margin-right: 66px;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info .pcm-abs-img-1 .img-sleeve {
  position: relative;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info .pcm-abs-img-1 .img-sleeve img {
  position: absolute;
  left: -144px;
  top: -80px;
  bottom: unset;
  right: unset;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info .pcm-abs-img-1 .img-sleeve {
    position: relative;
  }
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info .pcm-abs-img-1 .img-sleeve img {
    position: absolute;
    left: -10px;
    top: -100px;
    bottom: unset;
    right: unset;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container {
  width: unset;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container .pcm-abs-img-2 .img-sleeve {
  position: relative;
}
.pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container .pcm-abs-img-2 .img-sleeve img {
  position: absolute;
  left: unset;
  top: 40px;
  bottom: unset;
  right: -166px;
  height: unset;
  width: unset;
  z-index: unset;
}
@media only screen and (max-width: 576px) {
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container .pcm-abs-img-2 .img-sleeve {
    position: relative;
  }
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container .pcm-abs-img-2 .img-sleeve img {
    position: absolute;
    left: unset;
    top: 66px;
    bottom: unset;
    right: -5px;
    height: unset;
    width: unset;
    z-index: unset;
  }
}
@media screen and (max-width: 570px) {
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .compare-info {
    margin-right: 0px;
  }
  .pcm-content-sleeve-outer.inverse .product-compare-container .product-compare-content .button-container {
    width: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.pcv-content-sleeve-outer {
  background-image: url(../../../assets/images/product/product-compare/pet-gray-wave-bkgd-lg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #262836;
}
@media only screen and (max-width: 576px) {
  .pcv-content-sleeve-outer {
    background-image: url(../../../assets/images/product/product-compare/pet-gray-wave-bkgd-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #262836;
  }
}

.product-coverage .pc-item .pc-content .pc-header {
  text-align: center;
}
.product-coverage .pc-item .pc-content .pc-header p {
  font-family: var(--main-font-light);
  font-size: 21px;
  line-height: 32px;
  margin: 0px 0px 32px 0px;
}
.product-coverage .pc-item .pc-content h3 {
  margin-bottom: 20px;
  text-align: center;
}
.product-coverage .pc-item .pc-content h2 {
  margin-bottom: 12px;
}
.product-coverage .pc-item .pc-content h5, .product-coverage .pc-item .pc-content .h5 {
  margin-top: 40px;
  text-align: center;
}
.product-coverage .pc-item .pc-content button {
  margin-bottom: 40px;
  margin-top: 40px;
}
.product-coverage .pc-item .pc-content .pc-content-item p {
  display: flex;
  align-items: baseline;
}
.product-coverage .pc-item .pc-content .pc-content-item p i.fa-check {
  color: #0D725E;
  margin-right: 10px;
  pointer-events: none;
}
.product-coverage .pc-item .pc-content .pc-content-item p i.fa-times {
  color: #CA110F;
  margin-right: 10px;
  pointer-events: none;
}
.product-coverage .pc-item .pc-content .pc-content-item p span.dotted {
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
}
@media only screen and (max-width: 576px) {
  .product-coverage .pc-item .pc-content .pc-header {
    text-align: left;
  }
  .product-coverage .pc-item .pc-content .pc-header p {
    font-family: var(--main-font-regular);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px 0px 16px 0px;
  }
  .product-coverage .pc-item .pc-content h3 {
    text-align: left;
  }
  .product-coverage .pc-item .pc-content h5 {
    max-width: 226px;
    width: 100%;
    text-align: left;
  }
  .product-coverage .pc-item .pc-content button {
    margin-bottom: 20px;
    position: relative;
    width: 100%;
  }
}

.aon-announcement {
  background-color: #262836 !important;
  padding: 20px 25px 20px 20px;
}
@media only screen and (max-width: 768px) {
  .aon-announcement {
    padding: 16px 45px 16px 40px;
  }
}
@media only screen and (max-width: 576px) {
  .aon-announcement {
    padding: 16px 25px 16px 20px;
  }
}
.aon-announcement .aon-announcement-container {
  display: flex;
  justify-content: space-between;
}
.aon-announcement .aon-announcement-container .announcement-content {
  color: #FFFFFF !important;
  display: flex;
}
.aon-announcement .aon-announcement-container i.announcement-icon {
  color: #FFFFFF !important;
  margin-right: 8px;
}
.aon-announcement .aon-announcement-container i.fa-times {
  color: #FFFFFF !important;
  margin-left: 26px;
}
.aon-announcement .aon-announcement-container p {
  margin: 0px;
}
@media only screen and (max-width: 768px) {
  .aon-announcement .aon-announcement-container p {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }
}

.product-promo-banner {
  position: relative;
}
.product-promo-banner .product-promo-banner-container div {
  display: flex;
  padding: 20px 25px 20px 20px;
  border-bottom: 1px solid #FFFFFF;
}
.product-promo-banner .product-promo-banner-container div i {
  color: #012774;
  margin-right: 8px;
}
.product-promo-banner .product-promo-banner-container div i.fa-times {
  color: #46535E;
  margin-left: 8px;
}
.product-promo-banner .product-promo-banner-container div span {
  flex-grow: 1;
}

.banner_light_blue {
  background-color: #CBEEF6 !important;
}
.banner_light_blue .aon-announcement-container .announcement-content {
  color: #46535E !important;
}
.banner_light_blue .aon-announcement-container i.announcement-icon {
  color: #012774 !important;
}
.banner_light_blue .aon-announcement-container i.fa-times {
  color: #46535E !important;
}

.banner_dark_blue {
  background-color: #262836 !important;
}
.banner_dark_blue .aon-announcement-container .announcement-content {
  color: #FFFFFF !important;
}
.banner_dark_blue .aon-announcement-container i.announcement-icon {
  color: #FFFFFF !important;
}
.banner_dark_blue .aon-announcement-container i.fa-times {
  color: #FFFFFF !important;
}

ul.tab-list {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  border: 1px solid #003C55;
  background-color: #003C55;
}
ul.tab-list li.tab-item {
  text-align: center;
  background-color: #FFFFFF;
  border-right: 1px solid #003C55;
}
ul.tab-list li.tab-item a {
  text-decoration: none;
  color: #003C55;
}
ul.tab-list li.tab-item.solid {
  background-color: #003C55;
  border-right: 2px solid #003C55;
}
ul.tab-list li.tab-item.solid a {
  color: #FFFFFF;
}
ul.tab-list li.tab-item:last-child {
  border-right: none;
}

.page#product-coverage-details h1,
.page#product-coverage-details h3 {
  scroll-margin-top: 90px;
}
.page#product-coverage-details.Turquoise .pcd-header-sleeve-outer {
  background-image: url(../../../assets/images/product/product-insurance/auto-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details.Turquoise .pcd-header-sleeve-outer {
    background-image: url(../../../assets/images/product/product-insurance/auto-prod-detail-wave-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
.page#product-coverage-details.Tangerine .pcd-header-sleeve-outer {
  background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details.Tangerine .pcd-header-sleeve-outer {
    background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
.page#product-coverage-details.Shamrock .pcd-header-sleeve-outer {
  background-image: url(../../../assets/images/product/product-insurance/pp-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details.Shamrock .pcd-header-sleeve-outer {
    background-image: url(../../../assets/images/product/product-insurance/pp-prod-detail-wave-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
.page#product-coverage-details .pcd-header-sleeve-outer {
  background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-header-sleeve-outer {
    background-image: url(../../../assets/images/product/product-insurance/pet-prod-detail-wave-sm.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header {
  padding-top: 40px;
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header h1 {
  padding-bottom: 60px;
  margin-top: 4px;
  color: #262836;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header h1 {
    padding-bottom: 40px;
    margin-top: 12px;
  }
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header .xng-breadcrumb-item .xng-breadcrumb-link {
  font-size: 14px;
  line-height: 18px;
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header .xng-breadcrumb-item label.xng-breadcrumb-trail {
  font-size: 14px;
  line-height: 18px;
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header .xng-breadcrumb-separator {
  margin-left: 4px;
  margin-right: 4px;
}
.page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header img {
  margin-right: 20px;
  width: 80px;
  height: 80px;
  vertical-align: middle;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-header-sleeve-outer .product-coverage-details-header img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-types .content-title {
  margin-top: 20px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details .content-title {
  margin-top: 24px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details .content-title > * {
  width: auto;
  padding-left: 0;
  padding-right: 0;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details h3 {
  margin-bottom: 0px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details h5 {
    max-width: 300px;
    width: 100%;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details .fa-check {
  color: #0D725E;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .cover-details .fa-times {
  color: #CA110F;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content {
  background-color: #FFFFFF;
  text-align: center;
  margin-bottom: 40px;
  border: 1px solid #ACC0C4;
  border-radius: 8px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-img {
  margin-top: 32px;
  margin-bottom: 12px;
  width: 52px;
  height: 45px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .div-wrapper {
  margin-top: -28px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .div-wrapper.pet-title-margin {
  margin-top: 0px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .div-wrapper.pet-title-margin p {
  margin-bottom: 20px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .div-wrapper.pet-title-margin p:last-child {
  margin-bottom: 12px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .save-percent {
  background-color: #EEF6F7;
  padding-left: 32px;
  padding-right: 32px;
  display: block;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .save-percent.save-percent-details {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .save-percent {
    text-align: center;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .save-percent p.lg {
  font-family: var(--main-font-bold);
  margin-top: 20px;
  color: #262836;
  text-align: left;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .basic-card {
  border: none;
  border-radius: 4px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .card1 {
  background-color: #fbd5c2;
  width: 49.3%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 24px 0 0 0;
  margin-bottom: 32px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .card1 {
    border-radius: 0px;
    width: 100%;
    margin-bottom: 8px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .card2 {
  background-color: #EFAF8F;
  width: 49.3%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding: 24px 20px 0 20px;
  margin-bottom: 32px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .card2 {
    border-radius: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-left-cap {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #FFFFFF;
  opacity: 70%;
  border-bottom-left-radius: 4px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-left-cap {
    border-radius: 0px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-left-cap img {
  height: 18px;
  width: 14px;
  margin-right: 6px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-left-cap p {
  color: #012774;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-right-cap {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #FFFFFF;
  opacity: 70%;
  border-bottom-right-radius: 4px;
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-right-cap {
    border-radius: 0px;
  }
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-right-cap img {
  height: 18px;
  width: 14px;
  margin-right: 6px;
}
.page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .pets-content .pet-content-tile .bottom-right-cap p {
  color: #012774;
}
@media only screen and (max-width: 576px) {
  .page#product-coverage-details .pcd-content-sleeve-outer .page-content-sleeve.product-coverage-details .p-dropdown .wrap:after {
    color: #FFFFFF;
  }
}
@media screen and (max-width: 576px) {
  .page#product-coverage-details .dotted {
    text-decoration: underline;
    text-decoration-style: dashed;
  }
}
.page#product-coverage-details .page-content-sleeve.faqs {
  padding-top: 100px;
  margin-top: 0;
}
@media screen and (min-width: 992px) {
  .page#product-coverage-details .page-content-sleeve.faqs {
    padding-left: 15px;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .page#product-coverage-details .page-content-sleeve.faqs {
    padding-left: 0;
  }
}
.page#product-coverage-details .page-content-sleeve.faqs .faqs-content {
  align-items: start;
}
.page#product-coverage-details .page-content-sleeve.faqs .faq-sleeve.col-md-8 {
  width: 100% !important;
}

.page#help .help-page {
  scroll-margin-top: 90px;
}
.page#help .help-page .help-page-header {
  background-image: url(../../../assets/images/help/gray-prod-detail-wave-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-page-header {
    background-image: url(../../../assets/images/help/gray-prod-detail-wave-sm.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-page-header .page-content-sleeve {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.page#help .help-page .help-page-header .help-page-padding {
  padding-top: 40px;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-page-header .help-page-padding {
    padding-top: 20px;
  }
}
.page#help .help-page .help-page-header .help-page-padding h1 {
  color: #262836;
  padding-bottom: 80px;
  margin-top: 6px;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-page-header .help-page-padding h1 {
    padding-bottom: 40px;
    margin-top: 12px;
  }
}
.page#help .help-page .help-page-header .help-page-padding img {
  vertical-align: middle;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-page-header .help-page-padding img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
.page#help .help-page .help-page-header .vp-help-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 576px) {
  .page#help .help-page .help-page-header .vp-help-icon {
    display: none;
  }
}
.page#help .help-page .help-details {
  padding-top: 32px;
  padding-bottom: 150px;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-details {
    padding-bottom: 80px;
  }
}
.page#help .help-page .help-details section:not(:first-child) h2 {
  margin-top: 40px;
}
.page#help .help-page .help-details h2.category-name:not(:first-child) {
  margin-top: 40px;
  margin-bottom: 0px;
}
.page#help .help-page .help-details h3 {
  scroll-margin-top: 80px;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-details h3 {
    margin-left: 30px;
  }
}
.page#help .help-page .help-details .logo-icon img {
  max-width: 100px;
  height: auto;
  width: auto;
}
.page#help .help-page .help-card {
  background: #FFFFFF;
  border: 1px solid #ACC0C4;
  border-radius: 8px;
  width: 100%;
  padding: 32px;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .help-card {
    border-radius: 0;
    padding: 20px;
  }
}
.page#help .help-page .help-card .card {
  border: 0;
}
.page#help .help-page .help-card .card .card-body {
  padding: 0;
}
.page#help .help-page .help-card .card .card-body .page-content-sleeve {
  padding: 0;
}
.page#help .help-page .help-card .card .card-body p i.fal, .page#help .help-page .help-card .card .card-body p i.far {
  color: #46535E;
}
.page#help .help-page .help-card .card .accordion .expansion-panel {
  border: 0px;
  border-bottom: 1px solid #C0C0C0;
  padding: 20px 0px 20px 0px;
}
.page#help .help-page .icon-mb-6 {
  margin-bottom: 6px;
}
.page#help .help-page p {
  margin: 0px 0px 0px 0px;
}
.page#help .help-page .vertical {
  border: none;
  border-left: 1px solid #C0C0C0;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .page#help .help-page .vertical {
    border: none;
    border-top: 1px solid #C0C0C0;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
}
.page#help .help-page .back-to-top {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
  color: #007585;
  padding-top: 20px;
}
.page#help .help-page .back-to-top a:hover {
  color: #004852;
}
@media screen and (max-width: 576px) {
  .page#help .help-page .sticky-dropdown {
    background-color: #FFFFFF;
    position: sticky;
    top: 0px;
    z-index: 10;
  }
}

.app-content-sleeve .quote-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  min-height: calc(100vh - 504px);
}
@media screen and (max-width: 576px) {
  .app-content-sleeve .quote-sleeve {
    background-image: url(../../../assets/images/product/graywaves4-xsm.svg);
  }
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow {
  justify-content: space-between;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .aon-progress-bar.vertical {
  padding-top: 40px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .summary-content,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .review-purchase-right {
  padding-top: 40px;
}
@media screen and (max-width: 992px) {
  .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content,
  .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .summary-content,
  .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .review-purchase-right {
    padding-top: 0px;
  }
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content .edit i,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .summary-content .edit i,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .review-purchase-right .edit i {
  font-size: 18px;
  cursor: pointer;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .review-purchase-right .hidden-summary-detail {
  display: none !important;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content {
  padding-bottom: 40px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content .aon-static-content.aon-form-header {
  margin-bottom: 20px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content .term-period-dp .input-date-picker {
  height: 44px;
  border-radius: 0.25rem;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content .aon-activity-sableentityintersect sable-entity-intersect-flow-adapter .aon-form-component {
  margin-bottom: 32px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content .aon-activity-sableentityintersect sable-entity-intersect-flow-adapter .aon-form-component > .aon-form-label + .aon-form-radio-group {
  margin-top: 12px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow .quote-flow-content sable-forms-flow-adapter sable-input-radio .aon-form-component .aon-form-radio-group .aon-form-radio:only-child {
  margin-bottom: 0 !important;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .container.header h3 {
  font-family: var(--heading-font-bold);
  font-size: 44px;
  font-weight: 300;
  line-height: 56px;
  color: #262836;
  background: none;
  margin: 0px 0px 20px 0px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .container sable-form form .aon-auto-width {
  width: 100%;
}
@media screen and (max-width: 576px) {
  .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .container sable-form form .aon-auto-width {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .container sable-form form {
    width: 100%;
  }
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .container sable-form form.aon-form-full-width {
  width: 100%;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow sable-flow .almost-done-bg-color .edit-state {
  background: none;
  border-radius: none;
  box-shadow: none;
  border: none;
  padding: 0px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve.main .row.quote-flow app-claim-flow sable-flow .container.header p {
  font-size: 18px;
  line-height: 28px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .q-r-s-payperperiod {
  line-height: 19px;
  color: #46535E;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .benefit-perpay {
  display: inline-block;
  padding-bottom: 20px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve {
  color: #46535E;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve a {
  text-decoration: none;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .fal,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .far {
  color: #007585;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .summary-heading {
  color: #262836;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col {
  padding-left: 0px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .accordion .sable-label-pair .icon-col {
  display: none;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .accordion .expansion-panel {
  margin-top: 12px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .accordion .header-sleeve.expanded {
  padding-bottom: 0px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair .icon-col {
  width: 24px;
  margin-right: 5px;
  flex-shrink: 0;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.header .sable-label,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.header .sable-value, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.strong .sable-label,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.strong .sable-value {
  font-family: var(--main-font-bold);
  line-height: 20px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.about-you .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.about-you .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-summary-user.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.vehicles .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.vehicles .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.drivers .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.drivers .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-driver-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.incidents .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.incidents .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-accident-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.properties .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.properties .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-home-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.cat .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.cat .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.dog .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.dog .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.studentloanassistance .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.studentloanassistance .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-savings-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.job-title .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.job-title .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-job-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.highest-degree .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.highest-degree .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-diploma-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.sla-piggy-bank .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.sla-piggy-bank .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-savings-icon.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.health-screening-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.health-screening-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/health-screening-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.cancer-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.cancer-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/cancer-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.critical-illness-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.critical-illness-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/critical-illness-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.dental-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.dental-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/dental-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.hospital-cash-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.hospital-cash-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/hospital-cash-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.medical-protect-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.medical-protect-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/medical-protect-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.parent-care-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.parent-care-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/parent-care-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.personal-accident-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.personal-accident-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/personal-accident-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.term-life-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.term-life-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/term-life-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.travel-plan .icon-col {
  height: 21px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .sable-label-pair.travel-plan .icon-col::before {
  color: #007BB6;
  content: url("../../../assets/images/quote-item-list/travel-sm-marine.svg");
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .list-label-pair {
  margin-bottom: 5px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary .summary-col .list-label-pair .sable-label {
  font-weight: 700;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary app-quote-summary,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary .row, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary app-quote-summary,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary .row, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary app-quote-summary,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary .row, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary app-quote-summary,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary .row, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary app-quote-summary,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary .row {
  width: 100%;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary .expansion-panel, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary .expansion-panel, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary .expansion-panel, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary .expansion-panel, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary .expansion-panel {
  padding: 6px 0px 6px 0px;
  border-top: 1px solid #c0c0c0;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary .expansion-panel.accordion-item,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary .expansion-panel .accordion-header, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary .expansion-panel.accordion-item,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary .expansion-panel .accordion-header, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary .expansion-panel.accordion-item,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary .expansion-panel .accordion-header, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary .expansion-panel.accordion-item,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary .expansion-panel .accordion-header, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary .expansion-panel.accordion-item,
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary .expansion-panel .accordion-header {
  background-color: transparent;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.PetHealthHistorySummary .expansion-panel .accordion-body, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_AboutYouSummary .expansion-panel .accordion-body, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_StudentLoanSummary .expansion-panel .accordion-body, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_FinanceSummary .expansion-panel .accordion-body, .app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .left-pane .container .row.summary.SLA_EducationSummary .expansion-panel .accordion-body {
  margin-bottom: 0px;
  margin-top: 10px;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .right-pane.icon-col-cta {
  font-size: 18px;
  text-align: right;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .right-pane.icon-col-cta i {
  cursor: pointer;
}
.app-content-sleeve .quote-sleeve .quote-container .page-content-sleeve .summary-sleeve .right-pane.icon-col-cta.CoverageSummary {
  visibility: hidden;
}

.modal-dialog .modal-body .sable-label-pair .icon-col {
  width: 24px;
  margin-right: 5px;
  flex-shrink: 0;
}
.modal-dialog .modal-body .sable-label-pair .sable-label,
.modal-dialog .modal-body .sable-label-pair .sable-value {
  font-size: 14px;
  line-height: 18px;
}
.modal-dialog .modal-body .sable-label-pair.header .sable-label,
.modal-dialog .modal-body .sable-label-pair.header .sable-value {
  font-family: var(--main-font-bold);
  max-width: 280px;
}
.modal-dialog .modal-body .sable-label-pair.about-you .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-summary-user.svg");
}
.modal-dialog .modal-body .sable-label-pair.vehicles .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.vehicles .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
}
.modal-dialog .modal-body .sable-label-pair.drivers .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.drivers .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-driver-icon.svg");
}
.modal-dialog .modal-body .sable-label-pair.incidents .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.incidents .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-accident-icon.svg");
}
.modal-dialog .modal-body .sable-label-pair.properties .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.properties .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-home-icon.svg");
}
.modal-dialog .modal-body .sable-label-pair.cat .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.cat .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
}
.modal-dialog .modal-body .sable-label-pair.dog .icon-col {
  height: 21px;
}
.modal-dialog .modal-body .sable-label-pair.dog .icon-col::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
}

.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair {
  margin-bottom: 20px;
}
.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair:not(.vehicles):not(.primary-driver) {
  flex-wrap: wrap;
}
.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair .sable-label,
.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair .sable-value {
  flex-basis: 75%;
}
.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair:not(.primary-driver) .sable-label {
  font-family: var(--main-font-bold);
}
.Auto_PrimaryDriverSummaryKey app-quote-summary .summary-container sable-dataview lib-dataview-input-label .sable-label-pair .sable-value {
  margin-left: 28px;
}

.aon-modal-box .aon-static-content.aon-form-header h3 {
  margin-bottom: 12px;
}
.aon-modal-box .aon-static-content.aon-form-header p {
  margin-bottom: 20px;
}
.aon-modal-box .aon-form-component {
  margin-bottom: 20px !important;
}
.aon-modal-box .aon-form-component.aon-direction-col .aon-form-legend {
  margin-bottom: 20px;
}
.aon-modal-box .aon-form-component.aon-direction-col .aon-form-legend .aon-form-legend-text span {
  font-size: 16px;
  font-weight: 700;
  color: #5D6D78;
  line-height: 20px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.aon-modal-box .aon-form-component.aon-direction-col .aon-form-radio {
  padding: 12px;
  margin-bottom: 12px;
}
.aon-modal-box .aon-form-component.aon-direction-col .aon-form-radio:last-of-type {
  margin-bottom: 0px;
}
.aon-modal-box .aon-form-component.aon-direction-col .aon-form-radio p.vet-address {
  color: #46535E !important;
}

.aon-activity-coverage .container .card .card-body .qcc-sleeve {
  /*Fixed first column of a table in mobile mode - Start*/
  /*Fixed first column of a table in mobile mode - End*/
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .h1 {
  font-family: var(--heading-font-bold);
  font-size: 40px;
  font-weight: 300;
  line-height: 52px;
  color: #262836;
  background: none;
  margin: 0px 0px 20px 0px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .h1.secondary {
  font-family: var(--heading-font-bold);
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
  color: #262836;
  background: none;
  margin: 0px 0px 20px 0px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .table-cell-footer-text {
  font-size: 14px !important;
  line-height: 36px;
  font-family: var(--main-font-regular);
  color: #46535E;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .carrier-logo {
  height: 110px;
  background-position: 12px 50%;
  background-size: 65%;
  background-repeat: no-repeat;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .carrier-logo.carrier-268-logo {
  background-image: url(../../../assets/images/help/farmers.svg);
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .carrier-logo.carrier-294-logo {
  background-image: url(../../../assets/images/help/safeco.svg);
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .carrier-logo.carrier-305-logo {
  background-image: url(../../../assets/images/help/travelers-logo.svg);
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve table td:first-child,
.aon-activity-coverage .container .card .card-body .qcc-sleeve th:first-child {
  padding-left: 32px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve table td:first-child,
  .aon-activity-coverage .container .card .card-body .qcc-sleeve th:first-child {
    padding-left: 20px;
    padding-right: 10px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve table th:not(:first-child),
.aon-activity-coverage .container .card .card-body .qcc-sleeve td:not(:first-child) {
  padding-left: 12px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve table th:not(:first-child),
  .aon-activity-coverage .container .card .card-body .qcc-sleeve td:not(:first-child) {
    padding-left: 8px;
    padding-right: 8px !important;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve table th:not(:first-child) {
  border-left: 1px solid #C0C0C0;
}
@media screen and (max-width: 992px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .tscroll {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve {
    background-color: #F2F2F2;
  }
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .tscroll {
    overflow-x: scroll;
    font-size: 12px !important;
  }
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .tscroll table td:first-child,
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .tscroll th:first-child {
    left: 0;
    background-color: #FFFFFF;
    box-shadow: 0 3px 6px #C0C0C0;
  }
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .tscroll p {
    font-size: 12px !important;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .qc-header .fa-times {
  color: #007585;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .qc-header .qcc-table-heading {
  color: #012774 !important;
  float: left;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .qc-header button {
  width: unset;
  min-width: unset;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content h6,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .fa-arrow-left {
  color: #007585;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content h6.close-comparison {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content h4 {
  margin-bottom: 40px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content h4 {
    margin-bottom: 12px;
    color: #012774;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .basic-card {
  padding: 32px 0px 32px 0px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .basic-card.basic-card-padding {
  padding: 32px !important;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .basic-card {
    padding: 0px;
    border-radius: 0px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table tr {
  border-top: none;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table th div {
  position: sticky;
  margin-top: -25px;
  display: flex;
  z-index: 99;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table th div {
    position: inherit;
    margin-top: -10px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table th div p {
  background-color: #8CF2DE;
  padding: 4px;
  border-radius: 2px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table th p {
  font-family: var(--main-font-regular);
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table th span {
  font-family: var(--main-font-regular);
  font-weight: 300;
  font-size: 40px;
  line-height: 44px;
  color: #012774;
  margin-bottom: 4px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .auto-table td span p {
  text-decoration: underline dashed;
  text-underline-position: under;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table {
  width: 100%;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr:nth-child(2) th {
  font-size: 14px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table th {
  white-space: normal;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 32px;
  border-bottom: 1px solid #C0C0C0;
  color: #262836;
  font-size: 16px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table th {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table th p {
  font-family: var(--main-font-regular);
  margin-bottom: 0px;
  margin-right: 32px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table th p {
    padding-right: 10px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
  padding-right: 32px;
  text-align: left;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td p {
  margin-bottom: 0px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td p.secondary {
  text-decoration: underline dashed;
  text-underline-position: under;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td p b.secondary {
  text-decoration: underline dashed;
  text-underline-position: under;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table td {
  border-bottom: 1px solid #C0C0C0;
  border-top: 1px solid #C0C0C0;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr:nth-child(1) td,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr:nth-child(2) td {
  border-left: 1px solid #C0C0C0;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr:nth-child(1) td:first-child,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table tr:nth-child(2) td:first-child {
  border-left: 0 !important;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table i {
  margin-right: 10px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table i {
    margin-right: 4px;
  }
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table .fa-check {
  color: #0D725E;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content table .fa-times {
  color: #CA110F;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc tr,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc td {
  border-bottom: 0;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc th,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc td {
  padding-right: 12px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc th:last-child,
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc td:last-child {
  padding-right: 32px;
}
.aon-activity-coverage .container .card .card-body .qcc-sleeve .qcc-content .vb-asia-qcc tbody > tr:last-child > td {
  padding-bottom: 0px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .quote-coverage-comparison .qcc-sleeve .qcc-body {
    padding-top: 0px;
  }
}

.kentico-data-table table tr:nth-child(1) td,
.kentico-data-table table tr:nth-child(2) td {
  border-left: none !important;
}

.page-content-sleeve .qcc-sleeve .qcc-content .tscroll .direct-pay-tag::before {
  line-height: 13px;
  position: absolute;
  top: 23px;
  content: "Direct Pay";
  font-family: var(--main-font-bold);
  background-color: #CFF0FF;
  font-size: 12px;
  padding: 3px 4px;
  border-radius: 2px;
  color: #012774;
}
@media screen and (max-width: 576px) {
  .page-content-sleeve .qcc-sleeve .qcc-content .tscroll {
    overflow-x: scroll;
  }
}
.page-content-sleeve .qcc-sleeve .qcc-content .tscroll table td:first-child,
.page-content-sleeve .qcc-sleeve .qcc-content .tscroll th:first-child {
  left: 0;
  background-color: #FFFFFF;
}

.product-coverage-details .card .card-body {
  color: unset !important;
}
.product-coverage-details .card .card-body .basic-card .tscroll {
  overflow-x: auto;
  overflow-y: hidden;
}
.product-coverage-details .card .card-body .basic-card .tscroll table {
  border-collapse: collapse;
}
.product-coverage-details .card .card-body .basic-card .tscroll table tr,
.product-coverage-details .card .card-body .basic-card .tscroll table td,
.product-coverage-details .card .card-body .basic-card .tscroll table th {
  border: 2px solid;
}
.product-coverage-details .card .card-body .basic-card .tscroll table tr:first-child {
  text-align: center;
  color: #012774;
}
.product-coverage-details .card .card-body .basic-card .tscroll table td:first-child,
.product-coverage-details .card .card-body .basic-card .tscroll table th:first-child {
  position: sticky;
  left: 0;
  background-color: #FFFFFF;
}
.product-coverage-details .card .card-body .basic-card .tscroll table .tr-border-top {
  border-top: 4px solid;
}
.product-coverage-details .card .card-body .basic-card .tscroll table .tr-border-bottom {
  border-bottom: 4px solid;
}
.product-coverage-details .card .card-body .basic-card .tscroll table .tooltip .tooltiptext {
  max-width: 400px;
  width: max-content;
  left: 100%;
  top: 0;
}
@media screen and (max-width: 576px) {
  .product-coverage-details .card .card-body .basic-card .tscroll table .tooltip .tooltiptext {
    max-width: 200px;
  }
}
.product-coverage-details .card .card-body .basic-card .tscroll table .tooltip .tooltiptext::after {
  left: -10px;
  top: 20px;
  border-color: transparent #4C4D4F transparent transparent;
}

.qtc-main-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
@media screen and (max-width: 576px) {
  .qtc-main-sleeve {
    background-color: #F2F2F2;
  }
  .qtc-main-sleeve .col-md-6 {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.qtc-main-sleeve .qtc-page {
  padding-top: 40px;
  padding-bottom: 150px;
}
.qtc-main-sleeve .qtc-page .header-sleeve p {
  margin: 0px 0px 32px 0px;
}
@media screen and (max-width: 576px) {
  .qtc-main-sleeve .qtc-page .header-sleeve p {
    margin: 0px 0px 20px 0px;
  }
}
.qtc-main-sleeve .qtc-page .progress-bar-head .xng-breadcrumb-trail {
  margin-top: 0;
  margin-bottom: 0;
}
.qtc-main-sleeve .qtc-page .progress-bar-head .xng-breadcrumb-link {
  text-decoration: none;
}
.qtc-main-sleeve .qtc-page .progress-bar-head .content {
  display: flex;
  align-items: center;
}
.qtc-main-sleeve .qtc-page .progress-bar-head .progress-crumb .row {
  align-items: center;
  margin-right: 0px;
}
.qtc-main-sleeve .qtc-page .progress-bar-head .progress-crumb p {
  margin-bottom: 0px;
  text-decoration: underline;
}
.qtc-main-sleeve .qtc-page .basic-card {
  padding: 32px;
}
.qtc-main-sleeve .qtc-page .basic-card p:last-child {
  margin-bottom: 32px;
}

.article-page .mobile-back-link {
  color: #262836;
}
.article-page .link-pointer {
  cursor: pointer;
}
.article-page .article-page-content-sleeve {
  margin: 0 auto;
  height: 100%;
  max-width: 100%;
}
@media only screen and (max-width: 1200px) {
  .article-page .article-page-content-sleeve {
    width: 100%;
  }
}
@media only screen and (max-width: 1600px) {
  .article-page .article-page-content-sleeve {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 576px) {
  .article-page .article-page-content-sleeve {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.article-page .article-page-content-sleeve .xng-breadcrumb-list .xng-breadcrumb-separator {
  margin-left: 5px;
  margin-right: 5px;
}
@media screen and (max-width: 576px) {
  .article-page .s-s-para {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.article-page .article-page-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 100%;
}
@media screen and (max-width: 576px) {
  .article-page .article-page-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 100%;
  }
}
@media screen and (max-width: 576px) {
  .article-page .article-page-header .page-content-sleeve {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.article-page .article-page-header .article-page-padding {
  padding-top: 40px;
}
@media screen and (min-width: 768px) {
  .article-page .article-page-header .article-page-padding xng-breadcrumb {
    padding-left: 0;
  }
}
.article-page .article-page-header .article-page-padding h1 {
  color: #262836;
  padding-bottom: 80px;
  margin-top: 4px;
}
@media screen and (max-width: 576px) {
  .article-page .article-page-header .article-page-padding h1 {
    padding-bottom: 40px;
    margin-top: 12px;
  }
}
.article-page .article-page-header .article-page-padding img {
  vertical-align: middle;
}
@media screen and (max-width: 576px) {
  .article-page .article-page-header .article-page-padding img {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
.article-page .article-page-header .vp-article-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 576px) {
  .article-page .article-page-header .vp-article-icon {
    display: none;
  }
}
.article-page .article-details {
  padding-top: 80px;
  padding-bottom: 120px;
  justify-content: space-between;
}
@media screen and (max-width: 576px) {
  .article-page .article-details {
    padding-top: 0px;
    padding-bottom: 80px;
  }
}
.article-page .article-details h4 {
  margin: 40px 0px 20px 0px;
  color: #012774;
}
@media screen and (max-width: 576px) {
  .article-page .article-details h4 {
    margin: 30px 20px 20px 20px;
  }
}
.article-page .article-details h3 {
  color: #012774;
  margin: 0px 0px 0px 0px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details h3 {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.article-page .article-details h5 {
  margin: 0px 0px 0px 0px;
  color: #012774;
}
.article-page .article-details .article-para p {
  margin: 20px 0px 40px 0px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .article-para p {
    margin: 12px 20px 20px 20px;
  }
}
.article-page .article-details .quick-note {
  background-color: #f2f6ff;
  padding: 20px 0px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .quick-note {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.article-page .article-details .quick-note i {
  color: #012774;
  margin-left: 20px;
}
.article-page .article-details .quick-note p {
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .quick-note p {
    margin-right: 20px;
  }
}
.article-page .article-details .question-div {
  background-color: #F8F8F8;
  padding: 32px 32px 40px 32px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .question-div {
    padding: 32px 20px 32px 20px;
  }
  .article-page .article-details .question-div h5 {
    text-align: center;
  }
}
.article-page .article-details .question-div i {
  color: #012774;
  font-size: 44px;
  text-align: center;
}
.article-page .article-details .question-div .question-div-icon {
  margin-bottom: 40px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .question-div .question-div-icon {
    margin-bottom: 20px;
  }
}
.article-page .article-details .ready-to-save {
  background-color: #E4E4E4;
}
.article-page .article-details .ready-to-save h5 {
  margin: 32px 20px 32px 32px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .ready-to-save h5 {
    margin: 0px 0px 12px 0px;
    text-align: center;
  }
}
.article-page .article-details .ready-to-save button {
  margin: 20px 32px 20px 0px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .ready-to-save button {
    margin: 0px 0px 0px 0px;
  }
}
@media screen and (max-width: 576px) {
  .article-page .article-details .ready-to-save {
    padding: 20px;
  }
}
.article-page .article-details .related-article-details .author-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .related-article-details .author-avatar img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
  }
  .article-page .article-details .related-article-details .author-avatar span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.article-page .article-details .related-article-details .author-detail {
  border-bottom: 1px solid #C0C0C0;
}
.article-page .article-details .related-article-details h4 {
  margin: 0px 0px 0px 0px;
}
@media screen and (max-width: 576px) {
  .article-page .article-details .related-article-details h4 {
    color: #007585;
  }
}
.article-page .article-details .related-article-details h6 {
  margin: 12px 0px 8px 0px;
  color: #007585;
}
.article-page .article-details .related-article-details p {
  margin: 0px 0px 32px 0px;
}
.article-page .article-details .related-article-details .article-related-benefits {
  border-top: 1px solid #979797;
  padding-top: 32px;
  margin-top: 20px;
}
.article-page .article-details .related-article-details .article-related-benefits .basic-card {
  border-radius: 4px;
  padding: 20px;
  position: relative;
}
.article-page .article-details .related-article-details .article-related-benefits .basic-card .promo-message {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 12px;
  font-family: var(--main-font-bold);
  letter-spacing: 0;
  line-height: 13px;
  color: #0D725E;
  background-color: #C1F2E9;
  padding: 3px 4px;
}
.article-page .article-details .related-article-details .article-related-benefits .basic-card .product-logo {
  width: 36px;
  height: 36px;
  margin-right: 8px;
}
.article-page .article-details .related-article-details .article-related-benefits .basic-card .card-contents .card-title p {
  font-family: var(--main-font-bold);
}
.article-page .accordion .article-expansion-panel {
  border: 0;
  border-bottom: rgba(192, 192, 192, 0.5);
  background-color: #ffffff;
  border-radius: 0px;
}
@media screen and (max-width: 576px) {
  .article-page .accordion .article-expansion-panel {
    border-top: 1px solid rgba(192, 192, 192, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  }
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header {
  background-color: #fff;
  border: 0px;
  padding: 0px;
  color: #00519B;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve {
  display: flex;
  align-items: center;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve i {
  font-size: 20px;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve .article-header-text {
  margin-bottom: 6px;
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
}
@media screen and (max-width: 576px) {
  .article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve .article-header-text {
    margin-top: 6px;
  }
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve.expanded .article-header-text,
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve.expanded .article-header-controls {
  color: #00519B;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve.expanded:hover .article-header-text,
.article-page .accordion .article-expansion-panel.accordion-item .accordion-header .article-header-sleeve.expanded:hover .article-header-controls {
  color: #00519B;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-body {
  padding: 0px;
}
.article-page .accordion .article-expansion-panel.accordion-item .accordion-body .article-expansion-panel-text {
  background-color: #ffffff;
}
.article-page select {
  padding: 10px 20px 10px 20px;
}
.article-page select:not(.open):not(.disabled):not(.error):hover {
  border: 1px solid #007585;
  padding: 10px 20px 10px 20px;
}
.article-page .playIcon span i.fa-play::before {
  content: "\f04b";
}
.article-page .pause span i.fa-play::before {
  content: "\f04c";
}
.article-page progress {
  background-color: #9CDFFF;
}
.article-page progress::-webkit-progress-bar {
  background-color: #9CDFFF;
}
.article-page progress::-webkit-progress-value {
  background-color: #007585 !important;
}
.article-page .article-page-header-asia {
  display: none;
}

@media only screen and (min-width: 992px) {
  .article-page .article-page-content-sleeve {
    padding-left: 130px;
    padding-right: 130px;
  }
}
.article-page .article-page-content-sleeve .article-details {
  padding-top: 0px;
}
.article-page .article-page-content-sleeve .article-details .left-nav-display {
  display: none;
}
.article-page .article-page-content-sleeve h1 {
  color: #46535E;
  margin-bottom: 40px;
  margin-top: 4px;
}
@media screen and (max-width: 576px) {
  .article-page .article-page-content-sleeve h1 {
    margin-bottom: 20px;
    margin-top: 12px;
  }
}
@media screen and (min-width: 768px) {
  .article-page .col-md-6 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
}
.article-page .article-page-header-asia {
  display: block;
}
.article-page .article-page-header-asia .article-page-padding {
  padding-top: 90px;
}
.article-page .article-page-header-asia .article-page-padding img {
  vertical-align: middle;
  margin-right: 4px;
  width: 20px;
  height: 20px;
}
.article-page .article-page-header {
  display: none;
}

.aon-activity-coverage .header.container {
  display: block;
}
.aon-activity-coverage .container .card {
  background: unset;
  border: unset;
  border-radius: unset;
  box-shadow: unset;
  padding: unset;
  width: unset;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .select-plan {
  margin: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .row {
  margin-right: -10px;
  margin-left: -10px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .col-md-4,
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .col-12 {
  padding-right: 10px;
  padding-left: 10px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .basic-card.plan-list .div-border.singlebenefit {
  border: none;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-coverage-selection .basic-card:has(div.div-border.singlebenefit) {
  border: 2px solid #007585;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve.quote-benefit-selection .beneficiary-allocation-block a {
    padding: 0;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .dropdown-menu {
  width: 100%;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .expansion-panel {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve i.fal.fa-plus {
  margin-top: 16px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve i.fal.fa-plus.n-mt-0 {
  margin-top: 0px !important;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve i.fal.fa-minus {
  margin-top: 16px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve i.fal.fa-minus.n-mt-0 {
  margin-top: 0px !important;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve button.dropdown-toggle {
  background-color: transparent;
  color: black;
  border-color: black;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .dropdown-toggle:hover {
  border: 2px solid #007585;
  padding: 11px 33px 11px 11px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .dropdown-toggle::after {
  float: right;
  margin-top: 3%;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .contextual .message {
    display: flex;
    align-items: start;
  }
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve h1 {
    margin: 0px 0px 12px 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve a {
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve a {
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list-bottom .card-content-bottom .select-plan {
  display: flex;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list-bottom {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .content-left {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .content-left input.subtle {
  margin-right: 20px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .content-left input.subtle {
    margin-bottom: 20px;
    margin-right: 0px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .logo-image {
  height: 44px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .logo-image {
    margin-left: 20px;
    margin-top: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .coverage-options {
  margin-bottom: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve p.xl {
  margin: 0px 0px 40px 0px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve p.xl {
    margin: 0px 20px 20px 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .payment-info h5,
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .payment-info i {
  color: #262836;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .pay-label {
  font-family: var(--main-font-bold);
  background-color: #CFF0FF;
  font-size: 12px;
  padding: 3px 4px;
  border-radius: 2px;
  color: #012774;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .col-12,
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .col-md-4 {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list.row .card-sleeve.first {
  padding-right: 10px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list.row .card-sleeve.second {
  padding-right: 10px;
  padding-left: 10px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list.row .card-sleeve.third {
  padding-left: 10px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-cost.data-metric-m {
  color: #262836;
  font-family: var(--heading-font-light);
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan {
    margin-bottom: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .with-wellness {
  border-radius: 2px;
  float: right;
  margin-top: -5px;
  margin-right: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .with-wellness p {
  padding: 2px 4px 2px 4px;
  background-color: skyblue;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-header {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-benefits {
  padding-left: 20px;
  padding-right: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-benefits p {
  color: #5D6D78;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-cost-panel {
  background-color: #EEF6F7;
  padding: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-cost-panel p {
  margin: 0px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-data {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .plan-data img {
  max-width: 240px;
  margin-bottom: 8px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .price-data {
  text-align: right;
  margin: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan .price-data {
    text-align: left;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan h1 {
  font-family: var(--main-font-regular);
  font-weight: 300;
  font-size: 40px;
  line-height: 52px;
  margin-bottom: 6px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.tabbed-card {
  border: 1px solid #ACC0C4;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border {
  border: 2px solid #007585;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border .plan-header {
  padding-top: 19px;
  padding-left: 19px;
  padding-right: 19px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border .plan-benefits {
  padding-left: 19px;
  padding-right: 19px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border .plan-cost-panel {
  padding-left: 19px;
  padding-right: 19px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border .select-plan {
  margin-left: 19px;
  margin-right: 19px;
  margin-bottom: 19px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border .select-plan button {
  display: flex;
  background-color: #001B1F;
  justify-content: center;
  align-items: center;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .plan-list .plan.div-border.tabbed-card {
  padding: 31px !important;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve b span {
  color: #5D6D78;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile {
  margin-bottom: 20px;
  padding: 32px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile.checked {
  border: 2px solid #007585;
  padding: 31px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile input[type=checkbox] {
  display: none;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile {
    padding: 20px;
  }
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile .coverage-msg {
  color: #262836;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile label p {
  color: #46535E;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile a {
  color: #007585;
  text-decoration: underline;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile .wellness-cover {
  width: 280px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile .wellness-cover .aon-form-input-wrapper.select:after {
  content: "\f0d7";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  font-size: 16px;
  color: #46535E;
  line-height: 24px;
  position: absolute;
  z-index: 1;
  top: 38px;
  right: 16px;
  text-align: center;
  pointer-events: none;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile .wellness-cover select {
  appearance: none;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .benefit-addon-tile .wellness-cover p {
  font-weight: 700;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .selection-controls {
  padding-left: 20px;
  padding-right: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .selection-controls input[type=button] {
  margin-bottom: 20px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .coverage-detail-panel h6 {
  text-transform: uppercase;
  margin-top: 0px;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .coverage-detail-panel .sable-label {
  width: 65%;
}
.aon-activity-coverage .container .card .card-body .qcs-sleeve .coverage-detail-panel .sable-value {
  width: 35%;
  text-align: right;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .container .card .card-body .qcs-sleeve .page-content-sleeve {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.aon-activity-coverage .container .card .fa-check {
  color: #FFFFFF;
}
.aon-activity-coverage .container .coverage-detail-panel .sable-label-pair {
  color: #46535E;
  font-size: 14px;
  margin-bottom: 10px;
}
.aon-activity-coverage .container .coverage-detail-panel .sable-label.with-desc {
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-underline-position: under;
  -moz-text-decoration-line: underline;
  -moz-text-decoration-style: dashed;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-style: dashed;
}
.aon-activity-coverage .container .coverage-detail-panel .sable-label.with-desc i {
  color: #46535E;
}
.aon-activity-coverage .container .coverage-detail-panel .sable-value {
  text-align: right;
}
.aon-activity-coverage .container .coverage-detail-panel .sable-value .sable-label-value-item {
  width: 100%;
  text-align: right;
  font-size: 14px;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .flow-group {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.n-coverage-color {
  color: #5D6D78;
}

td.data-metric-m-table {
  text-align: left !important;
}

.aon-activity-coverage .header.container {
  display: block;
}

.itemlist-page .add-icon-color {
  color: #007585;
}
.itemlist-page .entity-card .canine::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/dog-sm-marine.svg");
  margin-right: 4px;
}
.itemlist-page .entity-card .feline::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/cat-sm-marine.svg");
  margin-right: 4px;
}
.itemlist-page .entity-card .vehicle::before {
  color: #007585;
  content: url("../../../assets/images/quote-item-list/quote-auto-icon.svg");
  margin-right: 4px;
}
.itemlist-page .entity-card .page-content-sleeve {
  padding: 0;
}
.itemlist-page .entity-card .entity {
  border: 1px solid #ACC0C4;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 20px;
}
.itemlist-page .entity-card .entity i.fa-trash-alt {
  cursor: pointer;
}
@media screen and (max-width: 576px) {
  .itemlist-page .entity-card .entity {
    padding: 12px;
  }
}
.itemlist-page .entity-card .entity .checkbox-wrapper {
  margin-bottom: 0;
}
.itemlist-page .entity-card .entity .checkbox-wrapper .fa-info-circle,
.itemlist-page .entity-card .entity .checkbox-wrapper .fa-edit {
  color: #007585;
}
.itemlist-page .entity-card .entity .checkbox-wrapper i.fa-edit {
  cursor: pointer;
}
.itemlist-page .entity-card .entity .checkbox-wrapper .sable-label-pair .icon-col {
  display: none;
}
.itemlist-page .entity-card .entity .checkbox-wrapper label {
  margin-bottom: 0px;
}
.itemlist-page .entity-card .entity .checkbox-wrapper i + label {
  font-family: var(--main-font-regular);
  font-weight: 400;
  position: relative;
  top: 0;
  padding-left: 11px;
  padding-right: 10px;
  cursor: pointer;
  line-height: 24px;
  font-size: 16px;
  display: block;
}
.itemlist-page .entity-card .entity .checkbox-wrapper.disabled {
  opacity: 1;
  cursor: default;
}
.itemlist-page .entity-card .entity .checkbox-wrapper.disabled .fa-info-circle,
.itemlist-page .entity-card .entity .checkbox-wrapper.disabled i + label {
  cursor: default;
}
.itemlist-page .entity-card .entity .aon-form-radio:not(.aon-direction-column):only-child {
  margin-bottom: 0 !important;
}
.itemlist-page .entity-card .entity.selected {
  border: 2px solid #007585;
  padding: 20px;
}
.itemlist-page .entity-card .entity.selected .aon-form-radio:not(.aon-direction-column):only-child {
  margin-bottom: 20px !important;
}
.itemlist-page .entity-card .entity.selected .aon-form-radio:not(.aon-direction-column) input[type=radio] + label:before {
  margin-right: 8px;
}
.itemlist-page .entity-card .entity.selected .payment-card-radio:not(.aon-direction-column):only-child {
  margin-bottom: 0 !important;
}
.itemlist-page .entity-card .entity .underwriting i.fa-check-circle {
  color: green;
}
.itemlist-page .entity-card .entity .underwriting i.fa-times-circle {
  color: red;
}
.itemlist-page .entity-card .entity .underwriting i.fa-check-tick {
  color: green;
  font-weight: 400;
}
.itemlist-page .entity-card .entity .underwriting i.fa-times-close {
  color: red;
}
.itemlist-page .entity-card .add-entity {
  background-color: #FFFFFF;
  border: 1px solid #ACC0C4;
  border-radius: 12px;
  padding: 20px 20px 20px 24px;
}
@media screen and (max-width: 576px) {
  .itemlist-page .entity-card .add-entity {
    padding: 12px;
  }
}
.itemlist-page .entity-card .back-btn {
  display: flex;
  justify-content: center;
}
.itemlist-page .entity-card .aon-two-part-telephone-input {
  display: flex;
}
.itemlist-page .entity-card .aon-two-part-telephone-input .country-code {
  padding-right: 20px;
}
.itemlist-page input[type=radio] + label:before {
  flex-shrink: 0;
}
.itemlist-page h5 {
  margin: 0px 0px 0px 0px;
  color: #012774;
}
.itemlist-page .xl-para {
  font-family: var(--main-font-light);
  font-size: 21px;
  margin: 0;
}
.itemlist-page .lg-para {
  display: flex;
  align-items: center;
  margin: 0;
}
.itemlist-page .lg-para div,
.itemlist-page .lg-para span,
.itemlist-page .lg-para a {
  font-family: var(--main-font-light);
  font-size: 18px;
}
@media screen and (max-width: 576px) {
  .itemlist-page .lg-para i {
    margin-left: 30px;
  }
}
.itemlist-page .quick-note {
  background-color: #CBEEF6;
  padding: 20px;
}
.itemlist-page .quick-note i {
  color: #012774;
}
.itemlist-page .driverlist {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
.itemlist-page .driverlist .sable-label {
  padding-left: 0;
}
.itemlist-page .driverlist .sable-value {
  text-align: right;
  margin-right: 0 !important;
  padding-right: 0;
}
.itemlist-page .incidentlist {
  justify-content: space-between;
  margin-left: 0;
  margin-right: 0;
}
.itemlist-page .incidentlist .sable-label {
  padding-left: 0;
}
.itemlist-page .incidentlist .sable-value {
  text-align: right;
  margin-right: 0 !important;
  padding-right: 0;
}
.itemlist-page .label-width {
  width: 100%;
}

.underwriting-result .alert {
  border: 0px;
}

.itemlist-page .addEditContainer .phone-number {
  display: none;
}

.q-com-sleeve .q-com-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}
@media screen and (max-width: 576px) {
  .q-com-sleeve .q-com-sleeve {
    background-image: url(../../../assets/images/product/graywaves4-xsm.svg);
  }
  .q-com-sleeve .q-com-sleeve .printcontent {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.q-com-sleeve .quote-save p {
  margin-bottom: 0;
  text-decoration: underline;
  color: #007585;
}
.q-com-sleeve .quote-save i {
  margin-right: 10px;
  color: #007585;
}
.q-com-sleeve .basic-card {
  padding: 32px;
  margin-bottom: 20px;
}
.q-com-sleeve .basic-card .total-payment-sablelabel {
  border-top: 1px solid #C0C0C0;
  padding-top: 20px;
  font-size: 20px;
}
@media screen and (max-width: 576px) {
  .q-com-sleeve .basic-card {
    padding: 20px;
    border-radius: 0px;
  }
  .q-com-sleeve .basic-card .quote-value {
    flex-direction: column;
  }
}
.q-com-sleeve .basic-card#first-card {
  margin-top: 40px;
}
.q-com-sleeve .basic-card#my-info div {
  margin-bottom: 20px;
}
.q-com-sleeve .basic-card ul {
  padding-inline-start: 32px;
  margin-bottom: 32px;
}
.q-com-sleeve .basic-card .data-metric-lg,
.q-com-sleeve .basic-card .data-metric-m {
  color: #012774;
  margin-bottom: 6px;
}
.q-com-sleeve .basic-card .fa-dog,
.q-com-sleeve .basic-card .fa-cat {
  color: #007585;
  font-size: 20px;
  margin-right: 4px;
}
.q-com-sleeve .basic-card .sable-label-pair {
  color: #46535E;
  flex-direction: column;
  margin-bottom: 20px;
}
.q-com-sleeve .basic-card .sable-label {
  font-weight: 700;
  margin-bottom: 4px;
}
.q-com-sleeve .cursor-pointer {
  cursor: pointer;
}
.q-com-sleeve .expansion-panel {
  border-top: 1px solid rgba(192, 192, 192, 0.5);
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  padding: 8px 0;
}
.q-com-sleeve .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text {
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 0px;
}
.q-com-sleeve .per-pay-text {
  text-align: right;
}
@media screen and (max-width: 576px) {
  .q-com-sleeve .per-pay-text {
    text-align: left;
  }
}

.page-not-found {
  margin-top: 40px;
}
.page-not-found .not-found-container {
  max-width: 820px;
  margin: 0 auto;
  flex-flow: column;
}
.page-not-found .not-found-container > i {
  font-size: 80px;
  line-height: 88px;
}
.page-not-found .not-found-container h6 {
  color: #5D6D78;
}
.page-not-found .not-found-container > img {
  width: max-content;
  margin: 0 auto 20px;
  max-width: 100%;
}
.page-not-found .not-found-container > button {
  margin: 0 auto;
}
.page-not-found .not-found-container p.xl {
  color: black;
}
.page-not-found .not-found-container p.errorid {
  color: #5D6D78;
}

@media screen and (max-width: 576px) {
  .client-code-not-found img {
    height: 300px;
    margin-bottom: 20px;
  }
}

.vb-global-error-page {
  background-image: url(../../../assets/images/vb-global-error-page/graywaves5-xlarge.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 100%;
  padding: 0px 22px;
}
@media screen and (max-width: 576px) {
  .vb-global-error-page {
    background-image: url(../../../assets/images/vb-global-error-page/graywaves5-xsm.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 100%;
  }
}
.vb-global-error-page .error-data {
  display: flex;
  justify-content: center;
}
.vb-global-error-page .section-1 {
  padding: 24px;
}
@media screen and (max-width: 1200px) {
  .vb-global-error-page .section-1 {
    position: relative;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .vb-global-error-page .section-1 {
    position: absolute;
  }
}
.vb-global-error-page .section-1 img {
  width: 273px;
  height: 365px;
}
@media screen and (max-width: 1200px) {
  .vb-global-error-page .section-1 img {
    position: absolute;
    left: -70px;
    bottom: -5px;
    width: 91px;
    height: 130px;
  }
}
@media screen and (min-width: 1200px) {
  .vb-global-error-page .section-1 img {
    position: absolute;
  }
}
.vb-global-error-page .section-2 {
  padding-top: 48px;
  padding-bottom: 96px;
}
@media screen and (max-width: 576px) {
  .vb-global-error-page .section-2 {
    width: 65%;
    padding-top: 85px;
    padding-bottom: 130px;
  }
}
.vb-global-error-page .section-2 img {
  width: 80px;
  height: 80px;
}
@media screen and (max-width: 576px) {
  .vb-global-error-page .section-2 img {
    width: 60px;
    height: 60px;
  }
}
.vb-global-error-page .section-3 {
  padding-top: 120px;
  padding-left: 24px;
  padding-bottom: 24px;
}
@media screen and (max-width: 576px) {
  .vb-global-error-page .section-3 {
    position: relative;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
}
.vb-global-error-page .section-3 img {
  width: 270px;
  height: 335px;
}
@media screen and (max-width: 576px) {
  .vb-global-error-page .section-3 img {
    position: absolute;
    top: 23px;
    width: 84px;
    height: 119px;
    right: -60px;
  }
}

.quote-review-sleeve {
  background-image: url(../../../assets/images/product/graywaves4-xlarge.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}
@media screen and (max-width: 576px) {
  .quote-review-sleeve {
    background-image: url(../../../assets/images/product/graywaves4-xsm.svg);
  }
}
.quote-review-sleeve .quote-review-page {
  padding-top: 80px;
  padding-bottom: 150px;
}
.quote-review-sleeve .quote-review-page h5 {
  margin: 0px 0px 0px 0px;
}
.quote-review-sleeve .quote-review-page label {
  margin-bottom: 2px;
}
.quote-review-sleeve .quote-review-page .mb-2 {
  margin-bottom: 2px !important;
}
.quote-review-sleeve .quote-review-page .data-metric-lg,
.quote-review-sleeve .quote-review-page .data-metric-m {
  color: #262836;
}
.quote-review-sleeve .quote-review-page hr {
  border-top: 1px solid rgba(192, 192, 192, 0.5);
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel {
  border: 0;
  border-bottom: rgba(192, 192, 192, 0.5);
  background-color: #ffffff;
  border-radius: 0px;
}
@media screen and (max-width: 576px) {
  .quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel {
    border-top: rgba(192, 192, 192, 0.5);
  }
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header {
  background-color: #fff;
  border: 0px;
  padding: 0px;
  color: #00519B;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve {
  display: flex;
  align-items: center;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve i {
  font-size: 20px;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve .quote-header-text {
  margin-bottom: 6px;
  flex-grow: 1;
  font-weight: 400;
  font-size: 16px;
}
@media screen and (max-width: 576px) {
  .quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve .quote-header-text {
    margin-top: 6px;
  }
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve.expanded .quote-header-text,
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve.expanded .quote-header-controls {
  color: #00519B;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve.expanded:hover .quote-header-text,
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-header .quote-header-sleeve.expanded:hover .quote-header-controls {
  color: #00519B;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-body {
  padding: 0px;
}
.quote-review-sleeve .quote-review-page .accordion .quote-expansion-panel.accordion-item .accordion-body .quote-expansion-panel-text {
  background-color: #ffffff;
}
@media screen and (max-width: 576px) {
  .quote-review-sleeve .quote-review-page .quote-review-cost {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
.quote-review-sleeve .icon-pointer {
  cursor: pointer;
}
.quote-review-sleeve .basic-card {
  padding: 32px;
}
@media screen and (max-width: 576px) {
  .quote-review-sleeve .basic-card {
    width: auto;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.quote-review-sleeve sable-dataview .accordion .expansion-panel {
  border-top: 1px solid rgba(192, 192, 192, 0.5);
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  padding: 8px 0;
}
.quote-review-sleeve sable-dataview .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text {
  line-height: 20px;
  font-size: 16px;
  margin-bottom: 0px;
}
.quote-review-sleeve sable-dataview .accordion .accordion-body {
  margin-top: 20px;
}
.quote-review-sleeve app-quote-review-summary .summary-container {
  margin-top: 20px;
}

.active-benefits-container .alert.banner.success p i {
  color: #007585;
}
.active-benefits-container .fa-phone {
  color: #46535E;
}
.active-benefits-container h1 {
  margin-bottom: 12px;
}
.active-benefits-container .aon-alert.banner p,
.active-benefits-container .alert.banner p {
  margin: 8px 0px 12px 0px;
}
.active-benefits-container .benefits-card {
  margin: 20px 0px !important;
  background: #FFFFFF;
  border: 1px solid #ACC0C4;
  border-radius: 8px;
  width: 100%;
  padding: 32px;
}
@media screen and (max-width: 576px) {
  .active-benefits-container .benefits-card {
    border-radius: 0;
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
    width: auto;
  }
}
.active-benefits-container .benefits-card .data-metric-m {
  color: #262836;
}
.active-benefits-container .benefits-card .flex-col-1 {
  flex: 1 1 0;
}
.active-benefits-container .benefits-card .flex-col-2 {
  flex: 2 1 0;
}
.active-benefits-container .b-b-header {
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
}
.active-benefits-container .rt-sidebar-top-position {
  margin-left: 34px;
}
.active-benefits-container .accordion .benefit-expansion-panel {
  border: 0;
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  background-color: #FFFFFF;
  border-radius: 0px;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header {
  background-color: #FFFFFF;
  border: 0px;
  padding: 0px;
  color: #00519B;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve {
  display: flex;
  align-items: center;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve i {
  font-size: 20px;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve .benefit-header-text {
  flex-grow: 1;
  color: #007585;
  font-weight: 400;
  padding: 20px 0px;
  margin-bottom: 0px;
}
@media screen and (max-width: 576px) {
  .active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve .benefit-header-text {
    padding: 10px 0px;
  }
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve .benefit-header-controls {
  color: #007585;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded .benefit-header-text,
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded .benefit-header-controls {
  color: #00519B;
  font-weight: 700;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded:hover .benefit-header-text,
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-header .benefit-header-sleeve.expanded:hover .benefit-header-controls {
  color: #00519B;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-body {
  padding: 0px;
}
.active-benefits-container .accordion .benefit-expansion-panel.accordion-item .accordion-body .benefit-expansion-panel-text {
  background-color: #FFFFFF;
}
.active-benefits-container .xs-para {
  font-size: 12px;
  line-height: normal;
}
.active-benefits-container .input_label {
  word-break: break-word;
}
.active-benefits-container .benefits_cost {
  text-align: right;
}
.active-benefits-container .link-button {
  text-align: left;
  background: none !important;
  border: none;
  color: #007585;
  padding: 0 !important;
  text-decoration: underline;
  cursor: pointer;
}
.active-benefits-container .row-color {
  background-color: #EEF6F7;
}
.active-benefits-container .benefit-relationship {
  flex-basis: 100%;
  color: #262836;
}
.active-benefits-container .highlight-policy-status {
  background-color: #C1F2E9;
  padding: 2px 4px 2px 4px;
  border-radius: 2px;
}
.active-benefits-container .policy-status-div {
  margin-top: 4px;
  margin-left: 10px;
}
.active-benefits-container .policy-status-div > p {
  display: inline-block;
  margin: 0 !important;
}
.active-benefits-container .popover-gray {
  background-color: #46535E !important;
}
.active-benefits-container .popover-body {
  color: #F9FCFC !important;
}
.active-benefits-container .bs-popover-bottom > .popover-arrow::after, .active-benefits-container .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-bottom-color: #46535E !important;
}

.benefit-modal .fal {
  color: #007585 !important;
}
.benefit-modal .close-btn {
  background-color: transparent;
  color: #001B1F;
  min-width: 0px;
}
.benefit-modal .benefits_cost {
  text-align: right;
}

.sub-total {
  color: #012774;
}

.registration-core input#WorkEmailSelected + label::before {
  padding-left: 15px;
}
.registration-core label.strong {
  font-weight: 700 !important;
}
.registration-core .WorkEmailSelected {
  display: flex !important;
  word-break: break-all;
  max-width: 360px;
  width: 100%;
}

.registration-modal .registration-sleeve {
  background-image: none;
}
.registration-modal .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
}
.registration-modal .img-sleeve {
  display: none;
}
.registration-modal .WorkEmailSelected {
  word-break: break-all;
  max-width: 300px;
  width: 100%;
}
.registration-modal input[type=email] {
  width: 80%;
}

@media only screen and (min-width: 992px) {
  .navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item {
    border-top: 4px solid transparent;
    min-height: 68px !important;
  }
}
.navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item:hover {
  background-color: #EEF6F7;
}
@media only screen and (min-width: 992px) {
  .navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item:hover {
    border-top: 4px solid #B80012;
  }
}
.navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item:hover .text-info {
  color: #B80012 !important;
}
.navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item.show {
  background-color: #EEF6F7;
}
@media only screen and (min-width: 992px) {
  .navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item.show {
    border-top: 4px solid #EB0017;
  }
}
.navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item.show .text-info {
  color: #EB0017 !important;
}
.navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item.nav-item-menu {
  background-color: #EEF6F7;
}
@media only screen and (max-width: 992px) {
  .navbar ul.head-menu.navbar-nav > li.nav-item-dropdown.anaval-language-selection .masthead-language-container li.nav-item.dropdown {
    border-bottom: 1px solid #C0C0C0 !important;
  }
}

.masthead-language-container .language-dropdown {
  background-color: #EEF6F7;
  left: unset;
  right: 0px;
  padding: 0px;
  border-radius: 0px;
  border: none;
}
.masthead-language-container .language-dropdown .active-language {
  color: #262836;
}
.masthead-language-container .language-dropdown .language-dropdown-menu {
  width: 300px;
  list-style: none;
  padding-left: 0px;
}
@media screen and (max-width: 992px) {
  .masthead-language-container .language-dropdown .language-dropdown-menu {
    width: 100%;
  }
}
.masthead-language-container .language-dropdown .language-dropdown-menu a {
  color: #262836;
  padding-top: 10px;
  padding-bottom: 10px;
}
.masthead-language-container .language-dropdown .language-dropdown-menu a:hover, .masthead-language-container .language-dropdown .language-dropdown-menu a:active, .masthead-language-container .language-dropdown .language-dropdown-menu a:focus {
  color: #262836;
  background-color: #CDDBDE !important;
  text-decoration: underline;
}
@media screen and (max-width: 576px) {
  .masthead-language-container {
    padding: 20px 15px 8px;
  }
}
@media screen and (max-width: 992px) {
  .masthead-language-container .language-dropdown-wrap {
    width: 100%;
    position: relative;
    display: block;
  }
  .masthead-language-container .language-dropdown-wrap .dropdown {
    border: 1px solid #46535E;
    background-color: transparent;
    appearance: none;
    padding: 10px 20px;
  }
  .masthead-language-container .language-dropdown-wrap:after {
    content: "\f0dd";
    font-family: "Font Awesome 6 Pro";
    font-size: 16px;
    color: #46535E;
    position: absolute;
    pointer-events: none;
    width: 20px;
    right: 0;
    top: 10px;
  }
}

@media screen and (max-width: 992px) {
  .masthead-language-container select {
    color: #262836;
  }
  .masthead-language-container select option {
    color: #46535E;
  }
  .masthead-language-container .dropdown:focus {
    color: #262836;
  }
}

.payment-transactions .nav-tabs {
  border-bottom: 1px solid #CDDBDE;
  position: relative;
}
.payment-transactions .nav-link.active {
  padding: 0px 20px 8px 20px;
  border-bottom: 4px solid #003C55;
  color: #003C55 !important;
  cursor: pointer;
}
.payment-transactions .nav-link:hover {
  padding: 0px 20px 8px 20px;
  border-bottom: 4px solid #5D6D78;
  color: #5D6D78 !important;
  cursor: pointer;
}
.payment-transactions .nav-link.active:hover {
  color: #003C55;
}
.payment-transactions .nav-tabs .nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  padding: 0px 20px 12px 20px;
  cursor: pointer;
  min-width: 140px;
  background-color: transparent;
  color: #5D6D78;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
.payment-transactions a:active,
.payment-transactions a:hover {
  outline: 0;
}
.payment-transactions .p-dropdown .dropdown {
  padding: 10px 20px;
  font-family: var(--main-font-light);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #46535E;
  margin: 0;
  appearance: none;
  position: relative;
  border-radius: 0;
}
.payment-transactions .p-dropdown .wrap {
  width: 100%;
  position: relative;
  display: block;
}
.payment-transactions .p-dropdown .wrap:after {
  content: "\f0dd";
  font-family: "Font Awesome 6 Pro";
  font-size: 16px;
  color: #46535E;
  position: absolute;
  pointer-events: none;
  width: 10%;
  right: 0;
  top: 30%;
}
.payment-transactions .w-tab-menu {
  border-bottom: 0px;
}
.payment-transactions .w-tab-menu .tab-badge {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  position: relative;
  top: -8px;
  right: 3px;
}
.payment-transactions .w-tab-menu .w-tab-content {
  padding-top: 28px;
  position: relative;
  display: block;
}
@media only screen and (max-width: 576px) {
  .payment-transactions .w-tab-menu .w-tab-content {
    padding-top: 0;
  }
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card {
  padding: 0px 0px 32px 0px;
  border-radius: 12px;
}
@media only screen and (max-width: 576px) {
  .payment-transactions .w-tab-menu .w-tab-content .basic-card {
    overflow: auto;
  }
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table td:first-child,
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th:first-child {
  padding-left: 32px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table td:last-child,
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th:last-child {
  padding-right: 32px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th {
  padding-top: 20px;
  padding-bottom: 8px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th p {
  color: #262836;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table td {
  padding-top: 16px;
  padding-bottom: 16px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th:not(:first-child),
.payment-transactions .w-tab-menu .w-tab-content .basic-card table td:not(:first-child) {
  padding-left: 12px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th:not(:last-child),
.payment-transactions .w-tab-menu .w-tab-content .basic-card table td:not(:last-child) {
  padding-right: 12px;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table th:not(:first-child) {
  border-left: 1px solid #CDDBDE;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card table tr {
  border-bottom: 1px solid #CDDBDE;
}
.payment-transactions .w-tab-menu .w-tab-content .basic-card app-payment-details .payment-details-not-switch-consented {
  padding: 32px 32px 0px 32px;
}
@media only screen and (max-width: 576px) {
  .payment-transactions .w-tab-menu .w-tab-content .basic-card app-payment-details .payment-details-not-switch-consented {
    padding: 0;
  }
}
.payment-transactions .payment-methods-container .entity,
.payment-transactions .payment-credit-card-ingenico .entity {
  border: 1px solid #CDDBDE;
  border-radius: 12px;
  padding: 20px;
}
.payment-transactions .payment-switch {
  padding: 32px 32px 0px 32px;
}
@media only screen and (max-width: 576px) {
  .payment-transactions .payment-switch {
    padding: 0;
  }
}
.payment-transactions .payment-switch .cancel-btn {
  min-width: 100px !important;
}
.payment-transactions .payment-switch .confirm-switch-btn {
  padding: 12px 10px;
}
.payment-transactions .payment-switch .payment-border {
  border: 1px solid #CDDBDE;
  border-radius: 12px;
}
.payment-transactions .payment-failed-mark {
  color: #EA2238 !important;
}
.payment-transactions #saved-cards {
  color: #262836;
}

@media only screen and (max-width: 576px) {
  .profile-page-container .payment-transactions .payment-switch .selectmethod {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .profile-page-container .payment-transactions .payment-switch .selectmethod > div {
    flex: 1;
  }
  .profile-page-container .payment-transactions .payment-switch .selectmethod > button {
    margin-top: 20px;
  }
}
.beneficiaries-info.profile-beneficiaries-container h6 {
  text-transform: none;
}
.beneficiaries-info.profile-beneficiaries-container h3 {
  margin: 0px;
  font-size: 24px !important;
}
.beneficiaries-info.profile-beneficiaries-container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.beneficiaries-info.profile-beneficiaries-container .basic-card {
  margin-bottom: 20px;
}
.beneficiaries-info.profile-beneficiaries-container .basic-card.edit-state {
  padding: 32px;
}
.beneficiaries-info.profile-beneficiaries-container .basic-card .fa-times,
.beneficiaries-info.profile-beneficiaries-container .basic-card .fa-edit {
  font-size: 20px;
  color: #007585;
  cursor: pointer;
  font-weight: 300;
}
@media only screen and (max-width: 576px) {
  .beneficiaries-info.profile-beneficiaries-container .basic-card p.lg {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 16px !important;
  }
}
.beneficiaries-info.profile-beneficiaries-container .basic-card .allocation-item-icon {
  width: 24px;
  height: 20px;
}
.beneficiaries-info.profile-beneficiaries-container .basic-card .allocation-beneficiary-wrapper {
  margin-bottom: 20px;
}
.beneficiaries-info.profile-beneficiaries-container .basic-card .allocation-beneficiary-wrapper:last-child {
  margin-bottom: 0;
}
.beneficiaries-info.profile-beneficiaries-container .accordion .expansion-panel p {
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 576px) {
  .dependents-info.profile-dependents-container h1 {
    color: #012774;
  }
}
.dependents-info.profile-dependents-container h5 {
  text-transform: none;
}
.dependents-info.profile-dependents-container h3 {
  margin: 0px;
  font-size: 24px !important;
}
.dependents-info.profile-dependents-container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dependents-info.profile-dependents-container .basic-card {
  padding: 32px;
}
.dependents-info.profile-dependents-container .basic-card .fa-times,
.dependents-info.profile-dependents-container .basic-card .fa-edit {
  font-size: 20px;
  color: #007585;
  cursor: pointer;
  font-weight: 300;
}
.dependents-info.profile-dependents-container .basic-card p a {
  text-decoration: underline;
  color: #007585;
}
@media only screen and (max-width: 576px) {
  .dependents-info.profile-dependents-container .basic-card p.lg {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 16px !important;
  }
}
.dependents-info .accordion .expansion-panel {
  border: 0px;
  border-bottom: 0px;
  padding: 0px 0px 0px 0px;
}
.dependents-info .dependency-data {
  display: inline-block;
  margin-bottom: 20px;
}
.dependents-info .dependency-data label {
  margin-bottom: 2px;
}
.dependents-info .dependency-data p {
  margin: 0px 0px 20px 0px;
}
.dependents-info .dependency-data-residant {
  margin-bottom: 20px;
  margin-left: 15px;
}
.dependents-info .dependency-data-residant label {
  margin-bottom: 2px;
}
.dependents-info .dependency-data-residant p {
  margin: 0px 0px 20px 0px;
}

.add-edit-dep .fa-times,
.add-edit-dep .fa-edit {
  font-size: 20px;
  color: #007585;
  cursor: pointer;
  font-weight: 300;
}

.dependents-info.profile-dependents-container .basic-card .phone-number {
  display: none;
}

.plan-summary-container .custom-accordion .header-sleeve .header-text h5 {
  color: #007585;
}
.plan-summary-container .summary-sleeve {
  margin-bottom: 20px;
}
.plan-summary-container .summary-sleeve .accordion {
  display: none;
}
.plan-summary-container .basic-card {
  padding: 32px;
}
.plan-summary-container .fa-trash-alt {
  cursor: pointer;
}

.anaval-cart.show {
  border-top: 4px solid #262836 !important;
  background-color: #262836 !important;
}
.anaval-cart.show .text-info .icon-badge-icon {
  color: #E5EFF0 !important;
}
.anaval-cart.show:hover, .anaval-cart.show:active {
  background-color: #262836 !important;
}
.anaval-cart.show:hover a.nav-link, .anaval-cart.show:active a.nav-link {
  background-color: #262836 !important;
}
.anaval-cart.show:hover a.nav-link .icon-badge-icon, .anaval-cart.show:active a.nav-link .icon-badge-icon {
  color: #E5EFF0 !important;
}

.main-navbar-controls .nav-cart-mobile .cart-dropdown .shopping-cart-container .cart-dropdown-menu .cart-item i.fal {
  color: #E5EFF0 !important;
}
.main-navbar-controls .nav-cart-mobile .cart-dropdown .shopping-cart-container .cart-dropdown-menu .cart-item i.fal:hover, .main-navbar-controls .nav-cart-mobile .cart-dropdown .shopping-cart-container .cart-dropdown-menu .cart-item i.fal:active, .main-navbar-controls .nav-cart-mobile .cart-dropdown .shopping-cart-container .cart-dropdown-menu .cart-item i.fal:focus {
  color: #E5EFF0 !important;
}
@media screen and (max-width: 768px) {
  .main-navbar-controls:has(+ #navbarsDefault) .cart-dropdown {
    min-width: calc(100vw - 50px) !important;
  }
}

.qContent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.disableTooltip + .tooltip {
  display: none !important;
}

.ellipseIcon {
  position: absolute;
  top: 0px;
  align-items: start !important;
}

.cart-dropdown {
  background-color: #262836;
  top: 60px;
  left: unset;
  right: -1px;
  overflow: auto;
  min-width: 400px;
  min-height: 354px;
  max-height: 80vh;
  padding: 0px;
  border-radius: 0px;
}
@media screen and (max-width: 768px) {
  .cart-dropdown {
    top: 70px;
    left: auto;
    min-width: 100vw !important;
    width: 100%;
    right: -50px;
    border: none;
  }
}
@media only screen and (max-width: 375px) {
  .cart-dropdown {
    top: 36px;
    left: -2px;
    min-width: 100vw !important;
    width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .shopping-cart-container .cart-dropdown-menu {
    width: 100%;
  }
}
.shopping-cart-container .vb-cart-sleeve {
  color: #262836;
  right: 0px;
  padding: 20px;
  overflow-y: auto;
  z-index: 1;
}
.shopping-cart-container .vb-cart-sleeve H5 {
  margin: 0px 0px 8px 0px;
}
.shopping-cart-container .vb-cart-sleeve a,
.shopping-cart-container .vb-cart-sleeve p,
.shopping-cart-container .vb-cart-sleeve span {
  color: #FFFFFF;
}
.shopping-cart-container .vb-cart-sleeve .cart-inverse-color {
  color: #E5EFF0;
}
.shopping-cart-container .vb-cart-sleeve .btn-group {
  width: 100%;
}
.shopping-cart-container .vb-cart-sleeve .btn-group button {
  width: 50%;
}
.shopping-cart-container .vb-cart-sleeve button {
  width: 100%;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-flex-layout {
  display: flex;
  flex-direction: row;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-flex-layout .lead-item {
  flex-grow: 1;
  margin: 0px 0px 16px 0px;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-flex-layout .lead-item.product-name {
  text-decoration: underline;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-cost span:first-child {
  display: block;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-cost h2 {
  display: inline-block;
  margin: 0px 8px 0px 0px;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-cost h6 {
  display: inline-block;
  margin: 0px 8px 0px 0px;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-cost span.xs {
  text-transform: capitalize;
}
.shopping-cart-container .vb-cart-sleeve .fa-exclamation-triangle,
.shopping-cart-container .vb-cart-sleeve .fa-edit,
.shopping-cart-container .vb-cart-sleeve .fa-trash-alt {
  font-size: 20px;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-item {
  margin-left: 0px;
  background-color: #CDDBDE !important;
  height: auto;
  padding: 16px;
  margin-bottom: 20px;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-item .vb-item-sleeve {
  width: 100%;
}
.shopping-cart-container .vb-cart-sleeve .vb-cart-item.row {
  flex-direction: column;
}
.shopping-cart-container .vb-cart-sleeve .cart-item i.fal {
  color: #E5EFF0 !important;
}
.shopping-cart-container .vb-cart-sleeve .cart-item i.fal:hover, .shopping-cart-container .vb-cart-sleeve .cart-item i.fal:active, .shopping-cart-container .vb-cart-sleeve .cart-item i.fal:focus {
  color: #E5EFF0 !important;
}
@media only screen and (max-width: 992px) {
  .shopping-cart-container .vb-cart-sleeve .cart-item i.fal {
    color: #E5EFF0 !important;
  }
  .shopping-cart-container .vb-cart-sleeve .cart-item i.fal:hover, .shopping-cart-container .vb-cart-sleeve .cart-item i.fal:active, .shopping-cart-container .vb-cart-sleeve .cart-item i.fal:focus {
    color: #E5EFF0 !important;
  }
}
.shopping-cart-container .vb-cart-sleeve .cost-copy {
  font-size: 20px;
  font-family: var(--main-font-bold);
  letter-spacing: 0;
  line-height: 24px;
}
.shopping-cart-container .vb-cart-sleeve .employee-cost {
  font-weight: 700;
}
.shopping-cart-container .align-right {
  text-align: end;
}
.shopping-cart-container .inverse-button {
  color: #262836;
  background-color: #E5EFF0;
  border: 1px solid #E5EFF0;
}
.shopping-cart-container .inverse-button:hover {
  text-decoration: underline;
  background-color: #E5EFF0;
  border: 1px solid #E5EFF0;
}
.shopping-cart-container .inverse-button:active {
  text-decoration: underline;
  background-color: #E5EFF0;
  border: 1px solid #E5EFF0;
}

.aon-activity-coverage .flow-group {
  margin-top: 32px;
}
.aon-activity-coverage .quote-benefit-selection .div-border {
  border: 2px solid #007585;
}
.aon-activity-coverage .quote-benefit-selection .fa-check {
  color: #FFFFFF;
}
.aon-activity-coverage .quote-benefit-selection .pay-frequency i {
  color: #5D6D78;
}
.aon-activity-coverage .quote-benefit-selection .pay-frequency .bg-color-slate {
  background-color: #4C4D4F;
}
.aon-activity-coverage .quote-benefit-selection .pay-frequency .popover .arrow:after {
  border-bottom-color: #4C4D4F;
}
.aon-activity-coverage .quote-benefit-selection .pay-frequency .popover .popover-body {
  color: #FFFFFF;
}
.aon-activity-coverage .quote-benefit-selection .plan-list-header .plan-review-quote h3 {
  display: inline-block;
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .quote-benefit-selection .plan-list-header .plan-review-quote h3 {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .aon-activity-coverage .quote-benefit-selection .plan-list-header .plan-review-quote a {
    padding: 0 !important;
  }
}
.aon-activity-coverage .quote-benefit-selection .plan-dependent .input_label {
  display: inline-block;
  white-space: nowrap;
}
.aon-activity-coverage .quote-benefit-selection .plan-info .coverages-list .plan-price {
  border-right: 1px solid #979797;
  padding-right: 20px;
}
.aon-activity-coverage .quote-benefit-selection .plan-info .coverages-list .coverage-name {
  display: inline-block;
}

.documents.profile-document-container .basic-card {
  padding: 32px;
  margin-bottom: 20px;
}
.documents.profile-document-container .basic-card.edit-state {
  padding: 20px;
  margin-bottom: 0px;
}
.documents.profile-document-container .basic-card .fa-info-circle {
  color: #46535E;
}
@media only screen and (max-width: 576px) {
  .documents.profile-document-container .basic-card p.lg {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 24px;
    margin-bottom: 16px !important;
  }
}
.documents.profile-document-container .other-documents kendo-grid.basic-card {
  padding: 0px;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents kendo-grid.basic-card {
    padding: 0px !important;
  }
}
.documents.profile-document-container .other-documents .k-table-md {
  font-family: var(--main-font-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #46535E;
  margin: 0px 0px 16px 0px;
  margin: 0px;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents .k-table-md {
    font-size: 12px;
    line-height: 16px;
  }
}
.documents.profile-document-container .other-documents kendo-label > .k-label {
  margin-right: 5px;
}
.documents.profile-document-container .other-documents caption {
  border-bottom: solid 1px;
}
.documents.profile-document-container .other-documents .k-toolbar,
.documents.profile-document-container .other-documents .k-table-thead,
.documents.profile-document-container .other-documents .k-grid .k-table-row.k-table-alt-row,
.documents.profile-document-container .other-documents .k-grid-content-sticky {
  background-color: #FFFFFF;
}
.documents.profile-document-container .other-documents td {
  border-color: transparent;
}
.documents.profile-document-container .other-documents td a {
  text-decoration: underline !important;
  cursor: default !important;
}
.documents.profile-document-container .other-documents td .no-export a {
  cursor: pointer !important;
}
.documents.profile-document-container .other-documents td:first-child {
  padding-left: 32px;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents td:first-child {
    padding-left: 20px;
  }
}
.documents.profile-document-container .other-documents td:last-child {
  padding-right: 32px;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents td:last-child {
    padding-left: 20px;
  }
}
.documents.profile-document-container .other-documents td kendo-svg-icon svg {
  display: none;
}
.documents.profile-document-container .other-documents th {
  border-color: #ACC0C4;
  border-left: 0px;
  border-right: 1px solid #ACC0C4;
}
.documents.profile-document-container .other-documents th:first-child {
  border-right: 0px;
}
.documents.profile-document-container .other-documents th.k-focus {
  outline: black auto 2px !important;
}
.documents.profile-document-container .other-documents th .k-column-title {
  font-weight: 700;
}
.documents.profile-document-container .other-documents th kendo-svg-icon svg {
  display: none;
}
.documents.profile-document-container .other-documents .k-table-td {
  border-bottom: 1px solid #ACC0C4;
}
.documents.profile-document-container .other-documents .k-table-td.k-focus {
  outline: black auto 2px !important;
}
.documents.profile-document-container .other-documents kendo-grid-toolbar {
  padding: 32px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents kendo-grid-toolbar {
    padding-left: 20px;
  }
}
.documents.profile-document-container .other-documents kendo-pager {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: transparent;
}
.documents.profile-document-container .other-documents kendo-pager.k-focus {
  outline: black auto 2px !important;
}
@media screen and (max-width: 576px) {
  .documents.profile-document-container .other-documents kendo-pager-next-buttons,
  .documents.profile-document-container .other-documents kendo-pager-prev-buttons {
    display: none;
  }
}
.documents.profile-document-container .other-documents .k-table-row:hover,
.documents.profile-document-container .other-documents .k-detail-row {
  background-color: #EEF6F7;
}
.documents.profile-document-container .other-documents .k-table-row:hover .k-grid-content-sticky {
  background-color: #EEF6F7;
}
.documents.profile-document-container .other-documents .remove-row-border td {
  background-color: #EEF6F7;
  border-bottom: 0px !important;
}
.documents.profile-document-container .other-documents .row-details-wrapper {
  overflow-wrap: anywhere;
  padding-top: 24px;
  padding-bottom: 32px;
}
@media screen and (max-width: 768px) {
  .documents.profile-document-container .other-documents .row-details-wrapper {
    padding-top: 4px;
    padding-bottom: 12px;
  }
}
.documents.profile-document-container .other-documents .k-hierarchy-cell .k-i-plus::before,
.documents.profile-document-container .other-documents .k-i-sort-desc-small::before {
  content: "\f078";
  font-family: "Font Awesome 6 Pro";
}
.documents.profile-document-container .other-documents .k-hierarchy-cell .k-i-minus::before,
.documents.profile-document-container .other-documents .k-i-sort-asc-small::before {
  content: "\f077";
  font-family: "Font Awesome 6 Pro";
}

.aon-activity-eoiquestions .eoi-header {
  margin-bottom: 40px;
}
.aon-activity-eoiquestions .banner {
  border-top: 0px;
  padding: 28px 23px 28px 32px;
}
.aon-activity-eoiquestions .eligibility-questions .title {
  font-size: 20px;
  font-family: var(--main-font-bold);
  letter-spacing: 0;
  line-height: 24px;
}
.aon-activity-eoiquestions .container.header p {
  margin: 0 0 12px !important;
}
.aon-activity-eoiquestions .basic-card {
  padding: 32px 32px 12px 32px;
  margin-bottom: 20px;
}
.aon-activity-eoiquestions .eligibility-questions-margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 576px) {
  .aon-activity-eoiquestions .container .card {
    margin-bottom: 20px;
  }
  .aon-activity-eoiquestions .container.header h3 {
    margin: 0 0 12px !important;
  }
  .aon-activity-eoiquestions .container.header p {
    margin: 0 0 32px !important;
  }
  .aon-activity-eoiquestions .alert-margin {
    padding-left: 20px;
    padding-right: 20px;
  }
  .aon-activity-eoiquestions .flow-group {
    padding-left: 20px;
    padding-right: 20px;
  }
  .aon-activity-eoiquestions .eligibility-questions-margin {
    margin-bottom: 20px;
  }
  .aon-activity-eoiquestions .eoi-header {
    margin-bottom: 32px;
    padding: 0px 20px;
  }
  .aon-activity-eoiquestions .eoi-tellus {
    padding: 0px 20px;
  }
}

.questions-page .qs-legend {
  display: inline-block;
}

.doc-upload-outer-sleeve .modal-header button.close .fal {
  color: #262836;
}
.doc-upload-outer-sleeve .modal-body .edit-state {
  padding: 32px;
  height: 50vh;
  overflow: auto;
}

app-participant-documents .participant-component .fa-check-circle {
  color: #0D725E !important;
  width: 20px;
  height: 24px;
}
app-participant-documents .participant-component .fa-exclamation-circle {
  color: #996300 !important;
  width: 20px;
  height: 24px;
}

.benefit-selection-beneficiary .top-container {
  position: relative;
  right: 46%;
}
.benefit-selection-beneficiary .justify-content-start {
  justify-content: flex-start;
}

.aon-activity-eoiresults .container.header p {
  margin: 0 0 20px !important;
}
.aon-activity-eoiresults .basic-card {
  padding: 32px;
}
.aon-activity-eoiresults .banner {
  border-top: 0px;
  padding: 28px 23px 28px 32px;
}
.aon-activity-eoiresults .entity {
  margin-bottom: 20px;
}
.aon-activity-eoiresults .last-child {
  margin-bottom: 0px !important;
}
.aon-activity-eoiresults .eligibility-questions-margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 576px) {
  .aon-activity-eoiresults .alert-margin {
    padding-left: 20px;
    padding-right: 20px;
  }
  .aon-activity-eoiresults .flow-group {
    padding-left: 20px;
    padding-right: 20px;
  }
  .aon-activity-eoiresults .eligibility-questions-margin {
    margin-bottom: 20px;
  }
}

.aon-activity-reviewpurchase.review-page {
  padding-bottom: 80px;
}
.aon-activity-reviewpurchase .container.header h3 {
  width: 66%;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card {
  padding: 32px;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .plan-details-width {
  flex: 0 0 58.33333333%;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .plan-details-width .relationship-text {
  font-size: 14px;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .plan-details-width .edit-quote,
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .plan-details-width .delete-quote {
  color: #007585;
  cursor: pointer;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .plan-details-width .covered-dependent {
  margin-bottom: 20px !important;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .total-payment-sablelabel {
  width: 172%;
  border-top: 1px solid #C0C0C0;
  padding-top: 20px;
  font-size: 20px;
}
.aon-activity-reviewpurchase .ben-review-quote .basic-card .PlanDetails .data-metric-m {
  color: #262836;
}
.aon-activity-reviewpurchase .ben-review-quote .important-information {
  background-color: #E4E4E4;
  box-shadow: 0 0 6px 0 #e0e0e0;
  padding: 20px;
  border-radius: 4px;
}
.aon-activity-reviewpurchase .ben-review-quote hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #CDDBDE;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel {
  border: 0px;
  border-bottom: 0px;
  padding: 0px;
  background-color: transparent;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item {
  box-shadow: none;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header {
  color: #007585;
  background-color: transparent;
  border: 0px;
  padding: 0px;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header:hover {
  cursor: pointer;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve {
  display: flex;
  align-items: center;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve i {
  font-size: 16px;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text {
  color: #007585;
  flex-grow: 1;
  font-family: var(--main-font-bold);
  font-size: 16px;
  line-height: 20px;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve .header-controls {
  color: #007585;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve:hover .header-text,
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve:hover .header-controls {
  color: #004852;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded .header-text,
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded .header-controls {
  color: #001B1F;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded:hover .header-text,
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .accordion-header .header-sleeve.expanded:hover .header-controls {
  color: #004852;
}
.aon-activity-reviewpurchase .ben-review-quote .expansion-panel.accordion-item .card-body {
  padding: 0px;
  color: #46535E;
}
.aon-activity-reviewpurchase .ben-review-quote .accordion .expansion-panel {
  border-top: 1px solid #CDDBDE;
  padding: 8px 0px 8px 0px;
}
.aon-activity-reviewpurchase .ben-review-quote .accordion .expansion-panel p {
  margin: 0px;
  padding: 0px;
}
.aon-activity-reviewpurchase .ben-review-quote .accordion .expansion-panel:last-child {
  border-bottom: 1px solid #CDDBDE;
}
.aon-activity-reviewpurchase .ben-review-quote .plan-details-adjusted-cost {
  width: 70%;
  float: right;
}
.aon-activity-reviewpurchase .ben-review-quote .sable-label-pair {
  color: #46535E;
  flex-direction: column;
  margin-bottom: 20px;
}
.aon-activity-reviewpurchase .ben-review-quote .sable-label {
  font-weight: 700;
  margin-bottom: 4px;
}
@media screen and (max-width: 576px) {
  .aon-activity-reviewpurchase .ben-review-quote .checkout-continue {
    margin-bottom: 40px;
  }
}
.aon-activity-reviewpurchase .container .flow-group {
  display: none;
}
.aon-activity-reviewpurchase .container .divider {
  display: none;
}
.aon-activity-reviewpurchase .container .card .card-body .flow-group {
  display: unset;
}
.aon-activity-reviewpurchase .hidden-detail {
  display: none !important;
}
.aon-activity-reviewpurchase .hidden-detail-reviewpurchase {
  display: none !important;
}

.checkout-bar {
  color: #E5EFF0 !important;
  height: 40px;
  width: 100%;
  background-color: #262836;
}
.checkout-bar .fa-shopping-cart {
  padding: 8px 8px 8px 130px;
  color: #E5EFF0 !important;
}
.checkout-bar .checkout {
  height: 24px;
  font-size: 16px;
  font-family: var(--main-font-bold);
  letter-spacing: 0;
  line-height: 24px;
}

.promo-discount-content-sleeve-outer .promo-discount-applied-msg-sleeve-outer,
.promo-discount-content-sleeve-outer .fa-check-circle {
  color: #0D725E;
}
.promo-discount-content-sleeve-outer .promo-discount-line-through {
  text-decoration: line-through;
}
.promo-discount-content-sleeve-outer .sable-label-value-item {
  color: #0D725E;
  font-size: 14px;
  line-height: 18px;
}
.promo-discount-content-sleeve-outer .sable-label-value-item:before {
  content: "\f645";
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  padding-right: 8px;
  vertical-align: middle;
  font-weight: 700;
}

.aon-activity-reviewpurchase .container.header p {
  display: none;
}

.checkout-tc .modal-content {
  margin-top: 0px !important;
}
.checkout-tc .modal-content .benefit-term-condition .modal-header {
  padding: 0px;
}
.checkout-tc .modal-content .benefit-term-condition .modal-header button.close .fal {
  color: #262836;
}
.checkout-tc .modal-content .benefit-term-condition .progress {
  height: 8px;
  border-radius: 4px;
  margin: 0px 0px 4px;
}
.checkout-tc .modal-content .benefit-term-condition .progress-bar {
  background-color: #0D725E;
}
.checkout-tc .modal-content .benefit-term-condition .edit-state {
  padding: 32px;
  background-color: #EEF6F7;
}
.checkout-tc .modal-content .benefit-term-condition .edit-state .body-container .term-details {
  height: calc(45vh - 40px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 576px) {
  .checkout-tc .modal-content .benefit-term-condition .edit-state .body-container .term-details {
    height: unset;
  }
}
.checkout-tc .modal-content .benefit-term-condition .edit-state .body-container .divider {
  background-color: #ACC0C4;
}
.checkout-tc .modal-content .benefit-term-condition .edit-state .body-container input[type=checkbox] + label:before {
  border-radius: 2px;
}
.checkout-tc .modal-content .benefit-term-condition .edit-state .body-container .content-underline {
  text-decoration: underline !important;
}

.purchase-right-section-sleeve label {
  font-weight: 400;
  font-family: var(--main-font-regular);
}
.purchase-right-section-sleeve .label-strong {
  font-family: var(--main-font-bold);
}
.purchase-right-section-sleeve .label-total-cost {
  font-size: 21px;
}
.purchase-right-section-sleeve .tax-css label {
  font-size: 14px;
}
.purchase-right-section-sleeve .benefit-row {
  display: flex;
  align-items: flex-start;
}
.purchase-right-section-sleeve .benefit-name {
  padding-right: 5px;
  flex-grow: 1;
  font-family: var(--main-font-regular);
}
.purchase-right-section-sleeve .benefit-name.benefit-product-type {
  font-family: var(--main-font-light) !important;
  font-weight: 300;
}
.purchase-right-section-sleeve .benefit-name.benefit-name-plan {
  font-family: var(--main-font-bold);
}
.purchase-right-section-sleeve .benefit-total-cost {
  font-family: var(--main-font-bold);
}

@media only screen and (min-width: 768px) {
  .mfa.inverse {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
}
.mfa.inverse .mfa-sleeve {
  background-repeat: no-repeat;
}

.mfa-section .select-options .left-spaces {
  margin-left: 5px;
}
@media screen and (max-width: 576px) {
  .mfa-section .select-options span.text-format {
    margin-left: 35px;
  }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  .mfa-section .select-options span.text-format {
    margin-left: 60px;
  }
}
@media screen and (max-width: 576px) {
  .mfa-section .enter-code .header-title {
    display: block;
  }
}
.mfa-section .resend-code-wrapper {
  align-items: center;
}
@media screen and (max-width: 576px) {
  .mfa-section .resend-code-wrapper {
    flex-direction: column;
    align-items: initial;
  }
}
.mfa-section .resend-code-wrapper input {
  margin-right: 0;
  margin-bottom: 20px;
}
@media screen and (min-width: 576px) {
  .mfa-section .resend-code-wrapper input {
    margin-right: 20px;
    margin-bottom: 0;
  }
}
.mfa-section a.btn {
  border: none;
}

.skinny-footer-active .mfa.inverse .mfa-sleeve {
  min-height: calc(100vh - 156px);
}

.plan-card-container .plan.basic-card {
  margin-bottom: 20px;
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card .no-cover-plan .header-text {
    margin-bottom: 10px;
  }
}
.plan-card-container .plan.basic-card.div-border {
  padding: 31px !important;
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card.div-border {
    padding: 19px !important;
  }
}
.plan-card-container .plan.basic-card .accordion .expansion-panel {
  border: 0px;
  border-top: 1px solid #CDDBDE;
  border-bottom: 1px solid #CDDBDE;
  padding: 20px 0px 20px 0px;
}
.plan-card-container .plan.basic-card .accordion .expansion-panel .header-text {
  font-size: 16px !important;
}
.plan-card-container .plan.basic-card .accordion .expansion-panel i {
  font-size: 16px !important;
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card .accordion .expansion-panel {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media screen and (min-width: 768px) {
  .plan-card-container .plan.basic-card .cost-container p {
    text-align: right;
  }
}
.plan-card-container .plan.basic-card .cost-container .headline-cost {
  font-family: var(--heading-font-light);
  font-size: 30px;
  line-height: 32px;
}
.plan-card-container .plan.basic-card .cost-container .cost-label {
  margin: 0px 0px 4px 0px;
  color: #5D6D78;
}
.plan-card-container .plan.basic-card .plan-card-spinner-sleeve {
  display: flex;
  margin-right: -32px;
  padding-top: 40px;
  justify-content: center;
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card .plan-card-spinner-sleeve {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: unset;
  }
}
.plan-card-container .plan.basic-card .available-amount-container {
  background-color: #EEF6F7;
  padding: 20px;
  /*ENSURE INPUTS HAVE TRANSPARENT BACKGROUND AGAINST AVAILABLE AMOUNT CONTAINER WHICH IS COLOURED*/
}
.plan-card-container .plan.basic-card .available-amount-container .k-numerictextbox,
.plan-card-container .plan.basic-card .available-amount-container button {
  background-color: transparent;
}
@media screen and (min-width: 576px) {
  .plan-card-container .plan.basic-card .available-amount-container .amount-input-container {
    padding-right: 5px;
  }
  .plan-card-container .plan.basic-card .available-amount-container .calc-button-container {
    padding-left: 5px;
  }
}
.plan-card-container .plan.basic-card .grid-divider {
  overflow-x: visible;
  position: relative;
}
.plan-card-container .plan.basic-card .grid-divider > [class*=col-]:nth-child(n+2):after {
  content: "";
  background-color: #CDDBDE;
  position: absolute;
  top: 0;
  bottom: 0;
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card .grid-divider > [class*=col-]:nth-child(n+2):after {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .plan-card-container .plan.basic-card .grid-divider > [class*=col-]:nth-child(n+2):after {
    width: 1px;
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .plan-card-container .plan.basic-card .available-amount-container {
    background-color: #F8F8F8;
  }
  .plan-card-container .plan.basic-card .available-amount-container .input-value {
    margin-bottom: 20px;
  }
  .plan-card-container .plan.basic-card .expansion-panel {
    padding: 20px;
  }
}

.plan-card-container .plan.basic-card .cost-container .headline-cost {
  color: #001B5A;
}
.plan-card-container .plan.basic-card.div-border .select-plan button {
  background-color: #001B1F !important;
}

.benefit-card-container .benefit.basic-card.div-border {
  padding: 31px !important;
}
@media screen and (max-width: 576px) {
  .benefit-card-container .benefit.basic-card.div-border {
    padding: 20px !important;
  }
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel {
  border: 0px;
  border-top: 1px solid rgba(192, 192, 192, 0.5);
  border-bottom: 1px solid rgba(192, 192, 192, 0.5);
  padding: 12px 0px;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel .accordion-body div.expansion-panel-content {
  margin-top: 12px;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel .accordion-body div.expansion-panel-content table {
  margin-bottom: 0px;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel .accordion-body div.expansion-panel-content table tr td:not(:first-child) {
  text-align: right;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel .accordion-body div.expansion-panel-content table th {
  border-top: none;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel .header-text {
  font-size: 16px !important;
}
.benefit-card-container .benefit.basic-card .accordion .expansion-panel i {
  font-size: 16px !important;
}
@media screen and (max-width: 576px) {
  .benefit-card-container .benefit.basic-card .accordion .expansion-panel {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.benefit-card-container .benefit.basic-card .cost-container .employee-cost {
  font-size: 32px;
  line-height: 32px;
  text-align: right;
  font-weight: 300;
}
.benefit-card-container .benefit.basic-card .cost-container .employer-cost,
.benefit-card-container .benefit.basic-card .cost-container .total-cost {
  text-align: right;
}
.benefit-card-container .benefit.basic-card .cost-container .cost-label {
  margin: 0;
  color: #5D6D78;
  text-align: right;
}
.benefit-card-container .benefit.basic-card .action-button {
  border: 1px solid #001B1F;
  flex: 1;
}
.benefit-card-container .benefit.basic-card .plan-image {
  height: 16px;
  width: 16px;
}
@media screen and (max-width: 768px) {
  .benefit-card-container .benefit.basic-card .cost-container .employee-cost,
  .benefit-card-container .benefit.basic-card .cost-container .employer-cost,
  .benefit-card-container .benefit.basic-card .cost-container .total-cost {
    text-align: left;
  }
  .benefit-card-container .benefit.basic-card .cost-container .cost-label {
    float: left;
  }
  .benefit-card-container .benefit.basic-card .expansion-panel {
    padding: 20px;
  }
}

.sable-view-for-benefit-card .QuoteSummaryKey .sable-label-pair,
.sable-view-for-benefit-card .summary-sleeve .sable-label-pair {
  color: #46535E;
  flex-direction: column;
  margin-bottom: 20px !important;
}
.sable-view-for-benefit-card .QuoteSummaryKey .sable-label,
.sable-view-for-benefit-card .summary-sleeve .sable-label {
  font-weight: 700;
  margin-bottom: 4px;
}
.sable-view-for-benefit-card .summary-sleeve .summary-heading {
  display: none;
}

.confirm-summaryview .hidden-detail {
  display: none !important;
}
.confirm-summaryview .hidden-detail-paymentconfirmation {
  display: none !important;
}

.is-invalid {
  border-radius: 4px;
  border-color: #EA2238;
  border-width: 1px;
  border-style: solid;
}

.is-invalidExpDate {
  border-radius: 4px;
  border-color: #CA110F;
  border-width: 2px;
  border-style: solid;
}

.payment-page {
  padding-bottom: 80px;
}
@media screen and (max-width: 576px) {
  .payment-page .payment-page-purchase-section {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 576px) {
  .payment-page .fixed-form-width-payment-card {
    width: 280px;
  }
}
.payment-page .fixed-form-width-payment-card .form-btn-negative-margin {
  margin-left: -32px;
}

.payment-confirmation .product-title {
  display: flex;
  flex-direction: row;
}
.payment-confirmation .product-title.isTabView {
  flex-direction: column;
  max-width: 55%;
  word-wrap: break-word;
}
.payment-confirmation .product-title.isTabView .policy-status-div {
  justify-items: flex-start;
}
@media screen and (max-width: 576px) {
  .payment-confirmation .product-title {
    flex-direction: column;
  }
}

.payment-confirm-btn-section {
  margin: 40px 0px 100px 0px;
}
@media screen and (max-width: 576px) {
  .payment-confirm-btn-section {
    margin: 20px 0px 40px 0px;
  }
  .payment-confirm-btn-section .payment-confirm-continue {
    margin-top: 20px;
  }
}

.firsttime-user .firsttime-user-sleeve {
  background-position: bottom left, top 222px right;
  background-repeat: no-repeat, no-repeat;
  background-size: 250px 204px, 207px 227px;
  justify-content: center;
  background-color: transparent;
  background-image: none;
}
@media only screen and (max-width: 576px) {
  .firsttime-user .firsttime-user-sleeve {
    background-image: none;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 125px 102px;
  }
  .firsttime-user .firsttime-user-sleeve .page-content-sleeve {
    background-image: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .firsttime-user .container {
    margin-right: unset;
    margin-left: unset;
  }
}

.other-info input[type=password].error,
.other-info input[type=text].error {
  background-color: transparent;
}
.other-info .banner p span {
  font-size: 16px !important;
}
.other-info label span {
  font-size: 16px !important;
}
.other-info span span {
  font-size: 16px !important;
}

.contact-preferences .description {
  margin-bottom: 40px;
}
@media screen and (max-width: 576px) {
  .contact-preferences .description {
    margin-bottom: 20px;
  }
}
.contact-preferences .checkboxcontent p {
  margin-bottom: 32px;
}
@media screen and (max-width: 576px) {
  .contact-preferences .checkboxcontent p {
    margin-bottom: 20px;
  }
}
.contact-preferences .a-enabled {
  pointer-events: visible;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .contact-preferences .container {
    margin-right: unset;
    margin-left: unset;
  }
}

.inverse.contact-preferences .label-disabled,
.inverse-content-block.contact-preferences .label-disabled {
  opacity: 1;
  cursor: not-allowed;
}

app-privacy-policy .modal-body,
app-terms-conditions .modal-body,
app-participant-consent .modal-body {
  max-height: 50vh;
  overflow-y: auto;
  padding: 32px !important;
  background-color: #EEF6F7;
}

.modal-dialog .modal-content .card-img {
  display: none;
}
.modal-dialog .modal-content #privacy-policy,
.modal-dialog .modal-content #terms-conditions {
  padding-left: 0px;
  padding-right: 0px;
}

.user-privacy-policy .page-only,
.user-terms-and-conditions .page-only {
  display: none;
}
.user-privacy-policy .modal-only,
.user-terms-and-conditions .modal-only {
  display: block !important;
}

#privacy-policy .modal-only,
#terms-conditions .modal-only {
  display: none;
}

.aon-activity-sableview .container .card .card-body app-sable-view div sable-dataview app-sable-dataview-card .confirmation-card-color {
  background-color: #F8F8F8;
  padding: 0px 20px;
}
.aon-activity-sableview .container .card .card-body app-sable-view div sable-dataview app-sable-dataview-card .confirmation-card-color div hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
.aon-activity-sableview .container .card .card-body app-sable-view div sable-dataview app-sable-dataview-card .session-date-font {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: #262836;
}
.aon-activity-sableview .container .card .card-body .flow-group {
  margin-top: 32px;
}

.aon-activity-sableform .container .card .card-body .aon-width-double {
  margin-bottom: 32px;
  color: #46535E;
}
.aon-activity-sableform .container .card .card-body textarea {
  margin-bottom: 0px;
}

.marketing-unsubscribe .image-block {
  background-image: url("/assets/images/unsubscribe.svg");
  height: 400px;
  background-repeat: no-repeat;
  background-size: contain;
}
.marketing-unsubscribe .content-block {
  padding-left: 20px;
}
.marketing-unsubscribe .content-block h1 {
  font-size: 48px !important;
  line-height: 64px;
}
.marketing-unsubscribe .content-block p.lg {
  font-size: 22px !important;
  line-height: 36px;
}

.quote-sable-flow sable-input-multiselect sable-contextual-alert .aon-contextual-alert {
  width: 165%;
}
@media only screen and (max-width: 576px) {
  .quote-sable-flow sable-input-multiselect sable-contextual-alert .aon-contextual-alert {
    width: 100%;
  }
}
.quote-sable-flow .aon-activity-sableentityintersect sable-entity-intersect-flow-adapter .aon-form-component {
  margin-bottom: 32px;
}
.quote-sable-flow .aon-activity-sableentityintersect sable-entity-intersect-flow-adapter .aon-form-component > .aon-form-label + .aon-form-radio-group {
  margin-top: 12px;
}
.quote-sable-flow sable-forms-flow-adapter sable-input-radio .aon-form-component .aon-form-radio-group .aon-form-radio:only-child {
  margin-bottom: 0 !important;
}

#product-hero .text-inner .breadcrumb-content-sleeve-outer {
  margin-bottom: 4px;
}
#product-hero .text-inner .breadcrumb-content-sleeve-outer .category-icon {
  height: 16px;
  width: 16px;
  margin-right: 6px;
}
#product-hero .text-inner .breadcrumb-content-sleeve-outer .aon-breadcrumb-trail {
  color: #5D6D78;
}
#product-hero .text-inner h2 {
  font-weight: 700;
}
#product-hero .text-bottom-section {
  display: flex;
}
@media only screen and (max-width: 576px) {
  #product-hero .text-bottom-section {
    flex-direction: column;
  }
}
#product-hero .text-bottom-section app-aon-cta button {
  margin-right: 20px !important;
}
@media only screen and (max-width: 576px) {
  #product-hero .text-bottom-section app-aon-cta button {
    margin-right: unset !important;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 576px) {
  #product-hero .text-bottom-section app-aon-cta:first-child button {
    margin-top: 0;
  }
}
#product-hero .text-bottom-section app-aon-cta:last-child button {
  margin-right: 0;
}

.modal-content app-user-dependants .dependants-card-modal .button-sleeve {
  margin-top: 32px;
}
.modal-content app-user-dependants .dependants-card-modal .participant-records > .row {
  margin-left: -32px;
  margin-right: -32px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media only screen and (max-width: 576px) {
  .modal-content app-user-dependants .dependants-card-modal .participant-records > .row {
    margin-left: -20px;
    margin-right: -20px;
  }
}
.modal-content app-user-dependants .dependants-card-modal .participant-records > .row.odd {
  background-color: #EEF6F7;
}
.modal-content app-user-dependants .dependants-card-modal .participant-records > .row .col {
  padding-left: 32px;
  padding-right: 32px;
}
.modal-content app-user-dependants .dependants-card-modal .add-control a {
  text-decoration: none;
}
.modal-content app-user-dependants .dependants-card-modal .add-control a .fa-plus {
  font-size: 16px;
}
.modal-content app-user-dependants .dependants-card-modal .add-control a span {
  text-decoration: underline;
}
.modal-content app-user-dependants .dependants-card-modal h3 {
  color: #001B5A;
}
.modal-content app-user-dependants .dependants-card-modal input[type=button].prominent,
.modal-content app-user-dependants .dependants-card-modal button.prominent {
  background-color: #001B5A;
  border: 1px solid #001B5A;
}
.modal-content app-user-dependants .dependants-card-modal .fa-calendar {
  color: #007BB6;
}

.dependents-card {
  margin-bottom: 40px;
}
.dependents-card .contextual .message {
  display: flex;
  align-items: start;
}
@media only screen and (max-width: 576px) {
  .dependents-card .dependants-container .eligible-participants {
    flex-direction: column;
  }
}
.dependents-card .dependants-container .fa-lock {
  font-size: 14px;
  line-height: 18px;
  color: #5D6D78;
}
.dependents-card #show-activity-content {
  margin-top: 40px;
}
@media only screen and (max-width: 576px) {
  .dependents-card #show-activity-content {
    margin-top: 20px;
  }
}
.dependents-card .button-sleeve {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media only screen and (max-width: 768px) {
  .dependents-card .button-sleeve {
    align-items: flex-start;
    margin-top: 20px;
  }
}

.benefit-details {
  /*START THIS SECTION SHOULD BE PULLED OUT INTO ITS OWN CSS FILE ULTIMATELY AS IT APPLIES TO THE BENEFIT DETAIL, CTA AND PLAN CARDS*/
  /*END THIS SECTION SHOULD BE PULLED OUT INTO ITS OWN CSS FILE ULTIMATELY AS IT APPLIES TO THE BENEFIT DETAIL, CTA AND PLAN CARDS*/
}
.benefit-details .cost-breakdown {
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
  justify-content: space-between;
}
.benefit-details .cost-breakdown p {
  padding: 0;
  margin: 0;
}
.benefit-details .cost-breakdown span {
  flex: 1;
  border-bottom: 2px dotted #333;
}
.benefit-details .cost-breakdown.total > p {
  font-family: var(--main-font-bold);
}
.benefit-details .category-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  color: #5D6D78;
  display: flex;
  align-items: center;
}
.benefit-details .category-name img {
  width: 16px;
  height: 16px;
}
@media screen and (min-width: 768px) {
  .benefit-details .cost-container p {
    text-align: right;
  }
}
.benefit-details .cost-container .headline-cost {
  font-family: var(--main-font-light);
  font-size: 32px;
  line-height: 32px;
  word-break: break-word;
}
.benefit-details .cost-container .headline-cost.cost-multi-payment-source-value {
  font-size: 20px;
}
.benefit-details .cost-container .cost-label {
  margin: 0px 0px 4px 0px;
  color: #5D6D78;
}
.benefit-details .cost-container .cost-label.has-info-icon {
  display: flex;
  justify-content: right;
  align-items: center;
}
@media screen and (max-width: 576px) {
  .benefit-details .cost-container .cost-label.has-info-icon {
    justify-content: left;
  }
}
.benefit-details .cost-container .cost-label.has-info-icon i {
  margin-left: 8px;
}
.benefit-details .accordion .expansion-panel {
  padding: 12px 0 12px 0;
}
.benefit-details .accordion .expansion-panel i.fa-download {
  font-size: 16px;
}
.benefit-details .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .header-text,
.benefit-details .accordion .expansion-panel.accordion-item .accordion-header .header-sleeve .header-controls i {
  font-size: 16px;
}
.benefit-details .accordion .expansion-panel table th,
.benefit-details .accordion .expansion-panel table td {
  border: 0;
}
.benefit-details .accordion .expansion-panel table tr th:not(:first-child),
.benefit-details .accordion .expansion-panel table tr td:not(:first-child) {
  text-align: right;
}
.benefit-details .accordion .expansion-panel table thead tr {
  background-color: #F2F2F2;
}
.benefit-details .accordion .expansion-panel table tbody tr:nth-child(even) {
  background-color: #F2F2F2;
}
.benefit-details .accordion .expansion-panel table tbody .participants-mobile-view tr {
  background-color: transparent;
}
@media screen and (max-width: 576px) {
  .benefit-details .accordion .expansion-panel table thead th:not(:first-child) {
    display: none;
  }
  .benefit-details .accordion .expansion-panel table td,
  .benefit-details .accordion .expansion-panel table th {
    display: block;
  }
  .benefit-details .accordion .expansion-panel table td {
    padding: 0 12px;
  }
  .benefit-details .accordion .expansion-panel table td[data-th] {
    font-family: var(--main-font-bold);
  }
  .benefit-details .accordion .expansion-panel table tr th:not(:first-child),
  .benefit-details .accordion .expansion-panel table tr td:not(:first-child) {
    text-align: left;
  }
  .benefit-details .accordion .expansion-panel table .participants-mobile-view .interim-wrapper {
    background-color: #F2F2F2;
  }
}
.benefit-details .pending-approval-wrapper {
  background-color: #CBEEF6;
  padding: 20px;
}
.benefit-details .basic-card {
  margin-bottom: 20px;
}
.benefit-details .basic-card .button-sleeve {
  margin-top: 20px;
}

.benefit-card-sleeve:last-child .benefit-details .basic-card {
  margin-bottom: 0px;
}

@media only screen and (max-width: 992px) {
  .page-has-balance-cards .benefit-card-sleeve:last-child .benefit-details .basic-card {
    margin-bottom: 20px;
  }
}

/*Handle multi instance implementation where benefit-details component is wrapped in an expansion panel*/
.expansion-panel .benefit-details .benefit-details-coverage-display {
  margin-top: 0px !important;
}

.benefit-selection-beneficiary .allocation-input-wrapper {
  width: 25%;
}
@media screen and (max-width: 576px) {
  .benefit-selection-beneficiary .allocation-input-wrapper {
    width: 39%;
  }
}
.benefit-selection-beneficiary hr {
  opacity: 1;
  border-top-color: #ACC0C4;
}

.profile-beneficiaries-container .benefit-selection-beneficiary .beneficiary-allocation-type .allocation-input-wrapper {
  width: 30%;
}
.profile-beneficiaries-container .benefit-selection-beneficiary .beneficiary-allocation-type .basic-card.edit-state {
  background-color: #FFFFFF;
}
.profile-beneficiaries-container .benefit-selection-beneficiary .non-designated-container .profile-add-edit-beneficiary .basic-card.edit-state {
  background-color: #FFFFFF;
}

.non-designated-container {
  margin: 0;
  background: #F9FCFC;
}
.non-designated-container > .beneficiary-allocation-block.col-md-7 {
  padding: 20px;
  border-radius: 8px;
}
.non-designated-container > .beneficiary-allocation-block.col-md-7 button:not(:hover) {
  background: transparent;
}

.designated-container .beneficiary-allocation-block {
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .beneficiary-allocation-type {
    margin-bottom: 20px;
  }
}

aon-tax-modeller-tables-modal .modal-body {
  height: calc(80vh - 40px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal .modal-header .modal-title {
    font-size: 20px;
    line-height: 26px;
  }
}
aon-tax-modeller-tables-modal ::-webkit-scrollbar {
  width: 8px;
  height: 1px !important;
}
aon-tax-modeller-tables-modal ::-webkit-scrollbar-track {
  background-color: #E4E4E4;
  width: 8px;
}
aon-tax-modeller-tables-modal ::-webkit-scrollbar-thumb {
  background: #5D6D78;
  border-radius: 4px;
  height: 1px;
}
aon-tax-modeller-tables-modal table {
  border-collapse: separate;
  border-spacing: 0px;
}
aon-tax-modeller-tables-modal table .row {
  margin: unset;
}
aon-tax-modeller-tables-modal table th,
aon-tax-modeller-tables-modal table td {
  border: 0;
  padding: 8px 0px !important;
  border-top: unset !important;
  border-bottom: unset !important;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal table th,
  aon-tax-modeller-tables-modal table td {
    line-height: unset !important;
  }
}
aon-tax-modeller-tables-modal table .c-align-left {
  text-align: left;
}
aon-tax-modeller-tables-modal table tr th:not(:first-child),
aon-tax-modeller-tables-modal table tr td:not(:first-child) {
  text-align: right;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal table tr th:not(:first-child):not(:last-child) {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
  aon-tax-modeller-tables-modal table tr td:not(:first-child):not(:last-child) {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}
aon-tax-modeller-tables-modal table tr td:first-child,
aon-tax-modeller-tables-modal table tr th:first-child {
  padding-left: 20px !important;
}
aon-tax-modeller-tables-modal table tr td:last-child,
aon-tax-modeller-tables-modal table tr th:last-child {
  padding-right: 20px !important;
}
aon-tax-modeller-tables-modal table thead tr {
  background-color: #F2F2F2;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal table thead tr th {
    font-size: 14px;
  }
}
aon-tax-modeller-tables-modal table tbody tr:nth-child(even) {
  background-color: #F2F2F2;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal table tbody tr td:not(:first-child) {
    font-size: 15px;
  }
  aon-tax-modeller-tables-modal table tbody tr td:first-child {
    font-size: 14px;
  }
}
aon-tax-modeller-tables-modal .value-emphasis-table {
  background-color: #F2F2F2;
  padding: 20px 0px;
}
aon-tax-modeller-tables-modal .value-emphasis-table tr:first-child th,
aon-tax-modeller-tables-modal .value-emphasis-table tr:first-child td {
  padding-bottom: unset !important;
  padding-top: unset !important;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal .value-emphasis-table tr:first-child th:not(:first-child),
  aon-tax-modeller-tables-modal .value-emphasis-table tr:first-child td:not(:first-child) {
    font-size: 14px;
  }
}
aon-tax-modeller-tables-modal .value-emphasis-table .value-emphasis-table-item td:first-child {
  padding-top: unset !important;
  font-size: 14px;
  line-height: 18px;
}
aon-tax-modeller-tables-modal .value-emphasis-table .value-emphasis-table-item td:not(:first-child) {
  color: #46535E;
  font-family: var(--main-font-light);
  font-size: 30px;
  line-height: 32px;
  font-weight: 300;
}
@media screen and (max-width: 576px) {
  aon-tax-modeller-tables-modal .value-emphasis-table .value-emphasis-table-item td:not(:first-child) {
    font-size: 18px !important;
  }
}

sable-dataview .sable-label-value-sleeve,
.sable-dataview .sable-label-value-sleeve {
  flex-direction: column;
}
sable-dataview .sable-label-value-sleeve p,
.sable-dataview .sable-label-value-sleeve p {
  margin-bottom: 2px;
}
sable-dataview .strong .sable-label-value-sleeve p.sable-label,
.sable-dataview .strong .sable-label-value-sleeve p.sable-label {
  font-weight: 700 !important;
}

.aon-activity-lifestyle-reasons .basic-card .icon-container {
  height: 60px;
  width: 60px;
  flex-grow: 1;
  text-align: right;
  font-size: 50px;
}

.help-nav .nav {
  background-color: #262836;
}
.help-nav .nav-tabs a {
  text-decoration: none;
  padding: 0px 20px 0px 20px;
  color: #E5EFF0;
}
.help-nav .nav-tabs .nav-link.active {
  color: #E5EFF0;
  background-color: #46535E;
  border-color: #46535E;
  font-family: var(--main-font-bold);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.help-nav .nav-tabs .nav-link {
  margin-bottom: unset;
  padding-top: 18px;
  padding-bottom: 18px;
}
.help-nav .nav-tabs .nav-link:focus-visible {
  outline-offset: -4px;
}
.help-nav .nav-tabs .nav-item {
  padding: 0;
}
@media screen and (max-width: 576px) {
  .help-nav .nav-tabs {
    display: none;
  }
}

.custom-aon-breadcrumb .aon-breadcrumb-root {
  margin: 0;
  color: #262836;
}
.custom-aon-breadcrumb .aon-breadcrumb-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.custom-aon-breadcrumb .aon-breadcrumb-item {
  list-style: none;
}
.custom-aon-breadcrumb .aon-breadcrumb-trail {
  display: flex;
  align-items: center;
  color: #262836;
  margin-bottom: 0px;
  font-family: var(--main-font-regular);
  font-weight: 400;
}
.custom-aon-breadcrumb .aon-breadcrumb-link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: inherit;
  transition: -webkit-text-decoration 0.3s;
  transition: text-decoration 0.3s;
  transition: text-decoration 0.3s, -webkit-text-decoration 0.3s;
  cursor: pointer;
  text-decoration: underline;
}
.custom-aon-breadcrumb .aon-breadcrumb-link-disabled {
  pointer-events: none;
  cursor: disabled;
}
.custom-aon-breadcrumb .aon-breadcrumb-separator {
  display: flex;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
}

.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .widget-section-heading {
  text-align: center;
  margin-bottom: 40px;
}
@media only screen and (min-width: 1200px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .widget-section-heading h2 {
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 576px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .widget-section-heading {
    text-align: left;
    margin-bottom: 20px;
  }
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row {
  justify-content: center;
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card {
  display: flex;
  padding: 0px;
  min-height: 216px;
}
@media only screen and (max-width: 992px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card {
    flex-direction: column;
    padding: 0px !important;
  }
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-image {
  width: 32%;
  flex-shrink: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  border-radius: 7px 0px 0px 7px;
}
@media only screen and (max-width: 992px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-image {
    width: 100% !important;
    min-height: 216px;
    border-radius: 7px 7px 0px 0px;
  }
}
@media only screen and (max-width: 576px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-image {
    border-radius: 0px;
  }
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text {
  padding: 32px;
  flex-grow: 1;
}
@media only screen and (max-width: 992px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text {
    padding: 20px 20px 32px 20px;
    width: 100%;
    flex-direction: column;
  }
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text .wrapper-headline-widget-button app-aon-cta button {
  margin-right: 20px;
}
@media only screen and (max-width: 576px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text .wrapper-headline-widget-button app-aon-cta button {
    margin-right: 0px;
  }
}
.wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text .wrapper-headline-widget-button app-aon-cta:last-child button {
  margin-right: 0px;
}
@media only screen and (max-width: 576px) {
  .wrapper-headline-widget-content-sleeve-outer .wrapper-headline-widget .wrapper-headline-widget-container .row .basic-card .wrapper-headline-widget-text .wrapper-headline-widget-button app-aon-cta:last-child button {
    margin-bottom: 0px;
  }
}

.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .basic-card {
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .basic-card {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .basic-card {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container h3 {
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 992px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .vertical-line {
    border-right: 1px solid #C0C0C0;
  }
}
@media only screen and (min-width: 992px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .event-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .horizontal-line {
  border-bottom: 1px solid #C0C0C0;
  margin: 20px 0px;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .horizontal-line {
    margin: 32px 0px;
  }
}
@media only screen and (min-width: 992px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .horizontal-line {
    border-bottom: 0px;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .content-top p {
  margin-bottom: 20px;
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .content-top p:last-child {
  margin-bottom: 0;
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .content-top p.window-dates {
  font-family: var(--main-font-bold);
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-widget-intro {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-widget-intro {
    margin-bottom: 32px;
  }
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-widget-intro h2 {
    text-align: center;
  }
}
@media only screen and (min-width: 992px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-widget-intro {
    text-align: center;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row {
  height: 100%;
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 p .lg {
  margin-bottom: 30px;
}
@media only screen and (max-width: 576px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 p .lg {
    margin-bottom: 20px;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 button {
  margin-right: 20px;
  margin-top: 20px;
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 button:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 576px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 button {
    margin-right: 0;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 .widget-content {
  height: 100%;
  display: flex;
}
@media only screen and (max-width: 576px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .row .col-12 .widget-content {
    flex-direction: column;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-info-container .days-left-section {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-info-container .days-left-section {
    margin-bottom: 0px;
  }
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-info-container .days-left-section p {
    font-family: var(--main-font-light);
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget .event-info-container .days-left-section p a {
    font-family: var(--main-font-light);
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .active-event-widget.multi-event-view .event-info-container .days-left-section {
  margin-bottom: 20px;
  margin-right: 0px;
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .display-direction {
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .display-direction {
    flex-direction: row;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .event-icon {
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .event-icon {
    margin-bottom: 0px;
  }
}
.cta-content-sleeve-outer .cta-widgets .cta-widgets-container .width-50 {
  flex-direction: column;
}
@media only screen and (min-width: 768px) {
  .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .width-50 {
    flex-direction: row;
    flex: 0 0 auto;
    width: 50%;
  }
}

.dark-mode .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .h5.color-1 {
  color: #262836 !important;
}
.dark-mode .cta-content-sleeve-outer .cta-widgets .cta-widgets-container .h5.color-3 {
  color: #5D6D78 !important;
}

.ls-content-sleeve-outer .ls-widgets .ls-widgets-container .basic-card {
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .ls-content-sleeve-outer .ls-widgets .ls-widgets-container .basic-card {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .ls-content-sleeve-outer .ls-widgets .ls-widgets-container .basic-card {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .ls-content-sleeve-outer .ls-widgets .ls-widgets-container h3 {
    margin-bottom: 8px;
  }
}
.ls-content-sleeve-outer .ls-widgets .ls-widgets-container .vl {
  border-left: 1px solid #C0C0C0;
}

.additional-events-signpost-widget-content-sleeve-outer .widget-container {
  margin-bottom: 0px;
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .contents p {
  margin-bottom: 20px;
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .contents p:last-child {
  margin-bottom: 0;
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .contents p.window-dates {
  font-family: var(--main-font-bold);
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container.basic-card {
  height: 100%;
}
@media only screen and (max-width: 992px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container.basic-card {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container.basic-card {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 576px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container h3 {
    margin-bottom: 8px;
  }
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .days-left-section {
  margin-bottom: 20px;
  margin-right: 20px;
}
@media only screen and (min-width: 768px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container .days-left-section p {
    font-family: var(--main-font-light);
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction {
  flex-direction: column;
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction .event-icon {
  margin-bottom: 20px;
}
@media only screen and (min-width: 992px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction .event-icon {
    margin-bottom: 0px;
  }
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction input[type=button] {
  margin-top: 20px;
}
@media only screen and (min-width: 992px) {
  .additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction {
    flex-direction: row;
  }
  .additional-events-signpost-widget-content-sleeve-outer .widget-container .display-direction input[type=button] {
    margin-top: 0px;
  }
}
.additional-events-signpost-widget-content-sleeve-outer .widget-container .horizontal-line {
  border-bottom: 1px solid #CDDBDE;
  margin: 32px 0px;
}

.broadcast-banner {
  background-color: #262836;
  border-bottom: 1px solid #E5EFF0;
}

.masthead-inbox kendo-grid {
  border-radius: 12px;
  box-shadow: 0px 0px 6px rgba(192, 192, 192, 0.5);
  background-color: #FFFFFF;
}
.masthead-inbox kendo-grid thead tr th {
  background-color: #FFFFFF;
  padding: 20px 32px 8px;
  border: none;
  border-bottom: 1px solid #C0C0C0;
  font-family: var(--main-font-bold);
  font-size: 16px;
}
.masthead-inbox kendo-grid tr td {
  background-color: #FFFFFF;
  padding: 16px 16px 16px 32px;
  border: none;
  border-bottom: 1px solid #C0C0C0;
  font-size: 16px;
}
.masthead-inbox kendo-grid .k-pager-numbers .k-link {
  color: #007585;
  font-size: 18px;
}
.masthead-inbox kendo-grid .k-pager-numbers .k-link:hover {
  background-color: transparent;
  color: #007585;
}
.masthead-inbox kendo-grid .k-pager-numbers .k-link.k-state-selected {
  background-color: #E4E4E4;
  border-radius: 50%;
  color: #007585;
}
.masthead-inbox kendo-grid .k-pager-numbers .k-link.k-state-selected:hover {
  color: #007585;
}
.masthead-inbox kendo-grid .k-pager-wrap .k-link {
  color: #007585;
}
.masthead-inbox kendo-grid .k-grid-remove-command {
  background: none;
  border: none;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .masthead-inbox kendo-grid kendo-pager-numeric-buttons > select {
    height: 100%;
  }
}
.masthead-inbox .modal-content img {
  max-width: 100%;
  height: auto;
}
.masthead-inbox .modal-content .modal-body {
  max-height: 50vh;
  overflow: auto;
}

.k-grid-filter-popup .k-filter-menu .k-action-buttons .k-button {
  min-width: auto;
}

.inbox-message-page-sleeve {
  padding: 32px;
  min-height: 100vh;
}
@media screen and (max-width: 576px) {
  .inbox-message-page-sleeve {
    padding: 20px;
  }
}
.inbox-message-page-sleeve .basic-card.edit-state {
  background-color: #EEF6F7;
}
.inbox-message-page-sleeve img {
  max-width: 100%;
}

app-enrolment-summary-control-icon .enroment-summary-control-icon-container {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  z-index: 1;
  top: 40px;
  height: 0px;
}
@media screen and (max-width: 992px) {
  app-enrolment-summary-control-icon .enroment-summary-control-icon-container.has-other-sticky-banner {
    top: 80px;
  }
}
@media only screen and (max-width: 768px) {
  app-enrolment-summary-control-icon .enroment-summary-control-icon-container:not(.active-benefits-plans) button {
    margin-top: 20px;
  }
}
app-enrolment-summary-control-icon .enroment-summary-control-icon-container button {
  border-radius: 50% !important;
  min-width: 0px !important;
  height: 44px;
  width: 44px !important;
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 1600px) {
  app-enrolment-summary-control-icon .enroment-summary-control-icon-container {
    margin-right: -200px;
  }
}

app-historical-statements .historical-statements-container .form-group .aon-form-input-wrapper .view-button {
  margin-left: 20px;
}
@media screen and (max-width: 576px) {
  app-historical-statements .historical-statements-container .form-group .aon-form-input-wrapper .view-button {
    margin-left: 0px;
    margin-top: 20px;
  }
}

h2#category-heading {
  margin-bottom: 12px;
  margin-top: 40px;
}
@media only screen and (max-width: 576px) {
  h2#category-heading {
    margin-bottom: 8px;
    margin-top: 20px;
  }
}

.category-product-list .row {
  margin-right: -10px;
  margin-left: -10px;
}
.category-product-list .col-sm-12,
.category-product-list .col-md-4 {
  padding-right: 10px;
  padding-left: 10px;
}
.category-product-list .basic-card {
  min-height: 100%;
}
.category-product-list .basic-card .middle-align {
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .category-product-list .basic-card h5 {
    color: #007585;
  }
}
.category-product-list .basic-card .sm-prod-title {
  font-weight: 700;
}
.category-product-list .basic-card .category-icon {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
@media only screen and (max-width: 576px) {
  .category-product-list .basic-card .category-icon {
    width: 36px;
    height: 36px;
    margin-right: 8px;
  }
}
.category-product-list .basic-card .cat-prods .basic-card {
  padding: 20px;
  align-items: center;
  border-radius: 4px;
}
.category-product-list .basic-card .cat-prods .basic-card:hover {
  background-color: #F9FCFC;
  border-color: #007585;
}
.category-product-list .basic-card .cat-prods .cat-prod-header {
  display: flex;
  align-items: center;
}
.category-product-list .basic-card .cat-prods .cat-prod-header .product-icon {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  align-self: flex-start;
}
@media only screen and (max-width: 576px) {
  .category-product-list .basic-card .cat-prods .cat-prod-header .product-icon {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
}
.category-product-list .basic-card .cat-prods .cat-prod-header h5 {
  color: #007585;
}
.category-product-list .basic-card .cat-prods .m-b-prod-card {
  margin-top: 20px;
}
@media only screen and (max-width: 576px) {
  .category-product-list .basic-card .cat-prods .m-b-prod-card {
    margin-bottom: 12px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .category-product-list .basic-card .cat-prods .m-b-prod-card.col-md-4 .basic-card {
    padding: 20px 20px 28px;
  }
}
@media only screen and (max-width: 576px) {
  .category-product-list {
    width: auto;
    margin-bottom: 80px;
  }
}

app-manual-allocation aon-input-currency.validation-error.ng-touched.ng-invalid .k-input-inner {
  border: 2px solid #EA2238;
}
app-manual-allocation aon-input-currency.validation-error.ng-touched.ng-valid .k-input .k-input-inner {
  border: 2px solid #EA2238;
}
app-manual-allocation aon-input-currency.validation-error.ng-untouched.ng-invalid .k-input-inner {
  border: 2px solid #EA2238;
}
app-manual-allocation aon-input-currency.validation-error.ng-untouched.ng-valid .k-input .k-input-inner {
  border: 2px solid #EA2238;
}
app-manual-allocation .validation-error aon-input-currency .k-input-inner {
  border: 2px solid #EA2238;
}
app-manual-allocation .cost-value {
  word-break: break-word;
}
@media only screen and (max-width: 576px) {
  app-manual-allocation .control-wrapper .payment-source-wrapper {
    margin-bottom: 20px;
  }
}
app-manual-allocation .control-wrapper .payment-source-amount-wrapper .aon-input-control {
  flex-grow: 2;
}
app-manual-allocation .control-wrapper .payment-source-amount-wrapper .icon-button-sleeve {
  width: auto;
}

app-payment-source-balance-card .payment-source-balance-card-container .payment-source-balance-card {
  width: 100%;
  margin-bottom: 20px;
}
app-payment-source-balance-card .payment-source-balance-card-container .payment-source-balance-card .basic-card {
  height: 100%;
}
app-payment-source-balance-card .payment-source-balance-card-container.horizontal-orientation {
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  app-payment-source-balance-card .payment-source-balance-card-container.horizontal-orientation {
    margin-bottom: 0px;
  }
}
app-payment-source-balance-card .payment-source-balance-card-container.vertical-orientation .row > *:last-child .payment-source-balance-card {
  margin-bottom: 0px;
}

app-benefit-details-mini .basic-card {
  height: 325px;
  padding: 20px;
}
app-benefit-details-mini .basic-card .content-top .tag-container {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: -20px;
}
app-benefit-details-mini .basic-card .content-top .benefit-name {
  overflow-wrap: anywhere;
}
app-benefit-details-mini .basic-card .content-top .tag {
  margin-right: -20px;
  border-radius: 0px 8px 0px 4px;
}
app-benefit-details-mini .basic-card .content-top .category-name {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
app-benefit-details-mini .basic-card .content-top .category-name img {
  width: 16px;
  height: 16px;
}
app-benefit-details-mini .basic-card .content-info {
  flex-grow: 1;
  overflow-y: auto;
}
app-benefit-details-mini .basic-card .content-info::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
app-benefit-details-mini .basic-card .content-info::-webkit-scrollbar-track {
  background-color: #E4E4E4;
  width: 8px;
}
app-benefit-details-mini .basic-card .content-info::-webkit-scrollbar-thumb {
  background: #5D6D78;
  border-radius: 4px;
  height: auto;
}
@media screen and (max-width: 576px) {
  app-benefit-details-mini .active-benefits-card.basic-card ngb-popover-window .popover-body {
    max-width: 200px !important;
  }
}

.coverage-eoi table th,
.coverage-eoi table td {
  border: 0;
}
.coverage-eoi table tr th:not(:first-child),
.coverage-eoi table tr td:not(:first-child) {
  text-align: right;
}
.coverage-eoi table thead tr {
  background-color: #F2F2F2;
}
.coverage-eoi table tbody tr:nth-child(even) {
  background-color: #F2F2F2;
}
.coverage-eoi table tbody .participants-mobile-view tr {
  background-color: transparent;
}
@media screen and (max-width: 576px) {
  .coverage-eoi table thead th:not(:first-child) {
    display: none;
  }
  .coverage-eoi table td,
  .coverage-eoi table th {
    display: block;
  }
  .coverage-eoi table td {
    padding: 0 12px;
  }
  .coverage-eoi table td[data-th] {
    font-family: var(--main-font-bold);
  }
  .coverage-eoi table tr th:not(:first-child),
  .coverage-eoi table tr td:not(:first-child) {
    text-align: left;
  }
  .coverage-eoi table .participants-mobile-view .interim-wrapper {
    background-color: #F2F2F2;
  }
  .coverage-eoi .coverage-table td[data-th]:before {
    content: attr(data-th);
  }
}

@media only screen and (min-width: 992px) {
  .app-header-sleeve {
    position: relative !important;
  }
}
.app-header-sleeve .delegate-sleeve {
  background-color: #46535E;
  color: #E5EFF0;
  min-height: 44px;
  z-index: auto;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  align-items: center;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar p {
  color: #E5EFF0;
  margin-bottom: 0px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate {
  display: flex;
  align-items: center;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) {
  list-style-type: none;
  margin: 0px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li {
  border-top: 4px solid transparent;
  border-bottom: 2px solid transparent;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li.show {
  border-top: 4px solid #F9FCFC !important;
  background-color: #5D6D78 !important;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li:hover {
  border-top: 4px solid #F9FCFC !important;
  background-color: #5D6D78;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li a {
  padding: 10px 20px 14px 20px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li a .fal {
  margin-left: 8px;
  color: #E5EFF0;
  font-size: 16px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li a:focus-visible {
  outline: 2px solid #FFFFFF !important;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li .dropdown-menu {
  background-color: #5D6D78;
  margin: 0px;
  border-radius: 0px;
  width: 300px;
  padding: 0px;
  border: 0px;
  left: unset;
  right: 0px;
  overflow-y: auto;
  max-height: 324px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li .dropdown-menu li {
  border-top: unset !important;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li .dropdown-menu li a {
  padding: 14px 20px;
  color: #E5EFF0;
  background-color: #5D6D78;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li .dropdown-menu li a:hover {
  background-color: #46535E;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) li .dropdown-menu li a:focus-visible {
  width: 100%;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ul:not(.nav-dropdown-list) .dropdown-toggle::after {
  display: none;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ::-webkit-scrollbar {
  width: 8px;
  height: auto;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ::-webkit-scrollbar-track {
  background-color: #E4E4E4;
  width: 8px;
}
.app-header-sleeve .delegate-sleeve .site-masthead-delegate-bar .select-delegate .delegate-user-list ::-webkit-scrollbar-thumb {
  background: #5D6D78;
  border-radius: 4px;
  height: auto !important;
  border: 1px solid #E4E4E4;
}

.popover.tax-summary-popover .popover-header {
  background-color: #F9FCFC;
}
.popover.tax-summary-popover .popover-body div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.popover.tax-summary-popover .popover-body div > span:first-child {
  margin-right: 8px;
}

.fund-allocation .fund-allocation-group {
  margin-bottom: 20px;
}
.fund-allocation .fund-allocation-choices .card-body .row {
  align-items: center;
}
.fund-allocation .fund-allocation-choices .card-body p.h4, .fund-allocation .fund-allocation-choices .card-body p.h5 {
  margin-bottom: 0px;
}
.fund-allocation .fund-allocation-choices .card-body .divider {
  margin: 20px 0px;
}
.fund-allocation .fund-allocation-choices .card-body .reset-btn {
  display: flex;
  justify-content: end;
}
.fund-allocation .fund-allocation-choices .card-body .total-cost {
  background-color: #EEF6F7;
  margin: 20px -32px -32px -32px;
  padding: 20px 15px 32px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
@media screen and (max-width: 576px) {
  .fund-allocation .fund-allocation-choices .card-body .total-cost {
    margin: 20px -19px -20px;
    border-radius: 0px;
  }
  .fund-allocation .fund-allocation-choices .card-body .total-cost p.h5 {
    margin-bottom: 10px;
  }
}

.self-service {
  /* Override the predefined class 'k-upload' */
  /* Override the predefined class 'k-upload' */
}
.self-service.dark-mode .editable-mbr-site-toolbar .toolbar-header span {
  color: #46535E !important;
}
.self-service .editable-mbr-site-toolbar {
  height: 35px;
  width: 100%;
  background-color: #EEF6F7;
  border: 1px solid #ACC0C4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.self-service .editable-mbr-site-toolbar > p {
  margin-bottom: 0px !important;
  font-size: 14px !important;
  padding-left: 10px !important;
}
.self-service .editable-mbr-site-toolbar.global-toolbar {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.self-service .editable-mbr-site-toolbar.local-toolbar {
  justify-content: space-between;
  margin-bottom: 0px;
  border-radius: 8px 8px 0px 0px;
}
.self-service .editable-mbr-site-toolbar.local-toolbar.spacer {
  margin-bottom: 20px;
}
.self-service .editable-mbr-site-toolbar.local-toolbar.inside-card {
  border: 0px;
  border-bottom: 1px solid #ACC0C4;
}
.self-service .editable-mbr-site-toolbar.local-toolbar.overlaid {
  position: absolute;
}
.self-service .editable-mbr-site-toolbar.local-toolbar.page-top {
  margin-top: 20px;
}
.self-service .editable-mbr-site-toolbar .toolbar-header {
  display: flex;
}
.self-service .editable-mbr-site-toolbar .toolbar-header .toolbar-header-label {
  font-weight: 700;
}
.self-service .editable-mbr-site-toolbar .toolbar-control {
  height: 100%;
  display: flex;
  align-items: center;
  width: 40px;
  justify-content: center;
  cursor: pointer;
  border-left: 1px solid #ACC0C4;
}
.self-service .editable-mbr-site-toolbar .toolbar-control::after {
  display: none !important;
}
.self-service .editable-mbr-site-toolbar .toolbar-control#menu-main-population-preview {
  display: flex;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.self-service .editable-mbr-site-toolbar .toolbar-control#menu-main-population-preview .label {
  font-weight: 700;
}
.self-service .editable-mbr-site-toolbar .toolbar-control#menu-main-population-preview .population-name {
  margin-right: 10px;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-menu-wrappper {
  height: 100%;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu {
  background-color: #EEF6F7;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li:not(.header) {
  padding-right: 0px;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li.header {
  font-family: var(--main-font-bold);
  background-color: white;
  cursor: default;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li i {
  width: 40px;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}
.self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li.dropdown-item:active, .self-service .editable-mbr-site-toolbar .toolbar-control-dropdown-menu ul li.dropdown-item.active {
  background-color: transparent;
  color: unset;
}
.self-service .content-editor-modal .modal-body .edit-state {
  max-height: 60vh;
  overflow: auto;
}
.self-service .content-editor-modal .modal-body .edit-state textarea {
  background-color: transparent;
}
.self-service .content-editor-modal .modal-body .edit-state kendo-fileselect {
  max-width: 280px;
}
.self-service .content-editor-modal .modal-body .edit-state kendo-fileselect .k-upload-dropzone,
.self-service .content-editor-modal .modal-body .edit-state kendo-fileselect .k-upload-button-wrap,
.self-service .content-editor-modal .modal-body .edit-state kendo-fileselect button {
  width: 100%;
}
.self-service .content-editor-modal .modal-body .edit-state .choose-existing-media-control {
  width: 280px;
}
.self-service .content-editor-modal .modal-body .edit-state .upload-container-queue {
  width: 280px;
}
.self-service .content-editor-modal .modal-body .edit-state .upload-container-queue .upload-container-queue-list .delete-container {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}
.self-service .k-upload {
  background-color: rgba(240, 240, 240, 0) !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: start !important;
}
.self-service .k-upload-dropzone {
  padding-inline: 0 !important;
}
.self-service .k-table .k-checkbox {
  opacity: 1;
  border: 1px solid var(--tbs-theme-interactive-default);
}
.self-service .k-pager .k-button {
  background-color: var(--tbs-theme-interactive-default) !important;
}
.self-service .k-pager-md .k-pager-numbers-wrap .k-button {
  margin-right: 1px;
}
.self-service .publishing-tasks {
  padding-top: 40px;
  padding-bottom: 120px;
}