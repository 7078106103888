@use '../3-helpers/variables';

sable-dataview,
.sable-dataview {

    .sable-label-value-sleeve {
        flex-direction: column;

        p {
            margin-bottom: 2px;
        }
    }

    .strong {
        .sable-label-value-sleeve {
            p {
                &.sable-label {
                    font-weight: variables.$main-font-weight-bold !important;
                }
            }
        }
    }
}