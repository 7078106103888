@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.aon-activity-coverage {
  .container {
    .card {
      .card-body {
        .qcc-sleeve {

          .h1 {
            @include mixins.heading($fontfamily: variables.$heading-font-bold, $fontsize: 40px, $lineheight: 52px);

            &.secondary {
              @include mixins.heading($fontfamily: variables.$heading-font-bold, $fontsize: 36px, $lineheight: 44px);
            }
          }

          .table-cell-footer-text {
            font-size: 14px !important;
            line-height: 36px;
            font-family: variables.$main-font-regular;
            color: variables.$label-text-color;
          }

          .carrier-logo {
            height: 110px;
            background-position: 12px 50%;
            background-size: 65%;
            background-repeat: no-repeat;

            &.carrier-268-logo {
              background-image: url(../../../assets/images/help/farmers.svg);
            }

            &.carrier-294-logo {
              background-image: url(../../../assets/images/help/safeco.svg);
            }


            &.carrier-305-logo {
              background-image: url(../../../assets/images/help/travelers-logo.svg);
            }
          }



          table td:first-child,
          th:first-child {
            padding-left: 32px;

            @media screen and (max-width: variables.$site-body-width-S) {
              padding-left: 20px;
              padding-right: 10px;
            }
          }

          table th:not(:first-child),
          td:not(:first-child) {
            padding-left: 12px;

            @media screen and (max-width: variables.$site-body-width-S) {
              padding-left: 8px;
              padding-right: 8px !important;
            }
          }

          table th:not(:first-child) {
            border-left: 1px solid variables.$bg-color-shadow;
          }


          @media screen and (max-width: variables.$site-body-width-L) {
            .tscroll {
              overflow-x: scroll;
            }
          }

          /*Fixed first column of a table in mobile mode - Start*/
          @media screen and (max-width: variables.$site-body-width-S) {
            background-color: variables.$bg-color-fog;

            .tscroll {
              overflow-x: scroll;
              font-size: 12px !important;

              table td:first-child,
              th:first-child {
                left: 0;
                background-color: variables.$white;
                box-shadow: 0 3px 6px variables.$bg-color-shadow;
              }

              p {
                font-size: 12px !important;
              }
            }
          }

          /*Fixed first column of a table in mobile mode - End*/
          .qcc-content {
            .content {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .qc-header {

              .fa-times {
                color: variables.$icon-default-color;
              }

              .qcc-table-heading {
                color: variables.$core-color-blue !important;
                float: left;
              }

              button {
                width: unset;
                min-width: unset;
              }
            }

            h6,
            .fa-arrow-left {
              color: variables.$icon-default-color;
            }

            h6.close-comparison {
              cursor: pointer;
              display: flex;
              align-items: center;
            }

            h4 {
              margin-bottom: 40px;

              @media screen and (max-width: variables.$site-body-width-S) {
                margin-bottom: 12px;
                color: variables.$core-color-blue;
              }
            }

            .basic-card {
              padding: 32px 0px 32px 0px;

              &.basic-card-padding {
                padding: 32px !important;
              }

              @media screen and (max-width: variables.$site-body-width-S) {
                padding: 0px;
                border-radius: 0px;
              }
            }

            .auto-table {
              tr {
                border-top: none;
              }

              th {
                div {
                  position: sticky;
                  margin-top: -25px;
                  display: flex;
                  z-index: 99;

                  @media screen and (max-width:variables.$site-body-width-S) {
                    position: inherit;
                    margin-top: -10px;
                  }

                  p {
                    background-color: variables.$quote-coverage-label-color;
                    padding: 4px;
                    border-radius: 2px;
                  }
                }

                p {
                  font-family: variables.$main-font-regular;
                  font-weight: variables.$main-font-weight-normal;
                  font-size: 14px;
                  line-height: 16px;
                  margin-bottom: 4px;
                }

                span {
                  font-family: variables.$main-font-regular;
                  font-weight: variables.$main-font-weight-light;
                  font-size: 40px;
                  line-height: 44px;
                  color: variables.$core-color-blue;
                  margin-bottom: 4px;
                }
              }

              td {
                span {
                  p {
                    text-decoration: underline dashed;
                    text-underline-position: under;
                  }
                }
              }
            }

            table {
              width: 100%;

              tr:nth-child(2) th {
                font-size: 14px;
              }

              th {
                white-space: normal;
                padding-top: 20px;
                padding-bottom: 20px;
                padding-right: 32px;
                border-bottom: 1px solid variables.$bg-color-shadow;
                color: variables.$data-metric-cost-color;
                font-size: 16px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  padding-top: 8px;
                  padding-bottom: 8px;
                }

                p {
                  font-family: variables.$main-font-regular;
                  margin-bottom: 0px;
                  margin-right: 32px;

                  @media screen and (max-width: variables.$site-body-width-S) {
                    padding-right: 10px;
                  }
                }
              }

              td {
                padding-top: 16px;
                padding-bottom: 16px;
                font-size: 16px;
                padding-right: 32px;
                text-align: left;

                @media screen and (max-width: variables.$site-body-width-S) {
                  padding-top: 8px;
                  padding-bottom: 8px;
                }

                p {
                  margin-bottom: 0px;

                  &.secondary {
                    text-decoration: underline dashed;
                    text-underline-position: under;
                  }

                  b {
                    &.secondary {
                      text-decoration: underline dashed;
                      text-underline-position: under;
                    }
                  }
                }
              }

              tr,
              td {
                border-bottom: 1px solid variables.$bg-color-shadow;
                border-top: 1px solid variables.$bg-color-shadow;
              }

              tr:nth-child(1) td,
              tr:nth-child(2) td {
                border-left: 1px solid variables.$bg-color-shadow;
              }

              tr:nth-child(1) td:first-child,
              tr:nth-child(2) td:first-child {
                border-left: 0 !important;
              }

              i {
                margin-right: 10px;

                @media screen and (max-width: variables.$site-body-width-S) {
                  margin-right: 4px;
                }
              }

              .fa-check {
                color: variables.$feedback-color-success-darker;
              }

              .fa-times {
                color: variables.$feedback-color-error-darker;
              }
            }

            .vb-asia-qcc {

              tr,
              td {
                border-bottom: 0;
              }

              th,
              td {
                padding-right: 12px;
              }

              th:last-child,
              td:last-child {
                padding-right: 32px;
              }

              tbody>tr:last-child>td {
                padding-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }

  .quote-coverage-comparison .qcc-sleeve .qcc-body {
    @media screen and (max-width: variables.$site-body-width-S) {
      padding-top: 0px;
    }
  }
}

.kentico-data-table {
  table {

    tr:nth-child(1) td,
    tr:nth-child(2) td {
      border-left: none !important;
    }
  }
}

.page-content-sleeve {
  .qcc-sleeve {
    .qcc-content {
      .tscroll {
        .direct-pay-tag::before {
          line-height: 13px;
          position: absolute;
          top: 23px;
          content: "Direct Pay";
          font-family: variables.$main-font-bold;
          background-color: variables.$quote-coverage-pay-background-color;
          font-size: 12px;
          padding: 3px 4px;
          border-radius: 2px;
          color: variables.$core-color-blue;
        }

        @media screen and (max-width:variables.$site-body-width-S) {
          overflow-x: scroll;
        }

        table td:first-child,
        th:first-child {
          left: 0;
          background-color: variables.$white;
        }
      }
    }
  }
}

.product-coverage-details {
  .card {
    .card-body {
      color: unset !important;

      .basic-card {
        .tscroll {
          overflow-x: auto;
          overflow-y: hidden;

          table {
            border-collapse: collapse;

            tr,
            td,
            th {
              border: 2px solid;
            }

            tr:first-child {
              text-align: center;
              color: variables.$core-color-blue;
            }

            td:first-child,
            th:first-child {
              position: sticky;
              left: 0;
              background-color: variables.$white;
            }

            .tr-border-top {
              border-top: 4px solid;
            }

            .tr-border-bottom {
              border-bottom: 4px solid;
            }

            .tooltip .tooltiptext {
              max-width: 400px;
              width: max-content;
              left: 100%;
              top: 0;

              @media screen and (max-width: variables.$site-body-width-S) {
                max-width: 200px;
              }
            }

            .tooltip .tooltiptext::after {
              left: -10px;
              top: 20px;
              border-color: transparent variables.$bg-color-slate transparent transparent;
            }

          }
        }
      }
    }
  }
}