@use '../3-helpers/variables';


.page {

  &.legal-content {

    .page-content-sleeve {
      padding-top: 40px;


      .page-only {
        padding-bottom: 150px;
      }

      ul,
      ol {
        margin-bottom: variables.$list-default-margin-bottom;
      }
    }
  }
}


.modal-content {

  .page {

    &.legal-content {
      background-color: transparent;
      min-height: unset;

      .page-content-sleeve {
        padding: 0px;

      }
    }
  }
}
