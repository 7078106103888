@use '../3-helpers/variables';

.pcr-content-sleeve-outer .product-carrier {
  background-image: url(../../../assets/images/product/product-carrier/partner-bkgd-shape.svg);
  background-repeat: no-repeat;
  background-position: 100% 20%;

  @media only screen and (max-width:variables.$site-body-width-S) {
    background-repeat: no-repeat;
    background-position: left 100px top 20px;
  }
}


.pcr-container {
  margin-top: 40px;
  margin-bottom: 80px;

  .pcr-content {
    h3 {
      margin-bottom: 20px;
    }

    .ci-content {
      @media screen and (max-width:variables.$site-body-width-S) {
        h4 {
          margin-bottom: 12px;
        }
      }
    }

    .ci-image {
      width: 244px;
      height: 60px;
      margin-bottom: 20px;

      @media screen and (max-width:variables.$site-body-width-S) {
        margin-bottom: 12px;
      }
    }

    .carrier-data-metric {
      font-family: variables.$main-font-light;
      font-size: 40px;
    }
  }

  @media only screen and (max-width:variables.$site-body-width-S) {
    margin-top: 40px;
    margin-bottom: 0px;

    .pcr-content {
      .carrier-item {
        margin-bottom: 40px;
      }

      h3 {
        margin-bottom: 12px;
      }
    }
  }
}
.pcr-logo-wrapper img{
  max-width: 100%;
}
