@use '../3-helpers/variables';

.fund-allocation {
    .fund-allocation-group {
        margin-bottom: 20px;
    }

    .fund-allocation-choices {
        .card-body {
            .row {
                align-items: center;
            }

            p {

                &.h4,
                &.h5 {
                    margin-bottom: 0px;
                }
            }

            .divider {
                margin: 20px 0px;
            }

            .reset-btn {
                display: flex;
                justify-content: end;
            }

            .total-cost {
                background-color: variables.$bg-color-gray07;
                margin: 20px -32px -32px -32px;
                padding: 20px 15px 32px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;

                @media screen and (max-width: variables.$site-body-width-S) {
                    margin: 20px -19px -20px;
                    border-radius: 0px;

                    p {
                        &.h5 {
                            margin-bottom: 10px;
                        }
                    }
                }

            }
        }
    }
}