//Used for all T3 pages (ie product details, coverage details, articles, global help FAQs)

@use '../3-helpers/variables';
@use '../3-helpers/mixins';


.page {

  &.T3 {

    select {
      padding: 0px 34px 0px 12px;
    }

    select:not(.open):not(.disabled):not(.error):hover {
      border: 1px solid variables.$app-color-marine-opacity-008;
      padding: 0px 34px 0px 12px;
    }

    .t3-icon {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background-color: #FFFFFF;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 28px;
      margin-left: 7px;
    }

    /*Left Navigation Menu Styling*/
    .sidebar {
      position: sticky;

      .nav-item {
        word-break: break-word;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: variables.$main-font-weight-light;
        line-height: 28px;

        .nav-link {
          &.nav-link-sub-category {
            &.active {
              border-left: none !important;
              padding-left: 20px
            }

            &:not(.active):hover {
              border-left: none !important;
              padding-left: 20px
            }
          }

          font-family: variables.$main-font-light;
          color: variables.$tab-link-color;
          text-decoration: none;
          padding: 6px 20px 6px 20px;
          border-radius: 0px;

          &.active {
            border-left: 4px solid variables.$link-active-color;
            color: variables.$link-active-color;
            font-family: variables.$main-font-bold;
            text-decoration: none;
            background-color: transparent;
            padding-left: 16px;
          }

          &:focus-visible {
            outline-offset: -3px;
          }

          &:not(.active):hover {
            border-left: 4px solid variables.$link-hover-color;
            color: variables.$link-hover-color;
            padding-left: 16px;
          }
        }
      }
    }

    .xng-breadcrumb-item {
      a {
        text-decoration: underline;
        text-transform: capitalize;
      }
    }

    @media only screen and (max-width: variables.$site-body-width-S) {
      .p-dropdown {
        .dropdown {
          background-color: variables.$core-color-blue;
          padding: 10px 20px;
          @include mixins.paragraph($fontfamily: variables.$main-font-light, $fontsize: variables.$text-size-xs + 4, $lineheight: 24px, $color: variables.$link-color-sky, $weight: variables.$main-font-weight-normal, $margin: 0);
          appearance: none;
          position: relative;
          border-radius: 0;
        }

        .wrap {
          width: 100%;
          position: relative;
          display: block;

          &:after {
            content: "\f0dd";
            font-family: variables.$font-awesome;
            font-size: variables.$text-size-xs + 4;
            color: variables.$link-color-sky;
            position: absolute;
            pointer-events: none;
            width: 10%;
            right: 0;
            top: 30%;
          }
        }
      }
    }


    .pcd-content-sleeve-outer {

      .page-content-sleeve {

        &.product-coverage-details {
          padding-top: 60px;
          padding-bottom: 120px;
          justify-content: space-between;

          &.product-coverage-details-nowave {
            padding-top: 80px;
          }

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-top: 32px;
            padding-bottom: 32px;

            &.product-coverage-details-nowave {
              padding-top: 32px;
            }
          }
        }
      }
    }


    .pd-content-sleeve-outer {

      .page-content-sleeve {

        &.product-details {
          padding-top: 80px;
          padding-bottom: 120px;
          justify-content: space-between;

          @media screen and (max-width: variables.$site-body-width-S) {
            padding-top: 32px;
            padding-bottom: 32px;
          }

          hr {
            border-top: 1px solid variables.$bg-color-shadow;
          }
        }
      }
    }


    .faq-content-sleeve-outer {
      background-image: url(../../../assets/images/product/product-insurance/pet-gray-wave-bkgd-lg.svg);
      background-size: cover;
      background-repeat: no-repeat;

      @media screen and (max-width: variables.$site-body-width-S) {
        background-image: url(../../../assets/images/product/product-insurance/pet-gray-wave-bkgd-sm.svg);
        background-size: cover;
        background-repeat: no-repeat;
      }

      .page-content-sleeve {

        &.faqs {
          padding-top: 180px;
          padding-bottom: 120px;
          margin-top: 80px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            margin-top: 20px;
            padding-top: 120px;
            padding-bottom: 80px;
          }
        }
      }
    }
  }
}


/*Additional styles for page when presented within a modal*/
.modal {
  .page {
    &.T3 {
      .sidebar {
        top: 0px;
      }
    }
  }
}

.xng-breadcrumb-item {
  a {
    text-decoration: underline;
    text-transform: capitalize;
  }
}


/*Styles for Benefit Info modal treatment of T3 content*/
app-benefit-info-modal {
  #benefit-info-modal {
    .modal-nav-bar {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: variables.$modal-navigation-bg-color;

      i.fa-light.fa-chevron-left {
        cursor: pointer;
      }
    }

    .page {

      .pd-content-sleeve-outer {
        .page-content-sleeve {

          &.benefit-information {
            @media only screen and (max-width: variables.$site-body-width-S) {
              padding-top: 20px;
            }

            padding-top: 40px;

            .benefit-information {
              #product-detail-content-sleeve {
                section {
                  @media only screen and (max-width: variables.$site-body-width-S) {
                    height: unset;
                  }

                  overflow-y: auto;
                  height: calc(100vh - 196px);
                  overflow-x: hidden;
                }

                a {
                  display: unset;
                  padding: unset;
                }

                li {
                  color: variables.$paragraph-color-one;
                }

                ol {
                  margin-bottom: variables.$list-default-margin-bottom;
                }

                ul {
                  list-style-type: disc;
                  margin-bottom: variables.$list-default-margin-bottom;

                  ul {
                    list-style-type: circle;
                  }
                }
              }
            }
          }
        }
      }
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: auto;
    }

    ::-webkit-scrollbar-track {
      background-color: variables.$bg-color-smoke;
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: variables.$scrollbar-thumb-color;
      border-radius: 4px;
      height: auto !important;
    }
  }
}

@media only screen and (max-width: variables.$site-body-width-L) {
  app-masthead-benefits {
    .modal-dialog {
      max-width: unset;
      width: 100vw;
    }
  }
}