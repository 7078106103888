@use '../3-helpers/variables';

.page {
  &.custom-logged-off-page {

    .page-content-sleeve {
      padding-top: 40px;
      padding-bottom: 150px;

      ul,
      ol {
        margin-bottom: variables.$list-default-margin-bottom;
      }
    }
  }
}
