@forward "../../global/3-helpers/variables";
@use "../../global/3-helpers/variables" as globvars;


//Client App specific style codes
//Colors

$app-color-shamrock: #1AB294;
$app-color-shamrock-light: #72D4C0;
$app-color-shamrock-shadow: rgba(10, 194, 152, 0.20);
$app-color-shamrock-opacity-01: rgba(26, 178, 148, 0.1);
$app-color-tangerine: #E38363;
$app-color-tangerine-light: #EFAF8F;
$app-color-tangerine-opacity-01: rgba(227, 131, 99, 0.1);
$app-color-turquoise: #29B0C3;
$app-color-turquoise-light: #94D8E1;
$app-color-turquoise-opacity-01: rgba(41, 176, 195, 0.1);
$app-color-marine-opacity-008: #007BB614;
$app-color-white-opacity-01: rgba(255, 255, 255, 0.1);
$app-color-white-opacity-03: rgba(255, 255, 255, 0.3);
$app-color-white-opacity-06: rgba(255, 255, 255, 0.6);
$app-color-white-opacity-15: rgba(255, 255, 255, 0.15);
$app-color-white-opacity-45: rgba(255, 255, 255, 0.45);
$app-color-white-opacity-025: rgba(255, 255, 255, 0.25);
$app-color-white-opacity-80: rgba(255, 255, 255, 0.8);


//App Specific Variables which may be set using global ones

$app-header-color-one: globvars.$core-color-navy;
$app-paragraph-color-two: globvars.$core-color-navy;

//Hero Section (Home AND Products)
$app-hero-subheader-text-color: globvars.$header-color-one;
$app-hero-product-text-color: globvars.$header-color-two;
$app-hero-divider-color: globvars.$bg-color-gray03;
$app-hero-background-color-one: globvars.$bg-color-gray03;
$app-hero-text-hover-color: $app-color-marine-opacity-008;


//Product Cards
$product-card-header-color: globvars.$header-color-two;
$product-card-desc-text-color: globvars.$header-color-three;
$product-card-cat-text-color: globvars.$header-color-three;
$tabbed-card-border-color: globvars.$bg-color-gray04;

//Plan Cards
$plan-card-available-amount-panel-bg-color: globvars.$bg-color-gray07;


//TabList
$tablist-solid-color: globvars.$white;
$tablist-solid-background: globvars.$secondary-color-peacock;
$tablist-color: globvars.$core-color-blue-darker;
$tablist-background: globvars.$white;

//Value Prop
$value-prop-header-color: globvars.$core-color-navy;
$value-prop-icon-circle-bg-color: globvars.$white;

//Case Study
$case-study-bg-color: globvars.$core-color-navy;
$case-study-left-cost-card-bg-color: $app-color-white-opacity-01;
$case-study-right-cost-card-bg-color: $app-color-white-opacity-03;
$case-study-datasection-top-header-row-bg-color: $app-color-white-opacity-15;
$case-study-datasection-bottom-header-row-bg-color: $app-color-white-opacity-45;
$case-study-datasection-top-bg-color: $app-color-white-opacity-01;
$case-study-datasection-bottom-bg-color: $app-color-white-opacity-025;
$case-study-main-text-color: globvars.$bg-color-gray06;
$case-study-datasection-table-total-row-bg-color: $app-color-white-opacity-80;
$case-study-datasection-table-total-row-header-color: globvars.$header-color-one;
$case-study-heading-text-color: globvars.$bg-color-gray07;
$case-study-divider-color: globvars.$bg-color-shadow;
//Product Editorial
$product-editorial-mobile-background-color: globvars.$bg-color-pearl;

//Profile navigation bar
$profile-nav-bar-background-color: globvars.$core-color-navy;
$profile-nav-link-border-background-color: globvars.$text-color-gray01;
$profile-nav-text-color: globvars.$bg-color-gray06;

//Help navigation bar
$help-nav-bar-background-color: globvars.$core-color-navy;
$help-nav-link-border-background-color: globvars.$text-color-gray01;
$help-nav-text-color: globvars.$bg-color-gray06;

//Default Modal Styles
$modal-backdrop-bg-color: globvars.$bg-color-slate;
$modal-bg-color: globvars.$white;
$modal-box-shadow: rgba(192, 192, 192, 0.50);

//GetQuote Modal
$getquote-modal-product-bg-color: $app-color-marine-opacity-008;
$getquote-modal-product-text-color: globvars.$paragraph-color-one;
$getquote-modal-close-btn-color: globvars.$core-color-teal-dark-active;

//Modal 'edit state' panel
$modal-edit-state-panel-bg: globvars.$bg-color-gray07;


//How It Works
$how-it-works-item-header-color: globvars.$core-color-blue;
$how-it-works-divider-color: globvars.$bg-color-shadow;

// Masthead Cart
$masthead-cart-bg-color: globvars.$core-color-navy;

//Masthead profile dropdown
$masthead-dropdown-authenticated-bg-color: globvars.$core-color-blue;
$masthead-dropdown-box-shadow: rgba(0, 0, 0, 0.25);
$masthead-profile-bg-color: globvars.$bg-color-gray07;
$masthead-profile-bg-color-mobile: globvars.$core-color-blue;
$masthead-profile-text-hover-bg-color: globvars.$bg-color-gray05;
$masthead-profile-text-color: globvars.$core-color-navy;
$masthead-resource-header-color: globvars.$core-color-teal-dark;
$masthead-resource-p-color: globvars.$core-color-teal-dark-hover;
$masthead-product-header-color: globvars.$core-color-blue-lighter;
$masthead-product-header-active-color: globvars.$core-color-blue-darker;
$masthead-language-bg-color: globvars.$bg-color-gray07;
$masthead-language-text-hover-bg-color: globvars.$bg-color-gray05;
$masthead-language-text-color: globvars.$core-color-navy;

//Quote coverage Comparision
$quote-coverage-label-color: #8CF2DE;

//Quote coverage selection
$quote-coverage-selection-border-color: globvars.$core-color-teal-dark;
$quote-end-view-selection-border-warning-color: globvars.$feedback-color-warning;
$quote-end-view-selection-border-error-color: globvars.$feedback-color-error;

//Product coverage detail
$product-coverage-detail-card1-color: #fbd5c2;

//Progress Bar
$progress-horizontal-sleeve-bg-color: globvars.$bg-color-gray07;

//Data Metric text
$data-metric-quote-flow-color: globvars.$core-color-blue;

//Icons
$profile-page-info-icon-color: globvars.$icon-color-two;
$help-page-info-icon-color: globvars.$icon-color-two;

//FooterV2
$footer-background-color: #262836;
$footer-link-color: #E5EFF0;

//Breadcrumb link color
$breadcrumb-link-color: globvars.$core-color-teal-dark;

//Product Article Pages
$related-benefit-article-line-color: #979797;

//Editorial Article Pages
$article-faq-background-color: globvars.$white;

//Editorial Feature Component
$campaign-feature-background-color: globvars.$white;

//rows
$tbs-odd-row-bg-color: globvars.$bg-color-gray07;

//Event Signpost
$event-signpost-divider-color: globvars.$bg-color-gray05;

//quote coverage 
$quote-coverage-pay-background-color: globvars.$bg-color-sky-lightest;

//benefit modal navigation
$modal-navigation-bg-color: globvars.$bg-color-gray07;

//benefit card
$benefit-card-accordion-padding: 12px 0 12px 0;
$benefit-card-accordion-font-size: 16px;
$benefit-card-icon-width: 16px;
$benefit-card-icon-height: 16px;