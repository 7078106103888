@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.evp-content-sleeve-outer {
  background-image: url('/assets/images/home/shared-container-backgrounds/dots3.svg'), url('/assets/images/home/shared-container-backgrounds/pebbles8.svg');
  background-position: left top, right center;
  background-repeat: no-repeat, no-repeat;

  @media only screen and (max-width: variables.$site-body-width-L) {
    background-position: left top, right 41%, left bottom;
    background-size: auto, 52px, auto;
  }

  .page-content-sleeve {

    .evp-container {

      h2 {
        font-weight: variables.$main-font-weight-bold !important;
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        app-aon-cta {
          button {
            margin-bottom: 20px;
          }
        }
      }

      .evp-description-text {

        p {

          @include mixins.paragraph ( // The followings were commented when fixing Bug 466412
            // But according to the latest UX design, I restored them because they are correct.
            $fontfamily: variables.$main-font-light,
            $fontsize: variables.$text-size-xl,
            $lineheight: 28px,
            $margin: 0px 0px 20px 0px);

          @media only screen and (max-width: variables.$site-body-width-L) {
            @include mixins.paragraph ($fontfamily: variables.$main-font-light,
              $fontsize: variables.$text-size-lg,
              $lineheight: 24px);
          }

          @media only screen and (max-width: variables.$site-body-width-S) {
            @include mixins.paragraph;
            margin-bottom: 0px;
          }
        }
      }

      #row-0 {
        margin-top: 0;

        @media only screen and (max-width: variables.$site-body-width-L) {
          margin-top: 41px;
          margin-bottom: 34px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-top: 16px;
          margin-bottom: 20px;
        }
      }

      #row-1 {
        margin-top: -23px;

        @media only screen and (max-width: variables.$site-body-width-L) {
          margin-top: 0
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-top: 0;
        }
      }


      #row-2 {
        margin-top: -52px;

        @media only screen and (max-width: variables.$site-body-width-L) {
          margin-top: -13px;
          margin-bottom: 34px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-top: 20px;
          margin-bottom: 0px;
        }
      }

      #img-0 {
        background-image: url('/assets/images/home/shared-container-backgrounds/photobg1.svg');
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 70px 0 36px 28px;

        @media only screen and (max-width: variables.$site-body-width-L) {
          padding-top: 39px;
          padding-left: 17px;
          padding-bottom: 20px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 16px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      #img-1 {
        background-image: url('/assets/images/home/shared-container-backgrounds/photobg2.svg');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 67px 0 52px 57px;

        @media only screen and (max-width: variables.$site-body-width-L) {
          padding-top: 18px;
          padding-left: 23px;
          padding-bottom: 35px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 16px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      #img-2 {
        background-image: url('/assets/images/home/shared-container-backgrounds/photobg3.svg');
        background-repeat: no-repeat;
        background-size: contain;
        padding: 80px 0 92px 0px;
        background-position: 28px 0px;

        @media only screen and (max-width: variables.$site-body-width-L) {
          padding-top: 50px;
          padding-bottom: 35px;
        }

        @media only screen and (max-width: variables.$site-body-width-S) {
          margin-bottom: 16px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      img.card-img {
        border-radius: 12px;
      }
    }
  }
}

#home .evp-content-sleeve-outer {
  background-position-x: 0px, 95%;
  background-position-y: 165px, 46%;
}

#product .evp-content-sleeve-outer {
  background-size: auto, auto;
  background-position: 0px 165px, 95% 46%;
}