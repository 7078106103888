@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.checkout-tc {
  .modal-content {
    margin-top: 0px !important;

    .benefit-term-condition {
      .modal-header {
        padding: 0px;

        button.close {
          .fal {
            color: variables.$icon-color-one;
          }
        }
      }

      .progress {
        height: 8px;
        border-radius: 4px;
        margin: 0px 0px 4px;
      }

      .progress-bar {
        background-color: variables.$feedback-color-success-darker;
      }

      .edit-state {
        padding: 32px;
        background-color: variables.$bg-color-gray07;

        .body-container {
          .term-details {
            @media screen and (max-width:variables.$site-body-width-S) {
              height: unset;
            }

            height: calc(45vh - 40px);
            overflow-y: auto;
            overflow-x: hidden;
          }

          .divider {
            background-color: variables.$bg-color-gray04;
          }

          input[type=checkbox]+label:before {
            border-radius: 2px;
          }

          .content-underline {
            text-decoration: underline !important;
          }
        }
      }
    }

  }
}