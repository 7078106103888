@use '../3-helpers/variables';


.byl-content-sleeve-outer {

  .page-content-sleeve {
    background-image: url('/assets/images/product/brands/backgrounds/partner-bkgd-shape.svg');
    background-repeat: no-repeat;
    background-position: 100% 50%;

    @media only screen and (max-width: variables.$site-body-width-S) {
      background-position: left 100px top 40px;
    }

    .byl-container {

      .row {
        align-items: center;
        justify-content: space-between;

        .text-wrapper {

          p {
            margin-bottom: 40px;

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-bottom: 20px;
            }
          }

          button {

            @media only screen and (max-width: variables.$site-body-width-S) {
              margin-top: 0px;
              margin-bottom: 32px;
              width: 100%;
            }
          }
        }

        .logo-wrapper {
          text-align: right;

          @media only screen and (max-width: variables.$site-body-width-XL) {
            text-align: center;
          }

          .logo {
            width: 100%;
            height: 80px;
            background-size: contain;
            background-repeat: no-repeat;
          }

          img {
            padding-bottom: 20px;
            padding-right: 20px;

            &:nth-child(4n) {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }
}