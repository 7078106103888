@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.marketing-unsubscribe {
  .image-block {
    background-image: url('/assets/images/unsubscribe.svg');
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content-block {
    padding-left: 20px;

    h1 {
      font-size: 48px !important;
      line-height: 64px;
    }

    p.lg {
      font-size: 22px !important;
      line-height: 36px;
    }
  }
}