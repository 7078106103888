@use '../3-helpers/variables';


//Each page provides its own container block specifications at app level.
//IE, the product cards themselves will be defined globally but their container may have different dimensions by consuming application or even page.

.app-content-sleeve {

  .page {

    &#profile {

      .w-tab-menu {
        margin-bottom: 20px;
      }

      .page-content-sleeve {

        &.active-benefits {
          padding-top: 40px;
          padding-bottom: 150px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 40px;
          }
        }

        &.active-benefits-plan {
          padding-top: 40px;
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 60px;

            .active-benefits-plan-container {
              .w-tab-menu {
                &:not(.active-benefits-nav-tabs) {
                  .w-tab-link {
                    padding: 8px 0 0;
                  }
                }
              }
            }
          }
        }

        &.personal-info {
          padding-top: 40px;
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }


        &.dependents-and-beneficiaries-info {
          padding-top: 40px;
          padding-bottom: 150px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 60px;
          }
        }


        &.documents {
          padding-top: 40px;
          padding-bottom: 130px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 40px;
          }
        }

        &.saved-quotes {
          padding-top: 40px;
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        &.payment-transactions {
          padding-top: 40px;
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 20px;
          }
        }

        &.security-info {
          padding-top: 40px;
          padding-bottom: 130px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 40px;
          }
        }

        &.mid-year-changes-info {
          padding-top: 40px;
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-top: 20px;
            padding-bottom: 40px;
          }
        }
      }

      .benefits-card {
        margin: 10px;
      }
    }
  }
}

