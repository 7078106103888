@use "../3-helpers/variables";
@use "../3-helpers/mixins";

.page {

  &#article-dashboard {

    .progress-crumb {
      label {
        display: block;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .article-dashboard-hero {
      padding-bottom: 40px;

      @media only screen and (max-width: variables.$site-body-width-S) {
        padding-bottom: 20px;
      }
    }

    .page-content-sleeve {
      &.article-dashboard-breadcrumb {
        padding-top: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
        }
      }

      &.article-dashboard-features {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.article-dashboard-coverage {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }


      &.articles-widget {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      &.article-dashboard-suggested-benefits {
        padding-top: 40px;
        padding-bottom: 40px;

        @media only screen and (max-width: variables.$site-body-width-S) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }
    }

    .sleeve-outer {
      &:last-child {
        .page-content-sleeve {
          padding-bottom: 120px;

          @media only screen and (max-width: variables.$site-body-width-S) {
            padding-bottom: 60px;
          }
        }
      }
    }
  }
}