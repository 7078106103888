@use '../3-helpers/variables';
@use '../3-helpers/mixins';

.pcm-content-sleeve-outer {



  &.inverse {
    min-height: unset;

    .product-compare-container {


      .product-compare-content {
        .product-data {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .compare-info {
          margin-right: 66px;

          .pcm-abs-img-1 {
            @include mixins.inline-image($left: -144px, $top: -80px);

            @media only screen and (max-width: variables.$site-body-width-S) {
              @include mixins.inline-image($left: -10px, $top: -100px);
            }
          }
        }

        .button-container {
          width: unset;

          .pcm-abs-img-2 {
            @include mixins.inline-image($right: -166px, $top: 40px);

            @media only screen and (max-width: variables.$site-body-width-S) {
              @include mixins.inline-image($right: -5px, $top: 66px);
            }
          }
        }
      }

      @media screen and (max-width:570px) {

        .product-compare-content {
          .compare-info {
            margin-right: 0px;
          }

          .button-container {
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}