@use '../3-helpers/variables';

//START GENERAL SCAFFOLDING - SHARED STYLING FOR ALL BREAKPOINTS


//HERO PHOTO CONTAINER
.product-intro {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 480px;
  max-height: 687px;
  overflow: hidden;

  @media only screen and (max-width: variables.$site-body-width-XL) {
    min-height: 420px;
  }

  @media only screen and (max-width: variables.$site-body-width-L) {
    min-height: 340px;
  }

  @media only screen and (max-width: variables.$site-body-width-S) {
    background-size: contain;
    padding-top: 144px;
    max-height: unset;
  }

  //BACKGROUND SVG CONTAINER
  .product-intro-sleeve {
    background-image: url('/assets/images/home/hero/backgrounds/herobg-xlarge.svg');
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 687px;

    .break-line {
      background-color: variables.$app-hero-divider-color;
      margin-top: 20px;
      margin-bottom: 20px;

      @media only screen and (max-width: variables.$site-body-width-XXL) {
        background-color: variables.$app-hero-background-color-one;
      }
    }

    .product-intro-text {
      padding-left: 0px;
      padding-right: 0px;


      .hero-subheader {
        color: variables.$app-hero-subheader-text-color;
        font-weight: variables.$main-font-weight-bold;
      }

      @media only screen and (max-width: variables.$site-body-width-S) {
        .product-title {
          max-width: 275px;
          width: 100%;
        }
      }

      .products-sleeve {
        margin: 0px;

        .product {
          padding-left: 0px;
          padding-right: 0px;

          .product-contents-sleeve {
            background-repeat: no-repeat;
            background-position: 0px;
            padding: 10px 0px 10px 32px;
            cursor: pointer;

            &.selected,
            &:hover {
              border-radius: 4px;
              background-color: variables.$app-hero-text-hover-color;
              margin-left: -10px;
              padding-left: 42px;
              background-position: 10px;
            }
          }

          &:nth-child(odd) {
            .product-contents-sleeve {
              margin-right: 32px;
            }
          }

          p {

            &.product-name {
              margin: 0px;

              @media only screen and (max-width: 575px) {
                font-size: variables.$text-size-xs;
              }

              a {
                color: variables.$app-hero-product-text-color;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }

      .text-top-section {
        .text-inner {
          .hero-header {
            word-break: break-word;
          }

          p {
            font-family: variables.$main-font-light;

            @media screen and (min-width:variables.$site-body-width-XL) {
              font-size: variables.$text-size-xl !important;
              line-height: variables.$line-height-xl !important;
            }

            @media screen and (min-width:variables.$site-body-width-S) and (max-height:variables.$site-body-width-XL) {
              font-size: variables.$text-size-lg;
              line-height: variables.$line-height-lg;
            }

            >a,
            &:not(.fa-classic, .fa-light, .fa-regular, .fa-solid, .fa-thin, .fal, .far, .fas, .fat, .aon-icon, .strong) {
              font-family: variables.$main-font-light;
            }

          }
        }
      }
    }

    @media screen and (min-width:variables.$site-body-width-S) {
      input.prominent {
        margin-right: 20px;
      }
    }
  }

  //END GENERAL SCAFFOLDING - SHARED STYLING FOR ALL BREAKPOINTS
  //START HOME AND PRODUCT HERO SPECIFICS AND BREAKPOINT VARIATIONS
  &#home-hero,
  &#product-hero {


    @media only screen and (min-width: variables.$site-body-width-XL) {
      .product-intro-sleeve {
        overflow: hidden;
        width: 656px;
        margin-left: 100px;
        padding: 60px 86px 60px 90px;
        min-height: 480px;

        .product-intro-text {
          width: 480px;
        }
      }
    }



    @media only screen and (max-width: variables.$site-body-width-XL) {
      background-position: top right;

      .product-intro-sleeve {
        overflow: hidden;
        background-position-x: -80px;
        padding: 40px 0 40px 40px;
        width: 656px;
        min-height: 420px;

        .product-intro-text {
          width: 430px;
        }
      }
    }

    @media only screen and (max-width: variables.$site-body-width-L) {

      .product-intro-sleeve {
        background-size: 992px;
        min-height: 340px;

        .product-intro-text {

          .text-top-section {

            h2 {
              color: variables.$app-hero-subheader-text-color;
            }
          }
        }
      }
    }



    @media only screen and (max-width: variables.$site-body-width-S) {


      .product-intro-sleeve {
        background-image: url('/assets/images/home/hero/backgrounds/herobg-xsmall.svg');
        background-position: top;
        padding: 44px 20px 20px 20px;
        width: 100%;
        max-height: unset;

        .product-intro-text {
          width: 100%;

          .text-top-section {
            h2 {
              color: variables.$app-hero-subheader-text-color;
              margin-bottom: 8px;
              width: 70%;
            }

            .text-inner {
              padding-top: 20px;
            }
          }

          .hero-subheader {
            margin-bottom: 6px !important;
          }
        }

        button {
          &.prominent {
            width: 100%;
          }
        }
      }
    }
  }

  //END HOME AND PRODUCT HERO SPECIFICS AND BREAKPOINT VARIATIONS
}

.home-hero {
  .text-bottom-section-category {
    .hero-subheader {
      color: variables.$app-hero-product-text-color !important;
      font-weight: variables.$main-font-weight-bold;
    }

    .basic-card {
      min-height: 100%;
      border-radius: 4px;
      padding: 20px;

      @media screen and (max-width:variables.$site-body-width-S) {
        padding: 16px 20px !important;
      }

      .category-names {
        @media screen and (max-width:variables.$site-body-width-S) {
          flex-direction: row;
          align-items: center;
        }

        flex-direction: column;
      }

      h5 {
        color: variables.$header-color-four;
        cursor: pointer;
      }
    }

    .category-icon {
      background-repeat: no-repeat;
      min-height: 36px;
      min-width: 36px;
      cursor: pointer;

      @media screen and (max-width:variables.$site-body-width-S) {
        margin-bottom: 0px;
        margin-right: 8px;
      }

      margin-bottom: 8px;
    }

    .product-card-left-margin {
      @media screen and (min-width:variables.$site-body-width-S) {
        margin-left: -10px;
      }

    }

    .product-card-top-margin {
      @media screen and (max-width:variables.$site-body-width-S) {
        margin-top: 10px;
      }
    }
  }

  h6 {
    color: variables.$text-color-gray02;
  }
}