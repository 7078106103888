@use '../3-helpers/variables';


.pi-content-sleeve-outer {


  .personal-info {

    .profile-personal-container {

      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .basic-card {
        padding: 32px;
        margin-bottom: 20px;

        &.edit-state {
          padding: 20px;
          margin-bottom: 0px;
        }

        .item {

          .alert {
            @media only screen and (min-width:variables.$site-body-width-S) {
              max-width: variables.$form-control-max-width;
            }
          }
        }

        .address-section,
        .phone-section {
          .form-group {
            &:last-child {
              margin-bottom: 20px;
            }
          }
        }

        .fa-info-circle {
          color: variables.$profile-page-info-icon-color;
        }


        @media only screen and (max-width:variables.$site-body-width-S) {



          p.lg {
            font-weight: variables.$main-font-weight-normal;
            font-size: 16px !important;
            line-height: 24px;
            margin-bottom: 16px !important;
          }
        }
      }
    }
  }
}
